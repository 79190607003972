import React, { useEffect, useState } from 'react';
import { Container, Tabs, Title, Paper, Text } from '@mantine/core';
import EmployeeManagementTab from './EmployeeManagementTab';
import PracticeInfoTab from './PracticeInfoTab';
import PaymentInformationTab from './PaymentInformationTab';
import { OrganizationProvider } from './../InsurancePortal/OrganizationContext';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ButtonContainer,
  ClinicRegistrationStepper,
  CompleteStepButton,
  NextStepButton,
  PrevStepButton,
  StepperProvider,
  useStepper,
} from './RegistrationSteppers';
import { subMenuList } from '../menu.config';
import { ModalsProvider } from '@mantine/modals';
import useHttpClient from '../hooks/useHttpClient';
import { Organization } from '../../types/organization.types';
import ConfirmAddEmployeesModal from './ConfirmAddEmployeesModal';
import { notifications } from '@mantine/notifications';
import EmployeeApprovalTab from './EmployeeApprovalTab';
import ThankYouModal from './ThankYouModal';

const InsuranceAdminPortal: React.FC = () => {
  const location = useLocation();
  const currentTab = location.hash.substring(1) || 'practice-info';
  const httpClient = useHttpClient();

  const { enabled, markStepAsDone, activeStep, subMenu } = useStepper();
  const [activeTab, setActiveTab] = useState<string>(currentTab);
  const [isPracticeInfoComplete, setIsPracticeInfoComplete] = useState(false);
  const [isPaymentInfoComplete, setIsPaymentInfoComplete] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [organization, setOrganization] = useState<Organization>();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isUpdatingEnrollmentStatus, setIsUpdatingEnrollmentStatus] =
    useState(false);
  const [userMetadata, setUserMetadata] = useState<any>(null);
  const navigate = useNavigate();

  const checkFieldsComplete = async () => {
    const validateForm = (infoComplete) => {
      if (infoComplete) {
        markStepAsDone(activeTab);
        return true;
      } else {
        markStepAsDone(activeTab, false);
        return false;
      }
    };

    if (activeTab === 'employees') {
      markStepAsDone(activeTab);
    }
    if (activeTab === 'employees_approval') {
      markStepAsDone(activeTab);
    }
    if (activeTab === 'practice-info') {
      return validateForm(isPracticeInfoComplete);
    }
    if (activeTab === 'payment-information') {
      return validateForm(isPaymentInfoComplete);
    }
    return true;
  };

  const fetchMetadata = async () => {
    try {
      const { data } = await httpClient.get(`/api/user/metadata`);
      setUserMetadata(data.metadata);
    } catch (error) {
      console.error('Error fetching metadata:', error);
    }
  };

  useEffect(() => {
    fetchMetadata();
  }, [httpClient, currentTab, markStepAsDone]);

  useEffect(() => {
    setActiveTab(currentTab);

    if (currentTab === 'next-steps') {
      markStepAsDone('next-steps');
    }
  }, [currentTab]);

  const handleOpenModal = () => {
    setModalOpened(true);
  };

  const validationCallback = async () => {
    const hasPassedValidation = await checkFieldsComplete();
    if (hasPassedValidation) {
      setActiveTab(currentTab);
    }
    return hasPassedValidation;
  };

  const handleUpdateEnrollmentStatus = async () => {
    setIsUpdatingEnrollmentStatus(true);
    try {
      // Update organization enrollment status
      const updateStatusResponse = await httpClient.put(
        '/api/organization/enrollmentstatus/update',
        {
          status: 'Employees Entered',
        },
      );

      if (updateStatusResponse.status === 200) {
        // Update user metadata
        const updatedMetadata = {
          ...userMetadata,
          hasConfirmedEmployees: true,
        };

        // Make sure we're sending the correct structure
        const metadataResponse = await httpClient.put(`/api/user/metadata`, {
          userMetadata: updatedMetadata,
        });

        if (metadataResponse.status === 200) {
          setUserMetadata(updatedMetadata);
          markStepAsDone('employees', true);

          notifications.show({
            title: 'Success',
            message: updateStatusResponse.data.message,
            color: 'green',
          });

          setIsUpdatingEnrollmentStatus(false);
          setIsConfirmationModalOpen(false);
          window.location.hash = 'employees_approval';
          setActiveTab('employees_approval');
        } else {
          throw new Error('Failed to update metadata');
        }
      }
    } catch (e) {
      console.error('Error:', e);
      notifications.show({
        title: 'Error',
        message: 'Error updating enrollment status',
        color: 'red',
      });
      setIsUpdatingEnrollmentStatus(false);
    }
  };

  useEffect(() => {
    setActiveTab(currentTab);

    httpClient.get(`/api/organization/`).then((res) => {
      setOrganization(res.data);
    });
  }, [currentTab, httpClient]);

  // #TODO: send email notification to org when their employees are 10 or more
  // useEffect(() => {
  //   if (activeTab === 'employees' && organization?.estimatedemployees >= 10) {
  //     httpClient
  //       .get(`/api/organization/notify`, { params: { id: organization.id } })
  //       .then((res) => {
  //         console.log(res);
  //       });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [activeTab, organization]);

  console.log('activeTab', activeTab);
  return (
    <OrganizationProvider>
      <Container size="xl" style={{ marginTop: '0', paddingTop: '0' }}>
        <ButtonContainer>
          <PrevStepButton enabled={enabled} />

          <ClinicRegistrationStepper />

          {/* {activeStep < subMenu.length - 1 ? ( */}
          {activeStep < subMenu.length ? (
            <ModalsProvider>
              {activeTab === 'next-steps' ? (
                <CompleteStepButton
                  enabled={enabled}
                  onClick={() => {
                    markStepAsDone(activeTab);
                    handleOpenModal();
                  }}
                />
              ) : (
                <NextStepButton
                  enabled={enabled && activeTab !== 'next-steps'}
                  validationCallback={validationCallback}
                  onClick={async () => {
                    if (
                      activeTab === 'employees' &&
                      !userMetadata?.hasConfirmedEmployees
                    ) {
                      setIsConfirmationModalOpen(true);
                      return false;
                    }
                    return true;
                  }}
                />
              )}
            </ModalsProvider>
          ) : (
            <CompleteStepButton
              enabled={enabled}
              onClick={() => {
                markStepAsDone(activeTab);
                handleOpenModal();
              }}
            />
          )}
        </ButtonContainer>

        <ThankYouModal
          opened={modalOpened}
          onClose={() => {
            setModalOpened(false);
            navigate('/insurance/clinic/dashboard');
          }}
          title="Thank you!"
          title2="You have completed your information"
          description="You will receive an email from us when your next step is ready."
        />

        <ConfirmAddEmployeesModal
          opened={isConfirmationModalOpen}
          onClose={() => setIsConfirmationModalOpen(false)}
          onSubmit={handleUpdateEnrollmentStatus}
          isAdding={isUpdatingEnrollmentStatus}
        />
        {/* <Title style={{ textAlign: 'center', marginBottom: '2rem' }}>
          Clinic Portal
        </Title> */}

        {/* <InsuranceMemberProvider>
          <CompleteRegistrationModal
            opened={modalOpened}
            onClose={() => setModalOpened(false)}
          />
        </InsuranceMemberProvider> */}

        <Tabs value={activeTab} keepMounted={false}>
          <Tabs.Panel value="practice-info">
            <PracticeInfoTab
              onFieldsCompleteChange={setIsPracticeInfoComplete}
            />
          </Tabs.Panel>

          <Tabs.Panel value="employees">
            <EmployeeManagementTab />
          </Tabs.Panel>

          <Tabs.Panel value="employees_approval">
            <EmployeeApprovalTab />
          </Tabs.Panel>

          <Tabs.Panel value="next-steps">
            <Title order={1} style={{ textAlign: 'center' }}>
              Employee Information Submitted
            </Title>
            <br />
            <Paper p="lg" withBorder mt="md">
              <Text size="md">
                Thank you for entering your employee information and asking your
                employees to update their information.
              </Text>
              <Text size="md">
                Once underwriting is completed, we will deliver you unique plan
                options for your practice. Your next step will be to choose the
                plan options that are right for you.
              </Text>
              <Text size="md">
                You will receive an email from us when your next step is ready.
                If you have any questions please reach out to us at
                support@ryzehealth.com
              </Text>
            </Paper>
          </Tabs.Panel>
          <Tabs.Panel value="payment-information">
            <PaymentInformationTab
              isMember={false}
              onFieldsCompleteChange={setIsPaymentInfoComplete}
            />
          </Tabs.Panel>
        </Tabs>
      </Container>
    </OrganizationProvider>
  );
};

const InsuranceAdminPortalWrapped: React.FC = () => {
  let subMenu = subMenuList.clinic.menu;

  return (
    <StepperProvider totalSteps={3} subMenu={subMenu}>
      <Container size="xl" style={{ marginTop: '2rem' }}>
        <InsuranceAdminPortal />
      </Container>
    </StepperProvider>
  );
};

export default InsuranceAdminPortalWrapped;
