import { Group, Button, Title } from '@mantine/core';
import PracticeReferralForm from './PracticeReferralForm';
import useHttpClient from '../hooks/useHttpClient';
import { useState } from 'react';
import { notifications } from '@mantine/notifications';
import axios from 'axios';
import { useForm } from 'react-hook-form';

function PracticeReferralScreen({
  currentStep,
  answers,
  setAnswers,
  next,
  back,
}) {
  const hooks = useForm({
    defaultValues: {},
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const key = currentStep + '.referral';

  const [referral, setReferral] = useState({});
  const [loading, setLoading] = useState(false);
  const httpClient = useHttpClient();

  const submitReferral = async (data) => {
    try {
      setLoading(true);
      const response = await axios.post(
        process.env.REACT_APP_API_URL + '/api/organization/referral',
        data,
      );

      if (response.status === 200 || response.status === 201) {
        notifications.show({
          title: 'Success',
          message: 'Referral info submitted successfully',
          color: 'green',
          position: 'bottom-center',
        });

        next();
      }
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to submit referral info: ' + error?.toString(),
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Title order={2} mb="md" ta="center">
        Practice Referral Form
      </Title>

      <PracticeReferralForm hooks={hooks} />

      <Group justify="flex-end" mt="xl">
        <Button
          variant="default"
          onClick={async () => {
            await setAnswers((answers) => {
              delete answers.referral;
              delete answers[key];
              return { ...answers };
            });
            back();
          }}
          disabled={loading}
        >
          Back
        </Button>
        <Button
          onClick={async () => {
            const referral = hooks.getValues();
            if (await hooks.trigger()) {
              await submitReferral(referral);
            }
          }}
          disabled={loading}
        >
          Next
        </Button>
      </Group>
    </>
  );
}

export default PracticeReferralScreen;
