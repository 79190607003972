import React, { useEffect } from 'react';
import { Modal, Text, Title } from '@mantine/core';
import useHttpClient from '../hooks/useHttpClient';

interface ThankYouModalProps {
  opened: boolean;
  onClose: () => void;
  title: string;
  title2?: string;
  description: React.ReactNode;
  description2?: React.ReactNode;
}

const ThankYouModal: React.FC<ThankYouModalProps> = ({
  opened,
  onClose,
  title,
  title2,
  description,
  description2,
}) => {
  const httpClient = useHttpClient();
  useEffect(() => {
    const updateLeadStatus = async () => {
      if (opened) {
        try {
          await httpClient.put('/api/subscriber/update-lead-status', {
            status: 'Plan Selected',
          });
        } catch (error) {
          console.error('Error updating lead status:', error);
        }
      }
    };

    updateLeadStatus();
  }, [opened]);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size={'lg'}
      centered
      styles={{
        header: {
          backgroundColor: '#ebe9fa', // Set your custom background color here
        },
        body: {
          backgroundColor: '#ebe9fa', // Set your custom background color here
        },
        content: {
          backgroundColor: '#ebe9fa', // Set your custom background color here
        },
      }}
    >
      <Modal.Title
        style={{
          textAlign: 'center',
        }}
      >
        <Title
          order={1}
          fw={'bold'}
          c="#3f29cd"
          style={{ textAlign: 'center' }}
        >
          {title}
        </Title>
        <Title
          order={2}
          fw={'bold'}
          c="#3f29cd"
          style={{ textAlign: 'center' }}
        >
          {title2}
        </Title>
      </Modal.Title>
      <Modal.Body
        style={{
          textAlign: 'center',
        }}
      >
        <Text size="md" fw={500} style={{ textAlign: 'center' }}>
          {description}
        </Text>
        <Text size="md" fw={500} style={{ textAlign: 'center' }}>
          {description2}
        </Text>
      </Modal.Body>
    </Modal>
  );
};

export default ThankYouModal;
