import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Group,
  Collapse,
  Paper,
  Grid,
  Input,
  PasswordInput,
} from '@mantine/core';
import { IMaskInput } from 'react-imask';
import { notifications } from '@mantine/notifications';
import useHttpClient from './hooks/useHttpClient';
import { STATES_ARRAY } from '../types/members.types';
import { useFormDirtyAlert } from '../helpers/useFormDirtyAlert';
import { loadScript } from '../helpers/loadScript';

type FormValues = {
  caqhnumber: string;
  caqhuserid: string;
  caqhpassword: string;
  credentialingContacts: CredentialingContactFields[];
};

type CredentialingContactFields = {
  id?: number; // Ensure ID is included for updates
  firstname: string;
  middlename: string;
  lastname: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone: string;
  fax: string;
  email: string;
  primarycontact: string;
  locationtype: string;
};

const states = STATES_ARRAY;

interface FormProps {
  memberId: number;
}

const CredentialingContactForm: React.FC<FormProps> = ({ memberId }) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isDirty, dirtyFields },
  } = useForm<FormValues>({});

  useFormDirtyAlert(reset, isDirty, dirtyFields);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'credentialingContacts',
  });

  const [collapsedIndices, setCollapsedIndices] = useState<boolean[]>([]);
  const [initialData, setInitialData] = useState<any>();
  const [showCAQHPassword, setShowCAQHPassword] = useState(false);
  const [addressPopulated, setAddressPopulated] = useState<boolean[]>([]);

  const httpClient = useHttpClient();

  const locationAddressRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handlePlaceSelect = useCallback(
    async (autocomplete: google.maps.places.Autocomplete, index: number) => {
      if (!autocomplete) return;

      const place = autocomplete.getPlace();
      if (!place.address_components) return;

      setValue(`credentialingContacts.${index}.street1`, '');
      setValue(`credentialingContacts.${index}.street2`, '');
      setValue(`credentialingContacts.${index}.city`, '');
      setValue(`credentialingContacts.${index}.state`, '');
      setValue(`credentialingContacts.${index}.zip`, '');

      let streetNumber = '';
      let route = '';
      let street2 = '';
      let city = '';
      let state = '';
      let zipCode = '';

      place.address_components?.forEach((component) => {
        const types = component.types;
        if (types.includes('street_number')) streetNumber = component.long_name;
        if (types.includes('route')) route = component.long_name;
        if (types.includes('subpremise')) street2 = component.long_name;
        if (types.includes('locality')) city = component.long_name;
        else if (types.includes('sublocality')) city = component.long_name;
        if (types.includes('administrative_area_level_1'))
          state = component.short_name;
        if (types.includes('postal_code')) zipCode = component.long_name;
      });

      const street1 = `${streetNumber} ${route}`.trim();
      if (street1) {
        setAddressPopulated((prev) => {
          const newState = [...prev];
          newState[index] = true;
          return newState;
        });
      }

      setValue(`credentialingContacts.${index}.street1`, street1);
      setValue(`credentialingContacts.${index}.street2`, street2);
      setValue(`credentialingContacts.${index}.city`, city);
      setValue(`credentialingContacts.${index}.state`, state);
      setValue(`credentialingContacts.${index}.zip`, zipCode);
      setValue(`credentialingContacts.${index}.country`, 'US');
    },
    [setValue],
  );

  const resetAddress = (index: number) => {
    setAddressPopulated((prev) => {
      const newState = [...prev];
      newState[index] = false;
      return newState;
    });
    if (locationAddressRefs.current[index]) {
      locationAddressRefs.current[index]!.value = '';
    }
  };

  useEffect(() => {
    setAddressPopulated((prev) => {
      const newState = [...prev];
      while (newState.length < fields.length) {
        newState.push(false);
      }
      return newState.slice(0, fields.length);
    });
  }, [fields.length]);

  useEffect(() => {
    const autocompletes: (google.maps.places.Autocomplete | null)[] = [];

    const initAutocomplete = () => {
      locationAddressRefs.current.forEach((ref, index) => {
        if (ref && window.google) {
          const autocomplete = new window.google.maps.places.Autocomplete(ref, {
            types: ['address'],
            componentRestrictions: { country: 'us' },
            fields: [
              'address_components',
              'formatted_address',
              'geometry',
              'name',
            ],
          });

          autocomplete.addListener('place_changed', () => {
            handlePlaceSelect(autocomplete, index);
          });

          autocompletes[index] = autocomplete;
        }
      });
    };

    const googleMapsScriptUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;

    if (!window.google) {
      loadScript(googleMapsScriptUrl)
        .then(() => {
          initAutocomplete();
        })
        .catch((err) =>
          console.error('Failed to load Google Maps script:', err),
        );
    } else {
      initAutocomplete();
    }

    return () => {
      autocompletes.forEach((autocomplete) => {
        if (autocomplete) {
          google.maps.event.clearInstanceListeners(autocomplete);
        }
      });
    };
  }, [fields.length, watch, handlePlaceSelect]);

  const fetchCredentialingContacts = async () => {
    console.log('Fetching credentialing contacts for memberId:', memberId); // Debug statement
    try {
      const contactsResponse = await httpClient.get(
        `/api/credentialingcontacts/${memberId}`,
      );
      console.log(
        'Credentialing contacts response status:',
        contactsResponse.status,
      ); // Debug statement

      const fetchedContacts = contactsResponse.data;
      console.log('Fetched credentialing contacts:', fetchedContacts); // Debug statement

      const sortedContacts = fetchedContacts.sort((a: any, b: any) => {
        const lastNameComparison = a.lastname.localeCompare(b.lastname);
        if (lastNameComparison !== 0) return lastNameComparison;
        const firstNameComparison = a.firstname.localeCompare(b.firstname);
        if (firstNameComparison !== 0) return firstNameComparison;
        return a.id - b.id;
      });

      const caqhResponse = await httpClient.get(`/api/caqh/${memberId}`);
      console.log('CAQH response status:', caqhResponse.status); // Debug statement

      let fetchedCAQH = { caqhnumber: '', caqhuserid: '', caqhpassword: '' };

      if (caqhResponse.data) {
        fetchedCAQH = caqhResponse.data;
      }
      console.log('Fetched CAQH:', fetchedCAQH); // Debug statement

      reset({ credentialingContacts: sortedContacts, ...fetchedCAQH });
      setInitialData({
        credentialingContacts: sortedContacts,
        ...fetchedCAQH,
      });
      setCollapsedIndices(sortedContacts.map(() => false));
    } catch (error) {
      console.error('Error fetching credentialing contacts:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch credentialing contacts',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  useEffect(() => {
    if (memberId) {
      fetchCredentialingContacts();
    }
  }, [memberId]);

  const toggleCollapse = (index: number) => {
    setCollapsedIndices((prev) => {
      const newCollapsedIndices = [...prev];
      newCollapsedIndices[index] = !newCollapsedIndices[index];
      return newCollapsedIndices;
    });
  };

  const onSubmit = async (data: FormValues) => {
    console.log('Submit form values:', data); // Debug statement

    const formatData = (items: CredentialingContactFields[]) => {
      return items.map((item) => ({
        ...item,
        memberid: memberId,
      }));
    };

    const formattedData = formatData(data.credentialingContacts);

    const createContact = async (contact: any) => {
      console.log('Creating contact:', contact); // Debug statement
      const response = await httpClient.post(
        '/api/credentialingcontacts',
        contact,
      );

      console.log('Create contact response:', response); // Debug statement
      return response;
    };

    const updateContact = async (contact: any) => {
      console.log('Updating contact:', contact); // Debug statement
      const response = await httpClient.put(
        `api/credentialingcontacts/${contact.id}`,
        contact,
      );

      console.log('Update contact response:', response); // Debug statement
      return response;
    };

    const deleteContact = async (id: number) => {
      console.log('Deleting contact ID:', id); // Debug statement
      const response = await httpClient.delete(
        `/api/credentialingcontacts/${id}`,
      );

      console.log('Delete contact response:', response); // Debug statement
    };

    const createCAQH = async (caqhData: any) => {
      const caqhDataWithMemberId = { ...caqhData, memberid: memberId }; // Ensure memberid is included
      console.log('Creating CAQH:', caqhDataWithMemberId); // Debug statement
      const response = await httpClient.post(`/api/caqh`, caqhDataWithMemberId);

      console.log('Create CAQH response:', response); // Debug statement
      return response;
    };

    const updateCAQH = async (caqhData: any) => {
      const caqhDataWithMemberId = { ...caqhData, memberid: memberId }; // Ensure memberid is included
      console.log('Updating CAQH:', caqhDataWithMemberId); // Debug statement
      const response = await httpClient.put(
        `/api/caqh/${memberId}`,
        caqhDataWithMemberId,
      );

      console.log('Update CAQH response:', response); // Debug statement
      return response;
    };

    const deleteCAQH = async () => {
      console.log('Deleting CAQH for memberId:', memberId); // Debug statement
      const response = await httpClient.delete(`/api/caqh/${memberId}`);
      console.log('Delete CAQH response:', response); // Debug statement
    };

    // Determine deletions, creations, and updates
    const initialIds = new Set<number>(
      initialData?.credentialingContacts.map((c: any) => c.id),
    );
    const currentIds = new Set<number>(formattedData.map((c: any) => c.id));
    const deletedIds = [...initialIds].filter(
      (id): id is number => !currentIds.has(id),
    );

    const createTasks = formattedData
      .filter((c: any) => !c.id || c.id === 0)
      .map((c: any) => createContact(c));
    const updateTasks = formattedData
      .filter((c: any) => c.id && c.id > 0 && initialIds.has(c.id))
      .map((c: any) => updateContact(c));

    const deleteTasks = deletedIds.map((id) => deleteContact(id));

    let caqhTask = null;
    if (data.caqhnumber || data.caqhuserid || data.caqhpassword) {
      caqhTask =
        initialData?.caqhnumber ||
        initialData?.caqhuserid ||
        initialData?.caqhpassword
          ? updateCAQH({
              caqhnumber: data.caqhnumber,
              caqhuserid: data.caqhuserid,
              caqhpassword: data.caqhpassword,
            })
          : createCAQH({
              caqhnumber: data.caqhnumber,
              caqhuserid: data.caqhuserid,
              caqhpassword: data.caqhpassword,
            });
    } else if (
      initialData?.caqhnumber ||
      initialData?.caqhuserid ||
      initialData?.caqhpassword
    ) {
      caqhTask = deleteCAQH();
    }

    try {
      const results = await Promise.all(
        [...createTasks, ...updateTasks, ...deleteTasks, caqhTask].filter(
          Boolean,
        ),
      ); // Filter out null tasks
      console.log('Results:', results); // Debug statement
      fetchCredentialingContacts();

      notifications.show({
        title: 'Success',
        message: 'Credentialing contacts updated successfully',
        color: 'green',
        position: 'bottom-center',
      });
    } catch (error) {
      console.error('Error submitting credentialing contact form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit credentialing contact',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  return (
    <Container>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Paper shadow="xs" p="sm" withBorder>
          <TextInput
            id="caqhnumber"
            label="CAQH Number"
            {...register('caqhnumber')}
          />
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                id="caqhuserid"
                label="CAQH User ID"
                {...register('caqhuserid')}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <PasswordInput
                id="caqhpassword"
                label="CAQH Password"
                visible={showCAQHPassword}
                onVisibilityChange={setShowCAQHPassword}
                {...register('caqhpassword')}
              />
            </Grid.Col>
          </Grid>
        </Paper>

        {fields.map((item, index) => (
          <Paper key={item.id} shadow="xs" p="md" withBorder>
            <Group justify="space-between">
              <Group>
                <h3>Credentialing Contact {index + 1}</h3>
                <Button
                  id={`toggle-collapse-credentialing-contact-${index}`}
                  onClick={() => toggleCollapse(index)}
                  size="xs"
                >
                  {collapsedIndices[index] ? 'Expand' : 'Collapse'}
                </Button>
                <Button
                  id={`remove-credentialing-contact-${index}`}
                  color="red"
                  onClick={() => remove(index)}
                  size="xs"
                >
                  Remove
                </Button>
              </Group>
            </Group>
            <Collapse in={!collapsedIndices[index]}>
              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    id={`credentialingContacts.${index}.firstname`}
                    label="First Name"
                    {...register(`credentialingContacts.${index}.firstname`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    id={`credentialingContacts.${index}.lastname`}
                    label="Last Name"
                    {...register(`credentialingContacts.${index}.lastname`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    id={`credentialingContacts.${index}.middlename`}
                    label="Middle Name"
                    {...register(`credentialingContacts.${index}.middlename`)}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextInput
                    id={`credentialingContacts.${index}.address`}
                    label="Аddress - Start typing the address below and pick one from the dropdown"
                    placeholder="Start by typing here"
                    data-index={index}
                    ref={(el) => (locationAddressRefs.current[index] = el)}
                    onChange={(e) => {
                      if (!e.target.value) {
                        resetAddress(index);
                      }
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    id={`credentialingContacts.${index}.street1`}
                    label={'\u0405treet 1'}
                    {...register(`credentialingContacts.${index}.street1`)}
                    disabled
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    id={`credentialingContacts.${index}.street2`}
                    label={'\u0405treet 2'}
                    {...register(`credentialingContacts.${index}.street2`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    id={`credentialingContacts.${index}.city`}
                    label="City"
                    {...register(`credentialingContacts.${index}.city`)}
                    disabled
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`credentialingContacts.${index}.state`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        id={`credentialingContacts.${index}.state`}
                        {...field}
                        label="State"
                        data={states.map((state) => ({
                          value: state,
                          label: state,
                        }))}
                        clearable
                        searchable
                        disabled
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    id={`credentialingContacts.${index}.zip`}
                    label="ZIP"
                    {...register(`credentialingContacts.${index}.zip`)}
                    maxLength={5}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.target.value = e.target.value.replace(/\D/g, '');
                    }}
                    disabled
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`credentialingContacts.${index}.country`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        id={`credentialingContacts.${index}.country`}
                        {...field}
                        label="Country"
                        data={[{ value: 'US', label: 'United States' }]}
                        clearable
                        searchable
                        disabled
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`credentialingContacts.${index}.phone`}
                    control={control}
                    render={({ field }) => (
                      <Input.Wrapper label="Phone">
                        <Input
                          id={`credentialingContacts.${index}.phone`}
                          data-testid={`credentialingContacts.${index}.phone`}
                          component={IMaskInput}
                          {...field}
                          mask="(000) 000-0000"
                          placeholder="(XXX) XXX-XXXX"
                        />
                      </Input.Wrapper>
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`credentialingContacts.${index}.fax`}
                    control={control}
                    render={({ field }) => (
                      <Input.Wrapper label="Fax">
                        <Input
                          id={`credentialingContacts.${index}.fax`}
                          data-testid={`credentialingContacts.${index}.fax`}
                          component={IMaskInput}
                          {...field}
                          mask="(000) 000-0000"
                          placeholder="(XXX) XXX-XXXX"
                        />
                      </Input.Wrapper>
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    id={`credentialingContacts.${index}.email`}
                    label="Email"
                    {...register(`credentialingContacts.${index}.email`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`credentialingContacts.${index}.primarycontact`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        id={`credentialingContacts.${index}.primarycontact`}
                        {...field}
                        label="Is this your primary credentialing contact?"
                        data={[
                          { value: 'Y', label: 'Yes' },
                          { value: 'N', label: 'No' },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`credentialingContacts.${index}.locationtype`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        id={`credentialingContacts.${index}.locationtype`}
                        {...field}
                        label="Location Type"
                        data={[
                          {
                            value: 'Hospital Affiliation',
                            label: 'Hospital Affiliation',
                          },
                          {
                            value: 'Practice Locations',
                            label: 'Practice Locations',
                          },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
              </Grid>
            </Collapse>
          </Paper>
        ))}
        <Group mt="md">
          <Button
            id="add-credentialing-contact"
            onClick={() =>
              append({
                firstname: '',
                middlename: '',
                lastname: '',
                street1: '',
                street2: '',
                city: '',
                state: '',
                zip: '',
                country: 'US',
                phone: '',
                fax: '',
                email: '',
                primarycontact: '',
                locationtype: '',
              })
            }
          >
            Add Credentialing Contact
          </Button>
        </Group>

        <Group mt="md">
          <Button id="save-credentialing-contact" type="submit">
            Save
          </Button>
        </Group>
      </form>
    </Container>
  );
};

export default CredentialingContactForm;
