import { Title, Text, Group, Button } from '@mantine/core';
import { useState } from 'react';
import { notifications } from '@mantine/notifications';
import useHttpClient from '../hooks/useHttpClient';
import { useForm } from 'react-hook-form';
import PracticeRegistrationForm from './PracticeRegistrationForm';

function PracticeRegistrationScreen({
  currentStep,
  answers,
  setAnswers,
  back,
  next,
}) {
  const key = currentStep + '.practice';
  const [practice, setPractice] = useState({});
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const httpClient = useHttpClient();
  const hooks = useForm({
    defaultValues: {},
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const submitPractice = async (data) => {
    try {
      setLoading(true);

      // Double-check password strength as a safeguard
      const formValues = hooks.getValues() as any;
      const passwordStrengthScore =
        formValues._passwordStrengthScore !== undefined
          ? formValues._passwordStrengthScore
          : 0;
      if (passwordStrengthScore < 3) {
        notifications.show({
          title: 'Weak Password',
          message:
            'Please choose a stronger password (at least "Good" strength).',
          color: 'red',
          position: 'bottom-center',
        });
        setLoading(false);
        return;
      }

      if (data.referralsource === 'Other') {
        data.referralsource = data.referralsourcecustom;
      }
      const { contact: _, ...answersWithoutContact } = answers;
      data.answers = answersWithoutContact;

      const response = await httpClient.post(
        '/api/organization/onboarding',
        data,
      );

      const insuranceForKey =
        Object.keys(data).filter((e) => e.includes('insuranceFor'))[0] || '!';

      if (response.status === 200 || response.status === 201) {
        if (
          data[insuranceForKey]?.['myself'] === true ||
          data[insuranceForKey]?.['family'] === true
        ) {
          await httpClient.post(
            `/api/subscriber/enrollment/add-self/${response.data?.organization?.id}?onboarding=true`,
            {
              firstname: data.practice.adminfirstname,
              lastname: data.practice.adminlastname,
              email: data.practice.email,
            },
          );
        }
        notifications.show({
          title: 'Success',
          message: 'Clinic registered successfully',
          color: 'green',
          position: 'bottom-center',
        });

        next();
      }
    } catch (error) {
      notifications.show({
        title: 'Error',
        message:
          error?.response?.data?.error ||
          'Your registration could not be completed at this time. Please try again later.',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Title order={2} mb="md" ta="center">
        Help Us Verify Your Eligibility
      </Title>
      <Text ml="xl" mr="xl" size="lg">
        To create your account, we need a few details to verify your medical
        practice—this helps us ensure a secure and trusted network of legitimate
        medical practices.
      </Text>

      <PracticeRegistrationForm hooks={hooks} />

      <Group justify="flex-end" mt="xl">
        <Button
          variant="default"
          onClick={async () => {
            setAnswers((answers) => {
              delete answers.practice;
              delete answers[key];
              return { ...answers };
            });
            back();
          }}
          disabled={loading}
        >
          Back
        </Button>
        <Button
          onClick={async () => {
            const practice = hooks.getValues();
            console.log('practice', practice);

            // Check password strength before proceeding
            const formValues = hooks.getValues() as any;
            const passwordStrengthScore =
              formValues._passwordStrengthScore !== undefined
                ? formValues._passwordStrengthScore
                : 0;
            if (passwordStrengthScore < 3) {
              notifications.show({
                title: 'Weak Password',
                message:
                  'Please choose a stronger password (at least "Good" strength).',
                color: 'red',
                position: 'bottom-center',
              });
              return;
            }

            setAnswers((answers) => {
              return { ...answers, [key]: true, practice };
            });

            if (await hooks.trigger()) {
              await submitPractice({ ...answers, [key]: true, practice });
            } else {
              const [key] = Object.keys(hooks.formState.errors);
              notifications.show({
                title: 'Error',
                message: 'Please check the form for errors.',
                color: 'red',
                position: 'bottom-center',
              });
            }
          }}
          disabled={loading}
          loading={loading}
        >
          Complete
        </Button>
      </Group>
    </>
  );
}

export default PracticeRegistrationScreen;
