import React, {
  useEffect,
  useState,
  useCallback,
  createContext,
  useContext,
} from 'react';
import { useParams } from 'react-router-dom';
import { Tabs } from '@mantine/core';
import MemberForm from './MemberForm';
import HospitalForm from './HospitalForm';
import PracticeForm from './PracticeForm';
import CredentialingContactForm from './CredentialingContactForm';
import SpecialtyForm from './SpecialtyForm';
import LiabilityForm from './LiabilityForm';
import EmploymentForm from './EmploymentForm';
import ReferenceForm from './ReferenceForm';
import CertificationForm from './CertificationForm';
import EducationTrainingForm from './EducationTrainingForm';
import DisclosureForm from './DisclosureForm';
import ProfessionalIDsForm from './ProfessionalIDsForm';
import { useMemberContext } from './MemberContext';
import { Member } from '../types/members.types';
import useHttpClient from './hooks/useHttpClient';
import { Title, Container } from '@mantine/core';
import { LoadingOverlay, Box } from '@mantine/core';
import { useLocation } from 'react-router-dom';
import { subMenuList } from './menu.config';
import UploadedDocumentsForm from './UploadedDocumentsForm';

interface MemberPortalProps {
  currentTab?: string;
}

type LoadingContextType = {
  loading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
};
const LoadingContext = createContext<LoadingContextType>({
  loading: false,
  startLoading: () => {},
  stopLoading: () => {},
});
export const useLoading = () => useContext(LoadingContext);

const WecareAdminMemberView: React.FC<MemberPortalProps> = () => {
  const location = useLocation();
  const currentTab = location.hash.substring(1) || 'personal-info';

  const { id } = useParams<{ id: string }>();
  const { isNewMember, memberId, setMemberId, setIsNewMember } =
    useMemberContext();

  const [member, setMember] = useState<Member>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [activeTab, setActiveTab] = useState<string>('personal-info');
  const [loading, setLoading] = useState<boolean>(true);

  const httpClient = useHttpClient();

  const fetchMember = async (id: number) => {
    try {
      const response = await httpClient.get(`/api/members/${id}`);
      const data = await response.data;
      setMember(data);
      setMemberId(id);
    } catch (error) {
      console.error('Error fetching member', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isNewMember && id) {
      console.log('isNewMember', isNewMember, id);
      fetchMember(parseInt(id));
    } else {
      setLoading(false);
    }
  }, [id, isNewMember]);

  const handleMemberCreated = (newMemberId: number) => {
    setIsNewMember(false);
    setMemberId(newMemberId);
  };

  const startLoading = () => setLoading(true);
  const stopLoading = () => {
    console.log('setLoading');
    setLoading(false);
    console.log('stopped');
  };

  const handleTabChange = useCallback(
    (value: string) => {
      if (hasUnsavedChanges && activeTab === 'members') {
        const confirmLeave = window.confirm(
          'You have unsaved changes. Do you really want to leave?',
        );
        if (!confirmLeave) {
          return;
        }
      }
      setActiveTab(value);
    },
    [hasUnsavedChanges, activeTab],
  );

  useEffect(() => {
    setActiveTab(currentTab);
  }, [currentTab]);

  return (
    <LoadingContext.Provider value={{ loading, startLoading, stopLoading }}>
      <Container size="xl">
        <Box style={{ position: 'relative' }}>
          <LoadingOverlay
            id="loading-overlay"
            visible={loading}
            zIndex={1000}
            overlayProps={{ radius: 'sm', blur: 2 }}
          />

          <Title style={{ textAlign: 'center', marginBottom: '2rem' }}>
            {subMenuList.member.menu.filter((m) => m.key === currentTab)[0]
              ?.value || 'Member Portal'}
          </Title>

          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            defaultValue="members"
          >
            <Tabs.Panel value="personal-info">
              {(isNewMember || member) && (
                <MemberForm
                  member={member}
                  setMember={setMember}
                  memberId={memberId}
                  onMemberCreated={handleMemberCreated}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                />
              )}
            </Tabs.Panel>
            <Tabs.Panel value="hospitals">
              <HospitalForm memberId={memberId} />
            </Tabs.Panel>
            <Tabs.Panel value="practices">
              <PracticeForm memberId={memberId} />
            </Tabs.Panel>
            <Tabs.Panel value="credentialingContacts">
              <CredentialingContactForm memberId={memberId} />
            </Tabs.Panel>
            <Tabs.Panel value="specialties">
              <SpecialtyForm memberId={memberId} />
            </Tabs.Panel>
            <Tabs.Panel value="liabilities">
              <LiabilityForm memberId={memberId} />
            </Tabs.Panel>
            <Tabs.Panel value="employment">
              <EmploymentForm memberId={memberId} />
            </Tabs.Panel>
            <Tabs.Panel value="references">
              <ReferenceForm memberId={memberId} />
            </Tabs.Panel>
            <Tabs.Panel value="certifications">
              <CertificationForm memberId={memberId} />
            </Tabs.Panel>
            <Tabs.Panel value="professionalIds">
              <ProfessionalIDsForm memberId={memberId} />
            </Tabs.Panel>
            <Tabs.Panel value="education">
              <EducationTrainingForm memberId={memberId} />
            </Tabs.Panel>
            <Tabs.Panel value="disclosures">
              <DisclosureForm memberId={memberId} />
            </Tabs.Panel>
            <Tabs.Panel value="uploadedDocuments">
              <UploadedDocumentsForm memberId={memberId} />
            </Tabs.Panel>
          </Tabs>
        </Box>
      </Container>
    </LoadingContext.Provider>
  );
};

export default WecareAdminMemberView;
