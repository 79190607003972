import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Title,
  TextInput,
  Button,
  Container,
  Grid,
  PasswordInput,
  Loader,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useParams, useLocation } from 'react-router-dom';
import useHttpClient from '../hooks/useHttpClient';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useNavigate } from 'react-router-dom';
import { getPrivilegeTypeFromPath } from '../../helpers/privilegeHelper';

interface AddUserFormProps {
  initialData?: any;
}

const AddUserForm: React.FC<AddUserFormProps> = ({ initialData }) => {
  const { enrollmentcode } = useParams<{ enrollmentcode: string }>();
  const location = useLocation();
  const [type, setType] = useState<string>('');
  const [formTitle, setFormTitle] = useState<string>('User Account Creation');
  const [isEmployeeForm, setIsEmployeeForm] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: initialData,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const [loading, setLoading] = useState(false);
  const password = watch('password');
  const [passwordStrengthScore, setPasswordStrengthScore] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const httpClient = useHttpClient();

  useEffect(() => {
    // More specific path detection to avoid confusion between similar routes
    // Direct employee registration path: 'insurance/register/employee/:enrollmentcode'
    // Employee user registration path: '/insurance/register/employee/user/:enrollmentcode'
    const pathParts = location.pathname.split('/');
    const isDirectEmployeeRegistration =
      pathParts.includes('employee') &&
      !pathParts.includes('user') &&
      pathParts[pathParts.length - 2] === 'employee';

    setIsEmployeeForm(isDirectEmployeeRegistration);

    // Get the type from the path
    const newType = getPrivilegeTypeFromPath(location.pathname);
    setType(
      newType || (isDirectEmployeeRegistration ? 'insurance_employee' : ''),
    );

    // Set the form title based on the type or path
    if (isDirectEmployeeRegistration) {
      setFormTitle('Employee Registration');
    } else {
      const titles = {
        insurance_physician: 'Physician Account Creation',
        insurance_organization: 'Clinic Portal Account Creation',
        insurance_employee: 'Employee Account Creation',
        wecaremember: 'Wecare Member Account Creation',
        wecareadmin: 'Wecare Admin Account Creation',
        ryzeadmin: 'Ryze Admin Account Creation',
      };
      setFormTitle(titles[newType] || 'User Account Creation');
    }
  }, [location]);

  // Fetch user data using the enrollmentcode
  useEffect(() => {
    const fetchData = async () => {
      if (!enrollmentcode) return;

      try {
        setLoading(true);
        let endpoint;

        if (isEmployeeForm) {
          endpoint = `/api/subscriber/enrollment/${enrollmentcode}`;
        } else {
          // Ensure we have a valid type before constructing the endpoint
          if (!type) {
            console.warn('Type is empty, waiting for type to be set');
            setLoading(false);
            return; // Exit early if type is not set yet
          }
          endpoint = `/api/user/member/${type}/${enrollmentcode}`;
        }

        const { data } = await httpClient.get(endpoint);
        reset(data);
      } catch (error) {
        console.error('Error fetching user data:', error);
        notifications.show({
          title: 'Error',
          message: error?.response?.data || 'Failed to load user data',
          color: 'red',
          position: 'bottom-center',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [enrollmentcode, reset, type, isEmployeeForm]);

  const onSubmit = async (data: any) => {
    if (passwordStrengthScore < 3) {
      notifications.show({
        title: 'Weak Password',
        message:
          'Please choose a stronger password (at least "Good" strength).',
        color: 'red',
        position: 'bottom-center',
      });
      return;
    }

    setLoading(true);
    try {
      const submissionData = {
        ...data,
        passwordConfirmation: undefined,
        email: data.email.toLowerCase(),
        type: isEmployeeForm ? 'employee' : type,
      };

      let endpoint;
      if (isEmployeeForm) {
        endpoint = `/api/subscriber/enrollment/${enrollmentcode}`;
      } else {
        // Ensure we have a valid type before constructing the endpoint
        if (!type) {
          throw new Error('Type is not set. Cannot submit the form.');
        }
        endpoint = `/api/user/member/${enrollmentcode}`;
      }

      const response = await httpClient.put(endpoint, submissionData);

      if (response.status === 200) {
        notifications.show({
          title: 'Success',
          message: 'Successfully registered',
          color: 'green',
          position: 'bottom-center',
        });

        // Navigate based on type
        const basePath = type && type.startsWith('wecare') ? '/wecare' : '';
        navigate(`${basePath}/login`);
      }
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: error?.response?.data || 'Failed to register user',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Title order={2} style={{ textAlign: 'center', marginBottom: '2rem' }}>
        {formTitle}
      </Title>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              label="First Name"
              required
              {...register('firstname', { required: true })}
              disabled={loading} // Disable while loading
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Last Name"
              required
              {...register('lastname', { required: true })}
              disabled={loading} // Disable while loading
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              label="Email"
              readOnly
              required
              {...register('email', {
                required: 'Email is required',
              })}
              disabled={true}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <PasswordInput
              label="Password"
              type="password"
              onVisibilityChange={setShowPassword}
              maxLength={50}
              required
              {...register('password')}
              disabled={loading} // Disable while loading
            />
            <PasswordStrengthBar
              password={password || ''}
              onChangeScore={(score) => setPasswordStrengthScore(score)}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <PasswordInput
              label="Confirm Password"
              required
              disabled={loading}
              visible={showPassword}
              onVisibilityChange={setShowPassword}
              {...register('passwordConfirmation', {
                required: 'Password confirmation is required',
                validate: (value) =>
                  value === password || 'Passwords do not match',
              })}
              error={errors.passwordConfirmation?.message?.toString()}
            />
          </Grid.Col>
        </Grid>
        <Button type="submit" mt="md" disabled={loading}>
          {loading ? (
            <>
              <Loader color="blue" size="xs" mr="xs" />
              Loading...
            </>
          ) : (
            'Register'
          )}
        </Button>
      </form>
    </Container>
  );
};

export default AddUserForm;
