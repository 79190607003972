import { useState, useEffect } from 'react';
import { UnstyledButton, Tooltip, Text, rem, Flex, Badge } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import classes from './DoubleNavbar.module.css';
import { useAuth } from './AuthProvider';
import { User } from 'tabler-icons-react';
import { RyzeAvatar } from './AvatarUploader';
import React from 'react';
import { Member } from '../types/members.types';
import useHttpClient from './hooks/useHttpClient';
import { hasAccess } from '../helpers/protectedComponent';
import { useNotifications } from './NotificationContext';

export function DoubleNavbar({ location, mainMenu = [], subMenu, approvals }) {
  // main menu
  const f = mainMenu.filter((menu) => menu.path == location.pathname)[0];
  const [active, setActive] = useState(f?.label || mainMenu?.[0]?.label);

  const [member, setMember] = useState<Member>(null);
  const httpClient = useHttpClient();

  const navigate = useNavigate();
  const { logoutAuth } = useAuth();
  const auth = useAuth();
  const privileges = auth.getPrivileges();
  const username = privileges[0].username;
  const type = privileges[0].type
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());

  const { pendingApprovals, pendingMemberApprovals, pendingClinicApprovals } =
    useNotifications();

  useEffect(() => {
    const path = mainMenu?.filter((l) => l.path === location?.pathname)[0]
      ?.label;
    setActive(path);
  }, [location]);

  const hasInsurancecPhysicianPrivilege = privileges.some(
    (p) => p.type === 'insurance_physician',
  );

  const mainLinks = mainMenu.map((link, index) => {
    if (link.requiredPrivileges?.length) {
      const privileges_ = privileges.map((p) => p.type);
      const hasAll = link.requiredPrivileges.every((item) =>
        privileges_.includes(item),
      );

      if (!hasAll) {
        return <React.Fragment key={`empty-${index}`}></React.Fragment>;
      }
    }

    // Determine the appropriate notification count based on the path
    let notificationCount = 0;
    if (link.notificationBadge) {
      if (link.path === '/wecare/admin/approve') {
        notificationCount = pendingApprovals;
      } else if (link.path === '/insurance/admin/approve') {
        // Prioritize member approvals, fallback to clinic approvals
        notificationCount =
          pendingMemberApprovals > 0
            ? pendingMemberApprovals
            : pendingClinicApprovals;
      }
    }

    return link.label === 'Signup for Ryze' &&
      hasInsurancecPhysicianPrivilege ? (
      <React.Fragment key={`empty-signup-${index}`}></React.Fragment>
    ) : (
      // in this example, it hides my 'My Profile' if the current logged member is not 'MD' or 'DO'
      // see protectedComponent.ts for more info
      hasAccess(
        link.label,
        member?.providertype,
        member?.employment_status,
      ) && (
        <Tooltip
          label={link.label}
          position="right"
          withArrow
          transitionProps={{ duration: 0 }}
          key={link.label || index}
        >
          <UnstyledButton
            onClick={() => {
              if (link.path === '/logout') {
                logoutAuth();
              } else {
                if (link.external) {
                  window.open(link.path, '_blank', 'noopener,noreferrer');
                } else {
                  navigate(link.path || '/');
                }
              }
            }}
            className={classes.mainLink}
            data-active={link.label === active || undefined}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <link.icon
              style={{
                width: rem(30),
                height: rem(30),
                marginRight: rem(12),
              }}
              stroke={1.5}
            />
            <Text size="sm" style={{ position: 'relative' }}>
              {link.label}
              {link.notificationBadge && notificationCount > 0 && (
                <Badge
                  color="red"
                  size="sm"
                  variant="filled"
                  radius="xl"
                  style={{
                    position: 'absolute',
                    top: '-8px',
                    right: '-27px',
                    minWidth: '18px',
                    height: '17px',
                    padding: '0 6px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {notificationCount}
                </Badge>
              )}
            </Text>
          </UnstyledButton>
        </Tooltip>
      )
    );
  });

  // sub menu
  const { hidden, menu, allowSwitchingBetweenPages = true } = subMenu;
  const hash = location.hash.substring(1);
  const [activeLink, setActiveLink] = useState(hash || menu?.[0]?.key);
  useEffect(() => {
    setActiveLink(hash);
  }, [hash, location.hash]);

  const links =
    !hidden &&
    menu?.map((link) => (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        className={
          allowSwitchingBetweenPages ? classes.link : classes.linkDisabled
        }
        data-active={
          activeLink === link.key ||
          (!activeLink && link.key === menu?.[0]?.key) ||
          undefined
        }
        href="#"
        onClick={(event) => {
          event.preventDefault();
          if (allowSwitchingBetweenPages) {
            setActiveLink(link.key);
            window.location.hash = link.key;
          }
        }}
        key={link.key}
      >
        {link.value}
      </a>
    ));

  const fetchMember = async () => {
    try {
      const response = await httpClient.get(
        `/api/members/${privileges[0].serviceid}`,
      );
      const data = await response.data;
      setMember(data);
    } catch (error) {
      console.error('Error fetching member', error);
    }
  };

  useEffect(() => {
    if (!member) fetchMember();
  }, []);

  return (
    <nav className={classes.navbar}>
      <div className={classes.wrapper}>
        <div className={links?.length ? classes.aside : classes.asideClosed}>
          {mainLinks}
        </div>
        {menu && <div className={classes.main}>{links}</div>}
      </div>
      <div className="classes.userInfo">
        {mainMenu?.length && (
          <Flex className={classes.userInfo} align="center">
            <RyzeAvatar radius="xl" mr={5} memberId={privileges[0].serviceid}>
              <User size={24} />
            </RyzeAvatar>
            <div style={{ width: 'calc(100% - 50px)' }}>
              <Text size="sm">{type}</Text>
              <Text size="xs" color="dimmed" className={classes.username}>
                {username}
              </Text>
            </div>
          </Flex>
        )}
      </div>
    </nav>
  );
}
