import { useRef } from 'react';
import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import { useAuth } from '../AuthProvider';
import { jwtDecode } from 'jwt-decode';

const useHttpClient = (): AxiosInstance => {
  const httpClientRef = useRef<AxiosInstance>();

  const auth = useAuth();

  if (!httpClientRef.current) {
    // Create an instance of Axios
    const httpClient = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });

    // Interceptor to add token to requests
    httpClient.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
          const decodedToken = jwtDecode(token);
          if (decodedToken.exp < new Date().getTime() / 1000) {
            auth.logoutAuth();
          }
        }
        return config;
      },
      (error: AxiosError): Promise<AxiosError> => {
        return Promise.reject(error);
      },
    );

    // Interceptor to handle responses
    httpClient.interceptors.response.use(
      (response: AxiosResponse): AxiosResponse => response,
      (error: AxiosError): Promise<AxiosError> => {
        console.log('response handler error', error.response?.status);
        // Optionally handle certain errors globally
        if (error.response?.status === 401) {
          // Only redirect if we're not on a registration page
          const isRegistrationPage =
            window.location.pathname.includes('/register/');

          if (!isRegistrationPage) {
            console.log('Unauthorized, redirecting to login...');
            localStorage.removeItem('token');

            const currentUrl = window.location.href;
            if (!currentUrl.includes('/login')) {
              if (currentUrl.includes('/wecare')) {
                window.location.href = '/wecare/login';
              } else {
                window.location.href = '/login';
              }
            }
          }
        }
        return Promise.reject(error);
      },
    );

    httpClientRef.current = httpClient;
  }

  return httpClientRef.current!;
};

export default useHttpClient;
