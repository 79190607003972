import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Group,
  Collapse,
  Paper,
  Grid,
  Input,
} from '@mantine/core';
import { IMaskInput } from 'react-imask';
import { notifications } from '@mantine/notifications';
import useHttpClient from './hooks/useHttpClient';
import { STATES_ARRAY } from '../types/members.types';
import CustomDateInput from './CustomDateInput';
import { useFormDirtyAlert } from '../helpers/useFormDirtyAlert';
import { loadScript } from '../helpers/loadScript';

interface FormProps {
  memberId: number;
}

const EmploymentForm: React.FC<FormProps> = ({ memberId }) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty, dirtyFields },
    setValue,
  } = useForm({});

  useFormDirtyAlert(reset, isDirty, dirtyFields);

  const httpClient = useHttpClient();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'employments',
  });

  const [collapsedIndices, setCollapsedIndices] = useState(
    fields.map(() => true),
  );
  const [initialData, setInitialData] = useState<any>();
  const [addressPopulated, setAddressPopulated] = useState<boolean[]>([]);

  const locationAddressRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handlePlaceSelect = useCallback(
    async (autocomplete: google.maps.places.Autocomplete, index: number) => {
      if (!autocomplete) return;

      const place = autocomplete.getPlace();
      if (!place.address_components) return;

      setValue(`employments.${index}.street1`, '');
      setValue(`employments.${index}.street2`, '');
      setValue(`employments.${index}.city`, '');
      setValue(`employments.${index}.state`, '');
      setValue(`employments.${index}.zip`, '');

      let streetNumber = '';
      let route = '';
      let street2 = '';
      let city = '';
      let state = '';
      let zipCode = '';

      place.address_components?.forEach((component) => {
        const types = component.types;
        if (types.includes('street_number')) streetNumber = component.long_name;
        if (types.includes('route')) route = component.long_name;
        if (types.includes('subpremise')) street2 = component.long_name;
        if (types.includes('locality')) city = component.long_name;
        else if (types.includes('sublocality')) city = component.long_name;
        if (types.includes('administrative_area_level_1'))
          state = component.short_name;
        if (types.includes('postal_code')) zipCode = component.long_name;
      });

      const street1 = `${streetNumber} ${route}`.trim();
      if (street1) {
        setAddressPopulated((prev) => {
          const newState = [...prev];
          newState[index] = true;
          return newState;
        });
      }

      setValue(`employments.${index}.street1`, street1);
      setValue(`employments.${index}.street2`, street2);
      setValue(`employments.${index}.city`, city);
      setValue(`employments.${index}.state`, state);
      setValue(`employments.${index}.zip`, zipCode);
      setValue(`employments.${index}.country`, 'US');
    },
    [setValue],
  );

  const resetAddress = (index: number) => {
    setAddressPopulated((prev) => {
      const newState = [...prev];
      newState[index] = false;
      return newState;
    });
    if (locationAddressRefs.current[index]) {
      locationAddressRefs.current[index]!.value = '';
    }
  };

  useEffect(() => {
    setAddressPopulated((prev) => {
      const newState = [...prev];
      while (newState.length < fields.length) {
        newState.push(false);
      }
      return newState.slice(0, fields.length);
    });
  }, [fields.length]);

  useEffect(() => {
    const autocompletes: (google.maps.places.Autocomplete | null)[] = [];

    const initAutocomplete = () => {
      locationAddressRefs.current.forEach((ref, index) => {
        if (ref && window.google) {
          const autocomplete = new window.google.maps.places.Autocomplete(ref, {
            types: ['address'],
            componentRestrictions: { country: 'us' },
            fields: [
              'address_components',
              'formatted_address',
              'geometry',
              'name',
            ],
          });

          autocomplete.addListener('place_changed', () => {
            handlePlaceSelect(autocomplete, index);
          });

          autocompletes[index] = autocomplete;
        }
      });
    };

    const googleMapsScriptUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;

    if (!window.google) {
      loadScript(googleMapsScriptUrl)
        .then(() => {
          initAutocomplete();
        })
        .catch((err) =>
          console.error('Failed to load Google Maps script:', err),
        );
    } else {
      initAutocomplete();
    }

    return () => {
      autocompletes.forEach((autocomplete) => {
        if (autocomplete) {
          google.maps.event.clearInstanceListeners(autocomplete);
        }
      });
    };
  }, [fields.length, watch, handlePlaceSelect]);

  const fetchEmployments = async () => {
    try {
      const response = await httpClient.get(`/api/employment/${memberId}`);
      if (!response.status.toString().startsWith('2')) {
        throw new Error('Failed to fetch employments');
      }
      const fetchedEmployments = await response.data;

      const sortedEmployments = fetchedEmployments.sort((a: any, b: any) => {
        if (a.current === 'Y' && b.current === 'Y') {
          return (
            new Date(a.startdate).getTime() - new Date(b.startdate).getTime()
          );
        } else if (a.current === 'Y') {
          return -1;
        } else if (b.current === 'Y') {
          return 1;
        } else if (!a.enddate) {
          return -1;
        } else if (!b.enddate) {
          return 1;
        } else {
          const endDateComparison =
            new Date(b.enddate).getTime() - new Date(a.enddate).getTime();
          if (endDateComparison !== 0) return endDateComparison;
          return a.id - b.id;
        }
      });

      reset({ employments: sortedEmployments });
      setInitialData(sortedEmployments);
    } catch (error) {
      console.error('Error fetching employments:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch employments',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  useEffect(() => {
    fetchEmployments();
  }, [memberId]);

  const toggleCollapse = (index: number) => {
    setCollapsedIndices((prev) => {
      const newCollapsedIndices = [...prev];
      newCollapsedIndices[index] = !newCollapsedIndices[index];
      return newCollapsedIndices;
    });
  };

  const onSubmit = async (data: any) => {
    console.log('submit form values', data);
    // Remove enddate for hospitals with active admitting arrangement status
    data.employments.forEach((employment: any) => {
      if (employment.current === 'Y') {
        delete employment.enddate;
      }
    });

    const formattedData = data.employments.map((item: any) => ({
      ...item,
      memberid: memberId,
    }));

    const createEmployment = async (employment: any) => {
      const response = await httpClient.post('/api/employment', employment);
      return response.data;
    };

    const updateEmployment = async (employment: any) => {
      const response = await httpClient.put(
        `/api/employment/${employment.id}`,
        employment,
      );
      return response.data;
    };

    const deleteEmployment = async (id: number) => {
      const response = await httpClient.delete(`/api/employment/${id}`);
      console.log('delete response', response);
    };

    const initialIds = new Set<number>(initialData?.map((e: any) => e.id));
    const currentIds = new Set<number>(formattedData.map((e: any) => e.id));
    const deletedIds = [...initialIds].filter(
      (id): id is number => !currentIds.has(id),
    );

    const createTasks = formattedData
      .filter((e: any) => !e.id || e.id === 0)
      .map((e: any) => createEmployment(e));
    const updateTasks = formattedData
      .filter((e: any) => e.id && e.id > 0 && initialIds.has(e.id))
      .map((e: any) => updateEmployment(e));
    const deleteTasks = deletedIds.map((id) => deleteEmployment(id));

    try {
      const results = await Promise.all([
        ...createTasks,
        ...updateTasks,
        ...deleteTasks,
      ]);
      console.log('Results:', results);
      fetchEmployments();

      notifications.show({
        title: 'Success',
        message: 'Employments updated successfully',
        color: 'green',
        position: 'bottom-center',
      });
    } catch (error) {
      console.error('Error submitting employment form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit employment',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  return (
    <Container>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        {fields.map((item, index) => {
          const currentField = watch(`employments.${index}.current`);
          return (
            <Paper key={item.id} shadow="xs" p="md" withBorder>
              <Group justify="space-between">
                <Group>
                  <h3>Employment Location {index + 1}</h3>
                  <Button
                    id={`toggle-collapse-employment-location-${index}`}
                    onClick={() => toggleCollapse(index)}
                    size="xs"
                  >
                    {collapsedIndices[index] ? 'Expand' : 'Collapse'}
                  </Button>
                  <Button
                    id={`remove-employment-location-${index}`}
                    color="red"
                    onClick={() => remove(index)}
                    size="xs"
                  >
                    Remove
                  </Button>
                </Group>
              </Group>
              <Collapse in={!collapsedIndices[index]}>
                <TextInput
                  id={`employments.${index}.employer`}
                  label="Practice/Employer"
                  {...register(`employments.${index}.employer`)}
                />
                <TextInput
                  id={`employments.${index}.departmentspecialty`}
                  label="Department/Specialty"
                  {...register(`employments.${index}.departmentspecialty`)}
                />
                <TextInput
                  id={`employments.${index}.address`}
                  label="Аddress - Start typing the address below and pick one from the dropdown"
                  placeholder="Start by typing here"
                  data-index={index}
                  ref={(el) => (locationAddressRefs.current[index] = el)}
                  onChange={(e) => {
                    if (!e.target.value) {
                      resetAddress(index);
                    }
                  }}
                />
                <Grid>
                  <Grid.Col span={6}>
                    <TextInput
                      id={`employments.${index}.street1`}
                      label={'\u0405treet 1'}
                      {...register(`employments.${index}.street1`)}
                      disabled
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      id={`employments.${index}.street2`}
                      label={'\u0405treet 2'}
                      {...register(`employments.${index}.street2`)}
                      disabled
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Controller
                      name={`employments.${index}.country`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          id={`employments.${index}.country`}
                          {...field}
                          label="Country"
                          data={[{ value: 'US', label: 'United States' }]}
                          clearable
                          searchable
                          disabled
                        />
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      id={`employments.${index}.city`}
                      label="City"
                      {...register(`employments.${index}.city`)}
                      disabled
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Controller
                      name={`employments.${index}.state`}
                      control={control}
                      render={({ field }) => (
                        <Select
                          id={`employments.${index}.state`}
                          {...field}
                          label="State"
                          data={STATES_ARRAY.map((state) => ({
                            value: state,
                            label: state,
                          }))}
                          clearable
                          searchable
                          disabled
                        />
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      id={`employments.${index}.zip`}
                      label="ZIP"
                      {...register(`employments.${index}.zip`)}
                      maxLength={5}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                        e.target.value = e.target.value.replace(/\D/g, '');
                      }}
                      disabled
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Controller
                      name={`employments.${index}.phone`}
                      control={control}
                      render={({ field }) => (
                        <Input.Wrapper label="Phone">
                          <Input
                            id={`employments.${index}.phone`}
                            data-testid={`employments.${index}.phone`}
                            component={IMaskInput}
                            mask="(000) 000-0000"
                            placeholder="(XXX) XXX-XXXX"
                            {...field}
                          />
                        </Input.Wrapper>
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Controller
                      name={`employments.${index}.fax`}
                      control={control}
                      render={({ field }) => (
                        <Input.Wrapper label="Fax">
                          <Input
                            id={`employments.${index}.fax`}
                            data-testid={`employments.${index}.fax`}
                            component={IMaskInput}
                            mask="(000) 000-0000"
                            placeholder="(XXX) XXX-XXXX"
                            {...field}
                          />
                        </Input.Wrapper>
                      )}
                    />
                  </Grid.Col>
                </Grid>
                <Controller
                  name={`employments.${index}.current`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      id={`employments.${index}.current`}
                      {...field}
                      label="Is this your current employer?"
                      data={[
                        { value: 'Y', label: 'Yes' },
                        { value: 'N', label: 'No' },
                      ]}
                      clearable
                    />
                  )}
                />
                <Grid>
                  <Grid.Col span={6}>
                    <CustomDateInput
                      control={control}
                      name={`employments.${index}.startdate`}
                      label="Start Date"
                    />
                  </Grid.Col>

                  {currentField !== 'Y' && (
                    <Grid.Col span={6}>
                      <CustomDateInput
                        control={control}
                        name={`employments.${index}.enddate`}
                        label="End Date"
                      />
                    </Grid.Col>
                  )}
                </Grid>
              </Collapse>
            </Paper>
          );
        })}

        <Group mt="md">
          <Button
            id="add-employment-location"
            onClick={() =>
              append({
                employer: '',
                departmentspecialty: '',
                street1: '',
                street2: '',
                country: '',
                city: '',
                state: '',
                zip: '',
                phone: '',
                fax: '',
                startdate: null,
                enddate: null,
                current: 'N',
              })
            }
          >
            Add Employment Location
          </Button>
        </Group>

        <Group mt="md">
          <Button id="save-employment" type="submit">
            Save
          </Button>
        </Group>
      </form>
    </Container>
  );
};

export default EmploymentForm;
