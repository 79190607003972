import { Steps } from 'intro.js-react';
import { useEffect } from 'react';

interface PlanSelectionOnboardingProps {
  stepsEnabled: boolean;
  onboardingSteps: (
    | { element: string; intro: string; position: string }
    | { element: string; intro: string; position?: undefined }
  )[];
  handleStepsCompletion: () => void;
}

const PlanSelectionOnboardingSteps = ({
  stepsEnabled,
  onboardingSteps,
  handleStepsCompletion,
}: PlanSelectionOnboardingProps) => {
  // Disable the skip button
  const disableSkipButton = () => {
    const skipButton = document.querySelector(
      '.introjs-skipbutton',
    ) as HTMLElement;
    if (skipButton) {
      skipButton.style.display = 'none';
      skipButton.style.pointerEvents = 'none';

      skipButton.addEventListener('click', (e: Event) => {
        e.preventDefault();
        e.stopPropagation();
      });
    }
  };

  useEffect(() => {
    // Initial disable
    disableSkipButton();

    // Create a MutationObserver to watch for DOM changes
    const observer = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        disableSkipButton();
      });
    });

    // Start observing the document
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Cleanup
    return () => {
      observer.disconnect();
    };
  }, [stepsEnabled]);

  return (
    <>
      <Steps
        enabled={stepsEnabled}
        steps={onboardingSteps}
        initialStep={0}
        onComplete={handleStepsCompletion} // Callback when the tour is completed
        onExit={() => {}}
        options={{
          scrollToElement: true, // Ensure scrolling to the element
          scrollPadding: 30,
          exitOnOverlayClick: false,
          exitOnEsc: false,
          disableInteraction: false,
          nextLabel: 'Next →',
          prevLabel: '← Back',
          skipLabel: '',
          doneLabel: 'Finish',
          skip: false,
          tooltipClass: 'introjs-tooltip',
        }}
      />
    </>
  );
};

export default PlanSelectionOnboardingSteps;
