import React from 'react';
import { Button, Group, Text, Grid, Card } from '@mantine/core';
import { Member } from './../../types/member-simple-types';
import { RyzeAvatar } from '../AvatarUploader';
import { IconCheck, IconX } from '@tabler/icons-react';

interface RequestsSectionProps {
  requests: Member[];
  onAccept: (connectionId: number, memberId: number) => void;
  onReject: (connectionId: number, memberId: number) => void;
  processedMemberId: number;
  loadingAction: 'accept' | 'reject' | null;
}

const RequestsSection: React.FC<RequestsSectionProps> = ({
  requests,
  onAccept,
  onReject,
  processedMemberId,
  loadingAction,
}) => {
  if (requests.length === 0) {
    return (
      <Group justify="left" p={10}>
        {' '}
        You don't have any requests yet...
      </Group>
    );
  }

  return (
    <Grid mb="xl">
      {requests.map((member) => (
        <Grid.Col key={member.id} span={{ base: 6, md: 4, lg: 3 }}>
          <Card
            shadow="sm"
            padding="lg"
            radius="md"
            withBorder
            style={{
              height: '100%',
            }}
          >
            <Card.Section withBorder inheritPadding py="xs">
              <Group justify="center">
                <RyzeAvatar
                  color="blue"
                  radius={60}
                  size={120}
                  memberId={member.id}
                >
                  {member.firstname[0] + member.lastname[0]}
                </RyzeAvatar>
              </Group>
              <Group justify="center" mt={10}>
                <Text
                  fw={700}
                  style={{
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                  }}
                >
                  {`${member.firstname} ${member.lastname}`}
                </Text>
              </Group>
            </Card.Section>

            <Card.Section py="md" style={{ textAlign: 'center' }}>
              <Button
                variant="light"
                color="green"
                leftSection={<IconCheck size={16} />}
                onClick={() => onAccept(member.connectionId, member.id)}
                style={{ width: '48%' }}
                loading={
                  processedMemberId === member.id && loadingAction === 'accept'
                }
                disabled={
                  processedMemberId === member.id &&
                  (loadingAction === 'accept' || loadingAction === 'reject')
                }
              >
                Accept
              </Button>
              &nbsp;
              <Button
                variant="light"
                color="red"
                leftSection={<IconX size={16} />}
                onClick={() => onReject(member.connectionId, member.id)}
                style={{ width: '48%' }}
                loading={
                  processedMemberId === member.id && loadingAction === 'reject'
                }
                disabled={
                  processedMemberId === member.id &&
                  (loadingAction === 'accept' || loadingAction === 'reject')
                }
              >
                Reject
              </Button>
            </Card.Section>
          </Card>
        </Grid.Col>
      ))}
    </Grid>
  );
};

export default RequestsSection;
