/**
 * Maps URL paths to their corresponding privilege types
 */
const pathToTypeMap = {
  '/insurance/member': 'insurance_physician',
  '/insurance/clinic': 'insurance_organization',
  '/insurance/employee': 'insurance_employee',
  '/wecare/member': 'wecaremember',
  '/wecare/admin': 'wecareadmin',
  '/admin': 'ryzeadmin',
  '/wecare/register/user': 'wecaremember',
  '/insurance/register/organization/user': 'insurance_organization',
  '/insurance/register/physician/user': 'insurance_physician',
  '/insurance/register/employee/user': 'insurance_employee',
  '/insurance/register/admin/user': 'ryzeadmin',
  '/insurance/register/wecareadmin/user': 'wecareadmin',
  '/insurance/register/user': 'insurance_organization',
} as const;

/**
 * Normalizes a path by converting to lowercase and removing trailing slashes
 */
const normalizePath = (path: string): string => {
  return path.toLowerCase().replace(/\/$/, '');
};

/**
 * Determines the privilege type based on the current pathname
 * @param pathname - The current URL pathname
 * @returns The corresponding privilege type or null if no match is found
 */
export const getPrivilegeTypeFromPath = (pathname: string): string | null => {
  const currentPath = normalizePath(pathname);

  const matchedPath = Object.keys(pathToTypeMap)
    .sort((a, b) => b.length - a.length) // Sort by length descending to match most specific paths first
    .find((path) => currentPath.startsWith(normalizePath(path)));

  return matchedPath ? pathToTypeMap[matchedPath] : null;
};

/**
 * Type representing all possible privilege types
 */
export type PrivilegeType = (typeof pathToTypeMap)[keyof typeof pathToTypeMap];
