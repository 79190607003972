import { useState, useEffect } from 'react';
import {
  Grid,
  Text,
  Paper,
  Container,
  Title,
  Timeline,
  Loader,
  Divider,
  Flex,
  Box,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import useHttpClient from './hooks/useHttpClient';
import { useMantineColorScheme, useComputedColorScheme } from '@mantine/core';
import { useAuth } from './hooks/useAuth';
import { useAuth as useAuth2 } from './AuthProvider';
import {
  IconBuildingBank,
  IconFriends,
  IconInbox,
  IconNotebook,
  IconUserCheck,
  IconUser,
  IconCircleCheck,
} from '@tabler/icons-react';
import { subMenuList } from './menu.config';
import { formatDistanceToNow } from 'date-fns';
import { Anchor } from '@mantine/core';
import StageLineChart from './DashboardWidgets/StageLineChart';

export default function Dashboard({ path }) {
  const { setColorScheme } = useMantineColorScheme();
  const computedColorScheme = useComputedColorScheme('light');
  const toggleColorScheme = () => {
    setColorScheme(computedColorScheme === 'dark' ? 'light' : 'dark');
  };

  const httpClient = useHttpClient();
  const navigate = useNavigate();
  const { organizationId, subscriberMemberId, wecareMemberId } = useAuth();
  const [userInfo, setUserInfo] = useState<any>();
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [metadata, setMetadata] = useState<any>({
    stepsDone: [],
    ts: [],
    timelineProgress: {
      clinic: { stepsDone: [], ts: [] },
      employee: { stepsDone: [], ts: [] },
      member: { stepsDone: [], ts: [] },
    },
  });

  const auth = useAuth2();
  const privileges = auth.getPrivileges();
  const username = privileges[0].username;
  const types = (privileges || [])
    .map((priv) => priv.type)
    .join(',')
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());

  // Check user privileges
  const hasOrganizationPrivilege = privileges.some(
    (p) => p.type === 'insurance_organization',
  );
  const hasPhysicianPrivilege = privileges.some(
    (p) => p.type === 'insurance_physician',
  );
  const hasEmployeePrivilege = privileges.some(
    (p) => p.type === 'insurance_employee',
  );

  const fetchUserInformation = async () => {
    console.log('subscriberMemberId', subscriberMemberId);
    setIsloading(true);
    try {
      if (subscriberMemberId) {
        const { data } = await httpClient.get(`/api/subscriber`);
        setUserInfo(data);
      }
      if (organizationId) {
        const { data } = await httpClient.get(`/api/organization`);
        setUserInfo((setUserInfo) => {
          return { ...setUserInfo, ...data };
        });
      }
      const { data } = await httpClient.get(`/api/user/metadata`);
      if (data.metadata) {
        // Ensure we have the expected structure
        const metadataWithDefaults = {
          stepsDone: data.metadata.stepsDone || [],
          ts: data.metadata.ts || [],
          timelineProgress: {
            clinic: data.metadata.timelineProgress?.clinic || {
              stepsDone: [],
              ts: [],
            },
            employee: data.metadata.timelineProgress?.employee || {
              stepsDone: [],
              ts: [],
            },
            member: data.metadata.timelineProgress?.member || {
              stepsDone: [],
              ts: [],
            },
          },
        };
        setMetadata(metadataWithDefaults);
      }
    } catch (error) {
      console.error('failed to fetch user information', error);
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    fetchUserInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriberMemberId, organizationId]);

  const stepDetails = {
    // clinic protal
    'practice-info': {
      description: 'Practice Information',
      icon: <IconUser size={20} />,
    },
    employees: {
      description: 'Manage Employees',
      icon: <IconFriends size={20} />,
    },
    employees_approval: {
      description: 'Approve Employees',
      icon: <IconUser size={20} />,
    },
    'next-steps': {
      description: 'Next Steps',
      icon: <IconInbox size={20} />,
    },
    // member / employee
    'personal-info': {
      description: 'Provide your contact and basic details.',
      icon: <IconUserCheck size={20} />,
    },
    'family-member-management': {
      description: 'Add details of your immediate family members.',
      icon: <IconFriends size={20} />,
    },
    priorities: {
      description: 'What you want most out of your insurance.',
      icon: <IconInbox size={20} />,
    },
    'plan-selection': {
      description: 'Pick the plan best suited for you.',
      icon: <IconNotebook size={20} />,
    },
    'payment-information': {
      description: 'Enter payment information to complete registration.',
      icon: <IconBuildingBank size={20} />,
    },
  };

  // Define timelines based on user roles
  const clinicTimeline = subMenuList?.clinic.menu;
  const memberTimeline = subMenuList?.independentPhysician.menu;
  const employeeTimeline = subMenuList?.employee.menu;

  // Determine which timeline(s) to show based on privileges
  const getTimelinesToShow = () => {
    // Both organization and employee privileges
    if (hasOrganizationPrivilege && hasEmployeePrivilege) {
      return {
        timeline1: {
          title: `Here are your items to complete your clinic's insurance registration: `,
          data: clinicTimeline || [],
        },
        timeline2: {
          title: `Register your own family's information here: `,
          data: employeeTimeline || [],
        },
      };
    }
    // Organization privilege only
    else if (hasOrganizationPrivilege) {
      return {
        timeline1: {
          title: `Here are your items to complete your clinic's insurance registration: `,
          data: clinicTimeline || [],
        },
      };
    }
    // Physician privilege only
    else if (hasPhysicianPrivilege) {
      return {
        timeline1: {
          title: `Let's complete the items below to get your quote! `,
          data: memberTimeline || [],
        },
      };
    }
    // Employee privilege only
    else if (hasEmployeePrivilege) {
      return {
        timeline1: {
          title: `Register your own family's information here: `,
          data: employeeTimeline || [],
        },
      };
    }
    // Default - use path-based timeline (as before)
    else {
      const timeline =
        {
          member: memberTimeline || [],
          clinic: clinicTimeline || [],
          employee: employeeTimeline || [],
        }[path] || [];

      return {
        timeline1: {
          title: '',
          data: timeline,
        },
      };
    }
  };

  const timelinesToShow = getTimelinesToShow();

  return (
    <Container size="xl">
      <Paper withBorder shadow="none" p={0} radius="md">
        <Grid>
          <Grid.Col span={12} p="xl" style={{ position: 'relative' }}>
            {isLoading ? (
              <Container
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Loader />
              </Container>
            ) : (
              <>
                {userInfo && (
                  <Title order={3} mt="20" style={{ textAlign: 'left' }}>
                    Hi {userInfo?.firstname}!
                  </Title>
                )}
                {/* 
                <Box m={20}>
                  <StageLineChart />
                </Box> */}

                {/* <Text
                  style={{
                    fontSize: '30px',
                    fontWeight: 600,
                    opacity: 0.1,
                    position: 'absolute',
                    width: 'calc(50% - 200px)',
                    bottom: '0px',
                    right: 20,
                    textAlign: 'right',
                  }}
                >
                  Health Insurance{' '}
                  <span style={{ fontWeight: 1000 }}>
                    built for independent physicians
                  </span>{' '}
                  to be the smarter healthcare choice
                </Text> */}
                {/* Always render timelines, don't depend on metadata?.stepsDone existence */}
                <Flex>
                  {/* First Timeline */}
                  {timelinesToShow.timeline1 && (
                    <Flex direction="column">
                      {timelinesToShow.timeline1.title && (
                        <Title order={5} mt="20" mb="10">
                          {timelinesToShow.timeline1.title}
                        </Title>
                      )}
                      <Timeline
                        active={-1}
                        bulletSize={28}
                        lineWidth={2}
                        mt="10"
                      >
                        {timelinesToShow.timeline1.data.map((item, idx) => {
                          if (item.key === 'payment-information') {
                            return null;
                          }

                          // Determine which timeline type metadata to use
                          let timelineType = 'clinic'; // default for organization privilege

                          // Check if this is an employee timeline within the clinic context
                          const currentPath = window.location.pathname;
                          if (currentPath.includes('/clinic/employee')) {
                            timelineType = 'employee';
                          } else if (
                            currentPath.includes('/insurance/member')
                          ) {
                            // Explicitly check for the physician member path
                            timelineType = 'member';
                          } else if (
                            hasPhysicianPrivilege &&
                            timelinesToShow.timeline1.title.includes('Member')
                          ) {
                            timelineType = 'member';
                          } else if (
                            hasEmployeePrivilege &&
                            timelinesToShow.timeline1.title.includes('Employee')
                          ) {
                            timelineType = 'employee';
                          } else if (
                            hasOrganizationPrivilege &&
                            timelinesToShow.timeline1.title.includes('Clinic')
                          ) {
                            timelineType = 'clinic';
                          }

                          // Get timeline-specific progress if available
                          const timelineProgress =
                            metadata?.timelineProgress?.[timelineType];
                          const stepsDone =
                            timelineProgress?.stepsDone ||
                            metadata?.stepsDone ||
                            [];
                          const timeStamps =
                            timelineProgress?.ts || metadata?.ts || [];

                          const baseHref =
                            timelineType === 'employee' &&
                            currentPath.includes('/clinic/employee')
                              ? `/insurance/clinic/employee#${item.key}`
                              : `/insurance/${path}#${item.key}`;

                          const isFirstItemSelectable =
                            idx === 0 && stepsDone.length === 0;
                          const isItemCompleted =
                            stepsDone?.[idx] === true ||
                            (stepsDone?.[idx] === false && timeStamps?.[idx]);
                          const lastCompletedIndex =
                            stepsDone.lastIndexOf(true);
                          const isLastItemSelectable =
                            idx === lastCompletedIndex + 1 &&
                            idx !== timelinesToShow.timeline1.data.length - 1;

                          const clickable =
                            isItemCompleted ||
                            isFirstItemSelectable ||
                            isLastItemSelectable;
                          return (
                            <Timeline.Item
                              key={item.key}
                              bullet={
                                stepsDone?.[idx] ? (
                                  <IconCircleCheck
                                    style={{ color: 'blue' }}
                                    size={36}
                                  />
                                ) : (
                                  <span
                                    style={{ color: 'grey', marginTop: '5px' }}
                                  >
                                    {stepDetails[item.key].icon || (
                                      <IconNotebook size={20} />
                                    )}
                                  </span>
                                )
                              }
                              title={
                                clickable ? (
                                  <Anchor href={baseHref}>{item.value}</Anchor>
                                ) : (
                                  item.value
                                )
                              }
                            >
                              <Text size="xs">
                                <i>{stepDetails[item.key].description}</i>
                              </Text>
                              <Text c="dimmed" size="xs" mt={4}>
                                {timeStamps?.[idx] &&
                                  'Updated ' +
                                    formatDistanceToNow(
                                      new Date(timeStamps?.[idx]),
                                      {
                                        addSuffix: true,
                                      },
                                    )}
                              </Text>
                            </Timeline.Item>
                          );
                        })}
                      </Timeline>
                    </Flex>
                  )}

                  {timelinesToShow.timeline2 && (
                    <Divider orientation="vertical" size="md" mx={70} />
                  )}

                  {/* Second Timeline (if both organization and employee privileges) */}
                  {timelinesToShow.timeline2 && (
                    <Flex direction="column">
                      <Title order={5} mt="20" mb="10">
                        {timelinesToShow.timeline2.title}
                      </Title>
                      <Timeline
                        active={-1}
                        bulletSize={28}
                        lineWidth={2}
                        mt="10"
                      >
                        {timelinesToShow.timeline2.data.map((item, idx) => {
                          if (item.key === 'plan-selection') {
                            return null;
                          }

                          // Get employee timeline progress for the second timeline
                          const timelineProgress =
                            metadata?.timelineProgress?.employee;
                          const stepsDone =
                            timelineProgress?.stepsDone ||
                            metadata?.stepsDone ||
                            [];
                          const timeStamps =
                            timelineProgress?.ts || metadata?.ts || [];

                          // Get current path to generate correct href
                          const currentPath = window.location.pathname;
                          const employeeHref = currentPath.includes('/clinic')
                            ? `/insurance/clinic/employee#${item.key}`
                            : `/insurance/employee#${item.key}`;

                          const isFirstItemSelectable =
                            idx === 0 && stepsDone.length === 0;
                          const isItemCompleted =
                            stepsDone?.[idx] === true ||
                            (stepsDone?.[idx] === false && timeStamps?.[idx]);
                          const lastCompletedIndex =
                            stepsDone.lastIndexOf(true);
                          const isLastItemSelectable =
                            idx === lastCompletedIndex + 1 &&
                            idx !== timelinesToShow.timeline2.data.length - 1;

                          const clickable =
                            isItemCompleted ||
                            isFirstItemSelectable ||
                            isLastItemSelectable;

                          return (
                            <Timeline.Item
                              key={item.key}
                              bullet={
                                stepsDone?.[idx] ? (
                                  <IconCircleCheck
                                    style={{ color: 'blue' }}
                                    size={36}
                                  />
                                ) : (
                                  <span
                                    style={{ color: 'grey', marginTop: '5px' }}
                                  >
                                    {stepDetails[item.key].icon || (
                                      <IconNotebook size={20} />
                                    )}
                                  </span>
                                )
                              }
                              title={
                                clickable ? (
                                  <Anchor href={employeeHref}>
                                    {item.value}
                                  </Anchor>
                                ) : (
                                  item.value
                                )
                              }
                            >
                              <Text c="dimmed" size="xs">
                                <i>{stepDetails[item.key].description}</i>
                              </Text>
                              <Text size="xs" mt={4}>
                                {timeStamps?.[idx] &&
                                  'Updated ' +
                                    formatDistanceToNow(
                                      new Date(timeStamps?.[idx]),
                                      {
                                        addSuffix: true,
                                      },
                                    )}
                              </Text>
                            </Timeline.Item>
                          );
                        })}
                      </Timeline>
                    </Flex>
                  )}
                </Flex>
              </>
            )}
          </Grid.Col>
        </Grid>
        <br />
      </Paper>
    </Container>
  );
}
