import React, { useState, useEffect } from 'react';
import useHttpClient from '../hooks/useHttpClient';
import { encryptImage, decryptImage } from './file-encryption';
import { parseMessage } from '../../helpers/stringUtils';
import { useAuth } from '../AuthProvider';
import { IconFile } from '@tabler/icons-react';
import { Anchor } from '@mantine/core';
import { notifications } from '@mantine/notifications';

// helpers
export const RyzeMessageAttachment = ({ url, conversation, id, scrollRef }) => {
  const [htmlContent, setHtmlContent] = useState(null);
  const auth = useAuth();
  const privileges = auth.getPrivileges();
  const currentUserEmail = privileges[0].username;

  async function toHTML(url, conv) {
    if (url.startsWith(process.env.REACT_APP_PUBLIC_S3_BUCKET, 0)) {
      // console.log(url, conv.key);

      const urlObj = new URL(url);
      const ivString = urlObj.searchParams.get('k');
      const password = conv.key;

      if (!ivString || !conv.key) {
        return (
          <img
            src={url}
            alt=""
            style={{ maxWidth: '400px', border: '1px solid red' }}
          />
        );
      }

      const ivArray = ivString.split(',').map(Number);
      const iv = new Uint8Array(ivArray);

      const response = await fetch(url);
      if (!response.ok) {
        console.error(
          'Failed to fetch the encrypted file:',
          response.statusText,
        );
        return '...';
      }

      const encryptedData = await response.arrayBuffer();
      const decryptedBlob = await decryptImage(encryptedData, iv, password);

      const src = URL.createObjectURL(decryptedBlob);

      if (scrollRef?.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }

      const filename = urlObj.pathname.split('/').pop();
      const fileExtension = filename.split('.').pop()?.toLowerCase();
      const fileBasename = filename.replaceAll(
        /msg\-\d+.-\w+-\w+-\w+-\w+-\w+-\w+-/gi,
        '',
      );
      if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
        return <img src={src} alt="" style={{ maxWidth: '400px' }} />;
      } else {
        return (
          <Anchor
            href={src}
            target="_blank"
            download={fileBasename}
            rel="noopener noreferrer"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
              color: 'white',
            }}
            className="hover:text-white underline"
          >
            <IconFile size={24} />
            {fileBasename}
          </Anchor>
        );
      }
    } else {
      return parseMessage(url, currentUserEmail);
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await toHTML(url, conversation);
        setHtmlContent(result);
      } catch (e) {
        console.log(e);
        setHtmlContent('...');
      }
    }

    fetchData();
  }, [url, conversation]);

  return <>{htmlContent}</>;
};

export function getFileExtension(url) {
  const match = url.match(/\.([a-zA-Z0-9]+)$/);
  return match ? match[1].toLowerCase() : null;
}

export function isAllowedFileType(url) {
  const allowedFileTypes = [
    'jpg',
    'jpeg',
    'png',
    'pdf',
    'txt',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'csv',
    'heic',
  ];
  const extension = getFileExtension(url);
  return extension && allowedFileTypes.includes(extension);
}

export function isImageFile(url) {
  const imageFileTypes = ['jpg', 'jpeg', 'png', 'heic'];
  const extension = getFileExtension(url);
  return extension && imageFileTypes.includes(extension);
}

const DragAndDropUpload = ({ children, conversation, setIsSubmitting }) => {
  const [files, setFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const httpClient = useHttpClient();

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    const droppedFiles = Array.from(event.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);

    // Automatically upload files
    uploadFiles(droppedFiles);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleFileSelect = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    uploadFiles(selectedFiles);
  };

  const uploadFiles = (fileList) => {
    setIsSubmitting(true);

    fileList.forEach(async (file) => {
      try {
        console.log(file.name);

        const formData = new FormData();

        const { id, key } = conversation;

        const { encryptedData, iv } = await encryptImage(file, key);
        const encryptedBlob = new Blob([encryptedData], {
          type: 'application/octet-stream',
        });

        if (!isAllowedFileType(file.name)) {
          throw new Error('File type not allowed');
        }

        formData.append('name', file.name);
        formData.append('file', encryptedBlob);
        // formData.append('file', file);
        formData.append('iv', iv.toString());

        const response = await httpClient.post(
          `/api/messages/attachment/${id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
        console.log(response);
        setIsSubmitting(false);
      } catch (e) {
        notifications.show({
          title: e?.response?.data || e?.message,
          message: '',
          color: 'red',
          position: 'bottom-center',
        });
        setIsSubmitting(false);
      }
    });
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      style={{
        border: isDragging ? '2px dashed #ccc' : '0px solid #f9f9f9',
        borderRadius: '10px',
        padding: '10px',
        textAlign: 'center',
        backgroundColor: isDragging ? '#e3f2fd' : 'rgba(180,180,180,0.2)',
        transition: 'background-color 0.3s',
        height: '100%',
      }}
    >
      {children}
      {/* {(conversation?.id || '') + ':::' + (conversation?.key || '')} */}
      <input
        type="file"
        multiple
        onChange={handleFileSelect}
        style={{
          display: 'none',
        }}
        id="file-input"
      />
    </div>
  );
};

export default DragAndDropUpload;
