import React, { useMemo, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Paper, Progress, Box, Text } from '@mantine/core';
import RyzeLogo from '../RyzeLogo';
import CustomerTypeScreen from './CustomerTypeScreen';
import PracticeOwnerFamilyScreen from './PracticeOwnerFamilyScreen';
import InsuranceStartDate from './InsuranceStartDate';
import PracticeRegistrationScreen from './PracticeRegistrationScreen';
import IndividualRegistrationScreen from './IndividualRegistrationScreen';
import EmailVerificationScreen from './EmailVerificationScreen';
import PracticeReferralScreen from './PracticeReferralScreen';
import SorryScreen from './SorryScreen';
import ProviderTypeScreen from './ProviderTypeScreen';
import CurrentlyWorkingScreen from './CurrentlyWorkingScreen';
import WorkSituationScreen from './WorkSituationScreen';
import ReferralSuccess from './ReferralSuccess';

function RyzeOnboarding() {
  const search = useLocation().search;
  const debug = new URLSearchParams(search).get('debug');
  const screen = new URLSearchParams(search).get('screen');

  const [answers, setAnswers] = useState({});
  const [screenHistory, setScreenHistory] = useState([
    screen || 'provider_type',
  ]);
  const [currentScreen, setCurrentScreen] = useState(screen || 'provider_type');

  const nodes = {
    //Starting Flow
    provider_type: ['currrently_working', 'sorry'],
    currrently_working: ['work_situation', 'sorry'],
    work_situation: ['customer_type'],

    // Working physicians
    customer_type: [
      'practice_owner_family',
      'individual_insurance_start_date',
      'referral_form',
    ],

    // Practice Owner
    practice_owner_family: ['practice_insurance_start_date'],
    practice_insurance_start_date: ['practice_registration'],
    practice_registration: ['email_verification'],

    // Individual
    individual_insurance_start_date: ['individual_registration'],
    individual_registration: ['email_verification'],

    // Referral
    referral_form: ['referral_success'],

    // Terminal
    email_verification: [],
    referral_success: [],
    sorry: [],
  };

  const back = () => {
    setScreenHistory((values) => {
      if (values.length > 1) {
        const newValues = values.slice(0, -1);
        setCurrentScreen(newValues[newValues.length - 1]);
        return newValues;
      }
      return values;
    });
  };

  const next = (pathIndex = 0) => {
    const availablePaths = nodes[currentScreen];
    if (availablePaths && availablePaths[pathIndex] !== undefined) {
      const nextScreen = availablePaths[pathIndex];
      setScreenHistory((values) => {
        setCurrentScreen(nextScreen);
        return [...values, nextScreen];
      });
    }
  };

  function findLongestPath(startScreen) {
    if (!nodes[startScreen] || nodes[startScreen].length === 0) return 0;
    return 1 + Math.max(...nodes[startScreen].map(findLongestPath));
  }

  const longestFuturePath = useMemo(
    () => findLongestPath(currentScreen),
    [currentScreen],
  );
  const completedSteps = screenHistory.length - 1;
  const totalSteps = completedSteps + longestFuturePath;

  const progressValue = Math.round((completedSteps / totalSteps) * 100);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (
        ['referral_success', 'sorry', 'email_verification'].includes(currentScreen)
      ) {
        return;
      }

      event.preventDefault();
      event.returnValue = '';
    };

    const handlePopState = (event) => {
      if (
        ['referral_success', 'sorry', 'email_verification'].includes(currentScreen)
      ) {
        return;
      }

      if (
        !window.confirm(
          'Are you sure you want to leave? Your changes may not be saved.',
        )
      ) {
        window.history.pushState(null, '', window.location.pathname);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.history.pushState(
      null,
      '',
      window.location.pathname + window.location.search,
    );
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [currentScreen]);

  return (
    <Container mt={50} mb={50} style={{ background: 'none' }}>
      <Paper withBorder shadow="md" p={30} radius="md" mt="lg">
        <RyzeLogo />

        <Box
          style={{ position: 'relative', width: '100%', marginBottom: '20px' }}
        >
          <Progress
            value={progressValue}
            color="blue"
            size="lg"
            style={{ height: '20px', borderRadius: '10px' }}
          />
          <Text
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: progressValue < 50 ? 'black' : 'white',
              fontWeight: 'bold',
              pointerEvents: 'none',
            }}
          >
            {`${progressValue}%`}
          </Text>
        </Box>

        {currentScreen === 'provider_type' && (
          <ProviderTypeScreen
            currentStep={currentScreen}
            answers={answers}
            setAnswers={setAnswers}
            next={next}
            back={back}
          />
        )}
        {currentScreen === 'currrently_working' && (
          <CurrentlyWorkingScreen
            currentStep={currentScreen}
            answers={answers}
            setAnswers={setAnswers}
            next={next}
            back={back}
          />
        )}
        {currentScreen === 'work_situation' && (
          <WorkSituationScreen
            currentStep={currentScreen}
            answers={answers}
            setAnswers={setAnswers}
            next={next}
            back={back}
          />
        )}
        {currentScreen === 'customer_type' && (
          <CustomerTypeScreen
            currentStep={currentScreen}
            answers={answers}
            setAnswers={setAnswers}
            next={next}
            back={back}
          />
        )}
        {currentScreen === 'practice_owner_family' && (
          <PracticeOwnerFamilyScreen
            currentStep={currentScreen}
            answers={answers}
            setAnswers={setAnswers}
            next={next}
            back={back}
          />
        )}
        {currentScreen === 'practice_insurance_start_date' && (
          <InsuranceStartDate
            currentStep={currentScreen}
            answers={answers}
            setAnswers={setAnswers}
            next={next}
            back={back}
          />
        )}
        {currentScreen === 'practice_registration' && (
          <PracticeRegistrationScreen
            currentStep={currentScreen}
            answers={answers}
            setAnswers={setAnswers}
            next={next}
            back={back}
          />
        )}
        {currentScreen === 'individual_insurance_start_date' && (
          <InsuranceStartDate
            currentStep={currentScreen}
            answers={answers}
            setAnswers={setAnswers}
            next={next}
            back={back}
          />
        )}
        {currentScreen === 'individual_registration' && (
          <IndividualRegistrationScreen
            currentStep={currentScreen}
            answers={answers}
            setAnswers={setAnswers}
            next={next}
            back={back}
          />
        )}
        {currentScreen === 'referral_form' && (
          <PracticeReferralScreen
            currentStep={currentScreen}
            answers={answers}
            setAnswers={setAnswers}
            back={back}
            next={next}
          />
        )}
        {currentScreen === 'email_verification' && <EmailVerificationScreen />}
        {currentScreen === 'referral_success' && <ReferralSuccess />}
        {currentScreen === 'sorry' && <SorryScreen back={back} />}

        {debug && (
          <>
            <pre>History: {JSON.stringify(screenHistory, null, 2)}</pre>
            <pre>Completed: {completedSteps}</pre>
            <pre>Remaining: {longestFuturePath}</pre>
            <pre>Total Steps: {totalSteps}</pre>
            <pre>Progress: {progressValue}%</pre>
            <pre>Current Screen: {currentScreen}</pre>
          </>
        )}
      </Paper>
    </Container>
  );
}

export default RyzeOnboarding;
