import {
  ActionIcon,
  Button,
  TextInput,
  Text,
  useMantineTheme,
  Title,
  Popover,
  Flex,
  Group,
} from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import classes from './PlanSelectionDataTable.module.css';
import { IconCheck, IconSearch, IconX } from '@tabler/icons-react';
import { useMemo, useState } from 'react';
import Papa from 'papaparse';
import { Loader } from '@mantine/core';
import HiddenCoveredBenefitsTable from './HiddenCoveredBenefitsTable';
import ViewSBCDocument from './ViewSBCDocument';

interface PlanSelectionDataTableProps {
  premiums: any[];
  planSelectionCallback: (arg: any) => void;
  selectedPlan: number;
  onLoad?: () => void;
}

export default function PlanSelectionDataTable({
  premiums = [],
  planSelectionCallback = (arg) => {},
  selectedPlan = 0,
  onLoad,
}) {
  const theme = useMantineTheme();
  const [query, setQuery] = useState('');
  const [plans, setPlans] = useState([]);
  const [records, setRecords] = useState([]);
  const [showPopOver, setShowPopOver] = useState<number | null>();
  const [filters, setFilters] = useState<any>({ hideOon: true });
  const [selectedSBCPlan, setSelectedSBCPlan] = useState<string | null>(null);

  // Handle plan selection
  const handlePlanSelect = (planId) => {
    planSelectionCallback(planId);
  };
  console.log('filteres', filters);
  // Generate option columns dynamically based on plans
  const optionColumns = plans.map((plan, option_idx) => {
    const index = option_idx + 1;
    const option = {
      id: 'plan' + index,
      key: { option_idx },
      title: (
        <>
          <Title order={3}>{plan.name}</Title>
          <Title order={6}>
            Estimated Premium: $
            {Math.round(
              premiums?.filter((p) => p.planId === index)[0]?.totalPremium,
            ) || 'X'}
          </Title>
          <Button
            fullWidth
            mt="md"
            variant={index === selectedPlan ? 'outline' : 'light'}
            color={index === selectedPlan ? 'none' : 'blue'}
            onClick={() => handlePlanSelect(plan.id)}
          >
            {index === selectedPlan ? (
              <IconCheck color="green" />
            ) : (
              'Select Plan'
            )}
          </Button>
        </>
      ),
      className: index === selectedPlan ? classes.selected : '',
      columns: [
        {
          accessor: 'Plan ' + index + ' INN',
          title: 'INN',
          titleClassName: index === selectedPlan ? classes.selected : '',
          cellsClassName: index === selectedPlan ? classes.selected : '',
          hidden: filters.hideInn || filters['hide_' + option_idx],
        },
        {
          accessor: 'Plan ' + index + ' OON',
          title: 'OON',
          titleClassName: index === selectedPlan ? classes.selected : '',
          cellsClassName: index === selectedPlan ? classes.selected : '',
          hidden: filters.hideOon || filters['hide_' + option_idx],
        },
      ],
    };
    return option;
  });

  // Filter records based on search query
  const filteredRecords = records?.filter((e) =>
    e.Benefit?.toLowerCase().includes(query.toLowerCase()),
  );

  useMemo(() => {
    Papa.parse('/benefits.csv', {
      header: true,
      skipEmptyLines: true,
      download: true,
      complete: ({ data }) => {
        // Create header row
        const additionalInformationHeaderRow = {
          Benefit: '##Additional Information',
          index: data.length,
          ...Object.keys(data[0])
            .filter((key) => key.includes('Plan'))
            .reduce((acc, key) => ({ ...acc, [key]: '' }), {}),
        };

        // Create SBC Document button row
        const viewSBCButtonsRow = {
          Benefit: '',
          index: data.length + 1,
          ...Object.keys(data[0])
            .filter((key) => key.includes('Plan'))
            .reduce(
              (acc, key) => ({
                ...acc,
                [key]: (
                  <Group justify="center">
                    <Button
                      my={20}
                      variant="light"
                      color="blue"
                      size="xs"
                      onClick={() => {
                        const planNumber = key.match(/Plan (\d+)/)?.[1];
                        setSelectedSBCPlan(planNumber || null);
                      }}
                    >
                      View More Details
                    </Button>
                  </Group>
                ),
              }),
              {},
            ),
        };

        setRecords(
          data
            .filter((e) => e.Benefit !== 'Plan Subtitle')
            .map((e, index) => {
              return { ...e, index };
            })
            .concat([additionalInformationHeaderRow, viewSBCButtonsRow]), // Add both rows
        );

        const plansCount = Array(
          Object.keys(data[0]).filter((d) => d.includes('Plan')).length / 2,
        ).keys();

        // get plans count/headers
        const plans = Array.from(plansCount).map((p, idx) => {
          let subtitle = '';

          if (data[0]['Benefit'] === 'Plan Subtitle') {
            subtitle = data[0]['Plan ' + (idx + 1) + ' INN'];
          }

          return {
            id: idx + 1,
            name: 'Option ' + (idx + 1),
            subtitle: subtitle,
          };
        });

        setPlans(plans);

        if (onLoad) {
          onLoad();
        }
      },
      error: (err) => {
        console.error('Error parsing CSV:', err);
      },
    });
  }, []);

  if (!premiums || premiums.length === 0) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Loader size="lg" />
        {/* <Text>Calculating Premiums</Text> */}
      </div>
    );
  }

  return (
    <>
      <div id="search-bar">
        {/* <Text ta="right" size="xs">
          *Hover on benefits marked with an asterisk for additional information
        </Text> */}

        <Text size="sm" fw={700}>
          Search for a covered benefit
        </Text>
        <TextInput
          color="green"
          placeholder="Search: eg. surgery"
          mb={'md'}
          styles={(x) => ({
            input: {
              border: `1.5px solid ${theme.colors.blue[6]}`,
              '::placeholder': {
                color: 'red',
                opacity: 1,
              },
            },
          })}
          leftSection={!query ? <IconSearch size={16} /> : null}
          rightSection={
            query && (
              <ActionIcon
                size="sm"
                variant="transparent"
                c="dimmed"
                onClick={() => setQuery('')}
              >
                <IconX size={14} />
              </ActionIcon>
            )
          }
          value={query}
          onChange={(e) => setQuery(e.currentTarget.value)}
        />
      </div>

      <Flex id="option-buttons" justify="center" align="flex-start" mb={20}>
        <Button.Group mr={10}>
          {plans.map((p, idx) => (
            <Button
              key={idx}
              variant="default"
              style={{
                height: '30px',
                padding: '5px 20px',
                color:
                  filters['hide_' + idx] === true
                    ? '#CCC'
                    : 'var(--mantine-color-blue-light-color)',
              }}
              onClick={() => {
                setFilters((e) => {
                  const newValues = {
                    ...e,
                    ['hide_' + idx]: !e['hide_' + idx],
                  };
                  const limitHiddenColumns =
                    Object.entries(newValues).filter(
                      (e) => e[0].includes('hide_') && e[1] === true,
                    ).length === plans.length;

                  if (limitHiddenColumns) {
                    return e;
                  }
                  return { ...newValues };
                });
              }}
            >
              {p.name}
            </Button>
          ))}
        </Button.Group>
        &nbsp;
        <Button.Group>
          <Button
            variant="default"
            style={{
              height: '30px',
              padding: '5px 20px',
              color:
                filters.hideInn === true
                  ? '#CCC'
                  : 'var(--mantine-color-blue-light-color)',
            }}
            onClick={() => {
              setFilters((e) => {
                if (!filters.hideInn && filters.hideOon) {
                  return { ...e, hideInn: !e.hideInn, hideOon: !e.hideOon };
                }
                return { ...e, hideInn: !e.hideInn };
              });
            }}
          >
            {`INN - In Network`}
          </Button>
          <Button
            variant="default"
            style={{
              height: '30px',
              padding: '5px 20px',
              color:
                filters.hideOon === true
                  ? '#CCC'
                  : 'var(--mantine-color-blue-light-color)',
            }}
            onClick={() => {
              setFilters((e) => {
                if (filters.hideInn && !filters.hideOon) {
                  return { ...e, hideInn: !e.hideInn, hideOon: !e.hideOon };
                }
                return { ...e, hideOon: !e.hideOon };
              });
            }}
          >
            {`OON - Out of Network`}
          </Button>
        </Button.Group>
      </Flex>

      <DataTable
        scrollAreaProps={{
          type: 'always',
          style: {
            maxHeight: 'none',
            height: '100%',
          },
        }}
        className={classes.root}
        withColumnBorders
        withTableBorder={false}
        borderRadius="sm"
        striped
        highlightOnHover
        customRowAttributes={(row) => {
          if (row.Benefit.match(/^\#\#/)) {
            return {
              style: {
                background: '#ebe9fa',
                fontWeight: 'bold',
                borderRight: 'none',
              },
            };
          } else {
            return {};
          }
        }}
        groups={[
          {
            id: 'Benefit',
            title: '',
            style: {
              textAlign: 'center',
              fontSize: '18px',
            },
            columns: [
              {
                accessor: 'Benefit',
                title: <div id="benefit-header">Benefit</div>,
                filtering: query !== '',
                customCellAttributes: ({ Benefit }, index) => {
                  if (Benefit.match(/^\#\#/)) {
                    return {
                      style: {
                        background: '#ebe9fa',
                        color: '#333',
                        fontWeight: 'bold',
                      },
                      value: 'asdas',
                    };
                  } else {
                    return {};
                  }
                },
                render: (row, idx) => {
                  const Benefit = row['Benefit'].replace(/^\#\#/, '');
                  const BenefitNotes = row['Benefit Notes'] || '';
                  return BenefitNotes?.length > 0 ? (
                    <Popover
                      width={400}
                      position="bottom"
                      withArrow
                      shadow="md"
                      opened={showPopOver === idx}
                    >
                      <Popover.Target>
                        <div
                          className={classes.benefit}
                          onMouseEnter={() => {
                            setShowPopOver(idx);
                          }}
                          onMouseLeave={() => {
                            setShowPopOver(null);
                          }}
                        >
                          {Benefit}*
                        </div>
                      </Popover.Target>
                      <Popover.Dropdown className={classes.ppopover}>
                        {BenefitNotes}
                      </Popover.Dropdown>
                    </Popover>
                  ) : (
                    Benefit
                  );
                },
              },
            ],
          },
          ...optionColumns,
        ]}
        records={[...filteredRecords]}
        defaultColumnProps={{}}
      />

      <HiddenCoveredBenefitsTable query={query} />
      <ViewSBCDocument
        planId={selectedSBCPlan || undefined}
        opened={selectedSBCPlan !== null}
        onClose={() => setSelectedSBCPlan(null)}
      />
    </>
  );
}
