import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Group,
  Collapse,
  Paper,
  Grid,
  Input,
} from '@mantine/core';
import { IMaskInput } from 'react-imask';
import { notifications } from '@mantine/notifications';
import useHttpClient from './hooks/useHttpClient';
import { useFormDirtyAlert } from '../helpers/useFormDirtyAlert';
import { loadScript } from '../helpers/loadScript';

interface FormProps {
  memberId: number;
}

const ReferenceForm: React.FC<FormProps> = ({ memberId }) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty, dirtyFields },
    setValue,
  } = useForm({});

  useFormDirtyAlert(reset, isDirty, dirtyFields);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'references',
  });

  const [collapsedIndices, setCollapsedIndices] = useState(
    fields.map(() => true),
  );
  const [initialData, setInitialData] = useState<any>();
  const httpClient = useHttpClient();
  const locationAddressRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [addressPopulated, setAddressPopulated] = useState<boolean[]>([]);

  const fetchReferences = async () => {
    try {
      const response = await httpClient.get(`/api/references/${memberId}`);
      const fetchedReferences = response.data;

      const sortedReferences = fetchedReferences.sort((a: any, b: any) => {
        const lastNameComparison = a.lastname.localeCompare(b.lastname);
        if (lastNameComparison !== 0) return lastNameComparison;
        const firstNameComparison = a.firstname.localeCompare(b.firstname);
        if (firstNameComparison !== 0) return firstNameComparison;
        return a.id - b.id;
      });

      reset({ references: sortedReferences });
      setInitialData(sortedReferences);
    } catch (error) {
      console.error('Error fetching references:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch references',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  useEffect(() => {
    fetchReferences();
  }, [memberId]);

  const toggleCollapse = (index: number) => {
    setCollapsedIndices((prev) => {
      const newCollapsedIndices = [...prev];
      newCollapsedIndices[index] = !newCollapsedIndices[index];
      return newCollapsedIndices;
    });
  };

  const handlePlaceSelect = useCallback(
    async (autocomplete: google.maps.places.Autocomplete, index: number) => {
      if (!autocomplete) return;

      const place = autocomplete.getPlace();
      if (!place.address_components) return;

      setValue(`references.${index}.street1`, '');
      setValue(`references.${index}.street2`, '');
      setValue(`references.${index}.city`, '');
      setValue(`references.${index}.state`, '');
      setValue(`references.${index}.zip`, '');

      let streetNumber = '';
      let route = '';
      let street2 = '';
      let city = '';
      let state = '';
      let zipCode = '';

      place.address_components?.forEach((component) => {
        const types = component.types;
        if (types.includes('street_number')) streetNumber = component.long_name;
        if (types.includes('route')) route = component.long_name;
        if (types.includes('subpremise')) street2 = component.long_name;
        if (types.includes('locality')) city = component.long_name;
        else if (types.includes('sublocality')) city = component.long_name;
        if (types.includes('administrative_area_level_1'))
          state = component.short_name;
        if (types.includes('postal_code')) zipCode = component.long_name;
      });

      const street1 = `${streetNumber} ${route}`.trim();
      if (street1) {
        setAddressPopulated((prev) => {
          const newState = [...prev];
          newState[index] = true;
          return newState;
        });
      }

      setValue(`references.${index}.street1`, street1);
      setValue(`references.${index}.street2`, street2);
      setValue(`references.${index}.city`, city);
      setValue(`references.${index}.state`, state);
      setValue(`references.${index}.zip`, zipCode);
      setValue(`references.${index}.country`, 'US');
    },
    [setValue],
  );

  const resetAddress = (index: number) => {
    setAddressPopulated((prev) => {
      const newState = [...prev];
      newState[index] = false;
      return newState;
    });
    if (locationAddressRefs.current[index]) {
      locationAddressRefs.current[index]!.value = '';
    }
  };

  useEffect(() => {
    setAddressPopulated((prev) => {
      const newState = [...prev];
      while (newState.length < fields.length) {
        newState.push(false);
      }
      return newState.slice(0, fields.length);
    });
  }, [fields.length]);

  useEffect(() => {
    const autocompletes: (google.maps.places.Autocomplete | null)[] = [];

    const initAutocomplete = () => {
      locationAddressRefs.current.forEach((ref, index) => {
        if (ref && window.google) {
          const autocomplete = new window.google.maps.places.Autocomplete(ref, {
            types: ['address'],
            componentRestrictions: { country: 'us' },
            fields: [
              'address_components',
              'formatted_address',
              'geometry',
              'name',
            ],
          });

          autocomplete.addListener('place_changed', () => {
            handlePlaceSelect(autocomplete, index);
          });

          autocompletes[index] = autocomplete;
        }
      });
    };

    const googleMapsScriptUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;

    if (!window.google) {
      loadScript(googleMapsScriptUrl)
        .then(() => {
          initAutocomplete();
        })
        .catch((err) =>
          console.error('Failed to load Google Maps script:', err),
        );
    } else {
      initAutocomplete();
    }

    return () => {
      autocompletes.forEach((autocomplete) => {
        if (autocomplete) {
          google.maps.event.clearInstanceListeners(autocomplete);
        }
      });
    };
  }, [fields.length, watch, handlePlaceSelect]);

  const onSubmit = async (data: any) => {
    console.log('submit form values', data);

    const formatData = (items: any[]) => {
      return items.map((item) => ({
        ...item,
        memberid: memberId,
      }));
    };

    const formattedData = formatData(data.references);

    const createReference = async (reference: any) => {
      const response = await httpClient.post('/api/references', reference);
      return response.data;
    };

    const updateReference = async (reference: any) => {
      const response = await httpClient.put(
        `/api/references/${reference.id}`,
        reference,
      );
      return response.data;
    };

    const deleteReference = async (id: number) => {
      await httpClient.delete(`/api/references/${id}`);
      console.log('delete response');
    };

    // Determine deletions, creations, and updates
    const initialIds = new Set<number>(initialData?.map((r: any) => r.id));
    const currentIds = new Set<number>(formattedData.map((r: any) => r.id));
    const deletedIds = [...initialIds].filter(
      (id): id is number => !currentIds.has(id),
    );

    const createTasks = formattedData
      .filter((r: any) => !r.id || r.id === 0)
      .map((r: any) => createReference(r));
    const updateTasks = formattedData
      .filter((r: any) => r.id && r.id > 0 && initialIds.has(r.id))
      .map((r: any) => updateReference(r));

    const deleteTasks = deletedIds.map((id) => deleteReference(id));

    try {
      const results = await Promise.all([
        ...createTasks,
        ...updateTasks,
        ...deleteTasks,
      ]);
      console.log('Results:', results);
      fetchReferences();

      notifications.show({
        title: 'Success',
        message: 'References updated successfully',
        color: 'green',
        position: 'bottom-center',
      });
    } catch (error) {
      console.error('Error submitting reference form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit reference',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  return (
    <Container>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        {fields.map((item, index) => (
          <Paper key={item.id} shadow="xs" p="md" withBorder>
            <Group justify="space-between">
              <Group>
                <h3>Reference {index + 1}</h3>
                <Button
                  id={`toggle-collapse-reference-${index}`}
                  onClick={() => toggleCollapse(index)}
                  size="xs"
                >
                  {collapsedIndices[index] ? 'Expand' : 'Collapse'}
                </Button>
                <Button
                  id={`remove-reference-${index}`}
                  color="red"
                  onClick={() => remove(index)}
                  size="xs"
                >
                  Remove
                </Button>
              </Group>
            </Group>
            <Collapse in={!collapsedIndices[index]}>
              <Grid>
                <Grid.Col span={6}>
                  <Controller
                    name={`references.${index}.providertype`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        id={`references.${index}.providertype`}
                        {...field}
                        label="Provider Type"
                        data={[
                          { value: 'physician', label: 'Physician' },
                          { value: 'other', label: 'Other medical provider' },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    id={`references.${index}.firstname`}
                    label="First Name"
                    {...register(`references.${index}.firstname`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    id={`references.${index}.lastname`}
                    label="Last Name"
                    {...register(`references.${index}.lastname`)}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <TextInput
                    id={`references.${index}.address`}
                    label="Аddress - Start typing the address below and pick one from the dropdown"
                    placeholder="Start by typing here"
                    data-index={index}
                    ref={(el) => (locationAddressRefs.current[index] = el)}
                    onChange={(e) => {
                      if (!e.target.value) {
                        resetAddress(index);
                      }
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    id={`references.${index}.street1`}
                    label={'\u0405treet 1'}
                    {...register(`references.${index}.street1`)}
                    disabled
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    id={`references.${index}.street2`}
                    label={'\u0405treet 2'}
                    {...register(`references.${index}.street2`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    id={`references.${index}.city`}
                    label="City"
                    {...register(`references.${index}.city`)}
                    disabled
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`references.${index}.state`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        id={`references.${index}.state`}
                        {...field}
                        label="State"
                        data={[
                          'AK',
                          'AL',
                          'AR',
                          'AS',
                          'AZ',
                          'CA',
                          'CO',
                          'CT',
                          'DC',
                          'DE',
                          'FL',
                          'GA',
                          'GU',
                          'HI',
                          'IA',
                          'ID',
                          'IL',
                          'IN',
                          'KS',
                          'KY',
                          'LA',
                          'MA',
                          'MD',
                          'ME',
                          'MI',
                          'MN',
                          'MO',
                          'MP',
                          'MS',
                          'MT',
                          'NC',
                          'ND',
                          'NE',
                          'NH',
                          'NJ',
                          'NM',
                          'NV',
                          'NY',
                          'OH',
                          'OK',
                          'OR',
                          'PA',
                          'PR',
                          'RI',
                          'SC',
                          'SD',
                          'TN',
                          'TX',
                          'UM',
                          'UT',
                          'VA',
                          'VI',
                          'VT',
                          'WA',
                          'WI',
                          'WV',
                          'WY',
                        ].map((state) => ({ value: state, label: state }))}
                        clearable
                        searchable
                        disabled
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    id={`references.${index}.zip`}
                    label="ZIP"
                    {...register(`references.${index}.zip`)}
                    maxLength={5}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.target.value = e.target.value.replace(/\D/g, ''); // Replace any non-numeric characters
                    }}
                    disabled
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`references.${index}.country`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        id={`references.${index}.country`}
                        {...field}
                        label="Country"
                        data={[{ value: 'US', label: 'United States' }]}
                        clearable
                        searchable
                        disabled
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    id={`references.${index}.emailaddress`}
                    label="Email Address"
                    {...register(`references.${index}.emailaddress`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`references.${index}.phone`}
                    control={control}
                    render={({ field }) => (
                      <Input.Wrapper label="Phone">
                        <Input
                          id={`references.${index}.phone`}
                          data-testid={`references.${index}.phone`}
                          component={IMaskInput}
                          mask="(000) 000-0000"
                          placeholder="(XXX) XXX-XXXX"
                          {...field}
                        />
                      </Input.Wrapper>
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`references.${index}.fax`}
                    control={control}
                    render={({ field }) => (
                      <Input.Wrapper label="Fax">
                        <Input
                          id={`references.${index}.fax`}
                          data-testid={`references.${index}.fax`}
                          component={IMaskInput}
                          mask="(000) 000-0000"
                          placeholder="(XXX) XXX-XXXX"
                          {...field}
                        />
                      </Input.Wrapper>
                    )}
                  />
                </Grid.Col>
              </Grid>
            </Collapse>
          </Paper>
        ))}
        <Group mt="md">
          <Button
            id="add-reference"
            onClick={() =>
              append({
                providertype: '',
                firstname: '',
                lastname: '',
                street1: '',
                street2: '',
                city: '',
                state: '',
                zip: '',
                country: 'US',
                emailaddress: '',
                phone: '',
                fax: '',
              })
            }
          >
            Add Reference
          </Button>
        </Group>
        <Group mt="md">
          <Button id="save-reference" type="submit">
            Save
          </Button>
        </Group>
      </form>
    </Container>
  );
};

export default ReferenceForm;
