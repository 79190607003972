import React from 'react';
import {
  Container,
  Box,
  Group,
  Card,
  Image,
  Text,
  Title,
  SimpleGrid,
  Center,
} from '@mantine/core';
import { IconPill } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { useComputedColorScheme } from '@mantine/core';

const benefits = [
  {
    id: 1,
    title: 'Ryze Health Insurance',
    description: 'Health insurance by doctors, for doctors',
    image: 'ryze_logo', // Special case for Ryze logo
    link: 'https://portal.ryzehealth.com/insurance/onboarding',
  },
  {
    id: 2,
    title: 'Discount Specialty Medication Pharmacy',
    description: 'Coming Soon',
    image: ``,
    fallbackImage:
      'https://images.unsplash.com/photo-1587854692152-cbe660dbde88?q=80&w=2069&auto=format&fit=crop',
  },
];

const WecareBenefitsPage = () => {
  const navigate = useNavigate();
  const computedColorScheme = useComputedColorScheme('light');

  const handleCardClick = (link: string) => {
    // For external links, open in a new tab
    if (!link) {
      return;
    }
    if (link.startsWith('http')) {
      window.open(link, '_blank', 'noopener,noreferrer');
    } else {
      // For internal links, use navigate
      navigate(link);
    }
  };

  const getImageSrc = (benefit) => {
    if (benefit.image === 'ryze_logo') {
      return computedColorScheme === 'dark'
        ? `${process.env.PUBLIC_URL}/Logo_White.png`
        : `${process.env.PUBLIC_URL}/Logo_Full.png`;
    }

    // Try to use the specified image, fall back to the fallback image if available
    return benefit.image || benefit.fallbackImage;
  };

  return (
    <>
      {/* Header Section */}
      <Box p="xs">
        <Group justify={'start'}>
          <IconPill size={30} />
          <Title order={2}>Benefits</Title>
        </Group>
      </Box>

      {/* Benefits Section */}
      <Container size="xl" my="md">
        <SimpleGrid cols={{ base: 1, sm: 2, md: 3 }} spacing="lg">
          {benefits.map((benefit) => (
            <Card
              key={benefit.id}
              shadow="sm"
              padding="lg"
              radius="md"
              withBorder
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              }}
              onClick={() => handleCardClick(benefit.link)}
              onMouseOver={(e) => {
                e.currentTarget.style.transform = 'translateY(-5px)';
                e.currentTarget.style.boxShadow = '0 10px 20px rgba(0,0,0,0.1)';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.transform = 'translateY(0)';
                e.currentTarget.style.boxShadow = '';
              }}
            >
              <Card.Section>
                {benefit.image === 'ryze_logo' ? (
                  <Center
                    p="xl"
                    style={{
                      height: 200,
                      backgroundColor:
                        computedColorScheme === 'dark' ? '#1A1B1E' : '#f8f9fa',
                    }}
                  >
                    <Image
                      src={getImageSrc(benefit)}
                      alt={benefit.title}
                      style={{
                        maxWidth: '80%',
                        maxHeight: '80%',
                        objectFit: 'contain',
                      }}
                    />
                  </Center>
                ) : (
                  <Image
                    src={getImageSrc(benefit)}
                    height={200}
                    alt={benefit.title}
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      if (
                        benefit.fallbackImage &&
                        target.src !== benefit.fallbackImage
                      ) {
                        target.src = benefit.fallbackImage;
                      }
                    }}
                  />
                )}
              </Card.Section>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  padding: '16px 0',
                }}
              >
                <Title order={4}>{benefit.title}</Title>
                <Text size="md" color="dimmed" mt="xs" style={{ flexGrow: 1 }}>
                  {benefit.description}
                </Text>
              </Box>
            </Card>
          ))}
        </SimpleGrid>
      </Container>
    </>
  );
};

export default WecareBenefitsPage;
