import { Stack, Box, Select, Grid } from '@mantine/core';
import { useState, useEffect } from 'react';
import CustomDateInput from '../CustomDateInput';
import {
  Controller,
  FieldValues,
  Control,
  UseFormRegister,
} from 'react-hook-form';

interface PracticeLocationAffliationFormProps {
  control: Control<FieldValues, any>;
  register: UseFormRegister<FieldValues>;
  practiceIndex: number;
  practiceLocationIndex: number;
  loading: boolean;
}

const PracticeLocationAffliationForm: React.FC<
  PracticeLocationAffliationFormProps
> = ({ control, register, practiceIndex, practiceLocationIndex, loading }) => {
  const [affiliation, setAffiliation] = useState<string | null>(null);

  const affiliationOptions = [
    { value: '', label: '--Select--' },
    {
      value: 'regular-weekly',
      label:
        'I see patients by appointment at least one day per week on a regular basis',
    },
    {
      value: 'regular-monthly',
      label:
        'I see patients by appointment at least one day per month, but less than one day per week on a regular basis',
    },
    {
      value: 'no-appointment',
      label: 'I see patients at this location, but not by appointment',
    },
    {
      value: 'cover',
      label:
        'I cover or fill-in for colleagues within the same medical group on an as needed basis',
    },
    {
      value: 'services',
      label:
        'I read tests, perform imaging, or provide other services as my primary function at this location',
    },
    { value: 'no-longer', label: 'I no longer practice at this location' },
    {
      value: 'employed',
      label:
        'I do not practice here, but the location is with in the medical group with which I am employed',
    },
    {
      value: 'never',
      label:
        'I never practiced here and have no affiliation with this location',
    },
    {
      value: 'duplicate',
      label: 'This is a duplicate of an existing location',
    },
  ];

  const showEndDate = affiliation === 'no-longer';

  useEffect(() => {
    const value = control._getWatch(
      `practices.${practiceIndex}.practicelocations.${practiceLocationIndex}.affiliation`,
    );
    setAffiliation(value);
  }, [control, practiceIndex, practiceLocationIndex]);

  return (
    <Box p="md" w="100%">
      <Stack>
        <Controller
          name={`practices.${[practiceIndex]}.practicelocations.${practiceLocationIndex}.affiliation`}
          control={control}
          render={({ field }) => (
            <Select
              id={`practice-location-affiliation-${practiceIndex}`}
              {...field}
              label="Please describe your affiliation with this location"
              placeholder="Select your affiliation"
              data={affiliationOptions}
              searchable
              clearable
              maxDropdownHeight={200}
              styles={{ dropdown: { maxWidth: '100%' } }}
              disabled={loading}
            />
          )}
        />

        <Grid>
          <Grid.Col span={showEndDate ? 6 : 12}>
            <CustomDateInput
              control={control}
              name={`practices.${[practiceIndex]}.practicelocations.${practiceLocationIndex}.startDate`}
              label="Provider's Start Date"
              disabled={loading}
            />
          </Grid.Col>

          {showEndDate && (
            <Grid.Col span={6}>
              <CustomDateInput
                control={control}
                name={`practices.${[practiceIndex]}.practicelocations.${practiceLocationIndex}.endDate`}
                label="Provider's End Date"
                disabled={loading}
              />
            </Grid.Col>
          )}
        </Grid>
      </Stack>
    </Box>
  );
};

export default PracticeLocationAffliationForm;
