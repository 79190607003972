import { useMemo, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Paper, Progress, Box, Text } from '@mantine/core';
import WecareLogo from '../WecareLogo';
import ProviderTypeScreen from './ProviderTypeScreen';
import WorkStatusScreen from './WorkStatusScreen';
import OwnershipStatusScreen from './OwnershipStatusScreen';
import LLCScreen from './LLCScreen';
import PracticeInfoScreen from './PracticeInfoScreen';
import LLCInfoScreen from './LLCInfoScreen';
import IndividualInfoScreen from './IndividualInfoScreen';
import EmailVerificationScreen from '../RyzeOnboarding/EmailVerificationScreen';

type WecareWorkingStatus =
  | 'plan_work_12_months'
  | 'retired'
  | 'in_training'
  | 'working_owner_medical_practice'
  | 'working_non_owner_partner_employee_1099'
  | 'working_1099_own_llc'
  | 'working_non_clinical_work';

function WecareOnboarding() {
  const search = useLocation().search;
  const debug = new URLSearchParams(search).get('debug');
  const screen = new URLSearchParams(search).get('screen');
  const emailFromUrl = new URLSearchParams(search).get('email');
  const npiFromUrl = new URLSearchParams(search).get('npi');

  const [answers, setAnswers] = useState({});
  const [individualInfo, setIndividualInfo] = useState({});
  const [practiceInfo, setPracticeInfo] = useState({});
  const [screenHistory, setScreenHistory] = useState([
    screen || 'provider_type',
  ]);
  const [currentScreen, setCurrentScreen] = useState(screen || 'provider_type');

  const nodes = {
    provider_type: ['work_status'],
    work_status: ['ownership_status', 'llc', 'individual_info'],

    ownership_status: [
      'practice_info_owner',
      'practice_info_employee',
      'llc_info',
      'individual_info',
    ],

    llc: ['llc_info', 'individual_info'],

    practice_info_owner: ['individual_info'],
    practice_info_employee: ['individual_info'],
    llc_info: ['individual_info'],

    individual_info: ['email_verification'],

    email_verification: [],
  };

  const back = () => {
    setScreenHistory((values) => {
      if (values.length > 1) {
        const newValues = values.slice(0, -1);
        setCurrentScreen(newValues[newValues.length - 1]);
        return newValues;
      }
      return values;
    });
  };

  const next = (pathIndex = 0) => {
    const availablePaths = nodes[currentScreen];
    if (availablePaths && availablePaths[pathIndex] !== undefined) {
      const nextScreen = availablePaths[pathIndex];
      setScreenHistory((values) => {
        setCurrentScreen(nextScreen);
        return [...values, nextScreen];
      });
    }
  };

  function findLongestPath(startScreen) {
    if (!nodes[startScreen] || nodes[startScreen].length === 0) return 0;
    return 1 + Math.max(...nodes[startScreen].map(findLongestPath));
  }

  const longestFuturePath = useMemo(
    () => findLongestPath(currentScreen),
    [currentScreen],
  );
  const completedSteps = screenHistory.length - 1;
  const totalSteps = completedSteps + longestFuturePath;
  const progressValue = Math.round((completedSteps / totalSteps) * 100);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (['provider_type', 'email_verification'].includes(currentScreen)) {
        return;
      }

      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [currentScreen]);

  useEffect(() => {
    if (emailFromUrl || npiFromUrl) {
      setIndividualInfo((prevInfo) => ({
        ...prevInfo,
        email: emailFromUrl || (prevInfo as any)?.email || '',
        npi: npiFromUrl || (prevInfo as any)?.npi || '',
      }));
    }
  }, [emailFromUrl, npiFromUrl]);

  return (
    <Container mt={50} mb={50} style={{ background: 'none' }}>
      <Paper withBorder shadow="md" p={30} radius="md" mt="lg">
        <WecareLogo />

        <Box
          style={{ position: 'relative', width: '100%', marginBottom: '20px' }}
        >
          <Progress
            value={progressValue}
            color="blue"
            size="lg"
            style={{ height: '20px', borderRadius: '10px' }}
          />
          <Text
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: progressValue < 50 ? 'black' : 'white',
              fontWeight: 'bold',
              pointerEvents: 'none',
            }}
          >
            {`${progressValue}%`}
          </Text>
        </Box>

        {currentScreen === 'provider_type' && (
          <ProviderTypeScreen
            answers={answers}
            setAnswers={setAnswers}
            next={next}
            back={back}
          />
        )}
        {currentScreen === 'work_status' && (
          <WorkStatusScreen
            answers={answers}
            setAnswers={setAnswers}
            next={next}
            back={back}
          />
        )}
        {currentScreen === 'ownership_status' && (
          <OwnershipStatusScreen
            answers={answers}
            setAnswers={setAnswers}
            next={next}
            back={back}
          />
        )}
        {currentScreen === 'llc' && (
          <LLCScreen
            answers={answers}
            setAnswers={setAnswers}
            next={next}
            back={back}
          />
        )}
        {currentScreen === 'practice_info_owner' && (
          <PracticeInfoScreen
            practiceInfo={practiceInfo}
            setPracticeInfo={setPracticeInfo}
            next={next}
            back={back}
          />
        )}
        {currentScreen === 'practice_info_employee' && (
          <PracticeInfoScreen
            isOwner={false}
            practiceInfo={practiceInfo}
            setPracticeInfo={setPracticeInfo}
            next={next}
            back={back}
          />
        )}
        {currentScreen === 'llc_info' && (
          <LLCInfoScreen
            answers={answers}
            setAnswers={setAnswers}
            next={next}
            back={back}
          />
        )}
        {currentScreen === 'individual_info' && (
          <IndividualInfoScreen
            individualInfo={individualInfo}
            setIndividualInfo={setIndividualInfo}
            practiceInfo={practiceInfo}
            answers={answers}
            next={next}
            back={back}
          />
        )}
        {currentScreen === 'email_verification' && <EmailVerificationScreen />}

        {debug && (
          <>
            <pre>History: {JSON.stringify(screenHistory, null, 2)}</pre>
            <pre>Completed: {completedSteps}</pre>
            <pre>Remaining: {longestFuturePath}</pre>
            <pre>Total Steps: {totalSteps}</pre>
            <pre>Progress: {progressValue}%</pre>
          </>
        )}
      </Paper>
    </Container>
  );
}

export default WecareOnboarding;
