import { Button, Group, Text, Radio } from '@mantine/core';

function CurrentlyWorkingScreen({ currentStep, answers, setAnswers, back, next }) {
  const key = currentStep + '.currentlyWorking';

  return (
    <>
      <Text size="lg">Are you currently working?</Text>

      <Group justify="flex-start" mt="xl">
        <Radio.Group
          value={answers[key] || ''}
          onChange={(newValue) => setAnswers((values) => ({ ...values, [key]: newValue }))}
        >
          <Radio value="yes" label="Yes" m={10} />
          <Radio value="planning" label="No, but I plan to within the next 12 months" m={10} />
          <Radio value="retired" label="I am retired" m={10} />
          <Radio value="training" label="I am in training" m={10} />
        </Radio.Group>
      </Group>

      <Group justify="flex-end" mt="xl">
        <Button
          variant="default"
          onClick={async () => {
            await setAnswers((answers) => {
              delete answers[key];
              return { ...answers };
            });
            back();
          }}
        >
          Back
        </Button>
        <Button
          onClick={() => {
            if (answers[key] === 'yes' || answers[key] === 'planning') {
              next(0);
            } else if (answers[key] === 'retired' || answers[key] === 'training') {
              next(1);
            }
          }}
          disabled={!answers[key]}
        >
          Next
        </Button>
      </Group>
    </>
  );
}

export default CurrentlyWorkingScreen;