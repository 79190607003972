import React, { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  TextInput,
  Container,
  Select,
  Grid,
  Title,
  Paper,
  Text,
  Group,
} from '@mantine/core';
import { STATES_ARRAY } from '../../types/members.types';
import { loadScript } from '../../helpers/loadScript';

interface PracticeReferralFormProps {
  initialData?: any;
  hooks: any;
}

const PracticeReferralForm: React.FC<PracticeReferralFormProps> = ({
  initialData,
  hooks,
}) => {
  const {
    control,
    register,
    reset,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = hooks;

  const [loading, setLoading] = useState(false);
  const addressRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let autocomplete: google.maps.places.Autocomplete | null = null;

    const handlePlaceSelect = async () => {
      if (!autocomplete) return;

      const place = autocomplete.getPlace();

      // Reset fields before handling the selected place
      setValue('address', '');
      setValue('street1', '');
      setValue('street2', '');
      setValue('city', '');
      setValue('state', '');
      setValue('zip', '');
      setValue('county', '');

      if (!place.address_components) {
        alert('Please select a complete address with a street number.');
        return;
      }

      let hasStreetNumber = false;
      place.address_components.forEach((component) => {
        if (component.types.includes('street_number')) {
          hasStreetNumber = true;
        }
      });

      if (!hasStreetNumber) {
        alert('Please select an address that includes a street number.');
        return;
      }

      let streetNumber = '';
      let route = '';
      let street2 = '';
      let city = '';
      let state = '';
      let zipCode = '';
      let county = '';

      place.address_components.forEach((component) => {
        const types = component.types;
        if (types.includes('street_number')) streetNumber = component.long_name;
        if (types.includes('route')) route = component.long_name;
        if (types.includes('subpremise')) street2 = component.long_name;
        if (types.includes('locality')) city = component.long_name;
        else if (types.includes('sublocality')) city = component.long_name;
        if (types.includes('administrative_area_level_1'))
          state = component.short_name;
        if (types.includes('postal_code')) zipCode = component.long_name;
        if (types.includes('administrative_area_level_2'))
          county = component.long_name.replace(' County', '');
      });

      const street1Value = `${streetNumber} ${route}`.trim();

      // Update the form fields and manually trigger validation only for address fields
      setValue('street1', street1Value, { shouldDirty: true });
      setValue('street2', street2, { shouldDirty: true });
      setValue('city', city, { shouldDirty: true });
      setValue('state', state, { shouldDirty: true });
      setValue('zip', zipCode, { shouldDirty: true });
      setValue('county', county, { shouldDirty: true });

      // Only trigger validation for address-related fields
      await trigger(['street1', 'street2', 'city', 'state', 'zip', 'county']);
    };

    const initAutocomplete = () => {
      if (addressRef.current && window.google) {
        autocomplete = new window.google.maps.places.Autocomplete(
          addressRef.current,
          {
            types: ['address'],
            componentRestrictions: { country: 'us' },
          },
        );
        autocomplete.addListener('place_changed', handlePlaceSelect);
      }
    };

    const googleMapsScriptUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
    loadScript(googleMapsScriptUrl)
      .then(initAutocomplete)
      .catch((err) => console.error('Failed to load Google Maps script:', err));

    return () => {
      if (autocomplete) {
        google.maps.event.clearInstanceListeners(autocomplete);
      }
    };
  }, [setValue, trigger]);

  useEffect(() => {
    if (initialData) {
      reset(initialData);
    }
  }, [initialData, reset]);

  return (
    <Group justify="center">
      <Container mt="xl" mb="xl">
        <form
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        >
          <>
            <Paper withBorder shadow="xs" p="md" mt="md">
              <Title order={3}>Your Information</Title>
              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    label="First Name"
                    required
                    error={errors?.firstname?.message}
                    {...register('firstname', {
                      required: 'This field is required.',
                    })}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Last Name"
                    required
                    error={errors?.lastname?.message}
                    {...register('lastname', {
                      required: 'This field is required',
                    })}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    type="email"
                    label="Email"
                    error={errors?.email?.message}
                    {...register('email', {
                      required: 'This field is required.',
                    })}
                    required
                  />
                </Grid.Col>
              </Grid>
            </Paper>
            <Paper withBorder shadow="xs" p="md" mt="md">
              <Title order={3} mb="sm">
                Practice Information
              </Title>
              <Grid>
                <Grid.Col span={12}>
                  <TextInput
                    label="Business Name"
                    required
                    disabled={loading}
                    error={errors?.name?.message}
                    {...register('name', {
                      required: 'This field is required',
                    })}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="state"
                    control={control}
                    rules={{
                      required: 'Please select a state',
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="State"
                        data={STATES_ARRAY.map((state) => ({
                          value: state,
                          label: state,
                        }))}
                        clearable
                        required
                        searchable
                        error={errors.state?.message}
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Practice Website"
                    disabled={loading}
                    {...register('practicewebsite', { required: false })}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Estimated Total Number of Employees"
                    type="number"
                    disabled={loading}
                    min={0}
                    error={errors.estimatedemployees?.message}
                    {...register('estimatedemployees', {
                      valueAsNumber: true,
                      min: { value: 0, message: 'Must be a positive number' },
                    })}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Estimated Number of Employees Joining Ryze"
                    type="number"
                    disabled={loading}
                    min={0}
                    {...register('estimatedjoiningemployees', {
                      valueAsNumber: true,
                      min: { value: 0, message: 'Must be a positive number' },
                    })}
                    error={errors.estimatedjoiningemployees?.message}
                  />
                </Grid.Col>
              </Grid>
            </Paper>
            {/* <Paper withBorder shadow="xs" p="md" mt="md">
              <Title order={3}>Address Information</Title>

              <Grid>
                <Grid.Col span={12}>
                  <TextInput
                    label={
                      '\u0410ddress - Start typing your address below and pick one from the dropdown'
                    }
                    ref={addressRef}
                    placeholder="Start by typing here"
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label={'\u0405treet 1'}
                    {...register('street1', {
                      required: 'This field is required.',
                    })}
                    disabled
                    required
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label={'\u0405treet 2'}
                    {...register('street2', {})}
                    disabled={loading}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="City"
                    {...register('city', {
                      required: 'This field is required.',
                    })}
                    disabled
                    readOnly
                    required
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="State"
                        data={STATES_ARRAY}
                        clearable
                        required
                        searchable
                        disabled
                        readOnly
                        {...register('state', {
                          required: 'This field is required.',
                        })}
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="ZIP"
                    required
                    disabled
                    readOnly
                    maxLength={5}
                    {...register('zip', {
                      required: 'This field is required.',
                    })}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="County"
                    required
                    disabled
                    readOnly
                    {...register('county', {
                      required: 'This field is required.',
                    })}
                  />
                </Grid.Col>
              </Grid>
            </Paper> */}
            <Paper withBorder shadow="xs" p="md" mt="md">
              <Title order={3}>Practice Contact</Title>
              <Text mt="xs" size="sm">
                Who should we speak to at the practice about Ryze?
              </Text>
              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    label="First Name"
                    required
                    error={errors?.referral_firstname?.message}
                    {...register('referral_firstname', {
                      required: 'This field is required',
                    })}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Last Name"
                    required
                    error={errors?.referral_lastname?.message}
                    {...register('referral_lastname', {
                      required: 'This field is required',
                    })}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Email"
                    required
                    error={errors?.referral_email?.message}
                    {...register('referral_email', {
                      required: 'This field is required',
                    })}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Title at Practice"
                    required
                    disabled={loading}
                    error={errors?.referral_title?.message}
                    {...register('referral_title', {
                      required: 'This field is required',
                    })}
                  />
                </Grid.Col>
              </Grid>
            </Paper>
          </>
        </form>
      </Container>
    </Group>
  );
};

export default PracticeReferralForm;
