import React from 'react';
import { Text, Select, Button, Group } from '@mantine/core';
import { useForm, Controller } from 'react-hook-form';
import { PROVIDER_TYPES } from '../../enums/providerTypes';

interface ProviderTypeScreenProps {
  answers: any;
  setAnswers: (answers: any) => void;
  next: (pathIndex?: number) => void;
  back: () => void;
}

const ProviderTypeScreen: React.FC<ProviderTypeScreenProps> = ({
  answers,
  setAnswers,
  next,
  back,
}) => {
  const { control, handleSubmit, watch } = useForm({
    defaultValues: { providertype: answers.providertype || '' },
  });

  const providerType = watch('providertype');

  const onSubmit = (data: { providertype: string }) => {
    setAnswers({ ...answers, providertype: data.providertype });

    next();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text size="xl" mb="md">
        What kind of provider are you?
      </Text>

      <Controller
        name="providertype"
        control={control}
        rules={{ required: 'Provider Type is required' }}
        render={({ field, fieldState: { error } }) => (
          <Select
            {...field}
            label="Provider Type"
            required
            error={error?.message}
            data={Object.entries(PROVIDER_TYPES).map(([value, label]) => ({
              value,
              label: `${value} - ${label}`,
            }))}
            clearable
            searchable
          />
        )}
      />

      <Group justify="flex-end" mt="xl">
        <Button variant="default" onClick={back}>
          Back
        </Button>
        <Button type="submit" disabled={!providerType}>
          Next
        </Button>
      </Group>
    </form>
  );
};

export default ProviderTypeScreen;