import React, { createContext, useState, useContext, useEffect } from 'react';
import useHttpClient from './hooks/useHttpClient';

interface NotificationContextType {
  pendingApprovals: number;
  pendingMemberApprovals: number;
  pendingClinicApprovals: number;
  updatePendingApprovals: (count: number) => void;
  updatePendingMemberApprovals: (count: number) => void;
  updatePendingClinicApprovals: (count: number) => void;
  refreshPendingApprovals: () => Promise<void>;
}

const NotificationContext = createContext<NotificationContextType>({
  pendingApprovals: 0,
  pendingMemberApprovals: 0,
  pendingClinicApprovals: 0,
  updatePendingApprovals: () => {},
  updatePendingMemberApprovals: () => {},
  updatePendingClinicApprovals: () => {},
  refreshPendingApprovals: async () => {},
});

export const useNotifications = () => useContext(NotificationContext);

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [pendingApprovals, setPendingApprovals] = useState(0);
  const [pendingMemberApprovals, setPendingMemberApprovals] = useState(0);
  const [pendingClinicApprovals, setPendingClinicApprovals] = useState(0);
  const httpClient = useHttpClient();

  const isPublicPath = (path: string) => {
    const publicPaths = [
      '/login',
      '/wecare/login',
      '/reset-password',
      '/insurance/onboarding',
      '/wecare/onboarding',
    ];
    return publicPaths.some((p) => path.startsWith(p));
  };

  const refreshPendingApprovals = async () => {
    try {
      // WeCare approvals
      try {
        const wecareResponse = await httpClient.get('/api/members/approve');
        setPendingApprovals(wecareResponse.data.length);
      } catch (error) {
        console.error('Error fetching WeCare approvals:', error);
      }

      // Insurance member approvals
      try {
        const memberResponse = await httpClient.get('/api/subscriber/approve');
        setPendingMemberApprovals(memberResponse.data.length);
      } catch (error) {
        console.error('Error fetching member approvals:', error);
      }

      // Insurance clinic approvals
      try {
        const clinicResponse = await httpClient.get(
          '/api/organization/approve',
        );
        setPendingClinicApprovals(clinicResponse.data.length);
      } catch (error) {
        console.error('Error fetching clinic approvals:', error);
      }
    } catch (error) {
      console.error('Error refreshing pending approvals count:', error);
    }
  };

  useEffect(() => {
    // Skip API calls for public paths
    if (!isPublicPath(window.location.pathname)) {
      refreshPendingApprovals();
    }
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        pendingApprovals,
        pendingMemberApprovals,
        pendingClinicApprovals,
        updatePendingApprovals: setPendingApprovals,
        updatePendingMemberApprovals: setPendingMemberApprovals,
        updatePendingClinicApprovals: setPendingClinicApprovals,
        refreshPendingApprovals,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
