import { useState } from 'react';
import { Text, Group, Stack, Modal, rem } from '@mantine/core';
import {
  IconUpload,
  IconPhoto,
  IconX,
  IconCheck,
  IconAlertCircle,
} from '@tabler/icons-react';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import useHttpClient from './hooks/useHttpClient';
import { notifications } from '@mantine/notifications';

interface CsvUploaderProps {
  title: string;
  opened: boolean;
  onClose: () => void;
}

const CsvUploader = ({ opened, onClose, title }: CsvUploaderProps) => {
  const [loading, setLoading] = useState(false);
  const httpClient = useHttpClient();

  const handleFileUpload = async (file: File) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await httpClient.post(
        '/api/payment/upload-and-update-status',
        formData,
      );
      notifications.show({
        title: 'Success',
        message: response.data.message,
        color: 'green',
        icon: <IconCheck />,
        autoClose: 5000,
      });
    } catch (error: any) {
      if (error.response?.status === 400) {
        error.response.data.errors.forEach((err: { message: string }) => {
          notifications.show({
            title: 'Error',
            message: err.message,
            color: 'red',
            icon: <IconAlertCircle />,
            autoClose: 8000,
          });
        });
      } else {
        notifications.show({
          title: 'Unexpected Error',
          message: 'An unexpected error occurred. Please try again.',
          color: 'red',
          icon: <IconAlertCircle />,
          autoClose: 5000,
        });
      }
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={title}
      closeOnClickOutside={!loading}
      closeOnEscape={!loading}
      withCloseButton={!loading}
      centered
      size="lg"
    >
      <Stack gap={'md'}>
        <Dropzone
          onDrop={(files) => files?.[0] && handleFileUpload(files[0])}
          maxSize={25 * 1024 * 1024}
          accept={[MIME_TYPES.csv]}
          multiple={false}
          loading={loading}
        >
          <Group justify="center" gap="xl" mih={220}>
            <Dropzone.Accept>
              <IconUpload
                style={{
                  width: rem(52),
                  height: rem(52),
                  color: 'var(--mantine-color-blue-6)',
                }}
                stroke={1.5}
              />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <IconX
                style={{
                  width: rem(52),
                  height: rem(52),
                  color: 'var(--mantine-color-red-6)',
                }}
                stroke={1.5}
              />
              <Text c="red" mt="sm" style={{ textAlign: 'center' }}>
                File not accepted. Please upload a valid CSV file.
              </Text>
            </Dropzone.Reject>
            <Dropzone.Idle>
              <IconPhoto
                style={{
                  width: rem(52),
                  height: rem(52),
                  color: 'var(--mantine-color-dimmed)',
                }}
                stroke={1.5}
              />
            </Dropzone.Idle>

            <div>
              <Text size="xl" inline>
                Drag your file here or click to select
              </Text>
              <Text size="sm" c="dimmed" inline mt={7}>
                Only CSV files are accepted, and the file size must not exceed
                25 MB.
              </Text>
            </div>
          </Group>
        </Dropzone>
      </Stack>
    </Modal>
  );
};

export default CsvUploader;
