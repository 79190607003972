import React, { useState, useEffect } from 'react';
import {
  Container,
  TextInput,
  Button,
  Group,
  Grid,
  Flex,
  Checkbox,
  Input,
  ActionIcon,
} from '@mantine/core';
import DarkModeToggle from '../DarkModeToggle';
import useHttpClient from '../hooks/useHttpClient';
import { Title, Table } from '@mantine/core';
import ConfirmMemberDeleteModal from '../ConfirmMemberDeleteModal';
import UpdateAccountStatusModal from '../UpdateAccountStatusModal';
import { notifications } from '@mantine/notifications';
import CsvUploader from '../CsvUploader';
import { IconDeviceFloppy, IconEdit } from '@tabler/icons-react';
import PaymentIDInputModal from './PaymentIDInputModal';

const Payments: React.FC = (props) => {
  const httpClient = useHttpClient();

  //Account States
  const [accounts, setAccounts] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [accountId, setAccountId] = useState<number>(0);

  //Loading States
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isUpdatingPaymentID, setIsUpdatingPaymentID] = useState<number | null>(
    null,
  );
  const [isEditingCurrentPaymentIDField, setIsEditingCurrentPaymentIDField] =
    useState<number | null>(null);

  //Modal States
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] =
    useState(false);
  const [
    openUpdateStatusConfirmationModal,
    setOpenUpdateStatusConfirmationModal,
  ] = useState(false);

  const [openBulkUpdatePaymentIDModal, setOpenBulkUpdatePaymentIDModal] =
    useState(false);

  const [openCSVUploader, setOpenCSVUploader] = useState(false);

  const maskPaymentId = (paymentId: string) => {
    return `${paymentId.slice(0, 2)}****${paymentId.slice(-2)}`;
  };

  const fetchAllAccounts = async () => {
    try {
      const response = await httpClient.get('/api/payment/all-accounts');
      setAccounts(response.data);
    } catch (error) {
      console.error('Error fetching members', error);
    }
  };

  useEffect(() => {
    fetchAllAccounts();
  }, []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleCheckboxChange = (id: number, checked: boolean) => {
    setSelectedAccounts((prev) =>
      checked ? [...prev, id] : prev.filter((accountId) => accountId !== id),
    );
  };

  const filteredAccounts = accounts
    .filter((account) =>
      `${account.paymentId} ${account.accountName} ${account.bankAccountNumber} ${account.bankRoutingNumber} ${account.accountStatus}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase()),
    )
    .sort((a, b) => {
      if (a.accountName > b.accountName) return 1;
      if (a.accountName < b.accountName) return -1;

      return 0;
    });

  const handleOpenBulkUpdatePaymentIDModal = async () => {
    setOpenBulkUpdatePaymentIDModal(true);
  };

  const handleOpenCSVUploader = async () => {
    setOpenCSVUploader(true);
  };

  const handleOpenUpdateStatusConfirmationModal = async () => {
    setOpenUpdateStatusConfirmationModal(true);
  };

  const handleDeleteAccountButtonClick = (memberId: number) => {
    setAccountId(memberId);
    setOpenDeleteConfirmationModal(true);
  };

  const handleSoftDeleteAccount = async (memberId: number) => {
    console.log('Account to delete:', memberId);
  };

  const handleStatusUpdateSuccess = () => {
    setOpenUpdateStatusConfirmationModal(false);
    fetchAllAccounts(); // Re-fetch accounts after update
    setSelectedAccounts([]); // Clear selected accounts
  };

  const handleDownloadCsv = async () => {
    setIsDownloading(true);
    try {
      const response = await httpClient.get(
        '/api/payment/download-payment-info',
      );

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'text/csv' });

        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = 'payment-info.csv';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);

        notifications.show({
          title: 'Success!',
          message: 'CSV Downloaded Successfully!',
          color: 'green',
          position: 'bottom-center',
        });
      }
    } catch (error) {
      console.error('Error downloading payment info', error);
      notifications.show({
        title: 'Error!',
        message: 'Error downloading payment info',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const handleUpdatingPaymentId = async (id: number, paymentId: number) => {
    setIsUpdatingPaymentID(id);
    try {
      const response = await httpClient.put(
        `/api/payment/update-payment-id/${id}`,
        { paymentId },
      );

      // Check if the response indicates success
      if (response.status === 200) {
        notifications.show({
          title: 'Success',
          message: `Payment ID updated successfully`,
          color: 'green',
        });

        setAccounts((prevAccounts) =>
          prevAccounts.map((account) =>
            account.id === id ? { ...account, paymentId } : account,
          ),
        );
      }
    } catch (error) {
      console.error('Error updating Payment ID:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to update Payment ID',
        color: 'red',
      });
    } finally {
      setIsUpdatingPaymentID(null);
      setIsEditingCurrentPaymentIDField(null);
    }
  };

  return (
    <Container size="xl">
      <Flex justify={'space-between'} gap={'md'}>
        <Title order={2} ta={'left'}>
          Payment Information
        </Title>

        {selectedAccounts.length > 0 && (
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleOpenUpdateStatusConfirmationModal();
            }}
            color={'blue'}
          >
            Manually Update Status
          </Button>
        )}
      </Flex>

      <ConfirmMemberDeleteModal
        opened={openDeleteConfirmationModal}
        onClose={() => setOpenDeleteConfirmationModal(false)}
        onSubmit={() => handleSoftDeleteAccount(accountId)}
        isDeleting={isDeleting}
      />

      <UpdateAccountStatusModal
        opened={openUpdateStatusConfirmationModal}
        onClose={() => setOpenUpdateStatusConfirmationModal(false)}
        selectedAccounts={selectedAccounts}
        onSuccess={handleStatusUpdateSuccess}
      />

      <CsvUploader
        title="Upload CSV to Bulk Update Account Status"
        opened={openCSVUploader}
        onClose={() => {
          setOpenCSVUploader(false);
          fetchAllAccounts();
        }}
      />

      <PaymentIDInputModal
        opened={openBulkUpdatePaymentIDModal}
        onClose={() => {
          setOpenBulkUpdatePaymentIDModal(false);
          fetchAllAccounts();
          setSelectedAccounts([]);
        }}
        selectedAccounts={selectedAccounts}
      />

      <Grid justify="space-between">
        <Grid.Col span="auto">
          <DarkModeToggle />
        </Grid.Col>
      </Grid>
      <Group my="lg" justify={'space-between'}>
        <Flex justify={'flex-start'} gap="sm">
          <TextInput
            placeholder="Search accounts"
            value={searchTerm}
            onChange={handleSearch}
          />
          {selectedAccounts.length > 0 && (
            <Button onClick={handleOpenBulkUpdatePaymentIDModal} color="blue">
              Bulk Update Payment IDs
            </Button>
          )}
        </Flex>

        <Flex justify={'flex-end'} gap="md">
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleOpenCSVUploader();
            }}
            color={'#6741d9'}
          >
            Bulk Update Account Status
          </Button>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleDownloadCsv();
            }}
            loading={isDownloading}
            disabled={isDownloading}
            color={'green'}
          >
            Download as CSV
          </Button>
        </Flex>
      </Group>
      <Group>
        <Table className="ryze-list table-striped table-highlight">
          <thead style={{ textAlign: 'left' }}>
            <tr>
              <th>
                <Checkbox
                  checked={selectedAccounts.length === filteredAccounts.length}
                  onChange={(e) =>
                    setSelectedAccounts(
                      e.target.checked
                        ? filteredAccounts.map((account) => account.id)
                        : [],
                    )
                  }
                />
              </th>
              <th>Payment ID</th>
              <th>Account Name</th>
              <th>Bank Account Number</th>
              <th>Bank Routing Number</th>
              <th>Account Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredAccounts.map((account) => (
              <tr key={account.id}>
                <td>
                  <Checkbox
                    checked={selectedAccounts.includes(account.id)}
                    onChange={(e) =>
                      handleCheckboxChange(account.id, e.target.checked)
                    }
                  />
                </td>
                <td>
                  <Input
                    disabled={isEditingCurrentPaymentIDField !== account.id}
                    style={{
                      fontWeight: 'bold',
                    }}
                    size="sm"
                    w={'75%'}
                    placeholder="Enter Payment ID"
                    type="text"
                    value={
                      isEditingCurrentPaymentIDField === account.id
                        ? account.paymentId
                        : maskPaymentId(account.paymentId?.toString())
                    }
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^0-9]/g, '');
                      const updatedAccounts = accounts.map((acc) =>
                        acc.id === account.id
                          ? { ...acc, paymentId: value }
                          : acc,
                      );
                      setAccounts(updatedAccounts);
                    }}
                    rightSectionPointerEvents="all"
                    rightSection={
                      <>
                        {isEditingCurrentPaymentIDField === account.id ? (
                          <ActionIcon
                            variant="transparent"
                            title="Save"
                            onClick={() => {
                              const updatedAccount = accounts.find(
                                (acc) => acc.id === account.id,
                              );
                              handleUpdatingPaymentId(
                                account.id,
                                updatedAccount?.paymentId || '',
                              );
                              setIsEditingCurrentPaymentIDField(null); // Exit edit mode after saving
                            }}
                            loading={isUpdatingPaymentID === account.id}
                            disabled={isUpdatingPaymentID === account.id}
                          >
                            <IconDeviceFloppy size={16} />
                          </ActionIcon>
                        ) : (
                          <ActionIcon
                            variant="transparent"
                            title="Edit"
                            onClick={() => {
                              const unmaskedAccount = {
                                ...account,
                                paymentId: account.paymentId || '',
                              };
                              setAccounts(
                                accounts.map((acc) =>
                                  acc.id === account.id ? unmaskedAccount : acc,
                                ),
                              );
                              setIsEditingCurrentPaymentIDField(account.id);
                            }}
                          >
                            <IconEdit size={16} />
                          </ActionIcon>
                        )}
                      </>
                    }
                  />
                </td>
                <td>{`${account.accountName}`}</td>
                <td>{`${account.bankAccountNumber}`}</td>
                <td>{`${account.bankRoutingNumber}`}</td>
                <td>{`${account.accountStatus.replace('_', ' ')}`}</td>
                <td>
                  {/* <Group>
                    <Button
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDeleteAccountButtonClick(account.id);
                      }}
                      color={'red'}
                    >
                      Delete
                    </Button>
                  </Group> */}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Group>
    </Container>
  );
};

export default Payments;
