import React from 'react';
import {
  Grid,
  Group,
  Card,
  Text,
  Button,
  Badge,
  TextInput,
  Stack,
  Loader,
  Box,
} from '@mantine/core';
import { IconSearch, IconCheck, IconUserPlus } from '@tabler/icons-react';
import { RyzeAvatar } from '../AvatarUploader';
import { Member } from './../../types/member-simple-types';

interface FindPeopleSectionProps {
  members: Member[] | null;
  isLoading: boolean;
  searchQuery: string;
  isConnected: Record<number, boolean>;
  onSearchChange: (value: string) => void;
  onSearch: () => void;
  onConnect: (memberId: number) => void;
  processedMemberId: number;
}

const FindPeopleSection: React.FC<FindPeopleSectionProps> = ({
  members,
  isLoading,
  searchQuery,
  isConnected,
  onSearchChange,
  onSearch,
  onConnect,
  processedMemberId,
}) => {
  return (
    <>
      <Group justify="left" mb="xl">
        <TextInput
          placeholder="Search by name, email or state... eg. NY"
          leftSection={<IconSearch size={16} />}
          value={searchQuery}
          onChange={(event) => onSearchChange(event.currentTarget.value)}
          style={{ width: '400px' }}
          maxLength={100}
        />
        {isLoading && <Loader type="dots" />}
      </Group>
      <Grid mb="xl">
        {!isLoading && members && members.length === 0 && (
          <Group justify="left" p={10}>
            No matches found...
          </Group>
        )}
        {members?.map((member) => (
          <Grid.Col key={member.id} span={{ base: 6, md: 4, lg: 3 }}>
            <Card
              shadow="sm"
              padding="lg"
              radius="md"
              withBorder
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <Card.Section withBorder inheritPadding py="xs">
                {/* <pre>{JSON.stringify(member, null, 2)}</pre> */}
                <Group>
                  <RyzeAvatar color="blue" radius="xl" memberId={member.id}>
                    {member.firstname[0] + member.lastname[0]}
                  </RyzeAvatar>
                  <Stack gap={0}>
                    <Text fw={700}>
                      {`${member.firstname} ${member.lastname}`}
                    </Text>
                    {/* <Text size="sm">
                      {member.homecity
                        ? member.homecity + ', ' + member.homestate
                        : ''}
                    </Text>
                    {member.employment[0] && (
                      <Text size="sm" c="dimmed">
                        {member.employment[0].position} at{' '}
                        {member.employment[0].employer}
                      </Text>
                    )} */}
                  </Stack>
                </Group>
              </Card.Section>

              <Card.Section py="md">
                <Group justify="center" gap="xs">
                  {member.specialties.slice(0, 3).map((specialty, index) => (
                    <Badge key={index} variant="light" color="blue">
                      {specialty.specialty}
                    </Badge>
                  ))}
                </Group>
              </Card.Section>

              <Box style={{ flexGrow: 1 }} />
              <Button
                fullWidth
                variant="light"
                color="blue"
                leftSection={
                  member.isSelf ? (
                    <></>
                  ) : isConnected[member.id] ? (
                    <IconCheck size={16} />
                  ) : (
                    <IconUserPlus size={16} />
                  )
                }
                onClick={() => onConnect(member.id)}
                loading={processedMemberId === member.id}
                disabled={
                  isConnected[member.id] || member.isConnected || member.isSelf
                }
              >
                {member.isSelf
                  ? 'This is you'
                  : isConnected[member.id]
                    ? 'Request Sent'
                    : member.isConnected
                      ? 'Already Connected'
                      : 'Connect'}
              </Button>
            </Card>
          </Grid.Col>
        ))}
      </Grid>
    </>
  );
};

export default FindPeopleSection;
