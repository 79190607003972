import React, { useState, useEffect} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  TextInput,
  Button,
  Container,
  Paper,
  Grid,
  Group,
  Title,
  Table,
  LoadingOverlay,
  Text,
  Modal,
  Flex,
  Loader,
  Select,
} from '@mantine/core';
import useHttpClient from '../hooks/useHttpClient';
import { useOrganizationContext } from './OrganizationContext';
import './css/EmployeeManagementTab.css';
import { OrganizationMember } from '../../types/subscribermember.types';
import CustomDateInput from '../CustomDateInput';
import {
  IconUser,
} from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import EmployeeListUpload from './EmployeeListUpload';
import { useAuth } from '../AuthProvider';

interface ExtendedOrganizationMember extends OrganizationMember {
  memberId?: number;
  familyMembers?: ExtendedOrganizationMember[];
}
interface FormValues {
  members: ExtendedOrganizationMember[];
}
const EmployeeApprovalTab: React.FC = () => {
  const { organizationId } = useOrganizationContext();
  const { control, register, reset } = useForm<FormValues>({
    defaultValues: { members: [] },
  });
  const [fields, setFields] = useState<ExtendedOrganizationMember[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [onApproval, setOnApproval] = useState(false);
  const [isAddingNewMember, setIsAddingNewMember] = useState(false);
  const [loading, setLoading] = useState(false);
  const httpClient = useHttpClient();
  const [selectedMember, setSelectedMember] =
    useState<ExtendedOrganizationMember | null>(null);
  const [filter, setFilter] = useState<string>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showCsvUploadModal, setShowCsvUploadModal] = useState(false);
  const [organizationDetails, setOrganizationDetails] = useState(null);
  const [employeeRecordExists, setEmployeeRecordExists] = useState(false);
  const [isRemovingEmployee, setIsRemovingEmployee] = useState(false);
  const auth = useAuth();
  const privileges = auth.getPrivileges();
  const currentUsername = privileges[0].username;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrganizationDetails = async () => {
      try {
        const response = await httpClient.get(`/api/organization/`);
        setOrganizationDetails(response.data);
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: 'Failed to fetch organization details',
          color: 'red',
          position: 'bottom-center',
        });
      }
    };

    fetchOrganizationDetails();
  }, [httpClient]);

  // Check if a userprivileges record exists for this organization's email
  useEffect(() => {
    if (organizationDetails && organizationDetails.email) {
      httpClient
        .get(
          `/api/user/exists?email=${encodeURIComponent(
            organizationDetails.email,
          )}&type=insurance_employee`,
        )
        .then(async (response) => {
          await setEmployeeRecordExists(response.data.exists);
        })
        .catch((error) => {
          console.error('Error checking employee record:', error);
        });
    }
  }, [organizationDetails, httpClient, isRemovingEmployee]);

  const fetchMembers = async () => {
    try {
      setLoading(true);
      const response = await httpClient.get(`/api/subscriber/organization`);
      const fetchedMembers: OrganizationMember[] = response.data;

      const mappedMembers: ExtendedOrganizationMember[] = fetchedMembers.map(
        (member) => ({
          ...member,
          memberId: member.id,
          id: undefined,
        }),
      );

      const membersWithFamilies = mappedMembers
        .filter(
          (member) =>
            member.primarysubscriber === null &&
            member.enrollmentstatus !== 'Removed',
        )
        .map((primaryMember) => ({
          ...primaryMember,
          familyMembers: mappedMembers
            .filter(
              (member) =>
                member.primarysubscriber === primaryMember.memberId &&
                member.enrollmentstatus !== 'Removed',
            )
            .sort((a, b) => {
              const relationshipOrder = ['Primary', 'Spouse', 'Child'];
              return (
                relationshipOrder.indexOf(a.subscriberrelationship) -
                relationshipOrder.indexOf(b.subscriberrelationship)
              );
            }),
        }));
      reset({ members: membersWithFamilies });
      setFields(membersWithFamilies);
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch members',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddMember = () => {
    setSelectedMember({
      firstname: '',
      lastname: '',
      primarysubscriber: null,
      familyMembers: [],
      subscriberrelationship: 'Primary',
      enrollmentstatus: 'Eligible',
      email: null,
    });
    setIsAddingNewMember(true);
    setIsEditing(true);
  };

  const handleApproval = async (
    status: string,
    primarySubscriberId: number,
  ) => {
    try {
      const { data } = await httpClient.put(
        `/api/subscriber/${primarySubscriberId}/approve-family`,
        { status },
      );
      notifications.show({
        title: 'Success',
        message: `Family ${status.toLowerCase()} successfully`,
        color: 'green',
        position: 'bottom-center',
      });
      await fetchMembers(); // Refresh the member list after approval/denial
      setSelectedMember((member) => {
        return { ...member, enrollmentstatus: status };
      });
    } catch (error) {
      notifications.show({
        title: 'Error',
        message: `Failed to ${status.toLowerCase()} family`,
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  const handleRemoveFamily = async (primarySubscriberId: number) => {
    setIsRemovingEmployee(true);
    try {
      const response = await httpClient.put(
        `/api/subscriber/${primarySubscriberId}/remove-family`,
        { status: 'Removed' },
      );

      // Get updated privileges and new token
      const privilegesResponse = await httpClient.post(
        '/api/auth/update-privileges',
      );

      if (privilegesResponse?.data?.new_token) {
        localStorage.setItem('token', privilegesResponse.data.new_token);

        notifications.show({
          title: 'Success',
          message: 'Family removed successfully',
          color: 'yellow',
          position: 'bottom-center',
        });

        fetchMembers(); // Refresh the member list after removal
        setSelectedMember(null);
        setIsEditing(false);
        window.location.href = '/insurance/clinic';
      } else {
        throw new Error('Failed to update privileges');
      }
    } catch (error) {
      console.log(error);
      notifications.show({
        title: 'Error',
        message: 'Failed to remove family',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setIsRemovingEmployee(false);
    }
  };

  const handleAddMyselfEmployee = async () => {
    if (!organizationDetails) {
      notifications.show({
        title: 'Error',
        message: 'Organization details not loaded yet',
        color: 'red',
        position: 'bottom-center',
      });
      return;
    }
    try {
      // First add self as employee
      await httpClient.post('/api/subscriber/enrollment/add-self', {
        firstname: organizationDetails.adminfirstname,
        lastname: organizationDetails.adminlastname,
        email: organizationDetails.email,
      });

      // Get updated privileges and new token
      const privilegesResponse = await httpClient.post(
        '/api/auth/update-privileges',
      );

      if (privilegesResponse?.data?.new_token) {
        localStorage.setItem('token', privilegesResponse.data.new_token);

        notifications.show({
          title: 'Success',
          message: 'Successfully added as an employee',
          color: 'green',
          position: 'bottom-center',
        });

        // Navigate to employee registration
        window.location.href = '/insurance/clinic/employee';
      } else {
        throw new Error('Failed to update privileges');
      }
    } catch (error) {
      notifications.show({
        title: 'Error',
        message:
          'Failed to add self as an employee. If you have already added self as employee, click on the Complete Registration tab on the right to continue.',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  const getStatusClass = (status: string) => {
    switch (status) {
      case 'Approved':
        return 'member-approved';
      case 'Denied':
        return 'member-denied';
      default:
        return '';
    }
  };

  const {
    control: memberControl,
    register: memberRegister,
    handleSubmit: memberHandleSubmit,
    setValue: setValue,
    reset: memberReset,
    formState: { isDirty: memberIsDirty },
  } = useForm<ExtendedOrganizationMember>({
    defaultValues: selectedMember,
  });

  const onSubmit = async (data: ExtendedOrganizationMember) => {
    setLoading(true);
    const { familyMembers, ...memberData } = data;

    if (isAddingNewMember && !memberData.id) {
      console.log('memberData new', memberData);

      try {
        delete memberData.memberId;
        await httpClient.post('/api/subscriber/employee', {
          ...memberData,
          organizationId,
        });
        notifications.show({
          title: 'Success',
          message: 'New member added successfully',
          color: 'green',
          position: 'bottom-center',
        });
        setIsAddingNewMember(false);
        setSelectedMember(null);
        fetchMembers();
      } catch (error) {
        console.log(error);
        notifications.show({
          title: 'Error',
          message: 'Failed to add new member. ' + error?.response?.data,
          color: 'red',
          position: 'bottom-center',
        });
      }
    } else {
      try {
        await httpClient.put(`/api/subscriber/${memberData.memberId}`, {
          ...memberData,
          memberId: undefined,
        });
        notifications.show({
          title: 'Success',
          message: 'Member updated successfully',
          color: 'green',
          position: 'bottom-center',
        });
        fetchMembers();
      } catch (error) {
        notifications.show({
          title: 'Error',
          message: 'Failed to update member',
          color: 'red',
          position: 'bottom-center',
        });
      }
    }
    setIsEditing(false);
    setLoading(false);
  };

  useEffect(() => {
    if (selectedMember) {
      memberReset(selectedMember);
    }
  }, [selectedMember, memberReset]);

  useEffect(() => {
    if (organizationId) {
      fetchMembers();
    }
  }, [organizationId]);

  const handleSetFilter = (filterText) => {
    setFilter(filterText);
  };

  const cancel = (e?: React.MouseEvent) => {
    if (isSubmitting) return;
    fetchMembers();
    setIsEditing((prev) => !prev);
    setOnApproval(false);
  };

  return (
    <Container>
      <Title order={1} style={{ textAlign: 'center' }}>
        Approve Employees
      </Title>
      <br />

      <Grid>
        <Grid.Col span={12}>
          <Paper withBorder shadow="xs" p="md">
            <Table highlightOnHover>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th style={{ width: 'auto' }}>Name</Table.Th>
                  <Table.Th>Status</Table.Th>
                  <Table.Th style={{ width: '180px' }}>Action</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {' '}
                {fields
                  .filter((member) => {
                    return [
                      'Registration Complete',
                      'Approved',
                      'Denied',
                    ].includes(member?.enrollmentstatus);
                  })
                  .map((member) => {
                    const memberKey = member.id || member.memberId;
                    const statusClass = getStatusClass(member.enrollmentstatus);

                    return (
                      <React.Fragment key={memberKey}>
                        <Table.Tr
                          style={{ cursor: 'pointer' }}
                          key={`member-${memberKey}`}
                          onClick={() => {
                            if (!isEditing) {
                              setSelectedMember(member);
                              setIsEditing(true);
                              setOnApproval(true);
                            }
                          }}
                          className={`member-row ${statusClass}`}
                        >
                          <Table.Td>
                            <IconUser
                              size="1rem"
                              stroke={1.5}
                              style={{ marginRight: '10px' }}
                            />
                            {member.firstname + ' ' + member.lastname}{' '}
                            {member.memberId}
                          </Table.Td>
                          <Table.Td>{member.enrollmentstatus}</Table.Td>

                          <Table.Td>
                            <Button
                              color="blue"
                              size="xs"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleApproval('Approved', member.memberId);
                              }}
                              disabled={member.enrollmentstatus === 'Approved'}
                            >
                              Approve
                            </Button>{' '}
                            <Button
                              color="red"
                              size="xs"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleApproval('Denied', member.memberId);
                              }}
                              disabled={member.enrollmentstatus === 'Denied'}
                            >
                              Reject
                            </Button>
                          </Table.Td>
                        </Table.Tr>
                      </React.Fragment>
                    );
                  }) || (
                  <Text p={10} style={{ fontStyle: 'italic' }}>
                    No data to show...
                  </Text>
                )}
              </Table.Tbody>
            </Table>
          </Paper>
        </Grid.Col>
      </Grid>

      <LoadingOverlay visible={loading} />

      <Modal
        opened={isEditing || onApproval}
        onClose={cancel}
        size="xl"
        title={
          <h2 style={{ margin: 0 }}>
            {!selectedMember?.memberId ? 'Add New ' : ''}
            Employee Member
          </h2>
        }
      >
        <Grid>
          {[
            // 'New',
            'Registration Complete',
            'Approved',
            'Denied',
          ].includes(selectedMember?.enrollmentstatus) && onApproval ? (
            <Grid.Col span="auto">
              <Group justify="center">
                <Button
                  color="green"
                  disabled={selectedMember.enrollmentstatus === 'Approved'}
                  onClick={() =>
                    handleApproval('Approved', selectedMember.memberId)
                  }
                >
                  Approve Employees
                </Button>
                <Button
                  color="red"
                  variant="filled"
                  disabled={selectedMember.enrollmentstatus === 'Denied'}
                  onClick={() =>
                    handleApproval('Denied', selectedMember.memberId)
                  }
                >
                  Reject Employees
                </Button>
              </Group>
            </Grid.Col>
          ) : null}
          {selectedMember?.memberId && !onApproval && (
            <Grid.Col
              span={onApproval ? 'content' : 12}
              style={
                !onApproval && {
                  display: 'flex',
                  justifyContent: 'flex-end',
                }
              }
            >
              <Button
                variant="outline"
                color="red"
                onClick={() => handleRemoveFamily(selectedMember.memberId)}
              >
                Remove{' '}
                {selectedMember?.subscriberrelationship === 'Primary'
                  ? 'Employee'
                  : selectedMember?.subscriberrelationship}
              </Button>
            </Grid.Col>
          )}
        </Grid>

        {selectedMember && (
          <form
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            onSubmit={memberHandleSubmit(onSubmit)}
          >
            <Paper withBorder shadow="xs" p="md" mt="md">
              <Grid justify="space-between" align="center">
                <Grid.Col span={7}>
                  <Title order={3}>
                    {selectedMember.firstname} {selectedMember.lastname}
                  </Title>
                </Grid.Col>
              </Grid>

              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    label="First Name"
                    {...memberRegister('firstname', { required: true })}
                    disabled={(!isEditing && !isAddingNewMember) || onApproval}
                    required
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Last Name"
                    {...memberRegister('lastname', { required: true })}
                    disabled={(!isEditing && !isAddingNewMember) || onApproval}
                    required
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <CustomDateInput
                    control={memberControl}
                    label="Date of Birth"
                    disabled={(!isEditing && !isAddingNewMember) || onApproval}
                    {...memberRegister('birthdate', {
                      onChange: (e) => {
                        console.log('Custom onChange event:', e.target.value);
                      },
                    })}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="sex"
                    control={memberControl}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Sex at Birth"
                        data={[
                          { value: 'M', label: 'M' },
                          { value: 'F', label: 'F' },
                        ]}
                        clearable
                        disabled={!isEditing || onApproval}
                      />
                    )}
                  />
                </Grid.Col>
                {selectedMember.subscriberrelationship === 'Primary' && (
                  <Grid.Col span={6}>
                    <TextInput
                      label="Email"
                      {...memberRegister('email', { required: true })}
                      disabled={
                        (!isEditing && !isAddingNewMember) || onApproval
                      }
                      required
                    />
                  </Grid.Col>
                )}

                <Grid.Col span={6}>
                  <TextInput
                    label="Enrollment Status"
                    {...memberRegister('enrollmentstatus', {
                      required: true,
                    })}
                    disabled
                  />
                </Grid.Col>
              </Grid>

              {isEditing && !onApproval && (
                <Flex justify="flex-end">
                  <Button
                    disabled={isSubmitting}
                    color="red"
                    mt="md"
                    style={{ marginRight: '5px' }}
                    onClick={cancel}
                  >
                    Cancel
                  </Button>
                  <Button disabled={isSubmitting} type="submit" mt="md">
                    {isSubmitting ? (
                      <>
                        <Loader color="blue" size="xs" mr="xs" />
                        Loading...
                      </>
                    ) : (
                      'Save'
                    )}
                  </Button>
                </Flex>
              )}
            </Paper>

            {selectedMember.familyMembers &&
              selectedMember.familyMembers.map((familyMember) => (
                <Paper
                  key={familyMember.memberId}
                  withBorder
                  shadow="xs"
                  p="md"
                  mt="md"
                >
                  <Title order={4}>
                    {familyMember.firstname} {familyMember.lastname}
                  </Title>

                  <Grid>
                    <Grid.Col span={6}>
                      <TextInput
                        label="First Name"
                        value={familyMember.firstname}
                        disabled
                      />
                    </Grid.Col>

                    <Grid.Col span={6}>
                      <TextInput
                        label="Last Name"
                        value={familyMember.lastname}
                        disabled
                      />
                    </Grid.Col>

                    <Grid.Col span={6}>
                      <TextInput
                        label="Enrollment Status"
                        value={familyMember.enrollmentstatus}
                        disabled
                      />
                    </Grid.Col>
                  </Grid>
                  {/* {isEditing && (
                    <Button type="submit" mt="md">
                      Save
                    </Button>
                  )} */}
                </Paper>
              ))}
          </form>
        )}
      </Modal>
      <EmployeeListUpload
        employeesUploaded={false}
        practiceId={organizationId}
        modalOpened={showCsvUploadModal}
        setModalOpened={setShowCsvUploadModal}
      />
      {/* <pre>{JSON.stringify(selectedMember, null, 2)}</pre> */}
    </Container>
  );
};

export default EmployeeApprovalTab;
