import React, { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Paper,
  Input,
  MultiSelect,
  Grid,
  Title,
  Checkbox,
} from '@mantine/core';
import CustomDateInput from './CustomDateInput';
import { IMaskInput } from 'react-imask';
import { useMemberContext } from './MemberContext';
import { Member, STATES_ARRAY, LANGUAGES } from '../types/members.types';
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import useHttpClient from './hooks/useHttpClient';
import { useFormDirtyAlert } from '../helpers/useFormDirtyAlert';
import { modals } from '@mantine/modals';
import { loadScript } from '../helpers/loadScript';
import { PROVIDER_TYPES } from '../enums/providerTypes';
import { SPECIAL_EXPERIENCES_OPTIONS } from '../enums/specialExperiencesOptions';
import { useAuth } from './AuthProvider';

interface MemberFormProps {
  member?: Member;
  setMember: (member: Member) => void;
  memberId: number;
  onMemberCreated?: (newMemberId: number) => void;
  setHasUnsavedChanges: (hasChanges: boolean) => void;
}

const MemberForm: React.FC<MemberFormProps> = ({
  member,
  setMember,
  memberId,
  onMemberCreated,
  setHasUnsavedChanges,
}) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isDirty, dirtyFields },
  } = useForm({
    defaultValues: member,
  });

  useFormDirtyAlert(reset, isDirty, dirtyFields);

  const { isNewMember } = useMemberContext();
  const navigate = useNavigate();
  const httpClient = useHttpClient();
  const { getPrivileges } = useAuth();

  const watchedFields = watch();

  const [homeAddressPopulated, setHomeAddressPopulated] = useState(false);
  const [mailingAddressPopulated, setMailingAddressPopulated] = useState(false);

  useEffect(() => {
    if (member) {
      reset(member);
      setHasUnsavedChanges(false);
    }
  }, [member, reset, setHasUnsavedChanges]);

  useEffect(() => {
    console.log(
      `watched fields ${JSON.stringify(watchedFields)} 
      member ${JSON.stringify(member)}`,
    );
    const hasChanges = !isEqual(watchedFields, member);
    console.log('has changes', hasChanges);
    setHasUnsavedChanges(hasChanges);
  }, [watchedFields, member, setHasUnsavedChanges]);

  const onSubmit = async (data: Member) => {
    try {
      let newInitialData = member;

      if (isNewMember) {
        if (['MD', 'DO'].includes(data.providertype)) {
          const openConfirmModalAsync = () => {
            return new Promise<boolean>((resolve) => {
              modals.openConfirmModal({
                title: <Title order={3}>Send Invitation</Title>,
                children:
                  'Would you like to send an email invitation to join WeCare?',
                labels: { confirm: 'Yes, send email', cancel: 'No' },
                onConfirm: () => resolve(true),
                onCancel: () => resolve(false),
              });
            });
          };
          if (await openConfirmModalAsync()) {
            data.sendEmail = true;
          }
        }

        const response = await httpClient.post('/api/members', data);
        const newMember = response.data;

        // Post empty disclosures for the new member
        await httpClient.post('/api/disclosures', {
          memberid: newMember.id,
          licenserelinquished: null,
          licenserelinquisheddescription: '',
          challengetolicensure: null,
          challengetolicensuredescription: '',
          clinicalprivileges: null,
          clinicalprivilegesdescription: '',
          surrenderedprivileges: null,
          surrenderedprivilegesdescription: '',
          terminatedforcause: null,
          terminatedforcausedescription: '',
          placedonprobation: null,
          placedonprobationdescription: '',
          withdrawnunderinvestigation: null,
          withdrawnunderinvestigationdescription: '',
          revokedboardcertifications: null,
          revokedboardcertificationsdescription: '',
          notrecertifyboardcertifications: null,
          notrecertifyboardcertificationsdescription: '',
          deacdschallenges: null,
          deacdschallengesdescription: '',
          disciplinedbygovernmentprograms: null,
          disciplinedbygovernmentprogramsdescription: '',
          refusedprofessionalinsurance: null,
          refusedprofessionalinsurancedescription: '',
          deniedprofessionalinsurance: null,
          deniedprofessionalinsurancedescription: '',
        });

        onMemberCreated && onMemberCreated(newMember.id);

        // Navigate to the new member's page
        reset();
        navigate(`/wecare/member/${newMember.id}`);
      } else {
        await httpClient.put(`/api/members/${memberId}`, data);
        console.log('Form updated successfully');

        // Update initial data with the updated data
        newInitialData = data;
      }

      // Update member with the new data
      setMember(newInitialData);

      // Reset the form with the new initial data
      reset(newInitialData);
      setHasUnsavedChanges(false);

      notifications.show({
        title: 'Success',
        message: isNewMember
          ? 'Member added successfully'
          : 'Member updated successfully',
        color: 'green',
        position: 'bottom-center',
      });
    } catch (error) {
      console.error('Error updating member', error);
      notifications.show({
        title: 'Error',
        message:
          error.response?.data?.message ||
          'Failed to update member. Please try again.',
        color: 'red',
        position: 'bottom-center',
        autoClose: 5000,
      });
    }
  };

  const homestreet1 = watch('homestreet1');
  const homestreet2 = watch('homestreet2');
  const homecity = watch('homecity');
  const homestate = watch('homestate');
  const homezip = watch('homezip');
  const homecountry = watch('homecountry');
  const homecounty = watch('homecounty');

  const [sameAsHome, setSameAsHome] = useState(false);
  useEffect(() => {
    if (sameAsHome) {
      setValue('mailingstreet1', homestreet1);
      setValue('mailingstreet2', homestreet2);
      setValue('mailingcity', homecity);
      setValue('mailingstate', homestate);
      setValue('mailingzip', homezip);
      setValue('mailingcountry', homecountry);
      setValue('mailingcounty', homecounty);
    }
  }, [
    homestreet1,
    homestreet2,
    homecity,
    homestate,
    homezip,
    homecountry,
    homecounty,
    sameAsHome,
    setValue,
  ]);

  const handleCheckboxChange = (checked: boolean) => {
    setSameAsHome(checked);
    if (checked) {
      const homeStreet1 = watch('homestreet1');
      const homeStreet2 = watch('homestreet2');
      const homeCity = watch('homecity');
      const homeState = watch('homestate');
      const homeZip = watch('homezip');
      const homeCountry = watch('homecountry');
      const homeCounty = watch('homecounty');

      setValue('mailingstreet1', homeStreet1);
      setValue('mailingstreet2', homeStreet2);
      setValue('mailingcity', homeCity);
      setValue('mailingstate', homeState);
      setValue('mailingzip', homeZip);
      setValue('mailingcountry', homeCountry);
      setValue('mailingcounty', homeCounty);
    }
  };

  const homeAddressRef = useRef<HTMLInputElement>(null);
  const mailingAddressRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let homeAutocomplete: google.maps.places.Autocomplete | null = null;
    let mailingAutocomplete: google.maps.places.Autocomplete | null = null;

    const handlePlaceSelect = async (
      autocomplete: google.maps.places.Autocomplete,
      prefix: 'home' | 'mailing',
    ) => {
      if (!autocomplete) return;

      const place = autocomplete.getPlace();
      if (!place.address_components) return;

      // Reset relevant address fields
      setValue(`${prefix}street1`, '');
      setValue(`${prefix}street2`, '');
      setValue(`${prefix}city`, '');
      setValue(`${prefix}state`, '');
      setValue(`${prefix}zip`, '');
      setValue(`${prefix}county`, '');

      let streetNumber = '';
      let route = '';
      let street2 = '';
      let city = '';
      let state = '';
      let zipCode = '';
      let county = '';

      place.address_components?.forEach((component) => {
        const types = component.types;
        if (types.includes('street_number')) streetNumber = component.long_name;
        if (types.includes('route')) route = component.long_name;
        if (types.includes('subpremise')) street2 = component.long_name;
        if (types.includes('locality')) city = component.long_name;
        else if (types.includes('sublocality')) city = component.long_name;
        if (types.includes('administrative_area_level_1'))
          state = component.short_name;
        if (types.includes('postal_code')) zipCode = component.long_name;
        if (types.includes('administrative_area_level_2'))
          county = component.long_name.replace(' County', '');
      });

      // Set values in the form
      setValue(`${prefix}street1`, `${streetNumber} ${route}`.trim());
      setValue(`${prefix}street2`, street2);
      setValue(`${prefix}city`, city);
      setValue(`${prefix}state`, state);
      setValue(`${prefix}zip`, zipCode);
      setValue(`${prefix}county`, county);
      setValue(`${prefix}country`, 'US');

      // If "Same as Home" is checked and this is the home address, update mailing address
      if (prefix === 'home' && sameAsHome) {
        setValue('mailingstreet1', `${streetNumber} ${route}`.trim());
        setValue('mailingstreet2', street2);
        setValue('mailingcity', city);
        setValue('mailingstate', state);
        setValue('mailingzip', zipCode);
        setValue('mailingcounty', county);
        setValue('mailingcountry', 'US');
      }

      // Only set the populated state if we actually got an address
      const street1 = `${streetNumber} ${route}`.trim();
      if (street1) {
        if (prefix === 'home') {
          setHomeAddressPopulated(true);
        } else if (prefix === 'mailing') {
          setMailingAddressPopulated(true);
        }
      }
    };

    const initAutocomplete = () => {
      if (homeAddressRef.current && window.google) {
        homeAutocomplete = new window.google.maps.places.Autocomplete(
          homeAddressRef.current,
          {
            types: ['address'],
            componentRestrictions: { country: 'us' },
          },
        );
        homeAutocomplete.addListener('place_changed', () =>
          handlePlaceSelect(homeAutocomplete!, 'home'),
        );
      }

      if (mailingAddressRef.current && window.google) {
        mailingAutocomplete = new window.google.maps.places.Autocomplete(
          mailingAddressRef.current,
          {
            types: ['address'],
            componentRestrictions: { country: 'us' },
          },
        );
        mailingAutocomplete.addListener('place_changed', () =>
          handlePlaceSelect(mailingAutocomplete!, 'mailing'),
        );
      }
    };

    // Load Google Maps script and initialize autocomplete
    const googleMapsScriptUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
    loadScript(googleMapsScriptUrl)
      .then(initAutocomplete)
      .catch((err) => console.error('Failed to load Google Maps script:', err));

    // Cleanup
    return () => {
      if (homeAutocomplete) {
        google.maps.event.clearInstanceListeners(homeAutocomplete);
      }
      if (mailingAutocomplete) {
        google.maps.event.clearInstanceListeners(mailingAutocomplete);
      }
    };
  }, [setValue, sameAsHome]);

  const resetHomeAddress = () => {
    setHomeAddressPopulated(false);
    if (homeAddressRef.current) {
      homeAddressRef.current.value = '';
    }
  };

  const resetMailingAddress = () => {
    setMailingAddressPopulated(false);
    if (mailingAddressRef.current) {
      mailingAddressRef.current.value = '';
    }
  };

  // Function to check if user has wecareadmin permission
  const hasWecareAdminPermission = () => {
    const privileges = getPrivileges();
    return privileges.some((privilege) => privilege.type === 'wecareadmin');
  };

  return (
    <Container>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Grid>
          <Grid.Col span={4}>
            <TextInput
              id="legal-firstname"
              label="Legal First Name"
              required
              {...register('firstname', { required: true })}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TextInput
              id="legal-lastname"
              label="Last Name"
              required
              {...register('lastname', { required: true })}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <TextInput
              id="legal-middlename"
              label="Middle Name"
              {...register('middlename')}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={4}>
            <TextInput
              id="npi"
              label="NPI"
              maxLength={10}
              {...register('npi')}
              onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.target.value = e.target.value.replace(/\D/g, ''); // Replace any non-numeric characters
              }}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Controller
              name="sex"
              control={control}
              render={({ field }) => (
                <Select
                  id="sex-at-birth"
                  {...field}
                  label="Sex at Birth"
                  data={[
                    { value: 'M', label: 'M' },
                    { value: 'F', label: 'F' },
                  ]}
                  clearable
                />
              )}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <CustomDateInput
              control={control}
              name="birthday"
              label="Date of Birth"
              preventFutureDates
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              id="personal-email"
              label="Personal Email"
              required
              {...register('email')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name="providertype"
              control={control}
              rules={{ required: 'Provider Type is required' }}
              render={({ field, fieldState: { error } }) => (
                <Select
                  id="provider-type"
                  {...field}
                  label="Provider Type"
                  required
                  error={error?.message}
                  data={Object.entries(PROVIDER_TYPES).map(
                    ([value, label]) => ({
                      value,
                      label: `${value} - ${label}`,
                    }),
                  )}
                  clearable
                  searchable
                  disabled={!hasWecareAdminPermission()}
                />
              )}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              id="birth-city"
              label="Birth City"
              {...register('birthcity')}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name="birthstate"
              control={control}
              render={({ field }) => (
                <Select
                  id="birth-state"
                  {...field}
                  label="Birth State"
                  data={STATES_ARRAY.map((state) => ({
                    value: state,
                    label: state,
                  }))}
                  clearable
                  searchable
                />
              )}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={6}>
            <Controller
              name="ssn"
              control={control}
              render={({ field }) => (
                <Input.Wrapper label="SSN">
                  <Input
                    id="ssn"
                    component={IMaskInput}
                    {...field}
                    mask="000-00-0000"
                    placeholder="XXX-XX-XXXX"
                  />
                </Input.Wrapper>
              )}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name="mobilephone"
              control={control}
              render={({ field }) => (
                <Input.Wrapper label="Personal Mobile Phone">
                  <Input
                    id="mobilephone"
                    component={IMaskInput}
                    {...field}
                    mask="(000) 000-0000"
                    placeholder="(XXX) XXX-XXXX"
                  />
                </Input.Wrapper>
              )}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={4}>
            <Controller
              name="preferredcommunication"
              control={control}
              render={({ field }) => (
                <Select
                  id="preferred-communication"
                  {...field}
                  label="Preferred Communication"
                  data={[
                    { value: 'Email', label: 'Email' },
                    { value: 'Phone', label: 'Phone' },
                  ]}
                  clearable
                />
              )}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Controller
              name="caretype"
              control={control}
              render={({ field }) => (
                <Select
                  id="caretype"
                  {...field}
                  label="Care Type"
                  data={[
                    { value: 'Primary', label: 'Primary Only' },
                    { value: 'Specialty', label: 'Specialty Only' },
                    { value: 'Both', label: 'Both' },
                  ]}
                  clearable
                />
              )}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Controller
              name="raceethnicity"
              control={control}
              render={({ field }) => (
                <Select
                  id="raceethnicity"
                  {...field}
                  label="Race/Ethnicity"
                  data={[
                    { value: 'Asian', label: 'Asian' },
                    {
                      value: 'Black or African American',
                      label: 'Black or African American',
                    },
                    {
                      value: 'Hispanic or Latino',
                      label: 'Hispanic or Latino',
                    },
                    {
                      value: 'Native American or Alaska Native',
                      label: 'Native American or Alaska Native',
                    },
                    {
                      value: 'Native Hawaiian or Other Pacific Islander',
                      label: 'Native Hawaiian or Other Pacific Islander',
                    },
                    { value: 'White', label: 'White' },
                    { value: 'Other', label: 'Other' },
                  ]}
                  clearable
                />
              )}
            />
          </Grid.Col>
        </Grid>
        <Grid>
          <Grid.Col span={12}>
            <Controller
              name="languages"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  id="languages"
                  {...field}
                  label="Languages"
                  data={LANGUAGES.map((language) => ({
                    value: language,
                    label: language,
                  }))}
                  searchable
                  clearable
                  value={field.value || []}
                />
              )}
            />
          </Grid.Col>
        </Grid>

        <Controller
          name="specialexperiences"
          control={control}
          render={({ field }) => (
            <MultiSelect
              id="specialexperiences"
              {...field}
              label="Special Experience, Skills, and Training"
              data={SPECIAL_EXPERIENCES_OPTIONS}
              searchable
              clearable
              value={field.value || []}
            />
          )}
        />

        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Home Address</Title>
          <TextInput
            id="home-address"
            label="Аddress - Start typing your address below and pick one from the dropdown"
            placeholder="Start by typing here"
            ref={homeAddressRef}
            onChange={(e) => {
              if (!e.target.value) {
                resetHomeAddress();
              }
            }}
          />
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                id="home-street-1"
                label={'\u0405treet 1'}
                {...register('homestreet1')}
                disabled
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                id="home-street-2"
                label={'\u0405treet 2'}
                {...register('homestreet2')}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                id="home-city"
                label="City"
                {...register('homecity')}
                disabled
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="homestate"
                control={control}
                render={({ field }) => (
                  <Select
                    id="home-state"
                    {...field}
                    label="State"
                    data={STATES_ARRAY.map((state) => ({
                      value: state,
                      label: state,
                    }))}
                    clearable
                    searchable
                    disabled
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                id="home-zip"
                label="ZIP"
                maxLength={5}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.target.value = e.target.value.replace(/\D/g, '');
                }}
                {...register('homezip')}
                disabled
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="homecountry"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Country"
                    data={[{ value: 'US', label: 'United States' }]}
                    clearable
                    searchable
                    disabled
                  />
                )}
              />
            </Grid.Col>
          </Grid>
          <TextInput
            id="home-county"
            label="County"
            {...register('homecounty')}
            disabled
          />
        </Paper>

        <Paper withBorder shadow="xs" p="md" mt="md">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Title order={3}>Mailing Address</Title>
            <Checkbox
              id="same-as-home-address"
              label="Same as Home Address"
              onChange={(event) => {
                handleCheckboxChange(event.currentTarget.checked);
                if (event.currentTarget.checked) {
                  resetMailingAddress();
                }
              }}
            />
          </div>
          <TextInput
            id="mailing-address"
            label="Аddress - Start typing the address below and pick one from the dropdown"
            placeholder="Start by typing here"
            ref={mailingAddressRef}
            disabled={sameAsHome}
            onChange={(e) => {
              if (!e.target.value) {
                resetMailingAddress();
              }
            }}
          />
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                id="mailing-street-1"
                label={'\u0405treet 1'}
                {...register('mailingstreet1')}
                disabled
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                id="mailing-street-2"
                label={'\u0405treet 2'}
                {...register('mailingstreet2')}
                disabled={sameAsHome}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                id="mailing-city"
                label="City"
                {...register('mailingcity')}
                disabled
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="mailingstate"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="State"
                    data={STATES_ARRAY.map((state) => ({
                      value: state,
                      label: state,
                    }))}
                    clearable
                    searchable
                    disabled
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                id="mailing-zip"
                label="ZIP"
                {...register('mailingzip')}
                disabled
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name="mailingcountry"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Country"
                    data={[{ value: 'US', label: 'United States' }]}
                    clearable
                    searchable
                    disabled
                  />
                )}
              />
            </Grid.Col>
          </Grid>
          <TextInput
            id="mailing-county"
            label="County"
            {...register('mailingcounty')}
            disabled
          />
        </Paper>
        <Button id={`save-member-${memberId}`} type="submit" mt="md">
          {memberId === 0 ? 'Add Member' : 'Save'}
        </Button>
      </form>
    </Container>
  );
};

export default MemberForm;
