import {
  Button,
  Group,
  Text,
  Radio,
  Image,
  Flex,
  Box,
  Title,
} from '@mantine/core';

function PracticeOwnerFamilyScreen({
  currentStep,
  answers,
  setAnswers,
  back,
  next,
}) {
  const key = currentStep + '.insuranceFor';

  return (
    <>
      <Text size="md" mb={20} ta="left">
        Want the best possible pricing? We can offer better rates when we
        underwrite for more people,
        <br />
        even if some choose not to enroll later
      </Text>

      <Flex direction="row" gap={20} mt={30} justify="center">
        <Box style={{ textAlign: 'center', width: '45%' }}>
          <Title order={5} mb={10}>
            Good: Covers all your employees
          </Title>
          <Image
            src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET}/celebrate2.jpg`}
            w="100%"
            h={150}
            mb={10}
            fit="contain"
          />
        </Box>

        <Box style={{ textAlign: 'center', width: '45%' }}>
          <Title order={5} mb={10}>
            Best: Covers employees <b>and</b> their families
          </Title>
          <Image
            src={`${process.env.REACT_APP_PUBLIC_S3_BUCKET}/celebrate1.png`}
            w="100%"
            h={150}
            mb={10}
            fit="contain"
          />
        </Box>
      </Flex>

      <Text size="md">
        Would you like us to include the option of covering employee families in
        your quote?
      </Text>
      <Group justify="flex-start" mt="xl">
        <Radio.Group
          value={answers[key] || ''}
          onChange={(newValue) =>
            setAnswers((values) => ({ ...values, [key]: newValue }))
          }
        >
          <Radio value="yes" label="Yes" m={10} />
          <Radio value="no" label="No" m={10} />
        </Radio.Group>
      </Group>

      <Group justify="flex-end" mt="xl">
        <Button
          variant="default"
          onClick={async () => {
            await setAnswers((answers) => {
              delete answers[key];
              return { ...answers };
            });
            back();
          }}
        >
          Back
        </Button>
        <Button onClick={() => next()} disabled={!answers[key]}>
          Next
        </Button>
      </Group>
    </>
  );
}

export default PracticeOwnerFamilyScreen;
