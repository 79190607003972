import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { Checkbox, Grid, Radio } from '@mantine/core';

interface WorkersCompFormProps {
  control: Control<any>;
  practiceIndex: number;
}

const PracticeLocationWorkersCompForm: React.FC<WorkersCompFormProps> = ({
  control,
  practiceIndex,
}) => {
  return (
    <Grid>
      <Grid.Col span={12}>
        <Controller
          name={`practices.${practiceIndex}.practicelocation_workerscomp.acceptWorkersCompensation`}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <Checkbox
              id={`practice-location-workerscomp-accept-workers-compensation-${practiceIndex}`}
              label="I accept Workers' Compensation patients at this location"
              checked={field.value}
              onChange={(e) => field.onChange(e.currentTarget.checked)}
            />
          )}
        />
      </Grid.Col>

      <Grid.Col span={12}>
        <Controller
          name={`practices.${practiceIndex}.practicelocation_workerscomp.isModifiedOrAlternativeDutyEvaluated`}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <Radio.Group
              id={`practice-location-workerscomp-is-modified-or-alternative-duty-evaluated-${practiceIndex}`}
              label="Is modified or alternative duty actively evaluated?"
              value={field.value ? 'yes' : 'no'}
              onChange={(value) => field.onChange(value === 'yes')}
            >
              <Radio mt="xs" value="yes" label="Yes" />
              <Radio mt="xs" value="no" label="No" />
            </Radio.Group>
          )}
        />
      </Grid.Col>

      <Grid.Col span={12}>
        <Controller
          name={`practices.${practiceIndex}.practicelocation_workerscomp.canProvideClaimantInfo`}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <Radio.Group
              id={`practice-location-workerscomp-can-provide-claimant-info-${practiceIndex}`}
              label="Can staff provide care information to compensation reps?"
              value={field.value ? 'yes' : 'no'}
              onChange={(value) => field.onChange(value === 'yes')}
            >
              <Radio mt="xs" value="yes" label="Yes" />
              <Radio mt="xs" value="no" label="No" />
            </Radio.Group>
          )}
        />
      </Grid.Col>

      <Grid.Col span={12}>
        <Controller
          name={`practices.${practiceIndex}.practicelocation_workerscomp.staffTrainedForWorkCases`}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <Radio.Group
              id={`practice-location-workerscomp-staff-trained-for-work-cases-${practiceIndex}`}
              label="Are staff trained in identifying and caring for work‐related cases?"
              value={field.value ? 'yes' : 'no'}
              onChange={(value) => field.onChange(value === 'yes')}
            >
              <Radio mt="xs" value="yes" label="Yes" />
              <Radio mt="xs" value="no" label="No" />
            </Radio.Group>
          )}
        />
      </Grid.Col>

      <Grid.Col span={12}>
        <Controller
          name={`practices.${practiceIndex}.practicelocation_workerscomp.willAccommodateUrgentWalkins`}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <Radio.Group
              id={`practice-location-workerscomp-will-accommodate-urgent-walk-ins-${practiceIndex}`}
              label="Will this location accommodate urgent walk-ins (or non-urgent within 48 hours)?"
              value={field.value ? 'yes' : 'no'}
              onChange={(value) => field.onChange(value === 'yes')}
            >
              <Radio mt="xs" value="yes" label="Yes" />
              <Radio mt="xs" value="no" label="No" />
            </Radio.Group>
          )}
        />
      </Grid.Col>
    </Grid>
  );
};

export default PracticeLocationWorkersCompForm;
