import { Button, Group, Modal, Text } from '@mantine/core';

interface DeleteConfirmationModalProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isDeleting: boolean;
  title: string;
  description: string;
  action: string;
  opacity: number;
}

const DeleteConfirmationModal = ({
  opened,
  onClose,
  onSubmit,
  isDeleting,
  title,
  description,
  action,
  opacity,
}: DeleteConfirmationModalProps) => {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={<Text fw="bolder">{title}</Text>}
      centered
      closeOnClickOutside={!isDeleting}
      closeOnEscape={!isDeleting}
      withCloseButton={!isDeleting}
      overlayProps={{
        opacity: opacity,
      }}
    >
      <Text>{description}</Text>
      <Group justify={'space-between'}>
        <Button disabled={isDeleting} onClick={onClose} mt="md" mr="xs">
          Cancel
        </Button>
        <Button
          disabled={isDeleting}
          loading={isDeleting}
          onClick={onSubmit}
          color="red"
          mt="md"
        >
          {action}
        </Button>
      </Group>
    </Modal>
  );
};

export default DeleteConfirmationModal;
