import { Button, Group, Modal, Select } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import useHttpClient from './hooks/useHttpClient';

interface UpdateAccountStatusModalProps {
  opened: boolean;
  onClose: () => void;
  selectedAccounts: any[];
  onSuccess?: () => void;
}

const UpdateAccountStatusModal = ({
  opened,
  onClose,
  selectedAccounts,
  onSuccess,
}: UpdateAccountStatusModalProps) => {
  const [newStatus, setNewStatus] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const httpClient = useHttpClient();

  const handleUpdateStatus = async () => {
    setIsUpdating(true);
    try {
      const response = await httpClient.put('/api/payment/mass-update-status', {
        accountIds: selectedAccounts,
        newStatus,
      });

      if (response.status === 200) {
        onSuccess();
        notifications.show({
          title: 'Success!',
          message: response.data.message,
          color: 'green',
          position: 'bottom-center',
        });
      }
    } catch (error) {
      console.error('Error updating account status', error);
      notifications.show({
        title: 'Error!',
        message: 'Error updating account status',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setIsUpdating(false);
      onClose();
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Select New Status"
      centered
      closeOnClickOutside={!isUpdating}
      closeOnEscape={!isUpdating}
      withCloseButton={!isUpdating}
    >
      <Select
        label="Account Status"
        placeholder="Pick a status"
        data={[
          { value: 'NEW', label: 'NEW' },
          { value: 'VERIFICATION_COMPLETE', label: 'VERIFICATION COMPLETE' },
          { value: 'VERIFICATION_FAILED', label: 'VERIFICATION FAILED' },
          { value: 'ACTIVE', label: 'ACTIVE' },
        ]}
        value={newStatus}
        onChange={setNewStatus}
      />

      <Group justify={'space-between'}>
        <Button
          disabled={isUpdating}
          color="red"
          onClick={onClose}
          mt="md"
          mr="xs"
        >
          Cancel
        </Button>
        <Button
          disabled={isUpdating}
          loading={isUpdating}
          onClick={handleUpdateStatus}
          color="blue"
          mt="md"
        >
          Update
        </Button>
      </Group>
    </Modal>
  );
};

export default UpdateAccountStatusModal;
