import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Flex,
} from '@mantine/core';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { notifications } from '@mantine/notifications';
import useHttpClient from '../hooks/useHttpClient';
import { useAuth } from '../hooks/useAuth';
import { useFormDirtyAlert } from '../../helpers/useFormDirtyAlert';
const CheckoutForm: React.FC<{
  clientSecret: string;
  paymentCustomerId: string;
}> = ({ clientSecret, paymentCustomerId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const httpClient = useHttpClient();
  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty, dirtyFields },
  } = useForm();
  useFormDirtyAlert(reset, isDirty, dirtyFields);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { subscriberMemberId, organizationId } = useAuth();

  const fetchSubscriberMember = async () => {
    try {
      const subscriber = await httpClient.get(
        `/api/subscriber/${subscriberMemberId}`,
      );
      return subscriber.data;
    } catch (error) {
      console.error('Failed to fetch subscribermember :', error);
    }
  };

  const fetchOrganization = async () => {
    if (organizationId) {
      try {
        const response = await httpClient.get(`/api/organization`);
        const data = response.data;
        return data;
      } catch (error) {
        console.error('Error fetching organization', error);
      }
    }
  };

  const onSubmit = async (data: any) => {
    if (!stripe || !elements) {
      return;
    }
    try {
      setIsSubmitting(true);
      //Get Name, Email for customer  creation
      let firstName, lastName, orgEmail;
      if (!subscriberMemberId) {
        // If clinic Admin
        const org = await fetchOrganization();
        firstName = org.adminfirstname;
        lastName = org.adminlastname;
        orgEmail = org.email;
      } else {
        const subscriber = await fetchSubscriberMember();
        firstName = subscriber.firstname;
        lastName = subscriber.lastname;
        orgEmail = subscriber.email;
      }

      const result = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${global.APP_HOST}/insurance/admin`,
          payment_method_data: {
            billing_details: {
              name: `${firstName} ${lastName}`,
              email: orgEmail,
            },
          },
        },
        redirect: 'if_required',
      });
      console.log('result from submit', result);
      if (result.error) {
        notifications.show({
          title: 'Error',
          message: result.error.message || 'Failed to set up payment method',
          color: 'red',
          position: 'bottom-center',
        });
      } else {
        // After successfully setting up the payment method, create a subscription
        const paymentMethodId = result.setupIntent.payment_method;
        const subscriptionResponse = await httpClient.post(
          '/api/stripe/create-subscription',
          JSON.stringify({
            paymentMethodId,
            customerId: paymentCustomerId,
            organizationId,
          }),
          {
            headers: { 'Content-Type': 'application/json' },
          },
        );
        console.log('subscription response', subscriptionResponse);
        const subscriptionData = await subscriptionResponse.data;

        if (subscriptionData.error) {
          notifications.show({
            title: 'Error',
            message: subscriptionData.error.message,
            color: 'red',
            position: 'bottom-center',
          });
        } else {
          notifications.show({
            title: 'Success',
            message: 'Subscription created successfully',
            color: 'green',
            position: 'bottom-center',
          });
        }
      }

      setIsSubmitting(false);
    } catch (e) {
      console.error('Error on confirming setup', e);
      setIsSubmitting(false);
    }
  };
  const options = {
    fields: {
      billingDetails: {
        name: 'never' as const,
        email: 'never' as const,
      },
    },
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
    >
      <PaymentElement options={options} />
      <Flex justify="flex-end">
        <Button type="submit" disabled={isSubmitting} mt="md">
          Sign Up
        </Button>
      </Flex>
    </form>
  );
};

export default CheckoutForm;
