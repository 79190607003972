import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  TextInput,
  Button,
  Grid,
  Loader,
  Select,
  Modal,
  Group,
  Text,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import useHttpClient from '../hooks/useHttpClient';
import { useNavigate } from 'react-router-dom';
import { PROVIDER_TYPES } from '../../enums/providerTypes';

interface EmployeeFormProps {
  isOpen?: boolean;
  formType?: 'colleague' | 'office_personnel';
  initialData?: any;
  setIsOpen: (isOpen: boolean) => void;
  selectedLocationId?: number;
  selectedMemberId?: number;
  cb: () => void;
}

const PracticeLocationEmployeeModal: React.FC<EmployeeFormProps> = ({
  isOpen,
  setIsOpen,
  formType,
  initialData,
  selectedLocationId,
  selectedMemberId,
  cb,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    defaultValues: initialData,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  // do not use useFormDirtyAlert here

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const httpClient = useHttpClient();

  // Fetch employee data from the database using the enrollmentcode
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await httpClient.get(
          `/api/practices/employee/${selectedMemberId}`,
        );
        const data = await response.data;
        console.log('fetching employee data', data);
        reset(data);
      } catch (error) {
        console.error('Error fetching employee data', error);
        notifications.show({
          title: 'Error',
          message: 'Failed to load employee data',
          color: 'red',
          position: 'bottom-center',
        });
      } finally {
        setLoading(false);
      }
    };

    if (selectedMemberId) {
      fetchData();
    } else {
      reset({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        npi: '',
        providertype: '',
      });
    }
  }, [selectedMemberId, reset]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const response = await httpClient.post(
        `/api/practices/employee/${selectedMemberId || -1}`,
        {
          ...data,
          employeetype: formType,
          practicelocationid: selectedLocationId,
          sendOnboardingEmail: formType === 'colleague',
        },
      );

      if (response.status === 201 || response.status === 200) {
        notifications.show({
          title: 'Success',
          message:
            formType === 'colleague'
              ? 'Colleague added successfully. An onboarding email has been sent.'
              : 'Successfully saved',
          color: 'green',
          position: 'bottom-center',
        });
        setIsOpen(false);
        reset({
          firstname: '',
          lastname: '',
          email: '',
          phone: '',
          npi: '',
          providertype: '',
        });
        cb();
      } else {
        throw new Error('Failed to update form');
      }
    } catch (error) {
      const message = error?.response?.data?.message;
      console.log('message', message);
      notifications.show({
        title: 'Error',
        message: 'Failed to save: ' + message,
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setLoading(false);
    }
  };

  const action = selectedMemberId ? 'Edit' : 'Add';
  const title =
    formType === 'colleague'
      ? `${action} Colleague`
      : `${action} Office Personnel`;

  return (
    <Modal
      size="lg"
      opened={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      title={
        <Text size="xl" fw={700} ta="center">
          {title}
        </Text>
      }
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              label="First Name"
              required
              {...register('firstname', { required: true })}
              disabled={loading}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Last Name"
              required
              {...register('lastname', { required: true })}
              disabled={loading}
            />
          </Grid.Col>
          {formType === 'colleague' ? (
            <Grid.Col span={6}>
              <Controller
                name="providertype"
                control={control}
                rules={{ required: 'Provider type is required' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="Provider Type"
                    required={formType === 'colleague'}
                    data={Object.entries(PROVIDER_TYPES).map(
                      ([value, label]) => ({
                        value,
                        label: `${value} - ${label}`,
                      }),
                    )}
                    clearable
                    searchable
                  />
                )}
              />
            </Grid.Col>
          ) : null}
          {formType === 'colleague' ? (
            <Grid.Col span={6}>
              <TextInput
                label="NPI"
                required
                maxLength={10}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.target.value = e.target.value.replace(/\D/g, ''); // Replace any non-numeric characters
                }}
                {...register('npi', { required: true })}
                disabled={loading}
              />
            </Grid.Col>
          ) : (
            <Grid.Col span={6}>
              <TextInput
                label="Phone"
                required
                {...register('phone', { required: true })}
                disabled={loading}
              />
            </Grid.Col>
          )}
          <Grid.Col span={6}>
            <TextInput
              label="Email"
              required
              {...register('email', { required: true })}
            />
          </Grid.Col>
        </Grid>
        <br />
        <Group justify="space-between">
          <Button
            type="button"
            mt="md"
            disabled={loading}
            color="red"
            onClick={() => {
              reset({
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                npi: '',
                providertype: '',
              });
              setIsOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button type="submit" mt="md" mr="xs" disabled={loading}>
            {loading ? (
              <>
                <Loader color="blue" size="xs" mr="xs" />
                Loading...
              </>
            ) : (
              'Save'
            )}
          </Button>
        </Group>
      </form>
    </Modal>
  );
};

export default PracticeLocationEmployeeModal;
