import { useState, useMemo } from 'react';
import {
  Grid,
  Text,
  Group,
  Button,
  Paper,
  Container,
  Title,
  Skeleton,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import useHttpClient from './hooks/useHttpClient';
import { useMantineColorScheme, useComputedColorScheme } from '@mantine/core';
import { Sun, MoonStars } from 'tabler-icons-react';
import { useAuth } from './hooks/useAuth';
import { useAuth as useAuth2 } from './AuthProvider';
import ClinicUsers from './ClinicUsers';
import AvatarUploader from './AvatarUploader';

export default function Settings() {
  const { setColorScheme } = useMantineColorScheme();
  const computedColorScheme = useComputedColorScheme('light');
  const toggleColorScheme = () => {
    setColorScheme(computedColorScheme === 'dark' ? 'light' : 'dark');
  };

  const httpClient = useHttpClient();
  const navigate = useNavigate();
  const { organizationId, subscriberMemberId, wecareMemberId } = useAuth();
  const [userInfo, setUserInfo] = useState<any>();
  const [loading, setIsloading] = useState<boolean>(false);
  const [showUsers, setShowUsers] = useState<any>(true);

  const toggleShowUsers = () => {
    setShowUsers((val) => !val);
  };

  const auth = useAuth2();
  const privileges = auth.getPrivileges();
  const username = privileges[0].username;
  const types = (privileges || [])
    .map((priv) => priv.type)
    .join(',')
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());

  const canManageUsers = privileges.some((priv) =>
    [
      'insurance_organization',
      'insurance_employee',
      'insurance_physician',
      'wecaremember',
      'wecareadmin',
      'ryzeadmin',
    ].includes(priv.type),
  );

  const fetchUserInformation = async () => {
    setIsloading(true);
    try {
      if (subscriberMemberId) {
        const { data } = await httpClient.get(`/api/subscriber`);
        setUserInfo(data);
      }
      if (organizationId) {
        const { data } = await httpClient.get(`/api/organization`);
        setUserInfo((setUserInfo) => {
          return { ...setUserInfo, ...data };
        });
      }
      if (wecareMemberId) {
        const { data } = await httpClient.get(`/api/user`);
        const { data: data2 } = await httpClient.get(
          `/api/members/` + wecareMemberId,
        );

        setUserInfo({ ...data, ...data2 });
      }
    } catch (error) {
      console.error('failed to fetch user information', error);
    } finally {
      setIsloading(false);
    }
  };

  useMemo(() => {
    fetchUserInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriberMemberId, organizationId, wecareMemberId]);

  const transition = showUsers
    ? {
        opacity: 1,
        transform: 'translateY(0)',
        transition: 'opacity 2s ease, transform 2s ease',
      }
    : {
        opacity: 0,
        transform: 'translateY(-20px)',
        transition: 'opacity 2s ease, transform 2s ease',
      };

  return (
    <Container size="xl">
      <Title style={{ textAlign: 'left', marginBottom: '2rem' }}>
        Settings
      </Title>
      <Paper withBorder shadow="md" p={0} radius="md">
        <Grid style={{ height: '520px', overflow: 'hidden' }}>
          <Grid.Col span={canManageUsers && showUsers ? 6 : 12} p={30}>
            <AvatarUploader memberId={subscriberMemberId || wecareMemberId} />
            {userInfo && (
              <Text ta="center" fz="lg" fw={500} mt="md">
                {organizationId > 0 ? (
                  <h3 style={{ marginBottom: 0 }}>{userInfo?.name}</h3>
                ) : (
                  ''
                )}
                {subscriberMemberId || wecareMemberId > 0
                  ? (userInfo?.firstname || '-') +
                    ' ' +
                    (userInfo?.lastname || '')
                  : organizationId > 0
                    ? (userInfo?.adminfirstname || '-') +
                      ' ' +
                      (userInfo?.adminlastname || '')
                    : ''}
              </Text>
            )}
            <Text ta="center" c="dimmed" fz="sm" mt={'md'}>
              {username}
              <br />
              {types}
            </Text>
            {userInfo && (
              <Container
                style={{
                  marginTop: '10px',
                  marginBottom: '20px',
                  width: '100%',
                }}
              >
                <Grid style={{ textAlign: 'center' }}>
                  {userInfo?.enrollmentstatus && (
                    <Grid.Col span={4}>
                      <Text size="xs">Enrollment Status</Text>
                      <Skeleton visible={loading}>
                        <Text>{userInfo?.enrollmentstatus || '-'}</Text>
                      </Skeleton>
                    </Grid.Col>
                  )}
                </Grid>
              </Container>
            )}
            <Group justify="center" mt={'xl'}>
              <Button onClick={toggleColorScheme}>
                {computedColorScheme === 'dark' ? (
                  <Sun style={{ width: 20, height: 20 }} />
                ) : (
                  <MoonStars style={{ width: 20, height: 20 }} />
                )}
                Dark Mode
              </Button>{' '}
              <Button onClick={() => navigate('/settings/reset-password')}>
                Reset Password
              </Button>{' '}
              {canManageUsers && (
                <Button onClick={toggleShowUsers}>
                  {showUsers ? 'Hide Portal Users' : 'Show Portal Users'}
                </Button>
              )}
            </Group>
          </Grid.Col>
          <Grid.Col
            span={6}
            p={30}
            style={{
              background: 'rgba(220,220,220,0.25)',
              height: '540px',
              ...(canManageUsers && showUsers ? transition : { opacity: 0 }),
            }}
          >
            <ClinicUsers userInfo={userInfo} organizationId={organizationId} />
          </Grid.Col>
        </Grid>
      </Paper>
    </Container>
  );
}
