import React, { useRef, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { InputBase } from '@mantine/core';
import { parse, isValid, format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { IconX } from '@tabler/icons-react';
import { ActionIcon } from '@mantine/core';
import { notifications } from '@mantine/notifications';

interface CustomDateInputProps {
  control: any; // This should be the control from useForm
  name: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  preventFutureDates?: boolean;
  preventPastDates?: boolean;
  error?: string;
}

type ValidationResult = true | string;
type DateValue = string | Date | null | undefined;

const CustomDateInput: React.FC<CustomDateInputProps> = ({
  control,
  name,
  label = 'Date of Birth',
  required = false,
  disabled = false,
  clearable = true,
  preventFutureDates = false,
  preventPastDates = false,
  error: errorMessage = '',
}) => {
  // Reference to track if we're in the submit phase
  const isSubmitting = useRef(false);

  // Show notification only during form submission
  const showNotification = (title: string, message: string) => {
    if (isSubmitting.current) {
      notifications.show({
        title,
        message,
        color: 'red',
        position: 'bottom-center',
        autoClose: 3000,
      });
    }
  };

  // Helper to check empty values
  const isEmpty = (value: DateValue): boolean => {
    if (value === null || value === undefined) return true;
    if (typeof value === 'string' && value.trim() === '') return true;
    return false;
  };

  // Check for ISO date format
  const isIsoDateFormat = (value: string): boolean => {
    return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(value);
  };

  // Check for MM/DD/YYYY format
  const isMMDDYYYYFormat = (value: string): boolean => {
    return /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(value);
  };

  // Parse date string (MM/DD/YYYY or ISO)
  const parseDate = (value: string): Date | null => {
    if (isMMDDYYYYFormat(value)) {
      const date = parse(value, 'MM/dd/yyyy', new Date());
      return isValid(date) ? date : null;
    }
    if (isIsoDateFormat(value)) {
      try {
        const date = new Date(value);
        return isValid(date) ? date : null;
      } catch (e) {
        return null;
      }
    }
    return null;
  };

  // Check if date is in the future
  const isFutureDate = (date: Date): boolean => {
    return preventFutureDates && date > new Date();
  };

  const isPastDate = (date: Date): boolean => {
    return preventPastDates && date < new Date();
  };

  // Listen for form submission events
  useEffect(() => {
    const handleBeforeSubmit = () => {
      isSubmitting.current = true;

      // Reset after a short delay to handle any post-submission validation
      setTimeout(() => {
        isSubmitting.current = false;
      }, 1000);
    };

    // Try to find the form element containing this component
    const findForm = () => {
      let element = document.getElementById(name);
      while (element && element.tagName !== 'FORM') {
        element = element.parentElement;
      }
      return element as HTMLFormElement;
    };

    const form = findForm();
    if (form) {
      form.addEventListener('submit', handleBeforeSubmit);
      return () => {
        form.removeEventListener('submit', handleBeforeSubmit);
      };
    }
  }, [name]);

  // Format value for display
  const formatInitialValue = (value: DateValue): string => {
    // Handle null/undefined cases
    if (isEmpty(value)) return '';

    // Handle string values
    if (typeof value === 'string') {
      const trimmedValue = value.trim();

      // Already in MM/DD/YYYY format
      if (isMMDDYYYYFormat(trimmedValue)) {
        return trimmedValue;
      }

      // Format ISO date from the database
      if (trimmedValue.includes('T')) {
        try {
          const zonedDate = toZonedTime(trimmedValue, 'UTC');
          return format(zonedDate, 'MM/dd/yyyy');
        } catch (e) {
          console.error('Error formatting date:', e);
          return trimmedValue;
        }
      }

      return trimmedValue;
    }

    // Format Date object
    if (value instanceof Date && isValid(value)) {
      return format(value, 'MM/dd/yyyy');
    }

    // Fallback
    return String(value);
  };

  // Process and validate date input
  const processDateInput = (
    value: string,
    previousValue: string,
  ): string | null => {
    // Handle empty input
    if (value === '') {
      return '';
    }

    // Handle deletion (backspace/delete)
    if (value.length < previousValue.length) {
      return value;
    }

    // Filter out invalid characters
    value = value.replace(/[^\d/]/g, '');

    // Remove double slashes
    value = value.replace(/\/\//g, '/');

    // Prevent inputs that are only slashes or have too many slashes
    if (value.replace(/[^/]/g, '').length > 2 || value === '/') {
      return null;
    }

    // Remove leading slash
    if (value.startsWith('/')) {
      value = value.substring(1);
    }

    // Limit total slashes to 2
    const slashCount = (value.match(/\//g) || []).length;
    if (slashCount > 2) {
      const parts = value.split('/').slice(0, 3);
      value = parts.join('/');
    }

    // Process month/day/year parts
    let parts = value.split('/');

    // Process month
    if (parts[0]) {
      if (parts[0].length > 2) {
        parts[0] = parts[0].substring(0, 2);
      }

      const monthValue = parseInt(parts[0]);
      if (parts[0].length === 2 && (monthValue < 1 || monthValue > 12)) {
        parts[0] = previousValue.split('/')[0] || '';
      }

      // Auto-add slash after month
      if (
        (parts[0].length === 2 ||
          (parts[0].length === 1 && parseInt(parts[0]) > 1)) &&
        parts.length === 1 &&
        !previousValue.includes('/')
      ) {
        parts.push('');
      }
    }

    // Process day
    if (parts[1]) {
      if (parts[1].length > 2) {
        parts[1] = parts[1].substring(0, 2);
      }

      const dayValue = parseInt(parts[1]);
      if (parts[1].length === 2 && (dayValue < 1 || dayValue > 31)) {
        parts[1] = previousValue.split('/')[1] || '';
      }

      // Auto-add slash after day
      if (
        (parts[1].length === 2 ||
          (parts[1].length === 1 && parseInt(parts[1]) > 3)) &&
        parts.length === 2 &&
        previousValue.split('/').length === 2
      ) {
        parts.push('');
      }
    }

    // Process year
    if (parts[2] && parts[2].length > 4) {
      parts[2] = parts[2].substring(0, 4);
    }

    // Reconstruct the value
    value = parts.join('/');

    // Final check for double slashes
    value = value.replace(/\/\//g, '/');

    return value;
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required,
        validate: {
          customRule: (value: DateValue): ValidationResult => {
            // Skip validation if not required and empty
            if (!required && isEmpty(value)) {
              return true;
            }

            // Handle string values
            if (typeof value === 'string') {
              const trimmedValue = value.trim();

              // Empty check for required fields
              if (!trimmedValue && required) {
                showNotification('Required Field', 'Date is required.');
                return 'Date is required.';
              }

              // Parse the date (handles both MM/DD/YYYY and ISO formats)
              const date = parseDate(trimmedValue);

              if (date) {
                // Check for future date if needed
                if (isFutureDate(date)) {
                  showNotification(
                    'Invalid Date',
                    'Future dates are not allowed.',
                  );
                  return 'Future dates are not allowed.';
                }
                if (isPastDate(date)) {
                  showNotification(
                    'Invalid Date',
                    'Past dates are not allowed.',
                  );
                  return 'Past dates are not allowed.';
                }
                return true;
              }

              // Invalid format but has value
              if (trimmedValue) {
                showNotification(
                  'Invalid Date Format',
                  'Please enter a valid date in MM/DD/YYYY format.',
                );
                return 'Please enter a valid date in MM/DD/YYYY format.';
              }
            }

            // Handle Date objects
            if (value instanceof Date) {
              if (!isValid(value)) {
                showNotification('Invalid Date', 'Please enter a valid date.');
                return 'Please enter a valid date.';
              }

              if (isFutureDate(value)) {
                showNotification(
                  'Invalid Date',
                  'Future dates are not allowed.',
                );
                return 'Future dates are not allowed.';
              }
              if (isPastDate(value)) {
                showNotification('Invalid Date', 'Past dates are not allowed.');
                return 'Past dates are not allowed.';
              }
              return true;
            }

            // Empty check for optional fields
            if (isEmpty(value) && !required) {
              return true;
            }

            // Default error for invalid input
            showNotification(
              'Invalid Date Format',
              'Please enter a valid date in MM/DD/YYYY format.',
            );
            return 'Please enter a valid date in MM/DD/YYYY format.';
          },
        },
      }}
      render={({ field, fieldState: { error } }) => {
        const formattedValue = formatInitialValue(field.value);

        // Custom onChange handler
        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const value = e.target.value;
          const previousValue = formattedValue || '';

          // Handle field being cleared
          if (value === '') {
            field.onChange('');
            // Force trigger revalidation to clear errors
            setTimeout(() => {
              field.onChange('');
            }, 0);
            return;
          }

          // Process the input value
          const processedValue = processDateInput(value, previousValue);

          // Only update if we have a valid processed value
          if (processedValue !== null) {
            field.onChange(processedValue);
          }
        };

        return (
          <div style={{ position: 'relative' }}>
            <div
              style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'flex-start',
              }}
            >
              <InputBase
                id={name}
                placeholder="MM/DD/YYYY"
                value={formattedValue || ''}
                label={label}
                disabled={disabled}
                required={required}
                onChange={handleChange}
                style={{ flex: 1 }}
                error={error?.message}
                maxLength={10}
              />
              {clearable && !disabled && field.value && (
                <ActionIcon
                  onClick={() => field.onChange(null)}
                  style={{
                    position: 'absolute',
                    right: '8px',
                    top: label ? '29px' : '8px',
                    backgroundColor: 'transparent',
                    border: 'none',
                  }}
                >
                  <IconX size={16} style={{ color: 'gray' }} />
                </ActionIcon>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

export default CustomDateInput;
