import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Grid,
  Paper,
  Title,
  Text,
  PasswordInput,
  Group,
  Modal,
} from '@mantine/core';
import { STATES_ARRAY } from '../../types/members.types';
import { notifications } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import PasswordStrengthBar from 'react-password-strength-bar';
import CustomDateInput from '../CustomDateInput';
import { loadScript } from '../../helpers/loadScript';
import useHttpClient from '../hooks/useHttpClient';
import { WECARE_LOGIN } from '../../StaticVars/locationRefs';

interface IndividualInfoScreenProps {
  answers: any;
  practiceInfo: any;
  individualInfo: any;
  setIndividualInfo: (answers: any) => void;
  next: () => void;
  back: () => void;
}

const IndividualInfoScreen: React.FC<IndividualInfoScreenProps> = ({
  answers,
  practiceInfo,
  individualInfo,
  setIndividualInfo,
  next,
  back,
}) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: answers,
  });

  const httpClient = useHttpClient();

  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrengthScore, setPasswordStrengthScore] = useState(0);
  const [loading, setLoading] = useState(false);
  const [emailExistsModalOpen, setEmailExistsModalOpen] = useState(false);
  const [stateSelectOpened, setStateSelectOpened] = useState(false);

  const navigate = useNavigate();
  const password = watch('password');

  const homeAddressRef = useRef<HTMLInputElement>(null);
  const [addressPopulated, setAddressPopulated] = useState(false);

  const handlePlaceSelect = useCallback(
    async (autocomplete: google.maps.places.Autocomplete) => {
      if (!autocomplete) return;

      const place = autocomplete.getPlace();
      if (!place.address_components) return;

      setValue('street1', '');
      setValue('street2', '');
      setValue('city', '');
      setValue('state', '');
      setValue('zip', '');

      let streetNumber = '';
      let route = '';
      let street2 = '';
      let city = '';
      let state = '';
      let zipCode = '';

      place.address_components?.forEach((component) => {
        const types = component.types;
        if (types.includes('street_number')) streetNumber = component.long_name;
        if (types.includes('route')) route = component.long_name;
        if (types.includes('subpremise')) street2 = component.long_name;
        if (types.includes('locality')) city = component.long_name;
        else if (types.includes('sublocality')) city = component.long_name;
        if (types.includes('administrative_area_level_1'))
          state = component.short_name;
        if (types.includes('postal_code')) zipCode = component.long_name;
      });

      const street1 = `${streetNumber} ${route}`.trim();
      if (street1) {
        setAddressPopulated(true);
      }

      setValue('street1', street1);
      setValue('street2', street2);
      setValue('city', city);
      setValue('state', state);
      setValue('zip', zipCode);
      setValue('country', 'US');
    },
    [setValue],
  );

  useEffect(() => {
    let homeAutocomplete: google.maps.places.Autocomplete | null = null;

    const initAutocomplete = () => {
      if (homeAddressRef.current && window.google) {
        homeAutocomplete = new window.google.maps.places.Autocomplete(
          homeAddressRef.current,
          {
            types: ['address'],
            componentRestrictions: { country: 'us' },
          },
        );

        homeAutocomplete.addListener('place_changed', () =>
          handlePlaceSelect(homeAutocomplete!),
        );
      }
    };

    const googleMapsScriptUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;

    loadScript(googleMapsScriptUrl)
      .then(() => initAutocomplete())
      .catch((err) => console.error('Failed to load Google Maps script:', err));

    return () => {
      if (homeAutocomplete) {
        google.maps.event.clearInstanceListeners(homeAutocomplete);
      }
    };
  }, [handlePlaceSelect]);

  const onSubmit = async (data: any) => {
    if (passwordStrengthScore < 3) {
      notifications.show({
        title: 'Weak Password',
        message:
          'Please choose a stronger password (at least "Good" strength).',
        color: 'red',
        position: 'bottom-center',
      });
      return;
    }

    const submissionData = {
      ...data,
      email: data.email.toLowerCase(),
    };
    delete submissionData.passwordConfirmation;

    setIndividualInfo(submissionData);

    setLoading(true);

    try {
      await httpClient.post('/api/members/onboarding', {
        individualInfo: submissionData,
        practiceInfo,
        answers,
      });

      notifications.show({
        title: 'Success',
        message: 'Onboarding completed successfully!',
        color: 'green',
        position: 'bottom-center',
      });

      next(); // Proceed to the next step
    } catch (error: any) {
      if (error?.response?.status === 409) {
        setEmailExistsModalOpen(true);
      } else {
        notifications.show({
          title: 'Error',
          message: error.message || 'An error occurred during onboarding.',
          color: 'red',
          position: 'bottom-center',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Text size="xl" mb="md">
        Almost there!
      </Text>

      <Modal
        opened={emailExistsModalOpen}
        onClose={() => setEmailExistsModalOpen(false)}
        title={
          <Text fw={600} c="blue">
            Account Already Exists
          </Text>
        }
        centered
        withCloseButton={false}
      >
        <Text mb="md">
          An account with that email has previously signed up for WeCare.
        </Text>
        <Button
          fullWidth
          onClick={() => {
            setEmailExistsModalOpen(false);
            navigate(WECARE_LOGIN);
          }}
        >
          Go to Login
        </Button>
      </Modal>

      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
      >
        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3} mb={'md'}>
            Let’s get your Login Information so we can create your Account.
          </Title>
          <Grid>
            <Grid.Col span={12}>
              <TextInput
                label="What email address would you like to use for your account?"
                required
                disabled={loading}
                {...register('email', { required: true })}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col span={6}>
              <PasswordInput
                label="Create a Password"
                required
                disabled={loading}
                visible={showPassword}
                maxLength={50}
                onVisibilityChange={setShowPassword}
                {...register('password', { required: true })}
              />
              <PasswordStrengthBar
                password={password}
                onChangeScore={setPasswordStrengthScore}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <PasswordInput
                label="Confirm Password"
                required
                disabled={loading}
                visible={showPassword}
                onVisibilityChange={setShowPassword}
                {...register('passwordConfirmation', {
                  required: 'Password confirmation is required',
                  validate: (value) =>
                    value === password || 'Passwords do not match',
                })}
                error={errors.passwordConfirmation?.message?.toString()}
              />
            </Grid.Col>
          </Grid>
        </Paper>

        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Personal Information</Title>
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="First Name"
                required
                {...register('firstname', { required: true })}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Last Name"
                required
                {...register('lastname', { required: true })}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <CustomDateInput
                control={control}
                name="birthday"
                label="Date of Birth"
                required
                preventFutureDates
              />
            </Grid.Col>
          </Grid>
          <br />

          <Grid>
            {(answers.providertype === 'MD' ||
              answers.providertype === 'DO') && (
              <>
                <Grid.Col span={6}>
                  <TextInput
                    label="Medical License Number"
                    required={answers.work_status !== 'in_training'}
                    {...register('licensenumber', {
                      required: answers.work_status !== 'in_training',
                    })}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="licensestate"
                    control={control}
                    rules={{
                      required:
                        answers.work_status !== 'in_training'
                          ? 'State is required'
                          : false,
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Меdіcal Lіcеnsе Ѕtatе" //WARNING Unicode characters here to avoid autopopulate
                        data={STATES_ARRAY}
                        clearable
                        searchable
                        required={answers.work_status !== 'in_training'}
                        onInput={(e) => {
                          const elem = e.target as HTMLInputElement;
                          setValue('licensestate', elem.value);
                          setTimeout(() => {
                            setStateSelectOpened(false);
                          });
                        }}
                        dropdownOpened={stateSelectOpened}
                        onDropdownOpen={() => setStateSelectOpened(true)}
                        onDropdownClose={() => setStateSelectOpened(false)}
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <CustomDateInput
                    control={control}
                    name="licenseexpiration"
                    label="Medical License Expiration Date"
                    required={answers.work_status !== 'in_training'}
                  />
                </Grid.Col>
              </>
            )}

            <Grid.Col span={6}>
              <TextInput
                label="NPI"
                required={
                  answers.providertype === 'MD' ||
                  (answers.providertype === 'DO' &&
                    answers.work_status !== 'in_training')
                }
                {...register('npi', {
                  required:
                    answers.providertype === 'MD' ||
                    (answers.providertype === 'DO' &&
                      answers.work_status !== 'in_training'),
                })}
                maxLength={10}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.target.value = e.target.value.replace(/\D/g, ''); // Replace any non-numeric characters
                }}
              />
            </Grid.Col>
          </Grid>
        </Paper>

        <Paper withBorder shadow="xs" p="md" mt="md">
          <Title order={3}>Home Address</Title>
          <TextInput
            label="Start typing your address"
            placeholder="Start by typing here"
            ref={homeAddressRef}
          />
          <Grid>
            <Grid.Col span={6}>
              <TextInput
                label="Street 1"
                {...register('street1')}
                disabled
                required
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput label="Street 2" {...register('street2')} />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput label="City" {...register('city')} disabled required />
            </Grid.Col>
            <Grid.Col span={6}>
              <Controller
                name={'state'}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label="State"
                    data={STATES_ARRAY.map((state) => ({
                      value: state,
                      label: state,
                    }))}
                    clearable
                    searchable
                    disabled
                    required
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput label="ZIP" {...register('zip')} disabled required />
            </Grid.Col>
          </Grid>
        </Paper>

        <Group justify="flex-end" mt="xl">
          <Button variant="default" onClick={back}>
            Back
          </Button>
          <Button type="submit">Complete</Button>
        </Group>
      </form>
    </Container>
  );
};

export default IndividualInfoScreen;
