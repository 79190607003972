import React, { useState, useEffect } from 'react';
import { useForm, Controller, FieldError } from 'react-hook-form';
import {
  Button,
  Container,
  Select,
  Group,
  Paper,
  Table,
  Title,
  Text,
  Modal,
  Loader,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import useHttpClient from './hooks/useHttpClient';
import { formatDistanceToNow } from 'date-fns';
import {
  DownloadAndRenderImage,
  DownloadAndRenderPDF,
} from './DownloadAndRender';
import { IconFile, IconTrash, IconUpload } from '@tabler/icons-react';
import { useDropzone } from 'react-dropzone';
import { useComputedColorScheme } from '@mantine/core';

interface FormProps {
  memberId: number;
}

const FileDropzone = ({
  name,
  register,
  setValue,
  errors,
  required,
  validate,
  file,
}) => {
  const [fileInfo, setFileInfo] = useState(null);
  const computedColorScheme = useComputedColorScheme('light');

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setValue(name, acceptedFiles, { shouldValidate: true });

        setFileInfo({
          name: file.name,
          type: file.type,
          size: file.size,
        });
      }
    },
  });

  useEffect(() => {
    if (!file) setFileInfo(null);
  }, [file]);

  return (
    <div
      {...getRootProps()}
      style={{
        border: '2px dashed ' + (isDragActive ? '#007bff' : '#ccc'),
        background: isDragActive ? 'rgba(0, 123, 255, 0.05)' : 'none',
        borderRadius: '20px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
      }}
    >
      <input
        id={name}
        data-testid="upload-documents-input"
        type="file"
        {...getInputProps()}
        {...register}
      />
      {isDragActive ? (
        <>
          <IconFile size={60} style={{ color: '#3f29cd' }} />
          <p>Drag & drop your documents here, or click to select a file</p>
        </>
      ) : (
        <>
          {fileInfo ? (
            <IconFile size={60} style={{ color: '#3f29cd' }} />
          ) : (
            <>
              <IconUpload size={60} style={{ color: '#3f29cd' }} />
              <p>Drag & drop your documents here, or click to select a file</p>
            </>
          )}
        </>
      )}
      {errors[name] && (
        <span style={{ color: 'red' }}>{String(errors[name].message)}</span>
      )}
      {fileInfo && (
        <div style={{ marginTop: '10px', fontSize: '0.8em' }}>
          <p>
            <strong>File Name:</strong> {fileInfo.name}
          </p>
          <p>
            <strong>File Type:</strong> {fileInfo.type} |{' '}
            <strong>File Size:</strong> {fileInfo.size} bytes
          </p>
        </div>
      )}
    </div>
  );
};

const UploadedDocumentsForm: React.FC<FormProps> = ({ memberId }) => {
  const {
    control,
    handleSubmit,
    getValues,
    register,
    reset,
    setValue,
    watch,
    formState: { errors, isDirty, dirtyFields },
  } = useForm();
  const watchAllFields = watch();

  const { file } = watch();
  const [fetchedDocuments, setFetchedDocuments] = useState(null);
  const httpClient = useHttpClient();
  const computedColorScheme = useComputedColorScheme('light');

  const [opened, setOpened] = useState(false);
  const [url, setUrl] = useState<any>(null);
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleOpenModal = (key) => {
    setUrl(process.env.REACT_APP_API_URL + '/api/upload/rawfile/' + key);
    setOpened(true);
  };

  const fetchDocuments = async () => {
    setLoading(true);
    try {
      const response = await httpClient.get(`/api/upload/document/${memberId}`);
      if (!response.status.toString().startsWith('2')) {
        // Checking for non-success status codes
        throw new Error('Failed to fetch uploaded documents');
      }

      setFetchedDocuments(response.data);
    } catch (error) {
      console.error('Error fetching document:', error);
      if (error.response && error.response.status === 404) {
        // reset({});
        return;
      }
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch uploaded documents',
        color: 'red',
        position: 'bottom-center',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!fetchedDocuments && memberId) fetchDocuments();
  }, [memberId]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', data.file[0]);
      formData.append('type', data.type);
      formData.append('memberid', String(memberId));
      const response = await httpClient.post(
        `/api/upload/document/${memberId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      fetchDocuments();

      notifications.show({
        title: 'Success',
        message: 'Document added successfully',
        color: 'green',
        position: 'bottom-center',
      });
      reset({});
    } catch (error) {
      console.error('Error uploading file:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit document',
        color: 'red',
        position: 'bottom-center',
      });
    }
    setLoading(false);
  };

  const handleDelete = async (key) => {
    setLoading(true);
    try {
      await httpClient.delete('/api/upload/document/' + key);
      notifications.show({
        title: 'Success',
        message: 'Document deleted successfully',
        color: 'green',
        position: 'bottom-center',
      });
      fetchDocuments();
    } catch (error) {
      console.error('Error delete file:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to delete document',
        color: 'red',
        position: 'bottom-center',
      });
    }
    setLoading(false);
  };

  const rows = fetchedDocuments?.map((e) => (
    <Table.Tr key={e.id}>
      <Table.Td>{e.documenttype || 'n/a'}</Table.Td>
      <Table.Td
        onClick={() => handleOpenModal(e.key)}
        style={{
          cursor: 'pointer',
          color: computedColorScheme === 'light' ? 'blue' : 'white',
        }}
      >
        {e.filename}
      </Table.Td>
      <Table.Td>
        {e.created_at &&
          formatDistanceToNow(e.created_at, {
            addSuffix: true,
          })}
      </Table.Td>{' '}
      <Table.Td>
        <a
          href="#uploadedDocuments"
          onClick={() => {
            if (!loading) handleDelete(e.key);
          }}
          style={{
            color: loading ? '#CCC' : 'red',
            textDecoration: 'none',
            fontWeight: 800,
          }}
        >
          <IconTrash size={20} />
        </a>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <Container>
      <Paper shadow="xs" withBorder>
        <Text mb={20}>
          Upload your licenses, cerifications and malpractice insurance here:
        </Text>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          encType="multipart/form-data"
        >
          {!!rows?.length && (
            <>
              <Title order={3} mb={10}>
                Documents
              </Title>

              <Table mb={20}>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Document Type</Table.Th>
                    <Table.Th>Filename</Table.Th>
                    <Table.Th>Created</Table.Th>
                    <Table.Th></Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>{rows}</Table.Tbody>
              </Table>
              <hr />
            </>
          )}
          <div>
            <Title order={3} mb={10}>
              Upload Form
            </Title>

            <label>Document Type</label>
            <Controller
              name="type"
              control={control}
              rules={{ required: 'Type is required' }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Select
                    id="type"
                    placeholder="Select a document type"
                    data={[
                      'License',
                      'Certification',
                      'Malpractice Insurance',
                      'Others',
                    ]}
                    {...field}
                    value={field.value}
                    onChange={(value) => {
                      setValue('file', null);
                      field.onChange(value);
                    }}
                    error={error?.message}
                    mb={20}
                  />
                </>
              )}
            />
            <label>
              Select a file{' '}
              <small style={{ color: '#888' }}>
                (supported formats are pdf/png/jpg)
              </small>
            </label>

            <FileDropzone
              name="file"
              register={register}
              setValue={setValue}
              file={file}
              errors={errors}
              required="File is required"
              validate={{
                validFileType: (value) => {
                  if (!value || value.length === 0) return 'No file selected';
                  const file = value[0];
                  const allowedTypes = [
                    'application/pdf',
                    'image/png',
                    'image/jpeg',
                  ];
                  if (allowedTypes.includes(file.type)) return true;
                  return 'Only PDF, PNG, or JPEG files are allowed';
                },
              }}
            />

            {errors.file && (
              <p style={{ color: 'red' }}>
                {(errors.file as FieldError)?.message || 'Unknown error'}
              </p>
            )}
          </div>
          <Group mt="md">
            <Button
              id="save-uploaded-documents"
              type="submit"
              disabled={loading}
            >
              {loading && <Loader size="sm" mr={10} />} Upload File
            </Button>
          </Group>
        </form>
      </Paper>
      <Modal
        size="xl"
        radius="md"
        opened={opened}
        onClose={() => setOpened(false)}
        title={<Title order={3}>Preview</Title>}
      >
        {(url?.match(/\.pdf$/i) && <DownloadAndRenderPDF url={url} />) ||
          (url?.match(/\.jpg|.\png$/i) && (
            <DownloadAndRenderImage url={url} />
          )) || <p>Unsupported file type</p>}
      </Modal>
    </Container>
  );
};

export default UploadedDocumentsForm;
