import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Group,
  Collapse,
  Paper,
  Grid,
  MultiSelect,
  Input,
  Autocomplete,
  Checkbox,
  Table,
  Title,
  Tooltip,
  Accordion,
  InputBase,
  rem,
} from '@mantine/core';
import { TimeInput } from '@mantine/dates';
import { IMaskInput } from 'react-imask';
import { notifications } from '@mantine/notifications';
import { LANGUAGES, Member, STATES_ARRAY } from '../types/members.types';
import useHttpClient from './hooks/useHttpClient';
import { useFormDirtyAlert } from '../helpers/useFormDirtyAlert';
import PracticeLocationEmployeeModal from './InsurancePortal/PracticeLocationEmployeeModal';
import { IconX, IconInfoCircle } from '@tabler/icons-react';
import { modals } from '@mantine/modals';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import PracticeLimitations from './PracticeLocationLimitationsForm';
import PracticeLocationAffliationForm from './InsurancePortal/PracticeLocationAffliationForm';
import PracticeLocationAccessibilityForm from './InsurancePortal/PracticeLocationAccessibilityForm';
import PatientSection from './PracticeLocationPatientForm';
import PracticeLocationTelehealthForm from './PracticeLocationTelehealthForm';
import PracticeLocationServicesForm from './PracticeLocationServicesForm';
import PracticeLocationRemittanceForm from './PracticeLocationRemittanceForm';
import PracticeLocationWorkersCompForm from './PracticeLocationWorkersCompForm';
import { loadScript } from '../helpers/loadScript';

const { Thead, Tbody, Th, Tr, Td } = Table;

interface FormProps {
  memberId: number;
}

const PracticeForm: React.FC<FormProps> = ({ memberId }) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { isDirty, dirtyFields },
  } = useForm({});

  useFormDirtyAlert(reset, isDirty, dirtyFields);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'practices',
  });

  const [collapsedIndices, setCollapsedIndices] = useState(
    fields.map(() => true),
  );
  const [initialData, setInitialData] = useState<any>();
  const [practicesList, setPracticesList] = useState<any[]>([]);
  const [practiceLocations, setPracticeLocations] = useState<any[]>([]);
  const [practiceSearchTerms, setPracticeSearchTerms] = useState<string[]>(
    fields.map(() => ''),
  );
  const [locationSearchTerms, setLocationSearchTerms] = useState<string[]>(
    fields.map(() => ''),
  );
  const httpClient = useHttpClient();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [formType, setFormType] = useState<'colleague' | 'office_personnel'>();
  const [selectedLocationId, setSelectedLocationId] = useState<number>();
  const [selectedMemberId, setSelectedMemberId] = useState<number>();

  const [autocompleteData, setAutocompleteData] = useState<
    Array<{ value: string; label: string; searchFields: string }>
  >([]);

  const [selectedPractice, setSelectedPractice] = useState<any>();
  const [ownedPractices, setOwnedPractices] = useState<any[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openPracticeLocationAccordion, setOpenPracticeLocationAccordion] =
    useState(true);

  const locationAddressRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [addressPopulated, setAddressPopulated] = useState<boolean[]>([]);

  const handlePlaceSelect = useCallback(
    async (autocomplete: google.maps.places.Autocomplete, index: number) => {
      if (!autocomplete) return;

      const place = autocomplete.getPlace();
      if (!place.address_components) return;

      // Reset relevant address fields
      setValue(`practices.${index}.street1`, '');
      setValue(`practices.${index}.street2`, '');
      setValue(`practices.${index}.city`, '');
      setValue(`practices.${index}.state`, '');
      setValue(`practices.${index}.zip`, '');
      setValue(`practices.${index}.county`, '');

      let streetNumber = '';
      let route = '';
      let street2 = '';
      let city = '';
      let state = '';
      let zipCode = '';
      let county = '';

      place.address_components?.forEach((component) => {
        const types = component.types;
        if (types.includes('street_number')) streetNumber = component.long_name;
        if (types.includes('route')) route = component.long_name;
        if (types.includes('subpremise')) street2 = component.long_name;
        if (types.includes('locality')) city = component.long_name;
        else if (types.includes('sublocality')) city = component.long_name;
        if (types.includes('administrative_area_level_1'))
          state = component.short_name;
        if (types.includes('postal_code')) zipCode = component.long_name;
        if (types.includes('administrative_area_level_2'))
          county = component.long_name.replace(' County', '');
      });

      const street1 = `${streetNumber} ${route}`.trim();
      if (street1) {
        setAddressPopulated((prev) => {
          const newState = [...prev];
          newState[index] = true;
          return newState;
        });
      }

      // Set values in the form
      setValue(`practices.${index}.street1`, street1);
      setValue(`practices.${index}.street2`, street2);
      setValue(`practices.${index}.city`, city);
      setValue(`practices.${index}.state`, state);
      setValue(`practices.${index}.zip`, zipCode);
      setValue(`practices.${index}.county`, county);
      setValue(`practices.${index}.country`, 'US');
    },
    [setValue],
  );

  const resetAddress = (index: number) => {
    setAddressPopulated((prev) => {
      const newState = [...prev];
      newState[index] = false;
      return newState;
    });
    if (locationAddressRefs.current[index]) {
      locationAddressRefs.current[index]!.value = '';
    }
  };

  useEffect(() => {
    setAddressPopulated((prev) => {
      const newState = [...prev];
      while (newState.length < fields.length) {
        newState.push(false);
      }
      return newState.slice(0, fields.length);
    });
  }, [fields.length]);

  useEffect(() => {
    const autocompletes: (google.maps.places.Autocomplete | null)[] = [];

    const initAutocomplete = () => {
      locationAddressRefs.current.forEach((ref, index) => {
        if (ref && window.google) {
          const autocomplete = new window.google.maps.places.Autocomplete(ref, {
            types: ['address'],
            componentRestrictions: { country: 'us' },
            fields: [
              'address_components',
              'formatted_address',
              'geometry',
              'name',
            ],
          });

          autocomplete.addListener('place_changed', () => {
            handlePlaceSelect(autocomplete, index);
          });

          autocompletes[index] = autocomplete;
        }
      });
    };

    const googleMapsScriptUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;

    if (!window.google) {
      loadScript(googleMapsScriptUrl)
        .then(() => {
          initAutocomplete();
        })
        .catch((err) =>
          console.error('Failed to load Google Maps script:', err),
        );
    } else {
      initAutocomplete();
    }

    // Cleanup
    return () => {
      autocompletes.forEach((autocomplete) => {
        if (autocomplete) {
          google.maps.event.clearInstanceListeners(autocomplete);
        }
      });
    };
  }, [fields.length, watch, handlePlaceSelect]);

  const handlePracticeSearchChange = (value: string, index: number) => {
    setPracticeSearchTerms((prev) => {
      const newSearchTerms = [...prev];
      newSearchTerms[index] = value;
      return newSearchTerms;
    });
  };

  const handleLocationSearchChange = (value: string, index: number) => {
    setLocationSearchTerms((prev) => {
      const newSearchTerms = [...prev];
      newSearchTerms[index] = value;
      return newSearchTerms;
    });
  };

  const fetchPracticeLocations = async (selectedPractice: any) => {
    const response = await httpClient.get(
      `/api/practices/${memberId}/locations`,
      {
        params: {
          id: selectedPractice.id,
          legalbusinessname: selectedPractice.legalbusinessname,
          taxid: selectedPractice.taxid,
          typeoftaxid: selectedPractice.typeoftaxid,
          organizationnpi: selectedPractice.organizationnpi,
        },
      },
    );

    const fetchedLocations = response.data;

    // Use a Set to track unique locations
    const uniqueLocations = new Set();
    const formattedLocations = fetchedLocations
      .map((location: any) => ({
        value: `${location.street1}, ${location.city}`,
        label: `${location.street1}, ${location.city}`,
        searchFields: `${location.street1} ${location.city} ${location.state} ${location.zip} ${location.phone} ${location.country} ${location.county} ${location.practicelocationemailaddress} ${location.practicelocationwebsite} ${location.appointmentschedulingwebsite} ${location.appointmentphonenumber} ${location.faxnumber} ${location.accessibility} ${location.languages.join(' ')} ${location.managerfname} ${location.managerlname} ${location.managerphone} ${location.manageremail}`,
        street1: location.street1,
        city: location.city,
        state: location.state,
        zip: location.zip,
        phone: location.phone,
        country: location.country,
        county: location.county,
        practicelocationemailaddress: location.practicelocationemailaddress,
        practicelocationwebsite: location.practicelocationwebsite,
        appointmentschedulingwebsite: location.appointmentschedulingwebsite,
        appointmentphonenumber: location.appointmentphonenumber,
        faxnumber: location.faxnumber,
        accessibility: location.accessibility,
        languages: location.languages,
        managerfname: location.managerfname,
        managerlname: location.managerlname,
        managerphone: location.managerphone,
        manageremail: location.manageremail,
      }))
      .filter((location) => {
        // Create a unique key for each location
        const uniqueKey = `${location.street1}-${location.city}`;
        if (uniqueLocations.has(uniqueKey)) {
          return false;
        }
        uniqueLocations.add(uniqueKey);
        return true;
      });

    setPracticeLocations(formattedLocations);
  };

  const handlePracticeSelect = (selectedValue: string, index: number) => {
    const businessName = selectedValue.split('_')[0];

    const selectedPractice = practicesList.find(
      (practice) => practice.legalbusinessname === businessName,
    );

    if (selectedPractice) {
      const fieldsToUpdate = [
        'legalbusinessname',
        'taxid',
        'typeoftaxid',
        'organizationnpi',
      ];

      fieldsToUpdate.forEach((field) => {
        setValue(`practices.${index}.${field}`, selectedPractice[field]);
      });

      setSelectedPractice(selectedPractice);
    }
  };

  const handlePracticeLocationSelect = (
    selectedValue: string,
    index: number,
  ) => {
    const locationDetails = practiceLocations.find(
      (location) => location.value === selectedValue,
    );

    if (locationDetails) {
      const fieldsToUpdate = [
        'street1',
        'city',
        'state',
        'zip',
        'phone',
        'country',
        'county',
        'practicelocationemailaddress',
        'practicelocationwebsite',
        'appointmentschedulingwebsite',
        'appointmentphonenumber',
        'faxnumber',
        'accessibility',
        'languages',
        'managerfname',
        'managerlname',
        'managerphone',
        'manageremail',
      ];

      fieldsToUpdate.forEach((field) => {
        setValue(`practices.${index}.${field}`, locationDetails[field]);
      });
    }
  };

  const fetchPracticesPage = async () => {
    try {
      const response = await httpClient.get(`/api/practices/${memberId}/all`);
      const fetchedPractices = response.data;

      const uniquePractices = Array.from(
        new Map(
          [...practicesList, ...fetchedPractices].map((practice) => [
            practice.legalbusinessname,
            practice,
          ]),
        ).values(),
      );

      const formattedPractices = uniquePractices
        .filter((practice) => practice && practice.legalbusinessname)
        .map((practice: any) => ({
          value: practice.legalbusinessname,
          label: `${practice.legalbusinessname}`,
          searchFields: `${practice.legalbusinessname} ${practice.taxid || ''} ${practice.organizationnpi || ''}`,
        }))
        .filter(
          (item, idx, self) =>
            idx === self.findIndex((t) => t.value === item.value),
        );

      setPracticesList(uniquePractices.filter((practice) => practice !== null));
      setAutocompleteData(formattedPractices.filter((item) => item !== null));
    } catch (error) {
      console.error('Error fetching practices:', error);
    }
  };

  const fetchPractices = async () => {
    try {
      const response = await httpClient.get(`/api/practices/${memberId}`);
      const fetchedPractices = response.data;

      console.log('fetchedPractices', fetchedPractices);

      const primaryPractice = fetchedPractices.find(
        (practice: any) => practice.primary,
      );
      const secondaryPractices = fetchedPractices
        .filter((practice: any) => !practice.primary)
        .sort((a: any, b: any) => a.id - b.id);

      const formatPractice = (practice: any) => {
        // Find if the current user is a practice owner for this location
        const isPracticeOwner =
          practice.practicelocations?.[0]?.member_practicelocation?.find(
            (member: any) =>
              member.memberid === memberId && member.practice_owner === true,
          );

        return {
          ...practice,
          phone: practice.practicelocations[0]?.phone || '',
          street1: practice.practicelocations[0]?.street1 || '',
          street2: practice.practicelocations[0]?.street2 || '',
          city: practice.practicelocations[0]?.city || '',
          state: practice.practicelocations[0]?.state || '',
          zip: practice.practicelocations[0]?.zip || '',
          country: practice.practicelocations[0]?.country || 'US',
          county: practice.practicelocations[0]?.county || '',
          practicelocationemailaddress:
            practice.practicelocations[0]?.practicelocationemailaddress || '',
          practicelocationwebsite:
            practice.practicelocations[0]?.practicelocationwebsite || '',
          appointmentschedulingwebsite:
            practice.practicelocations[0]?.appointmentschedulingwebsite || '',
          appointmentphonenumber:
            practice.practicelocations[0]?.appointmentphonenumber || '',
          faxnumber: practice.practicelocations[0]?.faxnumber || '',
          accessibility: practice.practicelocations[0]?.accessibility || '',
          languages: practice.practicelocations[0]?.languages || [],
          mondaystarttime: practice.practicelocations[0]?.mondaystarttime || '',
          mondayendtime: practice.practicelocations[0]?.mondayendtime || '',
          tuesdaystarttime:
            practice.practicelocations[0]?.tuesdaystarttime || '',
          tuesdayendtime: practice.practicelocations[0]?.tuesdayendtime || '',
          wednesdaystarttime:
            practice.practicelocations[0]?.wednesdaystarttime || '',
          wednesdayendtime:
            practice.practicelocations[0]?.wednesdayendtime || '',
          thursdaystarttime:
            practice.practicelocations[0]?.thursdaystarttime || '',
          thursdayendtime: practice.practicelocations[0]?.thursdayendtime || '',
          fridaystarttime: practice.practicelocations[0]?.fridaystarttime || '',
          fridayendtime: practice.practicelocations[0]?.fridayendtime || '',
          saturdaystarttime:
            practice.practicelocations[0]?.saturdaystarttime || '',
          saturdayendtime: practice.practicelocations[0]?.saturdayendtime || '',
          sundaystarttime: practice.practicelocations[0]?.sundaystarttime || '',
          sundayendtime: practice.practicelocations[0]?.sundayendtime || '',
          managerfname: practice.practicelocations[0]?.managerfname || '',
          managerlname: practice.practicelocations[0]?.managerlname || '',
          managerphone: practice.practicelocations[0]?.managerphone || '',
          manageremail: practice.practicelocations[0]?.manageremail || '',
          practice_owner: !!isPracticeOwner, // Now checks specifically for the current user
          practicelimitations: {
            limitGender:
              practice.practicelocations?.[0]?.practicelimitations
                ?.limitGender || false,
            genderType:
              practice.practicelocations?.[0]?.practicelimitations
                ?.genderType || '',
            ageMinimum:
              practice.practicelocations?.[0]?.practicelimitations
                ?.ageMinimum || 0,
            ageMaximum:
              practice.practicelocations?.[0]?.practicelimitations
                ?.ageMaximum || 0,
            limitAge:
              practice.practicelocations?.[0]?.practicelimitations?.limitAge ||
              false,
            limitOther:
              practice.practicelocations?.[0]?.practicelimitations
                ?.limitOther || false,
            otherLimitations:
              practice.practicelocations?.[0]?.practicelimitations
                ?.otherLimitations || '',
          },
          patientacceptance: {
            acceptAllNewPatients:
              practice.practicelocations?.[0]?.patientacceptance
                ?.acceptAllNewPatients || false,
            acceptNewPatients:
              practice.practicelocations?.[0]?.patientacceptance
                ?.acceptNewPatients || false,
            acceptExistingPatients:
              practice.practicelocations?.[0]?.patientacceptance
                ?.acceptExistingPatients || false,
            acceptMedicarePatients:
              practice.practicelocations?.[0]?.patientacceptance
                ?.acceptMedicarePatients || false,
            acceptMedicaidPatients:
              practice.practicelocations?.[0]?.patientacceptance
                ?.acceptMedicaidPatients || false,
            acceptPhysicianReferrals:
              practice.practicelocations?.[0]?.patientacceptance
                ?.acceptPhysicianReferrals || false,
            referralCircumstances:
              practice.practicelocations?.[0]?.patientacceptance
                ?.referralCircumstances || '',
            referralScreeningQuestions:
              practice.practicelocations?.[0]?.patientacceptance
                ?.referralScreeningQuestions || '',
            variesByHealthPlan:
              practice.practicelocations?.[0]?.patientacceptance
                ?.variesByHealthPlan || false,
          },
          practicelocation_telehealth: {
            doProvideTelehealthService:
              practice.practicelocations?.[0]?.practicelocation_telehealth
                ?.doProvideTelehealthService || false,
            isTelehealthApplicationHIPAACompliant:
              practice.practicelocations?.[0]?.practicelocation_telehealth
                ?.isTelehealthApplicationHIPAACompliant || false,
            canSupportFamilyCaregivers:
              practice.practicelocations?.[0]?.practicelocation_telehealth
                ?.canSupportFamilyCaregivers || false,
            audio:
              practice.practicelocations?.[0]?.practicelocation_telehealth
                ?.audio || false,
            audioVideo:
              practice.practicelocations?.[0]?.practicelocation_telehealth
                ?.audioVideo || false,
            onlineAdaptiveInterviews:
              practice.practicelocations?.[0]?.practicelocation_telehealth
                ?.onlineAdaptiveInterviews || false,
            secureTextMessaging:
              practice.practicelocations?.[0]?.practicelocation_telehealth
                ?.secureTextMessaging || false,
            remoteMonitoring:
              practice.practicelocations?.[0]?.practicelocation_telehealth
                ?.remoteMonitoring || false,
            storeAndForward:
              practice.practicelocations?.[0]?.practicelocation_telehealth
                ?.storeAndForward || false,
          },
          practicelocation_services: {
            ageAppropriateImmunizations:
              practice.practicelocations?.[0]?.practicelocation_services
                ?.ageAppropriateImmunizations || false,
            allergyInjections:
              practice.practicelocations?.[0]?.practicelocation_services
                ?.allergyInjections || false,
            other:
              practice.practicelocations?.[0]?.practicelocation_services
                ?.other || false,
            otherServices:
              practice.practicelocations?.[0]?.practicelocation_services
                ?.otherServices || '',
            specialSkillsPractitioner:
              practice.practicelocations?.[0]?.practicelocation_services
                ?.specialSkillsPractitioner || '',
            specialSkillsStaff:
              practice.practicelocations?.[0]?.practicelocation_services
                ?.specialSkillsStaff || '',
          },
          practicelocation_remittance: {
            electronicBilling:
              practice.practicelocations?.[0]?.practicelocation_remittance
                ?.electronicBilling || false,
            masterCard:
              practice.practicelocations?.[0]?.practicelocation_remittance
                ?.masterCard || false,
            visa:
              practice.practicelocations?.[0]?.practicelocation_remittance
                ?.visa || false,
            americanExpress:
              practice.practicelocations?.[0]?.practicelocation_remittance
                ?.americanExpress || false,
            otherCreditCard:
              practice.practicelocations?.[0]?.practicelocation_remittance
                ?.otherCreditCard || false,
            otherCreditCardType:
              practice.practicelocations?.[0]?.practicelocation_remittance
                ?.otherCreditCardType || '',
            billingDepartmentName:
              practice.practicelocations?.[0]?.practicelocation_remittance
                ?.billingDepartmentName || '',
            checkPayableTo:
              practice.practicelocations?.[0]?.practicelocation_remittance
                ?.checkPayableTo || '',
          },
          practicelocation_workerscomp: {
            acceptWorkersCompensation:
              practice.practicelocations?.[0]?.practicelocation_workerscomp
                ?.acceptWorkersCompensation || false,
            isModifiedOrAlternativeDutyEvaluated:
              practice.practicelocations?.[0]?.practicelocation_workerscomp
                ?.isModifiedOrAlternativeDutyEvaluated || false,
            canProvideClaimantInfo:
              practice.practicelocations?.[0]?.practicelocation_workerscomp
                ?.canProvideClaimantInfo || false,
            staffTrainedForWorkCases:
              practice.practicelocations?.[0]?.practicelocation_workerscomp
                ?.staffTrainedForWorkCases || false,
            willAccommodateUrgentWalkins:
              practice.practicelocations?.[0]?.practicelocation_workerscomp
                ?.willAccommodateUrgentWalkins || false,
          },
          isprimarylocation:
            practice.practicelocations?.[0]?.isprimarylocation || false,
        };
      };

      const formattedPrimaryPractice = primaryPractice
        ? formatPractice(primaryPractice)
        : null;
      const formattedSecondaryPractices = secondaryPractices.map(
        (practice: any) => formatPractice(practice),
      );

      let formattedPractices = [
        formattedPrimaryPractice,
        ...formattedSecondaryPractices,
      ].filter(Boolean);

      // If no primary location is set, make the first one primary
      const hasPrimaryLocation = formattedPractices.some(
        (practice) => practice.isprimarylocation,
      );

      if (!hasPrimaryLocation && formattedPractices.length > 0) {
        formattedPractices = formattedPractices.map((practice, index) => ({
          ...practice,
          isprimarylocation: index === 0, // Set first practice as primary
        }));
      }

      // Sort practices by isprimarylocation
      formattedPractices.sort((a, b) => {
        if (a.isprimarylocation && !b.isprimarylocation) return -1;
        if (!a.isprimarylocation && b.isprimarylocation) return 1;
        return 0;
      });

      reset({ practices: formattedPractices });
      setInitialData(formattedPractices);
    } catch (error) {
      console.error('Error fetching practices:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch practices',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  const fetchOwnedPractices = async () => {
    try {
      const response = await httpClient.get(
        `/api/members/practices-owned/${memberId}`,
      );
      const data = await response.data;
      setOwnedPractices(data.practicesOwned);
    } catch (error) {
      console.error('Error fetching owned practices', error);
    }
  };

  useEffect(() => {
    if (memberId) {
      fetchPractices();
      fetchOwnedPractices();
    }
  }, [memberId, isSubmitting]);

  useEffect(() => {
    fetchPracticesPage();
  }, []);

  useEffect(() => {
    if (selectedPractice) {
      fetchPracticeLocations(selectedPractice);
    }
  }, [selectedPractice]);

  const toggleCollapse = (index: number) => {
    setCollapsedIndices((prev) => {
      const newCollapsedIndices = [...prev];
      newCollapsedIndices[index] = !newCollapsedIndices[index];
      return newCollapsedIndices;
    });
  };

  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    console.log('submit form values', data);

    // Ensure only one primary location is set
    const primaryLocationCount = data.practices.filter(
      (p: any) => p.isprimarylocation,
    ).length;
    if (primaryLocationCount > 1) {
      notifications.show({
        title: 'Error',
        message: 'Only one practice location can be set as primary',
        color: 'red',
        position: 'bottom-center',
      });
      setIsSubmitting(false);
      return;
    }

    const formatData = (items: any[]) => {
      return items.map((item) => ({
        ...item,
        memberid: memberId,
      }));
    };

    const formattedData = formatData(data.practices);

    const createPractice = async (practice: any) => {
      const response = await httpClient.post('/api/practices', {
        memberId,
        practices: [practice],
      });
      return response.data;
    };

    const updatePractice = async (practice: any) => {
      const response = await httpClient.put(`/api/practices/${practice.id}`, {
        memberId,
        practices: [practice],
      });
      return response.data;
    };

    const deletePractice = async (id: number) => {
      await httpClient.delete(`/api/practices/${id}`);
      console.log('delete response');
    };

    // Determine deletions, creations, and updates
    const initialIds = new Set<number>(initialData?.map((h: any) => h.id));
    const currentIds = new Set<number>(formattedData.map((h: any) => h.id));
    const deletedIds = [...initialIds].filter(
      (id): id is number => !currentIds.has(id),
    );

    console.log('deletedIds', deletedIds);

    const createTasks = formattedData
      .filter((h: any) => !h.id || h.id === 0)
      .map((h: any) => createPractice(h));
    const updateTasks = formattedData
      .filter((h: any) => h.id && h.id > 0 && initialIds.has(h.id))
      .map((h: any) => updatePractice(h));

    const deleteTasks = deletedIds.map((id) => deletePractice(id));

    try {
      const results = await Promise.all([
        ...createTasks,
        ...updateTasks,
        ...deleteTasks,
      ]);
      console.log('Results:', results);
      fetchPractices();

      notifications.show({
        title: 'Success',
        message: 'Practices updated successfully',
        color: 'green',
        position: 'bottom-center',
      });
    } catch (error) {
      console.error('Error submitting practice form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit practice',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOwnerCheckboxChange = (index: number) => {};

  const addEmployee = (
    formType: 'colleague' | 'office_personnel',
    locationId: number,
  ) => {
    setFormType(formType);
    setSelectedMemberId(null);
    setSelectedLocationId(locationId);
    setIsOpen(true);
  };

  const deleteMember = async (member: Member, practicelocationid: number) => {
    try {
      modals.openConfirmModal({
        title: `Are you sure you want to delete '${member.firstname} ${member.lastname}'?`,
        labels: { confirm: 'Yes', cancel: 'No' },
        padding: 0,
        onCancel: () => {},
        onConfirm: async () => {
          const response = await httpClient.delete(
            `/api/practices/employee/${practicelocationid}/${member.id || -1}`,
          );
          console.log(response);
          notifications.show({
            title: 'Success',
            message: 'Employee deleted successfully',
            color: 'green',
            position: 'bottom-center',
          });
          fetchPractices();
        },
      });
    } catch (error) {
      console.log('delete response', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to delete employee record',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  const handlePrimaryLocationChange = (index: number, checked: boolean) => {
    if (checked) {
      // Uncheck all other primary location checkboxes
      const practices = getValues('practices');
      practices.forEach((_, i) => {
        if (i !== index) {
          setValue(`practices.${i}.isprimarylocation`, false);
        }
      });
    } else {
      // If unchecking, revert to initial values
      const practices = initialData || [];
      practices.forEach((practice: any, i: number) => {
        setValue(
          `practices.${i}.isprimarylocation`,
          practice.isprimarylocation || false,
        );
      });
    }
  };

  return (
    <MantineProvider>
      <ModalsProvider>
        <Container>
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
          >
            {fields.map((item, index) => {
              const taxid_type = watch(`practices.${index}.taxid_type`);
              return (
                <Paper
                  id={`practice-${index}-paper`}
                  key={item.id}
                  shadow="xs"
                  p="md"
                  mt="md"
                  withBorder
                >
                  <Group justify="space-between">
                    <Group>
                      <h3>
                        {watch(`practices.${index}.isprimarylocation`)
                          ? 'Primary Practice Location'
                          : `Practice Location ${index}`}
                      </h3>
                      <Button
                        id={`toggle-collapse-practice-${index}`}
                        onClick={() => toggleCollapse(index)}
                        size="xs"
                      >
                        {collapsedIndices[index] ? 'Expand' : 'Collapse'}
                      </Button>

                      <Button
                        id={`remove-practice-${index}`}
                        color="red"
                        onClick={() => remove(index)}
                        size="xs"
                      >
                        Remove
                      </Button>
                    </Group>
                    <Group>
                      <Autocomplete
                        id="practice-search"
                        placeholder="Search by Legal Business Name, Tax ID, or Organization NPI"
                        value={practiceSearchTerms[index]}
                        onChange={(value) => {
                          handlePracticeSearchChange(value, index);
                          if (value.length >= 2) {
                            handlePracticeSelect(value, index);
                          }
                        }}
                        data={autocompleteData}
                        filter={({ options, search }) => {
                          const searchLower = search.toLowerCase().trim();
                          if (searchLower.length < 2) return [];
                          return options.filter((item) => {
                            const option = item as { searchFields?: string };
                            return option.searchFields
                              ?.toLowerCase()
                              .includes(searchLower);
                          });
                        }}
                      />
                    </Group>
                  </Group>
                  <Collapse in={!collapsedIndices[index]}>
                    <Group my="xl" justify="space-evenly">
                      <Controller
                        name={`practices.${index}.isprimarylocation`}
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <Checkbox
                            id={`practices.${index}.isprimarylocation`}
                            label="Make this my primary practice location"
                            checked={field.value}
                            onChange={(event) => {
                              field.onChange(event.currentTarget.checked);
                              handlePrimaryLocationChange(
                                index,
                                event.currentTarget.checked,
                              );
                            }}
                          />
                        )}
                      />
                      <Controller
                        name={`practices.${index}.practice_owner`}
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <Checkbox
                            id={`practices.${index}.practice_owner`}
                            label="I own this practice location."
                            checked={field.value}
                            onChange={(event) =>
                              field.onChange(event.currentTarget.checked)
                            }
                          />
                        )}
                      />
                    </Group>
                    <Grid>
                      <Grid.Col span={6}>
                        <TextInput
                          id={`practices.${index}.legalbusinessname`}
                          label="Legal Business Name (as it appears on the W-9)"
                          {...register(`practices.${index}.legalbusinessname`)}
                        />
                      </Grid.Col>
                      <Grid.Col span={6}>
                        <Controller
                          {...register(`practices.${index}.taxid`)}
                          control={control}
                          render={({ field }) => (
                            <InputBase
                              id={`practices.${index}.taxid`}
                              {...field}
                              label="Tax ID (Select EIN or TIN)"
                              required
                              component={IMaskInput}
                              mask={
                                taxid_type === 'EIN'
                                  ? '00-0000000'
                                  : '000-00-0000'
                              }
                              placeholder={
                                taxid_type === 'EIN'
                                  ? 'XX-XXXXXXX'
                                  : 'XXX-XX-XXXX'
                              }
                              leftSectionWidth={95} // make select value appear enough to the right
                              leftSection={
                                <Controller
                                  {...register(`practices.${index}.taxid_type`)}
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      id={`practices.${index}.taxid_type`}
                                      {...field}
                                      data={[
                                        { value: 'EIN', label: 'EIN' },
                                        { value: 'TIN', label: 'TIN' },
                                      ]}
                                      variant="unstyled"
                                      styles={{
                                        input: {
                                          paddingRight: 0,
                                          textAlign: 'center',
                                        },
                                      }}
                                      onChange={(value) => {
                                        if (value && value !== taxid_type) {
                                          setValue(
                                            `practices.${index}.taxid_type`,
                                            value,
                                            {
                                              shouldValidate: true,
                                            },
                                          );
                                        }
                                      }}
                                    />
                                  )}
                                />
                              }
                              styles={{
                                input: { paddingLeft: rem(100) }, // avoid text overlap with select
                              }}
                            />
                          )}
                        />
                      </Grid.Col>

                      <Grid.Col span={6}>
                        <Controller
                          name={`practices.${index}.typeoftaxid`}
                          control={control}
                          render={({ field }) => (
                            <Select
                              id={`practices.${index}.typeoftaxid`}
                              {...field}
                              label="Type of Tax ID"
                              data={[
                                { value: 'Group', label: 'Group' },
                                { value: 'Individual', label: 'Individual' },
                              ]}
                              clearable
                            />
                          )}
                        />
                      </Grid.Col>
                      <Grid.Col span={6}>
                        <TextInput
                          id={`practices.${index}.organizationnpi`}
                          label="Organization (Type 2) NPI"
                          {...register(`practices.${index}.organizationnpi`)}
                          maxLength={10}
                          onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                            e.target.value = e.target.value.replace(/\D/g, ''); // Replace any non-numeric characters
                          }}
                        />
                      </Grid.Col>
                    </Grid>

                    <Paper shadow="xs" p="sm" withBorder mt="sm">
                      <Accordion
                        id={`practice-${index}-accordion`}
                        onChange={(value) =>
                          setOpenPracticeLocationAccordion(
                            value === 'practice-location-info',
                          )
                        }
                      >
                        <Accordion.Item value="practice-location-info">
                          <Accordion.Control>
                            <Group justify="space-between">
                              <h4
                                style={{
                                  margin: 0,
                                }}
                              >
                                Practice Location Information
                              </h4>
                              {
                                openPracticeLocationAccordion
                                // && (
                                // <Autocomplete
                                //   w={'40%'}
                                //   mr={20}
                                //   placeholder="Search by Practice Location Address"
                                //   value={locationSearchTerms[index]}
                                //   onChange={(value) => {
                                //     handleLocationSearchChange(value, index);
                                //     handlePracticeLocationSelect(value, index);
                                //   }}
                                //   data={practiceLocations}
                                //   filter={({ options, search }) => {
                                //     const searchLower = search
                                //       .toLowerCase()
                                //       .trim();
                                //     return options.filter((item) => {
                                //       const option = item as {
                                //         searchFields?: string;
                                //       };
                                //       return option.searchFields
                                //         ?.toLowerCase()
                                //         .includes(searchLower);
                                //     });
                                //   }}
                                //   onClick={(e) => e.stopPropagation()}
                                // />
                                // )
                              }
                            </Group>
                          </Accordion.Control>
                          <Accordion.Panel>
                            <Grid>
                              <Grid.Col span={12}>
                                <TextInput
                                  id={`practice-location-address-${index}`}
                                  label="Аddress - Start typing the address of your practice location below and pick one from the dropdown"
                                  placeholder="Start by typing here"
                                  ref={(el) =>
                                    (locationAddressRefs.current[index] = el)
                                  }
                                  onChange={(e) => {
                                    if (!e.target.value) {
                                      resetAddress(index);
                                    }
                                  }}
                                />
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <TextInput
                                  id={`practice-location-street1-${index}`}
                                  label="Ѕtreet 1"
                                  {...register(`practices.${index}.street1`)}
                                  disabled
                                />
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <TextInput
                                  id={`practice-location-street2-${index}`}
                                  label="Ѕtreet 2"
                                  {...register(`practices.${index}.street2`)}
                                />
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <TextInput
                                  id={`practice-location-city-${index}`}
                                  label="City"
                                  {...register(`practices.${index}.city`)}
                                  disabled
                                />
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <TextInput
                                  id={`practice-location-county-${index}`}
                                  label="County"
                                  {...register(`practices.${index}.county`)}
                                  disabled
                                />
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <Controller
                                  name={`practices.${index}.state`}
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      id={`practice-location-state-${index}`}
                                      {...field}
                                      label="State"
                                      data={STATES_ARRAY.map((state) => ({
                                        value: state,
                                        label: state,
                                      }))}
                                      clearable
                                      searchable
                                      disabled
                                    />
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <TextInput
                                  id={`practice-location-zip-${index}`}
                                  label="ZIP"
                                  {...register(`practices.${index}.zip`)}
                                  maxLength={5}
                                  onInput={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    e.target.value = e.target.value.replace(
                                      /\D/g,
                                      '',
                                    ); // Replace any non-numeric characters
                                  }}
                                  disabled
                                />
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <Controller
                                  name={`practices.${index}.country`}
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      id={`practice-location-country-${index}`}
                                      {...field}
                                      label="Country"
                                      data={[
                                        { value: 'US', label: 'United States' },
                                      ]}
                                      clearable
                                      searchable
                                      disabled
                                    />
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <TextInput
                                  id={`practice-location-email-${index}`}
                                  label="Practice Location Email Address"
                                  {...register(
                                    `practices.${index}.practicelocationemailaddress`,
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <TextInput
                                  id={`practice-location-website-${index}`}
                                  label="Practice Location Website"
                                  {...register(
                                    `practices.${index}.practicelocationwebsite`,
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <TextInput
                                  id={`practice-location-appointment-scheduling-website-${index}`}
                                  label="Appointment Scheduling Website"
                                  {...register(
                                    `practices.${index}.appointmentschedulingwebsite`,
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={4}>
                                <Controller
                                  name={`practices.${index}.appointmentphonenumber`}
                                  control={control}
                                  render={({ field }) => (
                                    <Input.Wrapper label="Appointment Phone Number">
                                      <Input
                                        id={`practice-location-appointment-phone-number-${index}`}
                                        component={IMaskInput}
                                        {...field}
                                        mask="(000) 000-0000"
                                        placeholder="(XXX) XXX-XXXX"
                                      />
                                    </Input.Wrapper>
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={4}>
                                <Controller
                                  name={`practices.${index}.phone`}
                                  control={control}
                                  render={({ field }) => (
                                    <Input.Wrapper label="Phone">
                                      <Input
                                        id={`practice-location-phone-${index}`}
                                        component={IMaskInput}
                                        {...field}
                                        mask="(000) 000-0000"
                                        placeholder="(XXX) XXX-XXXX"
                                      />
                                    </Input.Wrapper>
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={4}>
                                <Controller
                                  name={`practices.${index}.faxnumber`}
                                  control={control}
                                  render={({ field }) => (
                                    <Input.Wrapper label="Fax Number">
                                      <Input
                                        id={`practice-location-fax-number-${index}`}
                                        component={IMaskInput}
                                        {...field}
                                        mask="(000) 000-0000"
                                        placeholder="(XXX) XXX-XXXX"
                                      />
                                    </Input.Wrapper>
                                  )}
                                />
                              </Grid.Col>
                            </Grid>
                            <Controller
                              name={`practices.${index}.languages`}
                              control={control}
                              render={({ field }) => (
                                <MultiSelect
                                  id={`practice-location-languages-${index}`}
                                  {...field}
                                  label="Languages"
                                  data={LANGUAGES.map((language) => ({
                                    value: language,
                                    label: language,
                                  }))}
                                  searchable
                                  clearable
                                  value={field.value || []}
                                />
                              )}
                            />
                          </Accordion.Panel>
                        </Accordion.Item>

                        <Accordion.Item value="practice-accessibility">
                          <Accordion.Control>
                            <h4
                              style={{
                                margin: 0,
                              }}
                            >
                              Accessibility
                            </h4>
                          </Accordion.Control>
                          <Accordion.Panel>
                            <PracticeLocationAccessibilityForm
                              practiceIndex={index}
                              practiceLocationIndex={0}
                              control={control}
                              register={register}
                              loading={isSubmitting}
                            />
                          </Accordion.Panel>
                        </Accordion.Item>

                        <Accordion.Item value="office-manager-info">
                          <Accordion.Control>
                            <h4
                              style={{
                                margin: 0,
                              }}
                            >
                              Office Manager Contact Information
                            </h4>
                          </Accordion.Control>
                          <Accordion.Panel>
                            <Grid>
                              <Grid.Col span={6}>
                                <TextInput
                                  id={`practice-location-manager-first-name-${index}`}
                                  label="First Name"
                                  {...register(
                                    `practices.${index}.managerfname`,
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <TextInput
                                  id={`practice-location-manager-last-name-${index}`}
                                  label="Last Name"
                                  {...register(
                                    `practices.${index}.managerlname`,
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <Controller
                                  name={`practices.${index}.managerphone`}
                                  control={control}
                                  render={({ field }) => (
                                    <Input.Wrapper label="Phone">
                                      <Input
                                        id={`practice-location-manager-phone-${index}`}
                                        component={IMaskInput}
                                        {...field}
                                        mask="(000) 000-0000"
                                        placeholder="(XXX) XXX-XXXX"
                                      />
                                    </Input.Wrapper>
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <TextInput
                                  id={`practice-location-manager-email-${index}`}
                                  label="Email"
                                  {...register(
                                    `practices.${index}.manageremail`,
                                  )}
                                />
                              </Grid.Col>
                            </Grid>
                          </Accordion.Panel>
                        </Accordion.Item>

                        <Accordion.Item value="office-hours">
                          <Accordion.Control>
                            <h4
                              style={{
                                margin: 0,
                              }}
                            >
                              Office Hours
                            </h4>
                          </Accordion.Control>
                          <Accordion.Panel>
                            <Grid>
                              <Grid.Col span={6}>
                                <Controller
                                  name={`practices.${index}.mondaystarttime`}
                                  control={control}
                                  render={({ field }) => (
                                    <TimeInput
                                      id={`practice-location-monday-start-time-${index}`}
                                      {...field}
                                      label="Monday Start Time"
                                    />
                                  )}
                                />
                                <Controller
                                  name={`practices.${index}.mondayendtime`}
                                  control={control}
                                  render={({ field }) => (
                                    <TimeInput
                                      id={`practice-location-monday-end-time-${index}`}
                                      {...field}
                                      label="Monday End Time"
                                    />
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <Controller
                                  name={`practices.${index}.tuesdaystarttime`}
                                  control={control}
                                  render={({ field }) => (
                                    <TimeInput
                                      id={`practice-location-tuesday-start-time-${index}`}
                                      {...field}
                                      label="Tuesday Start Time"
                                    />
                                  )}
                                />
                                <Controller
                                  name={`practices.${index}.tuesdayendtime`}
                                  control={control}
                                  render={({ field }) => (
                                    <TimeInput
                                      id={`practice-location-tuesday-end-time-${index}`}
                                      {...field}
                                      label="Tuesday End Time"
                                    />
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <Controller
                                  name={`practices.${index}.wednesdaystarttime`}
                                  control={control}
                                  render={({ field }) => (
                                    <TimeInput
                                      id={`practice-location-wednesday-start-time-${index}`}
                                      {...field}
                                      label="Wednesday Start Time"
                                    />
                                  )}
                                />
                                <Controller
                                  name={`practices.${index}.wednesdayendtime`}
                                  control={control}
                                  render={({ field }) => (
                                    <TimeInput
                                      id={`practice-location-wednesday-end-time-${index}`}
                                      {...field}
                                      label="Wednesday End Time"
                                    />
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <Controller
                                  name={`practices.${index}.thursdaystarttime`}
                                  control={control}
                                  render={({ field }) => (
                                    <TimeInput
                                      id={`practice-location-thursday-start-time-${index}`}
                                      {...field}
                                      label="Thursday Start Time"
                                    />
                                  )}
                                />
                                <Controller
                                  name={`practices.${index}.thursdayendtime`}
                                  control={control}
                                  render={({ field }) => (
                                    <TimeInput
                                      id={`practice-location-thursday-end-time-${index}`}
                                      {...field}
                                      label="Thursday End Time"
                                    />
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <Controller
                                  name={`practices.${index}.fridaystarttime`}
                                  control={control}
                                  render={({ field }) => (
                                    <TimeInput
                                      id={`practice-location-friday-start-time-${index}`}
                                      {...field}
                                      label="Friday Start Time"
                                    />
                                  )}
                                />
                                <Controller
                                  name={`practices.${index}.fridayendtime`}
                                  control={control}
                                  render={({ field }) => (
                                    <TimeInput
                                      id={`practice-location-friday-end-time-${index}`}
                                      {...field}
                                      label="Friday End Time"
                                    />
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <Controller
                                  name={`practices.${index}.saturdaystarttime`}
                                  control={control}
                                  render={({ field }) => (
                                    <TimeInput
                                      id={`practice-location-saturday-start-time-${index}`}
                                      {...field}
                                      label="Saturday Start Time"
                                    />
                                  )}
                                />
                                <Controller
                                  name={`practices.${index}.saturdayendtime`}
                                  control={control}
                                  render={({ field }) => (
                                    <TimeInput
                                      id={`practice-location-saturday-end-time-${index}`}
                                      {...field}
                                      label="Saturday End Time"
                                    />
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <Controller
                                  name={`practices.${index}.sundaystarttime`}
                                  control={control}
                                  render={({ field }) => (
                                    <TimeInput
                                      id={`practice-location-sunday-start-time-${index}`}
                                      {...field}
                                      label="Sunday Start Time"
                                    />
                                  )}
                                />
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <Controller
                                  name={`practices.${index}.sundayendtime`}
                                  control={control}
                                  render={({ field }) => (
                                    <TimeInput
                                      id={`practice-location-sunday-end-time-${index}`}
                                      {...field}
                                      label="Sunday End Time"
                                    />
                                  )}
                                />
                              </Grid.Col>
                            </Grid>
                          </Accordion.Panel>
                        </Accordion.Item>

                        <Accordion.Item value="practice-location-affiliation">
                          <Accordion.Control>
                            <h4
                              style={{
                                margin: 0,
                              }}
                            >
                              Affiliation
                            </h4>
                          </Accordion.Control>
                          <Accordion.Panel>
                            <PracticeLocationAffliationForm
                              control={control}
                              register={register}
                              practiceIndex={index}
                              practiceLocationIndex={0}
                              loading={isSubmitting}
                            />
                          </Accordion.Panel>
                        </Accordion.Item>

                        <Accordion.Item value="practicelimitations">
                          <Accordion.Control>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                              }}
                            >
                              <h4 style={{ margin: 0 }}>
                                Practice Limitations
                              </h4>
                              <Tooltip
                                label="A limitation is any restriction you have set on the gender or age of your patient population"
                                position="right"
                                withArrow
                              >
                                <IconInfoCircle
                                  size={16}
                                  style={{ color: 'gray' }}
                                />
                              </Tooltip>
                            </div>
                          </Accordion.Control>
                          <Accordion.Panel>
                            <PracticeLimitations
                              index={index}
                              control={control}
                              getValues={getValues}
                            />
                          </Accordion.Panel>
                        </Accordion.Item>

                        <Accordion.Item value="patientacceptance">
                          <Accordion.Control>
                            <h4 style={{ margin: 0 }}>Patients</h4>
                          </Accordion.Control>
                          <Accordion.Panel>
                            <PatientSection index={index} control={control} />
                          </Accordion.Panel>
                        </Accordion.Item>

                        <Accordion.Item value="telehealth">
                          <Accordion.Control>
                            <h4 style={{ margin: 0 }}>Telehealth</h4>
                          </Accordion.Control>
                          <Accordion.Panel>
                            <PracticeLocationTelehealthForm
                              control={control}
                              practiceIndex={index}
                            />
                          </Accordion.Panel>
                        </Accordion.Item>

                        <Accordion.Item value="services">
                          <Accordion.Control>
                            <h4 style={{ margin: 0 }}>Services</h4>
                          </Accordion.Control>
                          <Accordion.Panel>
                            <PracticeLocationServicesForm
                              control={control}
                              practiceIndex={index}
                              watch={watch}
                            />
                          </Accordion.Panel>
                        </Accordion.Item>

                        <Accordion.Item value="payment">
                          <Accordion.Control>
                            <h4 style={{ margin: 0 }}>
                              Payment and Remittance
                            </h4>
                          </Accordion.Control>
                          <Accordion.Panel>
                            <PracticeLocationRemittanceForm
                              control={control}
                              practiceIndex={index}
                              watch={watch}
                            />
                          </Accordion.Panel>
                        </Accordion.Item>

                        <Accordion.Item value="workersCompensation">
                          <Accordion.Control>
                            <h4 style={{ margin: 0 }}>
                              Workers' Compensation Information
                            </h4>
                          </Accordion.Control>
                          <Accordion.Panel>
                            <PracticeLocationWorkersCompForm
                              control={control}
                              practiceIndex={index}
                            />
                          </Accordion.Panel>
                        </Accordion.Item>
                      </Accordion>
                    </Paper>

                    {getValues(`practices`)[index].practice_owner &&
                      dirtyFields.practices?.[index]?.practice_owner !==
                        true && (
                        <Paper shadow="xs" p="sm" withBorder mt="sm">
                          <Title size="md" mb={0} mt={20} ta="center">
                            Manage Practice Employees
                          </Title>

                          <Group justify="space-between">
                            <Title size="md" mb={0} mt={20}>
                              Colleague
                            </Title>
                            <Table border={1} cellPadding="10">
                              <Thead>
                                <Tr>
                                  {/* <Th>ID</Th> */}
                                  <Th>Type</Th>
                                  <Th>First Name</Th>
                                  <Th>Last Name</Th>
                                  <Th>Email</Th>
                                  <Th>NPI</Th>
                                  <Th></Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {getValues('practices')?.[0]
                                  ?.practicelocations?.[
                                    index
                                  ]?.member_practicelocation?.filter(
                                    (m) => m.employeetype === 'colleague',
                                  )
                                  .map(
                                    (
                                      {
                                        members: member,
                                        employeetype,
                                        practicelocationid,
                                      },
                                      index,
                                    ) => {
                                      return (
                                        <Tr key={index}>
                                          {/* <Td>{member.id}</Td> */}
                                          <Td>{member.providertype}</Td>
                                          <Td>{member.firstname}</Td>
                                          <Td>{member.lastname}</Td>
                                          <Td>{member.email}</Td>
                                          <Td>{member.npi}</Td>
                                          <Td width={'80px'}>
                                            <Group
                                              gap={4}
                                              justify="center"
                                              wrap="nowrap"
                                            >
                                              <Button
                                                size="xs"
                                                variant="filled"
                                                color="red"
                                                onClick={() => {
                                                  deleteMember(
                                                    member,
                                                    practicelocationid,
                                                  );
                                                }}
                                              >
                                                <IconX size={16} />
                                              </Button>
                                            </Group>
                                          </Td>
                                        </Tr>
                                      );
                                    },
                                  )}
                              </Tbody>
                            </Table>

                            <Button
                              mr="xs"
                              mb={10}
                              onClick={() =>
                                addEmployee(
                                  'colleague',
                                  getValues('practices')?.[index]
                                    ?.practicelocations?.[0]?.id,
                                )
                              }
                              disabled={
                                !getValues('practices')?.[index]
                                  ?.practicelocations?.[0]?.id
                              }
                            >
                              Add Colleague
                            </Button>
                          </Group>
                          <Group justify="space-between">
                            <Title size="md" mb={0} mt={20}>
                              Office Personnel
                            </Title>
                            <Table border={1} cellPadding="10">
                              <Thead>
                                <Tr>
                                  {/* <th>ID</th> */}
                                  {/* <Th>Type</Th> */}
                                  <Th>First Name</Th>
                                  <Th>Last Name</Th>
                                  <Th>Email</Th>
                                  <Th>Phone</Th>
                                  <Th></Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {getValues('practices')?.[0]
                                  ?.practicelocations?.[
                                    index
                                  ]?.member_practicelocation?.filter(
                                    (m) =>
                                      m.employeetype === 'office_personnel',
                                  )
                                  .map(
                                    (
                                      {
                                        members: member,
                                        employeetype,
                                        practicelocationid,
                                      },
                                      index,
                                    ) => {
                                      return (
                                        <Tr key={index}>
                                          {/* <Td>{member.id}</Td> */}
                                          {/* <Td>{member.providertype}</Td> */}
                                          <Td>{member.firstname}</Td>
                                          <Td>{member.lastname}</Td>
                                          <Td>{member.email}</Td>
                                          <Td>{member.mobilephone}</Td>
                                          <Td width={'80px'}>
                                            <Group
                                              gap={4}
                                              justify="center"
                                              wrap="nowrap"
                                            >
                                              <Button
                                                size="xs"
                                                variant="filled"
                                                color="red"
                                                onClick={() => {
                                                  deleteMember(
                                                    member,
                                                    practicelocationid,
                                                  );
                                                }}
                                              >
                                                <IconX size={16} />
                                              </Button>
                                            </Group>
                                          </Td>
                                        </Tr>
                                      );
                                    },
                                  )}
                              </Tbody>
                            </Table>
                            <Button
                              mr="xs"
                              mb={10}
                              onClick={() =>
                                addEmployee(
                                  'office_personnel',
                                  getValues('practices')?.[index]
                                    ?.practicelocations?.[0]?.id,
                                )
                              }
                              disabled={
                                !getValues('practices')?.[index]
                                  ?.practicelocations?.[0]?.id
                              }
                            >
                              Add Office Personnel
                            </Button>
                          </Group>
                        </Paper>
                      )}

                    {getValues(`practices`)[index].practice_owner &&
                      dirtyFields.practices?.[index]?.practice_owner ===
                        true && (
                        <Paper shadow="xs" p="sm" withBorder mt="sm">
                          <Title size="md" mb={0} mt={20} ta="center">
                            Manage Practice Employees
                          </Title>
                          <Group justify="space-between">
                            <i
                              style={{
                                color: 'red',
                                fontSize: '0.8em',
                                marginTop: '20px',
                                textAlign: 'center',
                                width: '100%',
                              }}
                            >
                              Save this practice location first to add employees
                            </i>
                          </Group>
                        </Paper>
                      )}
                  </Collapse>
                </Paper>
              );
            })}
            <Group mt="md">
              <Button
                id="add-practice"
                onClick={() =>
                  append({
                    legalbusinessname: '',
                    typeoftaxid: '',
                    taxid_type: 'EIN',
                    organizationnpi: '',
                    phone: '',
                    street1: '',
                    street2: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: 'US',
                    county: '',
                    practicelocationemailaddress: '',
                    practicelocationwebsite: '',
                    appointmentschedulingwebsite: '',
                    appointmentphonenumber: '',
                    faxnumber: '',
                    accessibility: '',
                    languages: [],
                    mondaystarttime: '',
                    mondayendtime: '',
                    tuesdaystarttime: '',
                    tuesdayendtime: '',
                    wednesdaystarttime: '',
                    wednesdayendtime: '',
                    thursdaystarttime: '',
                    thursdayendtime: '',
                    fridaystarttime: '',
                    fridayendtime: '',
                    saturdaystarttime: '',
                    saturdayendtime: '',
                    sundaystarttime: '',
                    sundayendtime: '',
                    managerfname: '',
                    managerlname: '',
                    managerphone: '',
                    manageremail: '',
                    primary: 'N',
                    practice_owner: false,
                    isprimarylocation: false,
                    practicelimitations: {
                      limitGender: false,
                      genderType: '',
                      limitAge: false,
                      ageMinimum: 0,
                      ageMaximum: 0,
                      limitOther: false,
                      otherLimitations: '',
                    },
                    patientacceptance: {
                      acceptAllNewPatients: false,
                      acceptNewPatients: false,
                      acceptExistingPatients: false,
                      acceptMedicarePatients: false,
                      acceptMedicaidPatients: false,
                      acceptPhysicianReferrals: false,
                      referralCircumstances: '',
                      referralScreeningQuestions: '',
                      variesByHealthPlan: false,
                    },
                    practicelocation_telehealth: {
                      doProvideTelehealthService: false,
                      isTelehealthApplicationHIPAACompliant: false,
                      canSupportFamilyCaregivers: false,
                      audio: false,
                      audioVideo: false,
                      onlineAdaptiveInterviews: false,
                      secureTextMessaging: false,
                      remoteMonitoring: false,
                      storeAndForward: false,
                    },
                    practicelocation_services: {
                      ageAppropriateImmunizations: false,
                      allergyInjections: false,
                      other: false,
                      otherServices: '',
                      specialSkillsPractitioner: '',
                      specialSkillsStaff: '',
                    },
                    practicelocation_remittance: {
                      electronicBilling: false,
                      masterCard: false,
                      visa: false,
                      americanExpress: false,
                      otherCreditCard: false,
                      otherCreditCardType: '',
                      billingDepartmentName: '',
                      checkPayableTo: '',
                    },
                    practicelocation_workerscomp: {
                      acceptWorkersCompensation: false,
                      isModifiedOrAlternativeDutyEvaluated: false,
                      canProvideClaimantInfo: false,
                      staffTrainedForWorkCases: false,
                      willAccommodateUrgentWalkins: false,
                    },
                  })
                }
              >
                Add Practice
              </Button>
            </Group>
            <Group mt="md">
              <Button id="save-practice" type="submit" loading={isSubmitting}>
                Save
              </Button>
            </Group>
          </form>
          <PracticeLocationEmployeeModal
            formType={formType}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            selectedLocationId={selectedLocationId}
            selectedMemberId={selectedMemberId}
            cb={fetchPractices}
          />
          <br />
          {/* <pre>{JSON.stringify(getValues(), null, 2)}</pre> */}
        </Container>
      </ModalsProvider>
    </MantineProvider>
  );
};

export default PracticeForm;
