// usage:
//
// <ProtectedComponent keyName="My Profile" providerType="MD" employmentStatus="Owner">
//      <MyProfileComponent />
// </ProtectedComponent>
//
// hasAccess("My Profile","MD","*") ? "SHOW ME" : "HIDE ME"
//

const privilegeFilter = {
  Home: [{ providerType: ['*'], employmentStatus: ['*'] }],
  'My Profile': [{ providerType: ['*'], employmentStatus: ['*'] }],
  Community: [
    {
      providerType: ['*'],
      employmentStatus: [
        'working_owner_medical_practice',
        'working_non_owner_partner_employee_1099',
        'working_1099_own_llc',
      ],
    },
  ],
  Messages: [
    {
      providerType: ['*'],
      employmentStatus: [
        'working_owner_medical_practice',
        'working_non_owner_partner_employee_1099',
        'working_1099_own_llc',
      ],
    },
  ],
  Benefits: [
    {
      providerType: ['MD', 'DO'],
      employmentStatus: [
        'plan_work_12_months',
        'working_owner_medical_practice',
        'working_non_owner_partner_employee_1099',
        'working_1099_own_llc',
      ],
    },
  ],
};

const hasAccess = (key, providerType, employmentStatus) => {
  const rules = privilegeFilter[key];

  // If key is not found, grant access
  if (!rules) return true;

  return rules.some(
    (rule) =>
      (rule.providerType.includes('*') ||
        rule.providerType.includes(providerType) ||
        providerType === '*') &&
      (rule.employmentStatus.includes('*') ||
        rule.employmentStatus.includes(employmentStatus) ||
        employmentStatus === '*'),
  );
};

const ProtectedComponent = ({
  keyName,
  providerType,
  employmentStatus,
  children,
}) => {
  return hasAccess(keyName, providerType, employmentStatus) ? children : null;
};

export { hasAccess, ProtectedComponent };
