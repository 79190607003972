export const PROVIDER_TYPES = {
  MD: 'Medical Doctor',
  DO: 'Osteopathic Doctor',
  ACU: 'Acupuncturist',
  ADC: 'Alcohol/Drug Counselor',
  AUD: 'Audiologist',
  BT: 'Biofeedback Technician',
  CRNA: 'Certified Registered Nurse Anesthetist',
  CSP: 'Christian Science Practitioner',
  CNS: 'Clinical Nurse Specialist',
  CP: 'Clinical Psychologist',
  CSW: 'Clinical Social Worker',
  DC: 'Doctor of Chiropractic',
  DDS: 'Doctor of Dental Surgery',
  DMD: 'Doctor of Dental Medicine',
  DPM: 'Doctor of Podiatric Medicine',
  DT: 'Dietician',
  LPN: 'Licensed Practical Nurse',
  MFT: 'Marriage/Family Therapist',
  MT: 'Massage Therapist',
  ND: 'Naturopath',
  NEU: 'Neuropsychologist',
  MW: 'Midwife',
  NMW: 'Nurse Midwife',
  NP: 'Nurse Practitioner',
  LN: 'Nutritionist',
  OT: 'Occupational Therapist',
  OPT: 'Optician',
  OD: 'Optometrist',
  PHA: 'Pharmacist',
  PT: 'Physical Therapist',
  PA: 'Physician Assistant',
  PC: 'Professional Counselor',
  RDH: 'Registered Dental Hygienist',
  RN: 'Registered Nurse',
  RNFA: 'Registered Nurse First Assistant',
  RT: 'Respiratory Therapist',
  SLP: 'Speech Pathologist',
  HOS: 'Hospitalist',
  APN: 'Advanced Practice Nurse',
  AA: 'Anesthesia Assistant',
  ABA: 'Applied Behavioral Analyst',
  AT: 'Athletic Trainers',
  GC: 'Genetic Counselor',
  SA: 'Surgical Assistant',
  CNM: 'Certified Nurse Midwife',
  CNA: 'Certified Nursing Assistant',
  LCSW: 'Licensed Clinical Social Worker',
  LMFT: 'Licensed Marriage and Family Therapist',
  RPh: 'Registered Pharmacist',
  PharmD: 'Doctor of Pharmacy',
} as const;
