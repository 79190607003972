import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { MantineProvider, Container, Title } from '@mantine/core';
import AdminPortal from './components/AdminPortal';
import WecareAdminMemberView from './components/WecareAdminMemberView';
import { MemberProvider } from './components/MemberContext';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import AuthProvider from './components/AuthProvider';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './components/Login/Login';
import PhysicianForm from './components/InsurancePortal/PhysicianForm';
import ClinicForm from './components/InsurancePortal/ClinicForm';
import InsuranceMemberPortal from './components/InsurancePortal/InsuranceMemberPortal';
import InsuranceAdminPortal from './components/InsurancePortal/InsuranceAdminPortal';
import InsuranceEmployeePortal from './components/InsurancePortal/InsuranceEmployeePortal';
import ConfirmationPage from './components/InsurancePortal/ConfirmationPage';
import PhysicianEmailVerificationPage from './components/InsurancePortal/PhysicianEmailVerificationPage';
import ClinicEmailVerificationPage from './components/InsurancePortal/ClinicEmailVerificationPage';
import ProviderPracticeForm from './components/ProviderPortal/ProviderPracticeForm';
import ProviderPhysicianForm from './components/ProviderPortal/ProviderPhysicianForm';
import ProviderPhysicianEmailVerificationPage from './components/ProviderPortal/ProviderPhysicianEmailVerificationPage';
import ProviderPracticeEmailVerificationPage from './components/ProviderPortal/ProviderPracticeEmailVerificationPage';
import InsurancePreRegistrationLandingPage from './components/InsurancePortal/InsurancePreRegistrationLandingPage';
import ProviderPreRegistrationLandingPage from './components/ProviderPortal/ProviderPreRegistrationLandingPage';
import IpaMemberSignup from './components/WecareMemberSignup';
import IpaMemberEmailVerificationPage from './components/WecareEmailVerificationPage';
import WecareMemberApprovalPage from './components/WecareMemberApprovalPage';
import NewsletterForm from './components/InsurancePortal/NewsletterForm';
import PasswordResetRequest from './components/Login/PasswordResetRequest';
import PasswordReset from './components/Login/PasswordReset';
import InsuranceApprovalPortal from './components/InsurancePortal/InsuranceApprovalPortal';
import AppShell from './components/AppShell';
import ErrorPage from './components/ErrorPage';
import Settings from './components/Settings';
import WecareLogin from './components/Login/WecareLogin';
import { useLocation } from 'react-router-dom';
import WecareMemberPortal from './components/WecareMemberPortal';
import { mantineTheme } from './theme';
import PlanGraphs from './components/InsurancePortal/PlanGraphs';
import Dashboard from './components/Dashboard';
import WecareMemberDashboard from './components/WecareMemberDashboard';
import Community from './components/Community/index';
import 'intro.js/introjs.css';
import '@mantine/dropzone/styles.css';
import Messages from './components/Community/messages';
import OpenEnrollmentEmployeeForm from './components/InsurancePortal/OpenEnrollmentEmployeeForm';
import AddUserForm from './components/InsurancePortal/AddUserForm';
import RyzeOnboarding from './components/RyzeOnboarding/RyzeOnboarding';
import WecareOnboarding from './components/WecareOnboarding/WecareOnboarding';
import WecareBenefitsPage from './components/WecareBenefitsPage';
import UnapprovedSplashPage from './components/UnapprovedSplashPage';
import './App.css';
import InsuranceAdminAccountManagement from './components/InsurancePortal/InsuranceAdminAccountManagement';
import { NotificationProvider } from './components/NotificationContext';

const App: React.FC = () => {
  const location = useLocation();
  const WecareExternalRedirect = () => {
    useEffect(() => {
      window.location.href = 'https://wecareipa.com';
    }, []);
    return null;
  };

  useEffect(() => {
    // Function to change the favicon
    const changeFavicon = (iconURL) => {
      const link = document.querySelector(
        "link[rel~='icon']",
      ) as HTMLLinkElement;
      if (link) {
        link.href = iconURL;
      } else {
        const newLink = document.createElement('link');
        newLink.rel = 'icon';
        newLink.href = iconURL;
        document.head.appendChild(newLink);
      }
    };

    // Update document title and favicon based on the route
    if (location.pathname.includes('/wecare')) {
      document.title = 'Wecare IPA Portal';
      changeFavicon(`${process.env.PUBLIC_URL}/wecare_icon.png`);
    } else {
      document.title = 'Ryze Health Portal';
      changeFavicon(`${process.env.PUBLIC_URL}/ryze_icon.png`);
    }
  }, [location]);

  return (
    <MemberProvider>
      <AuthProvider>
        <MantineProvider theme={mantineTheme}>
          <NotificationProvider>
            <Notifications />
            <Routes>
              <Route path="plans" element={<PlanGraphs />} />
              {/* Non Protected */}
              <Route path="/login" element={<Login />} />
              <Route
                path="/reset-password"
                element={<PasswordResetRequest />}
              />
              <Route path="/wecare/login" element={<WecareLogin />} />
              <Route
                path="/reset-password/:token"
                element={<PasswordReset />}
              />
              {/* Protected */}
              <Route
                path="/update-password"
                element={<PasswordReset force={true} />}
              />
              <Route
                element={<ProtectedRoute requiredPrivilege="wecaremember" />}
              />
              <Route
                path="/reset-password"
                element={<PasswordResetRequest />}
              />
              <Route path="/wecare/login" element={<WecareLogin />} />
              <Route
                path="/reset-password/:token"
                element={<PasswordReset />}
              />
              {/* Protected */}
              <Route
                element={<ProtectedRoute requiredPrivilege="wecaremember" />}
              >
                <Route
                  path="/wecare/member/"
                  element={
                    <AppShell subMenuKey="member">
                      <WecareMemberPortal />
                    </AppShell>
                  }
                />
              </Route>
              <Route element={<ProtectedRoute requiredPrivilege="ryzeadmin" />}>
                <Route
                  path="/insurance/admin/approve"
                  element={
                    <AppShell>
                      <InsuranceApprovalPortal />
                    </AppShell>
                  }
                />
                <Route
                  path="admin/register/physician"
                  element={
                    <AppShell>
                      <PhysicianForm />
                    </AppShell>
                  }
                />
                <Route
                  path="admin/register/organization"
                  element={
                    <AppShell>
                      <ClinicForm />
                    </AppShell>
                  }
                />
                <Route
                  path="admin/account-management"
                  element={
                    <AppShell>
                      <InsuranceAdminAccountManagement />
                    </AppShell>
                  }
                />
              </Route>
              <Route
                element={<ProtectedRoute requiredPrivilege="wecareadmin" />}
              >
                <Route
                  path="/"
                  element={
                    <AppShell>
                      <AdminPortal />
                    </AppShell>
                  }
                />
                <Route
                  path="/wecare/admin"
                  element={
                    <AppShell>
                      <AdminPortal />
                    </AppShell>
                  }
                />
                <Route
                  path="/member"
                  element={
                    <AppShell subMenuKey="member">
                      <WecareAdminMemberView />
                    </AppShell>
                  }
                />
                <Route
                  path="/wecare/member/:id"
                  element={
                    <AppShell subMenuKey="member">
                      <WecareAdminMemberView />
                    </AppShell>
                  }
                />
                <Route
                  path="/wecare/admin/approve"
                  element={
                    <AppShell>
                      <WecareMemberApprovalPage />
                    </AppShell>
                  }
                />
                {/* <Route
                  path="/insurance/admin/approve/physician"
                  element={
                    <Container size="xl" style={{ marginTop: '2rem' }}>
                      <Title
                        order={1}
                        style={{ textAlign: 'center', marginBottom: '2rem' }}
                      >
                        Ryze Insurance Member Approval
                      </Title>
                      <InsuranceMemberApprovalPage />
                    </Container>
                  }
                />
                <Route
                  path="/insurance/admin/approve/clinic"
                  element={
                    <Container size="xl" style={{ marginTop: '2rem' }}>
                      <Title
                        order={1}
                        style={{ textAlign: 'center', marginBottom: '2rem' }}
                      >
                        Ryze Clinic Member Approval
                      </Title>
                      <InsuranceClinicApprovalPage />
                    </Container>
                  }
                /> */}
              </Route>
              <Route element={<ProtectedRoute requiredPrivilege="ryzeadmin" />}>
                <Route
                  path="/insurance/admin/approve"
                  element={
                    <AppShell>
                      <InsuranceApprovalPortal />
                    </AppShell>
                  }
                />
              </Route>
              <Route
                path="/member/error"
                element={
                  <Container size="xl" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Error
                    </Title>
                  </Container>
                }
              />
              <Route
                path="/wecare/register/member"
                element={
                  <Container size="xl" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      WeCare IPA Registration
                    </Title>
                    <IpaMemberSignup />
                  </Container>
                }
              />
              {/* TEMP ROUTE */}
              <Route
                path="/wecare/register/user/*"
                element={<WecareExternalRedirect />}
              />

              <Route
                path="/wecare/register/member/verify/:enrollmentcode"
                element={
                  <Container size="xl" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Verification
                    </Title>
                    <IpaMemberEmailVerificationPage />
                  </Container>
                }
              />
              <Route
                path="/insurance/register/physician"
                element={
                  <Container size="xl" style={{ marginTop: '2rem' }}>
                    <PhysicianForm />
                  </Container>
                }
              />

              <Route
                path="/insurance/register/organization"
                element={
                  <Container size="xl" style={{ marginTop: '2rem' }}>
                    <ClinicForm />
                  </Container>
                }
              />
              {/* <Route
              path="insurance/register/employee/:enrollmentcode"
              element={
                <Container size="xl" style={{ marginTop: '2rem' }}>
                  <Title
                    order={1}
                    style={{ textAlign: 'center', marginBottom: '2rem' }}
                  >
                    Employee Signup
                  </Title>
                  <EmployeeForm />
                </Container>
              }
            /> */}
              {/* Replace the single user registration route with mapped routes */}
              {[
                '/insurance/register/organization/user',
                '/insurance/register/physician/user',
                '/insurance/register/employee/user',
                '/insurance/register/employee',
                '/insurance/register/admin/user',
                '/insurance/register/wecareadmin/user',
                '/insurance/register/user',
              ].map((path) => (
                <Route
                  key={path}
                  path={`${path}/:enrollmentcode`}
                  element={
                    <Container size="xl" style={{ marginTop: '2rem' }}>
                      <Title
                        order={1}
                        style={{ textAlign: 'center', marginBottom: '2rem' }}
                      >
                        User Signup
                      </Title>
                      <AddUserForm />
                    </Container>
                  }
                />
              ))}
              <Route
                path="insurance/register/organization/:enrollmentcode"
                element={
                  <Container size="xl" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Employee Signup
                    </Title>
                    <OpenEnrollmentEmployeeForm />
                  </Container>
                }
              />
              <Route
                path="insurance/register/newsletter"
                element={
                  <Container size="xl" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '0.5rem' }}
                    >
                      Ryze Health Insurance
                    </Title>
                    <Title
                      order={2}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Sign Up for Our Newsletter for More Information
                    </Title>
                    <NewsletterForm />
                  </Container>
                }
              />
              <Route
                path="/insurance/register/confirmation"
                element={
                  <Container size="xl" style={{ marginTop: '2rem' }}>
                    <ConfirmationPage />
                  </Container>
                }
              />
              <Route
                path="/insurance/register/physician/verify/:enrollmentcode"
                element={<PhysicianEmailVerificationPage />}
              />
              <Route
                path="/insurance/register/organization/verify/:enrollmentcode"
                element={
                  <Container size="xl" style={{ marginTop: '2rem' }}>
                    <ClinicEmailVerificationPage />
                  </Container>
                }
              />
              <Route
                element={
                  <ProtectedRoute requiredPrivilege="insurance_physician" />
                }
              >
                <Route
                  path="/insurance/member/"
                  element={
                    <AppShell>
                      <InsuranceMemberPortal />
                    </AppShell>
                  }
                />
              </Route>
              <Route
                element={
                  <ProtectedRoute requiredPrivilege="insurance_organization" />
                }
              >
                <Route
                  path="insurance/clinic/"
                  element={
                    <AppShell>
                      <InsuranceAdminPortal />
                    </AppShell>
                  }
                />
              </Route>
              <Route
                element={
                  <ProtectedRoute requiredPrivilege="insurance_employee" />
                }
              >
                <Route
                  path="insurance/employee/"
                  element={
                    <AppShell>
                      <InsuranceEmployeePortal />
                    </AppShell>
                  }
                />
                {/* <Route
                path="insurance/employee/"
                element={
                  <InsuranceMemberProvider>
                    <Container size="xl" style={{ marginTop: '2rem' }}>
                      <InsuranceEmployeePortal />
                    </Container>
                  </InsuranceMemberProvider>
                }
              /> */}
              </Route>
              <Route
                element={
                  <ProtectedRoute
                    requiredPrivilege={[
                      'insurance_employee',
                      'insurance_organization',
                    ]}
                  />
                }
              >
                <Route
                  path="insurance/clinic/employee/"
                  element={
                    <AppShell>
                      <InsuranceEmployeePortal />
                    </AppShell>
                  }
                />
              </Route>
              <Route
                path="/provider/register/physician"
                element={
                  <Container size="xl" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '0.5rem' }}
                    >
                      Ryze Provider Network
                    </Title>
                    <Title
                      order={2}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Physician Registration
                    </Title>
                    <ProviderPhysicianForm />
                  </Container>
                }
              />
              <Route
                path="/provider/register/practice"
                element={
                  <Container size="xl" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Ryze Provider Network
                    </Title>
                    <Title
                      order={2}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Independent Practice Registration
                    </Title>
                    <ProviderPracticeForm />
                  </Container>
                }
              />
              <Route
                path="/provider/register/confirmation"
                element={
                  <Container size="xl" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Confirmation
                    </Title>
                    <ConfirmationPage />
                  </Container>
                }
              />
              <Route
                path="/provider/register/physician/verify/:enrollmentcode"
                element={
                  <Container size="xl" style={{ marginTop: '2rem' }}>
                    <ProviderPhysicianEmailVerificationPage />
                  </Container>
                }
              />
              <Route
                path="/provider/register/practice/verify/:enrollmentcode"
                element={
                  <Container size="xl" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    >
                      Verification
                    </Title>
                    <ProviderPracticeEmailVerificationPage />
                  </Container>
                }
              />
              <Route
                path="/insurance/register/"
                element={
                  <Container size="xl" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    ></Title>
                    <InsurancePreRegistrationLandingPage />
                  </Container>
                }
              />
              <Route
                path="/provider/register/"
                element={
                  <Container size="xl" style={{ marginTop: '2rem' }}>
                    <Title
                      order={1}
                      style={{ textAlign: 'center', marginBottom: '2rem' }}
                    ></Title>
                    <ProviderPreRegistrationLandingPage />
                  </Container>
                }
              />

              {/* common menu items */}
              {[
                '/admin/dashboard',
                '/insurance/member/dashboard',
                '/insurance/clinic/dashboard',
                '/insurance/employee/dashboard',
              ].map((path) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <AppShell>
                      <Dashboard path={path.split('/')[2]} />
                    </AppShell>
                  }
                />
              ))}
              {['/wecare/member/dashboard'].map((path) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <AppShell>
                      <WecareMemberDashboard path={path.split('/')[2]} />
                    </AppShell>
                  }
                />
              ))}
              {[
                '/insurance/member/settings',
                '/insurance/clinic/settings',
                '/insurance/employee/settings',
                '/wecare/member/settings',
                '/wecare/admin/settings',
                '/admin/settings',
              ].map((path) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <AppShell>
                      <Settings />
                    </AppShell>
                  }
                />
              ))}
              <Route
                path="/settings/reset-password"
                element={
                  <AppShell>
                    <PasswordResetRequest />
                  </AppShell>
                }
              />
              <Route path="*" element={<ErrorPage />} />
              <Route path="/unauthorized" element={<UnapprovedSplashPage />} />
              <Route
                path="/insurance/onboarding"
                element={<RyzeOnboarding />}
              />
              <Route path="/wecare/onboarding" element={<WecareOnboarding />} />
              {/* community  */}
              <Route
                path="/wecare/community"
                element={
                  <AppShell>
                    <Community />
                  </AppShell>
                }
              />
              <Route
                path="/wecare/messages"
                element={
                  <AppShell>
                    <Messages />
                  </AppShell>
                }
              />
              <Route
                path="/wecare/benefits"
                element={
                  <AppShell>
                    <WecareBenefitsPage />
                  </AppShell>
                }
              />
            </Routes>
          </NotificationProvider>
        </MantineProvider>
      </AuthProvider>
    </MemberProvider>
  );
};

export default App;
