import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from 'react';
import useHttpClient from '../hooks/useHttpClient';
import { Member } from '../../types/members.types';
import { useAuth } from '../hooks/useAuth';
import { SubscriberMember } from '../../types/subscribermember.types';

interface MemberContextProps {
  subscriberMemberId: number | null;
  member: SubscriberMember | null;
  fetchMemberData: () => Promise<void>;
  refreshMemberData: () => void;
  isLoading: boolean;
  error: string | null;
}

const MemberContext = createContext<MemberContextProps | undefined>(undefined);

interface MemberProviderProps {
  children: ReactNode;
}

export const InsuranceMemberProvider: React.FC<MemberProviderProps> = ({
  children,
}) => {
  const [member, setMember] = useState<Member | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const httpClient = useHttpClient();
  const { subscriberMemberId, organizationId } = useAuth();

  const fetchMemberData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    console.log(
      'Fetching member data for subscriberMemberId:',
      subscriberMemberId,
    );
    try {
      const response = await httpClient.get(`/api/subscriber/`);
      const data = await response.data;
      setMember(data);
    } catch (err) {
      console.error('Error fetching member data', err);
      setError('Failed to fetch member data');
    } finally {
      setIsLoading(false);
    }
  }, [httpClient, subscriberMemberId]);

  // A function to trigger a refresh of member data
  const refreshMemberData = useCallback(() => {
    if (subscriberMemberId !== null && subscriberMemberId !== 0) {
      fetchMemberData();
    }
  }, [fetchMemberData, subscriberMemberId]);

  useEffect(() => {
    refreshMemberData();
  }, [subscriberMemberId, refreshMemberData]);

  return (
    <MemberContext.Provider
      value={{
        subscriberMemberId,
        member,
        fetchMemberData,
        refreshMemberData,
        isLoading,
        error,
      }}
    >
      {children}
    </MemberContext.Provider>
  );
};

export const useInsuranceMemberContext = (): MemberContextProps => {
  const context = useContext(MemberContext);
  if (!context) {
    throw new Error('useMemberContext must be used within a MemberProvider');
  }
  return context;
};
