import { Button, Group, Text, Radio } from '@mantine/core';

function ProviderTypeScreen({ currentStep, answers, setAnswers, back, next }) {
  const key = currentStep + '.providerType';

  return (
    <>
      <Text size="lg">What kind of provider are you?</Text>

      <Group justify="flex-start" mt="xl">
        <Radio.Group
          value={answers[key] || ''}
          onChange={(newValue) =>
            setAnswers((values) => ({ ...values, [key]: newValue }))
          }
        >
          <Radio value="physician" label="Physician (MD/DO)" m={10} />
          <Radio value="other" label="Other" m={10} />
        </Radio.Group>
      </Group>

      <Group justify="flex-end" mt="xl">
        <Button
          onClick={() => {
            if (answers[key] === 'physician') {
              next(0);
            } else if (answers[key] === 'other') {
              next(1);
            }
          }}
          disabled={!answers[key]}
        >
          Next
        </Button>
      </Group>
    </>
  );
}

export default ProviderTypeScreen;
