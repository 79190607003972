import { useForm } from 'react-hook-form';
import {
  Button,
  TextInput,
  Container,
  Title,
  Text,
  Card,
  Divider,
  Group,
  Box,
  Center,
  Alert,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { useStepper } from './RegistrationSteppers';
import useHttpClient from '../hooks/useHttpClient';
import { IconCircleCheckFilled, IconInfoCircle } from '@tabler/icons-react';

interface PaymentAccountInformationTabProps {
  onFieldsCompleteChange?: (isComplete: boolean) => void;
}

const PaymentAccountInformationTab = ({
  onFieldsCompleteChange,
}: PaymentAccountInformationTabProps) => {
  const { markStepAsDone } = useStepper();
  const httpClient = useHttpClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isBankInformationSubmitted, setIsBankInformationSubmitted] =
    useState(false);
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [currentAccountStatus, setCurrentAccountStatus] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const response = await httpClient.post('/api/payment/create-account', {
        ...data,
      });
      console.log('response', response.data);
      if (response.status === 200) {
        setIsBankInformationSubmitted(true);
      }
    } catch (e) {
      console.error('Error while submitting bank information');
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchAccountStatus = async () => {
    const accountStatus = await httpClient.get(
      `/api/payment/current-user-account-status`,
    );

    setCurrentAccountStatus(accountStatus.data.accountStatus);
  };

  const fetchPaymentDetails = async () => {
    const premiumNumbers = await httpClient.get(`/api/premium/`);
    if (
      premiumNumbers.data.length > 0 &&
      JSON.stringify(premiumNumbers.data[0]) !== JSON.stringify(paymentInfo)
    ) {
      setPaymentInfo(premiumNumbers.data[0]);
    }
  };

  useEffect(() => {
    fetchPaymentDetails();
  }, []);

  useEffect(() => {
    fetchAccountStatus();
  }, [isBankInformationSubmitted]);

  useEffect(() => {
    if (isBankInformationSubmitted) {
      markStepAsDone('payment-information');
      onFieldsCompleteChange(true);
    }
  }, [isBankInformationSubmitted]);

  return (
    <Container>
      <Title order={1} style={{ textAlign: 'center' }}>
        Payment Information
      </Title>
      <br />

      {(currentAccountStatus === 'VERIFICATION_COMPLETE' ||
        currentAccountStatus === 'NEW') && (
        <Container size="lg">
          <Card shadow="sm" padding="lg" radius="md" withBorder>
            <Center style={{ flexDirection: 'column' }}>
              <IconCircleCheckFilled
                size={70}
                color={'#82C91E'}
                style={{ marginBottom: '1rem' }}
              />
              <Text size="lg" fw="bold" style={{ textAlign: 'center' }}>
                Thank you for submitting your account information. We are
                currently verifying it. We will notify you if we need any
                additional information.
              </Text>
            </Center>
          </Card>
        </Container>
      )}

      {currentAccountStatus === 'ACTIVE' && (
        <Container size="lg">
          <Card shadow="sm" padding="lg" radius="md" withBorder>
            <Center style={{ flexDirection: 'column' }}>
              <IconCircleCheckFilled
                size={70}
                color={'#82C91E'}
                style={{ marginBottom: '1rem' }}
              />
              <Text size="lg" fw="bold" style={{ textAlign: 'center' }}>
                Thank you for submitting your information!
              </Text>
            </Center>
          </Card>
        </Container>
      )}

      {(currentAccountStatus === 'VERIFICATION_FAILED' ||
        currentAccountStatus === null) && (
        <>
          {/* Plan Summary */}
          {paymentInfo && (
            <Container size={'xxl'}>
              <Card shadow="sm" padding="lg" mb="md">
                <Title order={4}>
                  Current Plan Selected: {paymentInfo.planName}
                </Title>
                <Divider my="sm" />
                <Group align="flex-start" grow>
                  <Box>
                    <Text size={'sm'} mb={'xs'} fw={500}>
                      Total Members: {paymentInfo.totalMembers}
                    </Text>
                    <Text size={'sm'} mb={'xs'} fw={500}>
                      Total Families: {paymentInfo.totalFamilies}
                    </Text>
                    <Text size={'sm'} mb={'xs'} fw={500}>
                      Group Area Factor: {paymentInfo.groupAreaFactor}
                    </Text>
                    <Text size={'sm'} mb={'xs'} fw={500}>
                      State average Group Area Factor:{' '}
                      {paymentInfo.groupAreaFactorForStateAverage}
                    </Text>
                  </Box>
                  <div>
                    <Text size={'sm'} mb={'xs'} fw={500}>
                      Total Age-Gender Factor:{' '}
                      {paymentInfo.totalAgeGenderFactor}
                    </Text>
                    <Text size={'sm'} mb={'xs'} fw={500}>
                      Total Premium: ${paymentInfo.totalPremium}
                    </Text>
                    <Text size={'sm'} mb={'xs'} fw={500}>
                      Total State average premium: $
                      {paymentInfo.totalPremiumForStateAverage}
                    </Text>
                    <Text size={'sm'} mb={'xs'} fw={500}>
                      Average Monthly Premium (PMPM): $
                      {paymentInfo.averageMonthlyPremiumPMPM}
                    </Text>
                  </div>
                </Group>
              </Card>
            </Container>
          )}

          {currentAccountStatus === 'VERIFICATION_FAILED' && (
            <Alert
              c={'red'}
              title="Account Verification Failed"
              icon={<IconInfoCircle />}
              mt={'lg'}
              bg={'#FEE2E2'}
            >
              <Text size={'sm'} fw={500}>
                Your account information failed verification. Please
                double-check and resubmit.
              </Text>
            </Alert>
          )}

          <Container size={'sm'}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Account Name Field */}
              <TextInput
                label="Account Name"
                placeholder="Enter your account name"
                {...register('accountName')}
                required
                mb="md"
              />
              {errors.accountName && (
                <Text c="red" size="sm">
                  {errors.accountName.message.toString()}
                </Text>
              )}

              {/* Bank Routing Number Field */}
              <TextInput
                label="Bank Routing Number"
                placeholder="Enter your bank routing number"
                {...register('bankRoutingNumber', {
                  required: 'Bank Routing Number is required',
                  pattern: {
                    value: /^[0-9]+$/,
                    message: 'Bank Routing Number must contain only digits',
                  },
                })}
                mb="md"
              />
              {errors.bankRoutingNumber && (
                <Text c="red" size="sm">
                  {errors.bankRoutingNumber.message.toString()}
                </Text>
              )}

              {/* Bank Account Number Field */}
              <TextInput
                label="Bank Account Number"
                placeholder="Enter your bank account number"
                {...register('bankAccountNumber', {
                  required: 'Bank Account Number is required',
                  pattern: {
                    value: /^[0-9]+$/,
                    message: 'Bank Account Number must contain only digits',
                  },
                })}
                required
                mb="md"
              />
              {errors.bankAccountNumber && (
                <Text c="red" size="sm">
                  {errors.bankAccountNumber.message.toString()}
                </Text>
              )}

              {/* Submit Button */}
              <Button
                type="submit"
                fullWidth
                mt="md"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </form>
          </Container>
        </>
      )}
    </Container>
  );
};

export default PaymentAccountInformationTab;
