import React from 'react';
import { Text, Radio, Button, Group, Stack } from '@mantine/core';
import { useForm, Controller } from 'react-hook-form';

interface LLCScreenProps {
  answers: any;
  setAnswers: (answers: any) => void;
  next: (pathIndex?: number) => void;
  back: () => void;
}

const LLCScreen: React.FC<LLCScreenProps> = ({
  answers,
  setAnswers,
  next,
  back,
}) => {
  const { control, handleSubmit, watch } = useForm({
    defaultValues: { has_llc: answers.has_llc || '' },
  });

  const hasLLC = watch('has_llc');

  const onSubmit = (data: { has_llc: string }) => {
    setAnswers({ ...answers, has_llc: data.has_llc });

    if (data.has_llc === 'yes') {
      next(0);
    } else {
      next(1);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text size="xl" mb="md">
        Have you incorporated a business entity that you intend to use once you
        resume your medical practice?
      </Text>

      <Controller
        name="has_llc"
        control={control}
        rules={{ required: 'Please select an option' }}
        render={({ field, fieldState: { error } }) => (
          <Radio.Group
            {...field}
            value={field.value || ''}
            error={error?.message}
          >
            <Stack gap="sm" mt={10}>
              <Radio value="yes" label="Yes" />
              <Radio value="no" label="No" />
            </Stack>
          </Radio.Group>
        )}
      />

      <Group justify="flex-end" mt="xl">
        <Button variant="default" onClick={back}>
          Back
        </Button>
        <Button type="submit" disabled={!hasLLC}>
          Next
        </Button>
      </Group>
    </form>
  );
};

export default LLCScreen;
