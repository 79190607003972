import React from 'react';
import { Control, Controller, UseFormWatch } from 'react-hook-form';
import { Checkbox, Grid, Group, Stack, Text, TextInput } from '@mantine/core';

interface RemittanceFormProps {
  control: Control<any>;
  practiceIndex: number;
  watch: UseFormWatch<any>;
}

const PracticeLocationRemittanceForm: React.FC<RemittanceFormProps> = ({
  control,
  practiceIndex,
  watch,
}) => {
  const showOtherCreditCardType = watch(
    `practices.${practiceIndex}.practicelocation_remittance.otherCreditCard`,
  );

  return (
    <Grid>
      <Grid.Col span={12}>
        <Controller
          name={`practices.${practiceIndex}.practicelocation_remittance.electronicBilling`}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <Checkbox
              id={`practice-location-remittance-electronic-billing-${practiceIndex}`}
              label="Offers Electronic Billing"
              checked={field.value}
              onChange={(e) => field.onChange(e.currentTarget.checked)}
            />
          )}
        />
      </Grid.Col>

      <Grid.Col span={12}>
        <Text>Payments Accepted:</Text>
        <Group mt="xs">
          <Controller
            name={`practices.${practiceIndex}.practicelocation_remittance.masterCard`}
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <Checkbox
                id={`practice-location-remittance-master-card-${practiceIndex}`}
                label="MasterCard"
                checked={field.value}
                onChange={(e) => field.onChange(e.currentTarget.checked)}
              />
            )}
          />
          <Controller
            name={`practices.${practiceIndex}.practicelocation_remittance.visa`}
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <Checkbox
                id={`practice-location-remittance-visa-${practiceIndex}`}
                label="Visa"
                checked={field.value}
                onChange={(e) => field.onChange(e.currentTarget.checked)}
              />
            )}
          />
          <Controller
            name={`practices.${practiceIndex}.practicelocation_remittance.americanExpress`}
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <Checkbox
                id={`practice-location-remittance-american-express-${practiceIndex}`}
                label="American Express"
                checked={field.value}
                onChange={(e) => field.onChange(e.currentTarget.checked)}
              />
            )}
          />
          <Controller
            name={`practices.${practiceIndex}.practicelocation_remittance.otherCreditCard`}
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <Checkbox
                id={`practice-location-remittance-other-credit-card-${practiceIndex}`}
                label="Other Credit Card"
                checked={field.value}
                onChange={(e) => field.onChange(e.currentTarget.checked)}
              />
            )}
          />
        </Group>
      </Grid.Col>

      {showOtherCreditCardType && (
        <Grid.Col span={6}>
          <Controller
            name={`practices.${practiceIndex}.practicelocation_remittance.otherCreditCardType`}
            control={control}
            render={({ field }) => (
              <TextInput
                id={`practice-location-remittance-other-credit-card-type-${practiceIndex}`}
                label="Other Credit Card Type"
                {...field}
              />
            )}
          />
        </Grid.Col>
      )}

      <Grid.Col span={12}>
        <Stack>
          <Controller
            name={`practices.${practiceIndex}.practicelocation_remittance.billingDepartmentName`}
            control={control}
            render={({ field }) => (
              <TextInput
                id={`practice-location-remittance-billing-department-name-${practiceIndex}`}
                label="Billing Department Name"
                {...field}
              />
            )}
          />
          <Controller
            name={`practices.${practiceIndex}.practicelocation_remittance.checkPayableTo`}
            control={control}
            render={({ field }) => (
              <TextInput
                id={`practice-location-remittance-check-payable-to-${practiceIndex}`}
                label="Check Payable To"
                {...field}
              />
            )}
          />
        </Stack>
      </Grid.Col>
    </Grid>
  );
};

export default PracticeLocationRemittanceForm;
