import React, { useEffect, useState } from 'react';
import {
  Text,
  Group,
  Paper,
  Container,
  Title,
  Alert,
  Button,
  Flex,
} from '@mantine/core';
import useHttpClient from '../hooks/useHttpClient';
import { useInsuranceMemberContext } from './InsuranceMemberContext';
import { notifications } from '@mantine/notifications';
import PlanSelectionDataTable from './PlanSelectionDataTable';
import PlanGraphs from './PlanGraphs';
import { useStepper } from './RegistrationSteppers';
import { IconInfoCircle } from '@tabler/icons-react';
import { planSelectionOnboardingSteps } from '../../helpers/onboardingSteps';
import PlanSelectionOnboardingSteps from './PlanSelectionOnboardingSteps';

interface PlanSelectionProps {
  onFieldsCompleteChange?: (isComplete: boolean) => void;
}

const PlanSelectionTab: React.FC<PlanSelectionProps> = ({
  onFieldsCompleteChange,
}) => {
  const { subscriberMemberId } = useInsuranceMemberContext();
  const [premiumData, setPremiumData] = useState<any>(null);
  const httpClient = useHttpClient();
  const [selectedPlan, setSelectedPlan] = useState<number>(null);
  const { markStepAsDone } = useStepper();
  const [onboardingStepsEnabled, setOnboardingStepsEnabled] = useState(false);
  const [graphsLoaded, setGraphsLoaded] = useState(false);
  const [dataTableLoaded, setDataTableLoaded] = useState(false);

  const [userMetadata, setUserMetadata] = useState<any>(null);

  useEffect(() => {
    const fetchMember = async () => {
      try {
        // Fetch member data
        const { data: memberData } = await httpClient.get(`/api/subscriber/`);
        setSelectedPlan(Number(memberData?.planid));

        // Fetch premium data
        const { data: premiumData } = await httpClient.get(
          `/api/premium/primarysubscriber`,
        );
        setPremiumData(premiumData);
      } catch (error) {
        console.error('Error fetching data:', error);
        notifications.show({
          title: 'Error',
          message: 'Failed to load data',
          color: 'red',
          position: 'bottom-right',
        });
      }
    };

    if (subscriberMemberId) {
      fetchMember();
    }
  }, [subscriberMemberId]);

  useEffect(() => {
    if (selectedPlan) {
      markStepAsDone('plan-selection', true);
      onFieldsCompleteChange(true);
    }
  }, [selectedPlan]);

  const handlePlanSelect = async (planId: string) => {
    try {
      setSelectedPlan(Number(planId));
      const response = await httpClient.put(
        `/api/subscriber/plan/${subscriberMemberId}`,
        { planId },
      );
      console.log('Plan selected successfully:', response.data);

      notifications.show({
        title: 'Success',
        message: 'Plan selected successfully',
        color: 'green',
        position: 'bottom-center',
      });
    } catch (error) {
      console.error('Error selecting plan:', error);
      notifications.show({
        title: 'Error',
        message: 'Error selecting plan:',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  // Fetch user metadata and check if the user has completed the plan selection tour
  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const { data } = await httpClient.get(`/api/user/metadata`);
        setUserMetadata(data.metadata);
        console.log('Current userMetadata:', data.metadata);

        if (
          !data.metadata?.hasCompletedPlanTour &&
          graphsLoaded &&
          dataTableLoaded
        ) {
          setOnboardingStepsEnabled(true);
        }
      } catch (error) {
        console.error('Error fetching metadata:', error);
      }
    };

    fetchMetadata();
  }, [graphsLoaded, dataTableLoaded, httpClient]);

  // Update user metadata to indicate that the user has completed the plan selection tour
  const completeTour = async ({ userMetadata }) => {
    try {
      const updatedMetadata = { ...userMetadata, hasCompletedPlanTour: true };
      console.log('Updating userMetadata:', updatedMetadata);

      await Promise.all([
        httpClient.put(`/api/user/metadata`, {
          userMetadata: updatedMetadata,
        }),
        httpClient.put('/api/subscriber/deal', {
          status: 'Seen/Chosen Plan',
        }),
      ]);

      setUserMetadata(updatedMetadata);
      setOnboardingStepsEnabled(false);
    } catch (error) {
      console.error('Error updating user metadata:', error);
    }
  };

  return (
    <Container size={'xxl'}>
      {/* {JSON.stringify(userMetadata, null, 2)} */}
      <PlanSelectionOnboardingSteps
        stepsEnabled={onboardingStepsEnabled}
        onboardingSteps={planSelectionOnboardingSteps}
        handleStepsCompletion={() => {
          setOnboardingStepsEnabled(false);
          completeTour({ userMetadata });
        }}
      />
      <Flex
        align="center"
        justify="center"
        style={{ position: 'relative', marginTop: '16px' }}
      >
        <Title order={1} ta="center">
          Plan Selection
        </Title>
      </Flex>
      <Group mt="md" justify="right">
        <Button
          onClick={() => setOnboardingStepsEnabled(true)}
          id="help-button"
        >
          Help and tips for this page
        </Button>
      </Group>
      <br />
      <Text fw={500} size="lg">
        Plan Comparison Graphs
      </Text>
      <PlanGraphs onLoad={() => setGraphsLoaded(true)} />
      <br />
      <br />
      <Group justify="space-between" style={{ marginTop: '40px' }}>
        <Text fw={500} size="lg">
          Choose a Plan
        </Text>
      </Group>
      {/* Plan Table Display */}
      <Paper shadow="md" p="lg" withBorder mt="md">
        <PlanSelectionDataTable
          premiums={premiumData}
          planSelectionCallback={handlePlanSelect}
          selectedPlan={selectedPlan}
          onLoad={() => setDataTableLoaded(true)}
        />

        <Alert
          title="Currently not covered"
          icon={<IconInfoCircle />}
          mt={'lg'}
        >
          Routine Dental Services (Adult), Infertility Treatment,
          Long-Term/Custodial Nursing Home Care, Private-Duty Nursing, Routine
          Eye Exam (Adult), Treatment for Temporomandibular Joint Disorders,
          Nutritional Counseling, Prosthetic Devices, Basic Dental Care - Child,
          Orthodontia - Child, Major Dental Care - Child, Basic Dental Care -
          Adult, Orthodontia - Adult, Major Dental Care – Adult, Accidental
          Dental, Bariatric Surgery, Cosmetic Surgery, Routine Foot Care,
          Acupuncture, Weight Loss Programs, Chiropractic Care, Hearing Aids
          <br />
          <i></i>
        </Alert>
        <Alert title="Disclaimer" icon={<IconInfoCircle />} mt={'lg'}>
          Preliminary plan details may be subject to change. Final plan pricing
          will be confirmed once your registration has been completed and
          approved.
        </Alert>
        <Alert title="Got a question?" icon={<IconInfoCircle />} mt={'lg'}>
          Check out our{' '}
          <a
            href="https://www.ryzehealth.com/faq"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline hover:text-blue-700"
          >
            FAQ section
          </a>{' '}
          for answers.
        </Alert>
      </Paper>
    </Container>
  );
};

export default PlanSelectionTab;
