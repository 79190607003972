import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Group,
  Collapse,
  Paper,
  Grid,
} from '@mantine/core';
import {
  SpecialtyFields,
  FormValues,
  specialties,
  nonPhysicianSpecialties,
} from '../types/specialties.types';
import { notifications } from '@mantine/notifications';
import useHttpClient from './hooks/useHttpClient';
import CustomDateInput from './CustomDateInput';
import { useFormDirtyAlert } from '../helpers/useFormDirtyAlert';

interface FormProps {
  memberId: number;
}

const SpecialtyForm: React.FC<FormProps> = ({ memberId }) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { isDirty, dirtyFields },
  } = useForm<FormValues>({
    defaultValues: {
      primaryspecialty: {
        memberId: memberId,
        type: 'primary',
        specialty: null,
        subspecialty: [],
        boardcertified: 'No',
        nonphysician: 'No',
        certificationnumber: '',
        certificationexpiration: null,
        recertificationdate: null,
        initialcertificationdate: null,
        certifyingboard: '',
      },
      secondaryspecialties: [],
    },
    mode: 'onChange',
  });

  useFormDirtyAlert(reset, isDirty, dirtyFields);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'secondaryspecialties',
  });

  const [collapsedIndices, setCollapsedIndices] = useState(
    fields.map(() => true),
  );
  const [initialData, setInitialData] = useState<FormValues>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const httpClient = useHttpClient();

  const fetchSpecialties = async () => {
    try {
      const response = await httpClient.get(`/api/specialties/${memberId}`);
      const fetchedSpecialties: SpecialtyFields[] = response.data;

      console.log('fetchedSpecialties', fetchedSpecialties);

      if (fetchedSpecialties.length === 0) {
        console.log('No specialties found for this member.');
        return;
      }

      const primarySpecialty = fetchedSpecialties.find(
        (s) => s.type === 'primary',
      );

      // Format primary specialty with subspecialty if it exists
      if (primarySpecialty && primarySpecialty.subspecialty.length > 0) {
        primarySpecialty.specialty = `${primarySpecialty.specialty} - ${primarySpecialty.subspecialty[0]}`;
        primarySpecialty.subspecialty = primarySpecialty.subspecialty.slice(1);
      }

      const secondarySpecialties = fetchedSpecialties
        .filter((s) => s.type === 'secondary')
        .map((specialty) => {
          if (specialty.subspecialty.length > 0) {
            return {
              ...specialty,
              specialty: `${specialty.specialty} - ${specialty.subspecialty[0]}`,
              subspecialty: specialty.subspecialty.slice(1),
            };
          }
          return specialty;
        })
        .sort((a, b) => a.id! - b.id!);

      const resetData = {
        primaryspecialty: primarySpecialty || {
          memberId: memberId,
          type: 'primary',
          specialty: null,
          subspecialty: [],
          nonphysician: '',
          boardcertified: '',
          certificationnumber: '',
          certificationexpiration: null,
          recertificationdate: null,
          initialcertificationdate: null,
          certifyingboard: '',
        },
        secondaryspecialties: secondarySpecialties,
      };

      reset(resetData, {
        keepDirty: false,
        keepValues: false,
      });

      setInitialData({
        primaryspecialty: primarySpecialty!,
        secondaryspecialties: secondarySpecialties,
      });
    } catch (error) {
      console.error('Error fetching specialties:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch specialties',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  useEffect(() => {
    fetchSpecialties();
  }, [memberId, isSubmitting]);

  const toggleCollapse = (index: number) => {
    setCollapsedIndices((prev) => {
      const newCollapsedIndices = [...prev];
      newCollapsedIndices[index] = !newCollapsedIndices[index];
      return newCollapsedIndices;
    });
  };

  const onSubmit = async (data: FormValues) => {
    setIsSubmitting(true);

    // Make sure every specialty has the correct memberId before sending
    data.primaryspecialty.memberId = memberId;
    data.secondaryspecialties.forEach((s) => {
      s.memberId = memberId;
    });

    const createSpecialty = async (specialty: SpecialtyFields) => {
      const response = await httpClient.post('/api/specialties', specialty);
      return response.data;
    };

    const updateSpecialty = async (specialty: SpecialtyFields) => {
      const response = await httpClient.put(
        `/api/specialties/${specialty.id}`,
        specialty,
      );
      return response.data;
    };

    const deleteSpecialty = async (id: number) => {
      await httpClient.delete(`/api/specialties/${id}`);
      console.log('delete response');
    };

    const initialSecondaryIds = new Set(
      initialData?.secondaryspecialties.map((s) => s.id),
    );
    const currentSecondaryIds = new Set(
      data.secondaryspecialties.map((s) => s.id),
    );
    const deletedIds = [...initialSecondaryIds].filter(
      (id) => !currentSecondaryIds.has(id),
    );

    const primaryTask =
      !data.primaryspecialty.id || data.primaryspecialty.id === 0
        ? createSpecialty(data.primaryspecialty)
        : updateSpecialty(data.primaryspecialty);

    const secondaryCreationTasks = data.secondaryspecialties
      .filter((s) => !s.id || s.id === 0)
      .map((s) => createSpecialty(s));
    const secondaryUpdateTasks = data.secondaryspecialties
      .filter((s) => s.id && s.id > 0 && initialSecondaryIds.has(s.id))
      .map((s) => updateSpecialty(s));

    const secondaryDeleteTasks = deletedIds.map(deleteSpecialty);

    try {
      const results = await Promise.all([
        primaryTask,
        ...secondaryCreationTasks,
        ...secondaryUpdateTasks,
        ...secondaryDeleteTasks,
      ]);
      console.log('Results:', results);
      fetchSpecialties();

      notifications.show({
        title: 'Success',
        message: 'Specialties updated successfully',
        color: 'green',
        position: 'bottom-center',
      });
    } catch (error) {
      console.error('Error submitting specialty form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit specialty',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Paper withBorder shadow="xs" p="md">
          <Group>
            <h3>Primary Specialty</h3>
          </Group>
          <Grid>
            <Grid.Col span={8}>
              <Controller
                name="primaryspecialty.specialty"
                control={control}
                render={({ field: { onChange, ...field } }) => {
                  return (
                    <Select
                      id="primaryspecialty.specialty"
                      {...field}
                      label="Specialty"
                      searchable
                      clearable
                      data={[
                        ...Object.keys(specialties),
                        ...Object.keys(nonPhysicianSpecialties),
                        ...Object.entries(specialties).flatMap(
                          ([specialty, subs]) =>
                            subs.map((sub) => `${specialty} - ${sub}`),
                        ),
                        ...Object.entries(nonPhysicianSpecialties).flatMap(
                          ([specialty, subs]) =>
                            subs.map((sub) => `${specialty} - ${sub}`),
                        ),
                      ].filter(
                        (option, index, self) => self.indexOf(option) === index,
                      )}
                      onChange={(value) => {
                        setValue('primaryspecialty.specialty', value);
                        if (!value) {
                          setValue('primaryspecialty.subspecialty', []);
                        } else if (value.includes(' - ')) {
                          const subspecialty = value.split(' - ')[1];
                          setValue('primaryspecialty.subspecialty', [
                            subspecialty,
                          ]);
                        } else {
                          setValue('primaryspecialty.subspecialty', []);
                        }
                      }}
                    />
                  );
                }}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <Controller
                name="primaryspecialty.boardcertified"
                control={control}
                render={({ field }) => (
                  <Select
                    id="primaryspecialty.boardcertified"
                    {...field}
                    label="Board Certified?"
                    data={[
                      { value: 'N', label: 'No' },
                      { value: 'Y', label: 'Yes' },
                    ]}
                    clearable
                  />
                )}
              />
            </Grid.Col>
          </Grid>

          {watch('primaryspecialty.boardcertified') === 'Y' && (
            <Grid>
              <Grid.Col span={6}>
                <TextInput
                  label="Certification Number"
                  {...register('primaryspecialty.certificationnumber')}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <CustomDateInput
                  control={control}
                  name="primaryspecialty.initialcertificationdate"
                  label="Initial Certification Date"
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <CustomDateInput
                  control={control}
                  name="primaryspecialty.recertificationdate"
                  label="Last Recertification Date (If Applicable)"
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <CustomDateInput
                  control={control}
                  name="primaryspecialty.certificationexpiration"
                  label="Expiration Date (If Applicable)"
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label="Certifying Board"
                  {...register('primaryspecialty.certifyingboard')}
                />
              </Grid.Col>
            </Grid>
          )}
        </Paper>

        {fields.map((item, index) => (
          <Paper key={item.id} shadow="xs" p="md" mt="10" withBorder>
            <Group>
              <h3>Secondary Specialty {index + 1}</h3>
              <Button
                id={`toggle-collapse-secondary-specialty-${index}`}
                onClick={() => toggleCollapse(index)}
                size="xs"
              >
                {collapsedIndices[index] ? 'Expand' : 'Collapse'}
              </Button>
              <Button
                id={`remove-secondary-specialty-${index}`}
                color="red"
                onClick={() => remove(index)}
                size="xs"
              >
                Remove
              </Button>
            </Group>
            <Collapse in={!collapsedIndices[index]}>
              <Grid>
                <Grid.Col span={8}>
                  <Controller
                    name={`secondaryspecialties.${index}.specialty`}
                    control={control}
                    render={({ field: { onChange, ...field } }) => {
                      return (
                        <Select
                          id={`secondaryspecialties.${index}.specialty`}
                          {...field}
                          label="Specialty"
                          searchable
                          clearable
                          data={[
                            ...Object.keys(specialties),
                            ...Object.keys(nonPhysicianSpecialties),
                            ...Object.entries(specialties).flatMap(
                              ([specialty, subs]) =>
                                subs.map((sub) => `${specialty} - ${sub}`),
                            ),
                            ...Object.entries(nonPhysicianSpecialties).flatMap(
                              ([specialty, subs]) =>
                                subs.map((sub) => `${specialty} - ${sub}`),
                            ),
                          ].filter(
                            (option, index, self) =>
                              self.indexOf(option) === index,
                          )}
                          onChange={(value) => {
                            setValue(
                              `secondaryspecialties.${index}.specialty`,
                              value,
                            );
                            if (!value) {
                              setValue(
                                `secondaryspecialties.${index}.subspecialty`,
                                [],
                              );
                            } else if (value.includes(' - ')) {
                              const subspecialty = value.split(' - ')[1];
                              setValue(
                                `secondaryspecialties.${index}.subspecialty`,
                                [subspecialty],
                              );
                            } else {
                              setValue(
                                `secondaryspecialties.${index}.subspecialty`,
                                [],
                              );
                            }
                          }}
                        />
                      );
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Controller
                    name={`secondaryspecialties.${index}.boardcertified`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        id={`secondaryspecialties.${index}.boardcertified`}
                        {...field}
                        label="Board Certified?"
                        data={[
                          { value: 'Y', label: 'Yes' },
                          { value: 'N', label: 'No' },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
              </Grid>
              {watch(`secondaryspecialties.${index}.boardcertified`) ===
                'Y' && (
                <Grid>
                  <Grid.Col span={6}>
                    <TextInput
                      id={`secondaryspecialties.${index}.certificationnumber`}
                      label="Certification Number"
                      {...register(
                        `secondaryspecialties.${index}.certificationnumber`,
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <CustomDateInput
                      control={control}
                      name={`secondaryspecialties.${index}.initialcertificationdate`}
                      label="Initial Certification Date"
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <CustomDateInput
                      control={control}
                      name={`secondaryspecialties.${index}.recertificationdate`}
                      label="Last Recertification Date (If Applicable)"
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <CustomDateInput
                      control={control}
                      name={`secondaryspecialties.${index}.certificationexpiration`}
                      label="Expiration Date (If Applicable)"
                    />
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <TextInput
                      id={`secondaryspecialties.${index}.certifyingboard`}
                      label="Certifying Board"
                      {...register(
                        `secondaryspecialties.${index}.certifyingboard`,
                      )}
                    />
                  </Grid.Col>
                </Grid>
              )}
            </Collapse>
          </Paper>
        ))}
        <Group mt="md" mb="lg">
          <Button
            id="add-secondary-specialty"
            onClick={() =>
              append({
                id: 0,
                memberId: memberId,
                type: 'secondary',
                nonphysician: 'No',
                specialty: null,
                subspecialty: [],
                boardcertified: 'No',
                certificationnumber: '',
                certificationexpiration: null,
                recertificationdate: null,
                initialcertificationdate: null,
                certifyingboard: '',
              })
            }
          >
            Add Secondary Specialty
          </Button>
        </Group>

        <Group mt="md">
          <Button id="save-specialty" type="submit" loading={isSubmitting}>
            Save
          </Button>
        </Group>
      </form>
    </Container>
  );
};

export default SpecialtyForm;
