import { Table } from '@mantine/core';

import { Group, Modal, TextInput } from '@mantine/core';
import useHttpClient from '../hooks/useHttpClient';
import { useState, useEffect } from 'react';

interface ViewOrganizationMembersProps {
  opened: boolean;
  onClose: () => void;
  organizationId: number;
}

const ViewOrganizationMembers: React.FC<ViewOrganizationMembersProps> = ({
  opened,
  onClose,
  organizationId,
}) => {
  const httpClient = useHttpClient();
  const [members, setMembers] = useState([]);
  const [searchMember, setSearchMember] = useState('');

  const planConversion = {
    1: 'Plan A',
    2: 'Plan B',
    3: 'Plan C',
    4: 'Plan D',
    5: 'Plan E',
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchMember(event.target.value);
  };

  const fetchOrgMembers = async () => {
    try {
      const response = await httpClient.get(
        `/api/organization/${organizationId}/members`,
      );
      setMembers(response.data);
    } catch (error) {
      console.error('Error fetching organization members:', error);
    }
  };

  useEffect(() => {
    fetchOrgMembers();
  }, [organizationId]);

  const filteredMembers = members.filter((member) =>
    `${member?.firstname} ${member?.lastname}`
      .toLowerCase()
      .includes(searchMember.toLowerCase()),
  );

  const planName = (planId: number) => {
    return planConversion[planId];
  };

  const formatDate = (timestamp: string) => {
    return new Date(timestamp).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Organization Members"
      centered
      size="auto"
      styles={{
        body: {
          height: '500px',
          maxWidth: '90vw',
          overflowX: 'auto',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Group mb={'md'}>
        <TextInput
          placeholder="Search members"
          value={searchMember}
          onChange={handleSearch}
        />
      </Group>
      <Table className="ryze-list table-striped table-highlight">
        <thead style={{ textAlign: 'left' }}>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Gender</th>
            <th>Birthdate</th>
            <th>Coverage Type</th>
            <th>Zip Code</th>
            <th>Plan</th>
            <th>Relation</th>
            <th>COBRA</th>
            <th>Retired</th>
          </tr>
        </thead>
        <tbody>
          {filteredMembers.length === 0 ? (
            <tr>
              <td colSpan={10} style={{ textAlign: 'center' }}>
                {searchMember
                  ? 'No matching members found'
                  : 'No members found'}
              </td>
            </tr>
          ) : (
            filteredMembers.map((member) => (
              <tr key={member?.id}>
                <td>{member?.firstname}</td>
                <td>{member?.lastname}</td>
                <td>{member?.sex}</td>
                <td>{formatDate(member?.birthdate)}</td>
                <td>{member?.type}</td>
                <td>{member?.zip}</td>
                <td>{planName(member?.planid)}</td>
                <td>{member?.subscriberrelationship}</td>
                <td>{member?.cobra}</td>
                <td>{member?.retired}</td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </Modal>
  );
};

export default ViewOrganizationMembers;
