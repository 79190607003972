import {
  Container,
  Group,
  Title,
  Table,
  Grid,
  Flex,
  TextInput,
  Pagination,
  Select,
  ActionIcon,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import useHttpClient from '../hooks/useHttpClient';
import DarkModeToggle from '../DarkModeToggle';
import { useDebouncedValue } from '@mantine/hooks';
import { IconDownload, IconEye } from '@tabler/icons-react';
import ViewOrganizationMembers from './ViewOrganizationMembers';
import { notifications } from '@mantine/notifications';
const EligibilityInformation: React.FC = () => {
  const httpClient = useHttpClient();
  const [organizations, setOrganizations] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearch] = useDebouncedValue(searchTerm, 300);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState('10');
  const [isOrganizationLoading, setIsOrganizationLoading] = useState(false);
  const [openOrganizationMembersModal, setOpenOrganizationMembersModal] =
    useState(false);
  const [organizationId, setOrganizationId] = useState(0);
  const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);

  const fetchAllOrganizations = async (
    page: number,
    limit: number,
    search: string,
  ) => {
    try {
      setIsOrganizationLoading(true);
      const response = await httpClient.get('/api/organization/all', {
        params: {
          page,
          limit,
          search,
        },
      });
      setOrganizations(response.data.organizations);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching organizations:', error);
    } finally {
      setIsOrganizationLoading(false);
    }
  };

  useEffect(() => {
    fetchAllOrganizations(currentPage, Number(pageSize), debouncedSearch);
  }, [currentPage, pageSize, debouncedSearch]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (value: string) => {
    setPageSize(value);
    setCurrentPage(1); // Reset to first page when changing page size
  };

  const handleOpenOrganizationMembersModal = (organizationId: number) => {
    setOrganizationId(Number(organizationId));
    setOpenOrganizationMembersModal(true);
  };

  const handleDownloadCsv = async (
    organizationId: number,
    organizationName: string,
  ) => {
    setIsDownloadingCsv(true);
    try {
      const response = await httpClient.get(
        `/api/organization/${organizationId}/download-eligibility-file`,
      );

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'text/csv' });

        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `eligibility-file-for-${organizationName}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);

        notifications.show({
          title: 'Success!',
          message: 'CSV Downloaded Successfully!',
          color: 'green',
          position: 'bottom-center',
        });
      }
    } catch (error) {
      console.error('Error downloading payment info', error);
      notifications.show({
        title: 'Error!',
        message: 'Error downloading payment info',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setIsDownloadingCsv(false);
    }
  };

  return (
    <Container size={'xl'}>
      <Group justify="space-between">
        <Title order={2} ta={'left'}>
          Eligibility Information
        </Title>
      </Group>
      <ViewOrganizationMembers
        opened={openOrganizationMembersModal}
        onClose={() => setOpenOrganizationMembersModal(false)}
        organizationId={organizationId}
      />
      <Grid justify="space-between">
        <Grid.Col span="auto">
          <DarkModeToggle />
        </Grid.Col>
      </Grid>
      <Group my={'lg'} justify={'space-between'}>
        <Flex justify={'flex-start'} gap="sm">
          <TextInput
            placeholder="Search organizations"
            value={searchTerm}
            onChange={handleSearch}
          />
        </Flex>
      </Group>
      <Group>
        <Table className="ryze-list table-striped table-highlight">
          <thead style={{ textAlign: 'left' }}>
            <tr>
              <th>Organization Name</th>
              <th>NPI</th>
              <th>Total Employees</th>
              <th>Admin First Name</th>
              <th>Admin Last Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {isOrganizationLoading ? (
              <tr>
                <td colSpan={6} style={{ textAlign: 'center' }}>
                  Loading...
                </td>
              </tr>
            ) : organizations.length === 0 ? (
              <tr>
                <td colSpan={6} style={{ textAlign: 'center' }}>
                  {searchTerm
                    ? 'No matching organizations found'
                    : 'No organizations found'}
                </td>
              </tr>
            ) : (
              organizations.map((organization) => (
                <tr key={organization?.id}>
                  <td>{organization?.name}</td>
                  <td>{organization?.npi}</td>
                  <td>{organization?.estimatedjoiningemployees}</td>
                  <td>{organization?.adminfirstname}</td>
                  <td>{organization?.adminlastname}</td>
                  <td>
                    <Group>
                      <ActionIcon
                        onClick={(event) => {
                          event.stopPropagation();
                          handleOpenOrganizationMembersModal(organization?.id);
                        }}
                        color="blue"
                      >
                        <IconEye size={16} />
                      </ActionIcon>
                      <ActionIcon
                        onClick={(event) => {
                          event.stopPropagation();
                          handleDownloadCsv(
                            organization?.id,
                            organization?.name,
                          );
                        }}
                        color="green"
                      >
                        <IconDownload size={16} />
                      </ActionIcon>
                    </Group>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Group>
      <Group justify="flex-end" align="center" mt="md">
        <Select
          label="Items per page"
          value={pageSize}
          onChange={handlePageSizeChange}
          data={[
            { value: '10', label: '10' },
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
          ]}
        />
        <Pagination
          mt={'lg'}
          total={totalPages}
          value={currentPage}
          onChange={handlePageChange}
        />
      </Group>
    </Container>
  );
};

export default EligibilityInformation;
