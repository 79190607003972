import { Button, Group, Text } from '@mantine/core';
import { useForm } from 'react-hook-form';
import CustomDateInput from '../CustomDateInput';

import { notifications } from '@mantine/notifications';

// This is a modified copy ClinicForm
function InsuranceStartDate({ currentStep, answers, setAnswers, back, next }) {
  const key = currentStep + '.individualInsuranceWhen';

  const {
    control,
    register,
    getValues,
    trigger,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    defaultValues: {
      expectedstartdate: answers[key],
    },
  });

  const handleCheckboxChange = (k) => (v) => {
    const value = v.target.checked ? true : false;
    setAnswers((values) => {
      values[key] = { ...values?.[key], [k]: value };
      return { ...values };
    });
  };

  return (
    <>
      <Text size={'lg'} mb={10}>
        When are you looking to start your new insurance with Ryze?
      </Text>
      <Group>
        <CustomDateInput
          control={control}
          name="expectedstartdate"
          preventPastDates
          label=""
          required
          error={errors.expectedstartdate?.message?.toString()}
          {...register('expectedstartdate')}
        />
      </Group>
      <Group justify="flex-end" mt="xl">
        <Button
          variant="default"
          onClick={async () => {
            await setAnswers((answers) => {
              delete answers[key];
              return { ...answers };
            });
            back();
          }}
        >
          Back
        </Button>
        <Button
          onClick={async () => {
            const expectedstartdate = getValues('expectedstartdate');
            setAnswers((values) => {
              return { ...values, [key]: expectedstartdate };
            });

            if (!(await trigger())) {
              notifications.show({
                title: 'Incorrect date format',
                message: 'Please double check the date you entered.',
                color: 'red',
                position: 'bottom-center',
              });
            } else {
              next();
            }
          }}
          //   disabled={!Object.keys(answers[key] || {}).length}
        >
          Next
        </Button>
      </Group>
    </>
  );
}

export default InsuranceStartDate;
