import { Button, Container, Title, Text } from '@mantine/core';
import { useAuth } from './AuthProvider';

function UnapprovedSplashPage() {
  const auth = useAuth();

  return (
    <Container style={{ textAlign: 'center' }} mt="xl">
      <Title order={1} style={{ fontSize: '3rem' }} mb="xl">
        404 - Unauthorized Access
      </Title>
      <Text size="lg" mb="xl">
        Your account is currently pending approval. You will be notified via
        email when this process is complete.
      </Text>
      <Button variant="outline" onClick={() => auth.goHome()}>
        Go back to Home
      </Button>
    </Container>
  );
}

export default UnapprovedSplashPage;
