import { Button, Group, Modal, Text } from '@mantine/core';

interface ConfirmAddEmployeesModalProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isAdding: boolean;
}

const ConfirmAddEmployeesModal = ({
  opened,
  onClose,
  onSubmit,
  isAdding,
}: ConfirmAddEmployeesModalProps) => {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={
        <Text fw={700} size="xl">
          Added Employees Confirmation
        </Text>
      }
      centered
      closeOnClickOutside={!isAdding}
      closeOnEscape={!isAdding}
      withCloseButton={!isAdding}
    >
      <Modal.Body my={'sm'}>
        <Text size="lg" ta={'center'}>
          Have you entered all your employees?
        </Text>
      </Modal.Body>

      <Group justify={'space-between'}>
        <Button
          disabled={isAdding}
          color="red"
          onClick={onClose}
          mt="md"
          mr="xs"
        >
          No
        </Button>
        <Button
          disabled={isAdding}
          loading={isAdding}
          onClick={onSubmit}
          mt="md"
        >
          Yes
        </Button>
      </Group>
    </Modal>
  );
};

export default ConfirmAddEmployeesModal;
