import React from 'react';
import { Control, Controller, UseFormWatch } from 'react-hook-form';
import { Checkbox, Grid, Stack, TextInput } from '@mantine/core';

interface ServicesFormProps {
  control: Control<any>;
  practiceIndex: number;
  watch: UseFormWatch<any>;
}

const PracticeLocationServicesForm: React.FC<ServicesFormProps> = ({
  control,
  practiceIndex,
  watch,
}) => {
  const showOtherServices = watch(
    `practices.${practiceIndex}.practicelocation_services.other`,
  );

  return (
    <Grid>
      <Grid.Col span={12}>
        <Stack>
          <Controller
            name={`practices.${practiceIndex}.practicelocation_services.ageAppropriateImmunizations`}
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <Checkbox
                id={`practice-location-age-appropriate-immunizations-${practiceIndex}`}
                label="Age Appropriate Immunizations"
                checked={field.value}
                onChange={(e) => field.onChange(e.currentTarget.checked)}
              />
            )}
          />
          <Controller
            name={`practices.${practiceIndex}.practicelocation_services.allergyInjections`}
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <Checkbox
                id={`practice-location-allergy-injections-${practiceIndex}`}
                label="Allergy Injections"
                checked={field.value}
                onChange={(e) => field.onChange(e.currentTarget.checked)}
              />
            )}
          />
          <Controller
            name={`practices.${practiceIndex}.practicelocation_services.other`}
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <Checkbox
                id={`practice-location-other-${practiceIndex}`}
                label="Other"
                checked={field.value}
                onChange={(e) => field.onChange(e.currentTarget.checked)}
              />
            )}
          />
        </Stack>
      </Grid.Col>

      {showOtherServices && (
        <Grid.Col span={6}>
          <Controller
            name={`practices.${practiceIndex}.practicelocation_services.otherServices`}
            control={control}
            render={({ field }) => (
              <TextInput
                id={`practice-location-other-services-${practiceIndex}`}
                label="Other Services"
                {...field}
              />
            )}
          />
        </Grid.Col>
      )}

      <Grid.Col span={12}>
        <Stack>
          <Controller
            name={`practices.${practiceIndex}.practicelocation_services.specialSkillsPractitioner`}
            control={control}
            render={({ field }) => (
              <TextInput
                id={`practice-location-special-skills-practitioner-${practiceIndex}`}
                label="Special Skills By The Practitioner"
                {...field}
              />
            )}
          />
          <Controller
            name={`practices.${practiceIndex}.practicelocation_services.specialSkillsStaff`}
            control={control}
            render={({ field }) => (
              <TextInput
                id={`practice-location-special-skills-staff-${practiceIndex}`}
                label="Special Skills By The Staff"
                {...field}
              />
            )}
          />
        </Stack>
      </Grid.Col>
    </Grid>
  );
};

export default PracticeLocationServicesForm;
