import { useContext, createContext, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import useHttpClient from './hooks/useHttpClient';
import { jwtDecode } from 'jwt-decode';
import { notifications } from '@mantine/notifications';

interface Privilege {
  type: string;
  serviceid: number;
  username: string;
  approved: boolean;
}

interface AuthContextType {
  loginAuth: (
    data: { username: string; password: string },
    redirect: 'insurance' | 'wecare',
  ) => Promise<void | Error>;
  logoutAuth: () => void;
  getToken: () => string | null;
  getPrivileges: () => Privilege[];
  goHome: () => void;
}

interface DecodedToken {
  id: string;
  privileges: Privilege[];
  exp: number;
  iat: number;
  forcePasswordUpdate?: boolean;
}

const AuthContext = createContext<AuthContextType>({
  loginAuth: async () => {},
  logoutAuth: () => {},
  getToken: () => null,
  getPrivileges: () => [],
  goHome: () => {},
});

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const navigate_ = useNavigate();
  const navigate = (path) => {
    localStorage.setItem('home', path);
    navigate_(path);
  };
  const httpClient = useHttpClient();

  let lockWait = false;

  const loginAuth = async (
    data: { username: string; password: string },
    redirect: 'insurance' | 'wecare',
  ) => {
    try {
      const res = await httpClient.post('/api/auth/login', data);
      const resData = res.data;
      if (resData.token) {
        localStorage.setItem('token', resData.token);
        const decodedToken: DecodedToken = jwtDecode(resData.token);

        if (decodedToken.forcePasswordUpdate) {
          navigate_(`/update-password`);
          return;
        }

        // Check if user has any privileges with approved=false or null
        const hasUnapprovedPrivileges = decodedToken.privileges.some(
          (p) => p.approved === false || p.approved === null,
        );

        if (hasUnapprovedPrivileges) {
          navigate('/unauthorized');
          return;
        }

        // Only look for approved privileges
        const approvedPrivileges = decodedToken.privileges.filter(
          (p) => p.approved,
        );

        const insurancePrivilege = approvedPrivileges.find((priv) =>
          [
            'ryzeadmin',
            'insurance_physician',
            'insurance_organization',
            'insurance_employee',
          ].includes(priv.type),
        );
        const wecarePrivilege = approvedPrivileges.find((priv) =>
          ['wecaremember', 'wecareadmin'].includes(priv.type),
        );
        // Handle insurance redirects
        if (redirect === 'insurance') {
          console.log(redirect, decodedToken, insurancePrivilege);
          if (insurancePrivilege) {
            switch (insurancePrivilege.type) {
              case 'ryzeadmin':
                navigate('/insurance/admin/approve');
                break;
              case 'insurance_physician':
                navigate('/insurance/member/dashboard');
                break;
              case 'insurance_organization':
                navigate('/insurance/clinic/dashboard');
                break;
              case 'insurance_employee':
                navigate('/insurance/employee');
                break;
              default:
                navigate('/no-access'); // Fallback if no match
            }
          } else {
            return new Error('Permission Denied'); // Fallback if no valid privileges are found
          }
        }
        // Handle wecare redirects
        else if (redirect === 'wecare') {
          if (wecarePrivilege) {
            if (wecarePrivilege.type === 'wecareadmin') {
              navigate('/wecare/admin');
            } else if (wecarePrivilege.type === 'wecaremember') {
              navigate('/wecare/member/dashboard');
            } else {
              navigate('/no-access');
            }
          } else {
            return new Error('Permission Denied'); // Fallback if no valid privileges are found
          }
        }
      }
    } catch (e) {
      //   console.log(e);
      notifications.show({
        title: 'Incorrect email or password',
        message:
          'Double check you entered your email and password correctly. If you forgot your password, you can reset it using the button below.',
        color: 'red',
        position: 'bottom-center',
      });
      return new Error('Incorrect email or password');
    }
  };

  const logoutAuth = () => {
    localStorage.removeItem('token');
    if (localStorage.getItem('home').match(/^\/wecare/)) {
      navigate('/wecare/login');
    } else {
      navigate('/login');
    }
  };

  const getToken = (): string | null => {
    return localStorage.getItem('token');
  };

  const getPrivileges = (): Privilege[] => {
    const token = getToken();
    if (token) {
      const decodedToken: DecodedToken = jwtDecode(token);
      // const currentTimestamp = Math.floor(Date.now() / 1000);
      // const elapsed = currentTimestamp - decodedToken.iat;

      // if (
      //   !lockWait &&
      //   elapsed > Number(process.env.REACT_APP_PUBLIC_TOKEN_EXPIRY || 900)
      // ) {
      //   lockWait = true;
      //   httpClient
      //     .post('/api/auth/refresh-token', {
      //       refresh_token: token,
      //     })
      //     .then((res) => {
      //       if (res.data?.new_token) {
      //         // console.log('decode token refreshed');
      //         localStorage.setItem('token', res.data?.new_token);
      //         lockWait = false;
      //       }
      //     });
      // }

      // Check if user has any privileges with approved=false or null
      const hasUnapprovedPrivileges = decodedToken.privileges.some(
        (p) => p.approved === false || p.approved === null,
      );

      if (hasUnapprovedPrivileges) {
        navigate('/unauthorized');
      }

      return decodedToken.privileges.filter((p) => p.approved);
    } else {
      logoutAuth();
    }
  };

  const goHome = () => {
    const homePath = localStorage.getItem('home') || '/';

    // If we're on the unauthorized page or home path is /unauthorized,
    // redirect to a default login page based on the token
    if (!homePath || homePath === '/unauthorized') {
      const token = getToken();
      if (token) {
        try {
          const decodedToken: DecodedToken = jwtDecode(token);
          const wecarePrivilege = decodedToken.privileges.find((priv) =>
            ['wecaremember', 'wecareadmin'].includes(priv.type),
          );

          if (wecarePrivilege) {
            navigate('/wecare/login');
          } else {
            navigate('/login');
          }
        } catch (e) {
          // If token decoding fails, default to regular login
          navigate('/login');
        }
      } else {
        // No token, default to regular login
        navigate('/login');
      }
    } else {
      navigate(homePath);
    }
  };

  return (
    <AuthContext.Provider
      value={{ loginAuth, logoutAuth, getToken, getPrivileges, goHome }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
