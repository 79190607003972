export const planSelectionOnboardingSteps = [
  {
    element: '#graph-slider',
    intro:
      'Toggle between the different graph types to visualize your plan information in various formats',
  },
  {
    element: '#graph-explanation',
    intro:
      'This paragraph explains the purpose of each graph, and how to use them to make an informed decision',
  },
  {
    element: '#benefit-header',
    intro:
      'Hover over certain benefits marked with an asterisk to see additional information',
  },
  {
    element: '#search-bar',
    intro:
      "Use this search bar to find specific benefits you're interested in, such as Dialysis or Chemo, to see if they are covered and require prior authorization",
  },
  {
    element: '#option-buttons',
    intro:
      'Use these filter buttons to choose which plans and details you want to view side by side, including in network and out of network benefits for each plan',
  },
  {
    element: '#help-button',
    intro: 'Click here at anytime to view this tutorial again',
  },
];
