import React from 'react';
import { Text, Radio, Button, Group, Stack } from '@mantine/core';
import { useForm, Controller } from 'react-hook-form';

interface OwnershipStatusScreenProps {
  answers: any;
  setAnswers: (answers: any) => void;
  next: (pathIndex?: number) => void;
  back: () => void;
}

const OwnershipStatusScreen: React.FC<OwnershipStatusScreenProps> = ({
  answers,
  setAnswers,
  next,
  back,
}) => {
  const { control, handleSubmit, watch } = useForm({
    defaultValues: { owner_status: answers.owner_status || '' },
  });

  const ownerStatus = watch('owner_status');

  const onSubmit = (data: { owner_status: string }) => {
    setAnswers({ ...answers, owner_status: data.owner_status });

    if (data.owner_status === 'working_owner_medical_practice') {
      next(0);
    } else if (
      data.owner_status === 'working_non_owner_partner_employee_1099'
    ) {
      next(1);
    } else if (data.owner_status === 'working_1099_own_llc') {
      next(2);
    } else {
      next(3);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text size="xl" mb="md">
        Which of these best describes you?
      </Text>

      <Controller
        name="owner_status"
        control={control}
        rules={{ required: 'Please select an option' }}
        render={({ field, fieldState: { error } }) => (
          <Radio.Group
            {...field}
            value={field.value || ''}
            error={error?.message}
          >
            <Stack gap="sm" mt={10}>
              <Radio
                value="working_owner_medical_practice"
                label="I am an owner/co-owner of a medical practice"
              />
              <Radio
                value="working_non_owner_partner_employee_1099"
                label="I am a non-owner partner, employee, or 1099 contractor of a medical practice"
              />
              <Radio
                value="working_1099_own_llc"
                label="I am 1099 with my own business entity"
              />
              <Radio
                value="working_non_clinical_work"
                label="I work in a non-clinical setting"
              />
            </Stack>
          </Radio.Group>
        )}
      />

      <Group justify="flex-end" mt="xl">
        <Button variant="default" onClick={back}>
          Back
        </Button>
        <Button type="submit" disabled={!ownerStatus}>
          Next
        </Button>
      </Group>
    </form>
  );
};

export default OwnershipStatusScreen;
