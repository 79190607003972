import React, { useEffect, useState } from 'react';
import {
  Container,
  Paper,
  Title,
  Text,
  Button,
  Grid,
  Group,
  TextInput,
  Collapse,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import useHttpClient from '../hooks/useHttpClient';
import { useNotifications } from '../NotificationContext';

interface Member {
  id: string;
  firstname: string;
  lastname: string;
  licensenumber: string;
  licensestate: string;
  npi: string;
  npimatch: string;
  affiliations: string[];
  message?: string;
  emailverification?: boolean;
  email: string;
  created_at: string; // Timestamp from DB
  answers?: Record<string, string>;
}

const InsuranceMemberApprovalPage: React.FC = () => {
  const [members, setMembers] = useState<Member[]>([]);
  const httpClient = useHttpClient();
  const [searchTerm, setSearchTerm] = useState('');
  const [loadingMemberId, setLoadingMemberId] = useState<string | null>(null);
  const [loadingAction, setLoadingAction] = useState<'approve' | 'deny' | null>(
    null,
  );
  const [expandedMemberIds, setExpandedMemberIds] = useState<Set<string>>(
    new Set(),
  );
  const [showAll, setShowAll] = useState(false);
  const { updatePendingMemberApprovals } = useNotifications();

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await httpClient.get('/api/subscriber/approve');

        if (response.status === 200) {
          const data = response.data;

          // Sort by created_at descending (most recent first)
          const sortedData = data.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime(),
          );

          setMembers(sortedData);
          // Update the notification count
          updatePendingMemberApprovals(sortedData.length);
        } else {
          notifications.show({
            title: 'Error',
            message: 'Failed to fetch members for approval.',
            color: 'red',
            position: 'bottom-center',
          });
        }
      } catch (error) {
        console.error('Error fetching members:', error);
        notifications.show({
          title: 'Error',
          message: 'An error occurred while fetching members.',
          color: 'red',
          position: 'bottom-center',
        });
      }
    };

    fetchMembers();
  }, [httpClient, updatePendingMemberApprovals]);

  // Function to format timestamp to MM/DD/YYYY
  const formatDate = (timestamp: string) => {
    return new Date(timestamp).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  };

  const formatAnswerKey = (key: string) => {
    const parts = key.split('.');
    const label = parts[parts.length - 1]
      .replace(/([A-Z])/g, ' $1') // camelCase -> space
      .replace(/^./, (str) => str.toUpperCase()); // capitalize first letter
    return label;
  };

  // Function to handle approval or denial
  const handleAction = async (memberId: string, approved: boolean) => {
    try {
      setLoadingMemberId(memberId);
      setLoadingAction(approved ? 'approve' : 'deny');

      const response = await httpClient.put('/api/user/approve/member', {
        id: memberId,
        approved,
        type: 'insurance_physician',
      });

      if (response.status === 200) {
        notifications.show({
          title: approved ? 'Member Approved' : 'Member Denied',
          message: approved
            ? 'Member has been approved successfully.'
            : 'Member has been denied.',
          color: approved ? 'green' : 'red',
          position: 'bottom-center',
        });

        // Remove the member from the list after action
        setMembers((prevMembers) => {
          const updatedMembers = prevMembers.filter(
            (member) => member.id !== memberId,
          );
          // Update the notification count
          updatePendingMemberApprovals(updatedMembers.length);
          return updatedMembers;
        });
      } else {
        throw new Error('Action failed');
      }
    } catch (error) {
      console.error('Error performing action:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to update member status.',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setLoadingMemberId(null);
      setLoadingAction(null);
    }
  };

  const filteredMembers = members.filter((member) =>
    `${member.firstname} ${member.lastname} ${member.npi} ${member.email}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase()),
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const toggleMemberExpansion = (memberId: string) => {
    setExpandedMemberIds((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(memberId)) {
        newSet.delete(memberId);
      } else {
        newSet.add(memberId);
      }
      return newSet;
    });
  };

  const toggleAll = () => {
    if (showAll) {
      setExpandedMemberIds(new Set());
    } else {
      setExpandedMemberIds(new Set(members.map((m) => m.id)));
    }
    setShowAll(!showAll);
  };

  return (
    <Container>
      <Group justify="space-between" mb={10}>
        <Title order={2} ta="center">
          Members Pending Approval
        </Title>
        <TextInput
          placeholder="Search members"
          value={searchTerm}
          onChange={handleSearch}
        />
      </Group>

      <Group justify="flex-end" mb={20}>
        <Button variant="light" onClick={toggleAll}>
          {showAll ? 'Hide Onboarding Answers' : 'Show Onboarding Answers'}
        </Button>
      </Group>

      {filteredMembers.length === 0 ? (
        <Title order={4} ta="center" mt={50} fw={500}>
          No members requiring approval.
        </Title>
      ) : (
        filteredMembers.map((member, index) => (
          <Paper key={index} withBorder shadow="sm" p="md" mb="md">
            <Grid>
              <Grid.Col span={6}>
                <Text>
                  <strong>Name:</strong> {member.firstname} {member.lastname}
                </Text>
                <Text>
                  <strong>Medical License:</strong> {member.licensenumber} (
                  {member.licensestate})
                </Text>
                <Text>
                  <strong>Registration Date:</strong>{' '}
                  {formatDate(member.created_at)}
                </Text>
                {member.affiliations?.length > 0 && (
                  <Text>
                    <strong>Affiliations:</strong>{' '}
                    {member.affiliations.join(', ')}
                  </Text>
                )}
              </Grid.Col>
              <Grid.Col span={6}>
                <Text>
                  <strong>NPI:</strong> {member.npi}
                </Text>
                <Text>
                  <strong>NPI Match:</strong>{' '}
                  <span
                    style={{
                      color: member.npimatch === 'match' ? 'green' : 'red',
                    }}
                  >
                    {member.npimatch}
                  </span>
                </Text>
                <Text>
                  <strong>Email:</strong> {member.email}{' '}
                  <span
                    style={{
                      color: member.emailverification ? 'green' : 'red',
                    }}
                  >
                    ({member.emailverification ? 'Verified' : 'Not Verified'})
                  </span>
                </Text>
              </Grid.Col>
            </Grid>
            {member.answers && (
              <>
                <Button
                  size="xs"
                  variant="subtle"
                  onClick={() => toggleMemberExpansion(member.id)}
                  mt="sm"
                >
                  {expandedMemberIds.has(member.id)
                    ? 'Hide Onboarding Answers'
                    : 'Show Onboarding Answers'}
                </Button>

                <Collapse in={expandedMemberIds.has(member.id)}>
                  <Paper withBorder p="sm" mt="sm" radius="md">
                    <Title order={5} mb="xs">
                      Onboarding Answers
                    </Title>
                    <Grid>
                      {Object.entries(member.answers)
                        .filter(
                          ([, value]) =>
                            typeof value !== 'object' || value === null,
                        )
                        .map(([key, value], idx) => (
                          <Grid.Col span={6} key={idx}>
                            <Text>
                              <strong>{formatAnswerKey(key)}:</strong>{' '}
                              {String(value)}
                            </Text>
                          </Grid.Col>
                        ))}
                    </Grid>
                  </Paper>
                </Collapse>
              </>
            )}
            {member.message && (
              <Text mt="md">
                <strong>Message:</strong> {member.message}
              </Text>
            )}
            <Grid justify="space-between" mt="md">
              <Grid.Col span={6}>
                <Button
                  disabled={
                    !member.emailverification || loadingMemberId === member.id
                  }
                  color="green"
                  fullWidth
                  onClick={() => handleAction(member.id, true)}
                  loading={
                    loadingMemberId === member.id && loadingAction === 'approve'
                  }
                  style={{ marginRight: 10 }}
                >
                  Approve
                </Button>
              </Grid.Col>
              <Grid.Col span={6}>
                <Button
                  color="red"
                  variant="outline"
                  fullWidth
                  onClick={() => handleAction(member.id, false)}
                  disabled={loadingMemberId === member.id}
                  loading={
                    loadingMemberId === member.id && loadingAction === 'deny'
                  }
                >
                  Deny
                </Button>
              </Grid.Col>
            </Grid>
          </Paper>
        ))
      )}
    </Container>
  );
};

export default InsuranceMemberApprovalPage;
