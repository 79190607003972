import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Paper,
  Group,
  Grid,
  Textarea,
  Collapse,
  Select,
  MultiSelect,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import useHttpClient from './hooks/useHttpClient';
import { specialties } from '../types/specialties.types';
import CustomDateInput from './CustomDateInput';
import { useFormDirtyAlert } from '../helpers/useFormDirtyAlert';

type TrainingFields = {
  id?: number;
  trainingtype: string;
  institutionhospitalname: string;
  specialty: string;
  subspecialty: string[];
  startdate: string | null;
  enddate: string | null;
  description: string;
};

type FormValues = {
  degree: string;
  medicalschool: string;
  educationstartdate: string | null;
  educationenddate: string | null;
  trainings: TrainingFields[];
  memberid: number;
};

interface FormProps {
  memberId: number;
}

const EducationTrainingForm: React.FC<FormProps> = ({ memberId }) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { isDirty, dirtyFields },
  } = useForm<FormValues>();

  useFormDirtyAlert(reset, isDirty, dirtyFields);

  const httpClient = useHttpClient();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'trainings',
  });

  const [collapsedIndices, setCollapsedIndices] = useState<boolean[]>([]);
  const [initialData, setInitialData] = useState<FormValues | null>(null);

  const toggleCollapse = (index: number) => {
    setCollapsedIndices((prev) => {
      const newCollapsedIndices = [...prev];
      newCollapsedIndices[index] = !newCollapsedIndices[index];
      return newCollapsedIndices;
    });
  };

  const fetchEducationAndTraining = async () => {
    console.log('Fetching education and training for memberId:', memberId);
    try {
      const response = await httpClient.get(
        `/api/educationtraining/${memberId}`,
      );
      console.log('Education and training response status:', response.status);
      if (!response.status.toString().startsWith('2')) {
        throw new Error('Failed to fetch education and training');
      }
      const data = await response.data;
      console.log('Fetched education and training:', data);

      const sortedTrainings = data.training.sort(
        (a: TrainingFields, b: TrainingFields) => {
          if (a.enddate && b.enddate) {
            const endDateComparison =
              new Date(a.enddate).getTime() - new Date(b.enddate).getTime();
            if (endDateComparison !== 0) return endDateComparison;
          }
          if (a.startdate && b.startdate) {
            const startDateComparison =
              new Date(a.startdate).getTime() - new Date(b.startdate).getTime();
            if (startDateComparison !== 0) return startDateComparison;
          }
          return a.id - b.id;
        },
      );

      const educationData = {
        degree: data.education.degree || '',
        medicalschool: data.education.medicalschool || '',
        educationstartdate: data.education.educationstartdate || null,
        educationenddate: data.education.educationenddate || null,
        trainings: sortedTrainings.length ? sortedTrainings : [],
        memberid: data.education.memberid,
      };

      reset(educationData);
      setInitialData(educationData);
      setCollapsedIndices(
        sortedTrainings.length ? sortedTrainings.map(() => true) : [true],
      );
    } catch (error) {
      console.error('Error fetching education and training:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch education and training',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  useEffect(() => {
    if (memberId) {
      fetchEducationAndTraining();
    }
  }, [memberId]);

  const onSubmit = async (data: FormValues) => {
    console.log('Submit form values:', data);

    const formatData = (items: TrainingFields[]) => {
      return items.map((item) => ({
        ...item,
        memberid: memberId,
        startdate: item.startdate
          ? new Date(item.startdate).toISOString().slice(0, 10)
          : null,
        enddate: item.enddate
          ? new Date(item.enddate).toISOString().slice(0, 10)
          : null,
      }));
    };

    const formattedTrainings = formatData(data.trainings);
    const educationData = {
      ...data,
      memberid: memberId,
      educationstartdate: data.educationstartdate
        ? new Date(data.educationstartdate).toISOString().slice(0, 10)
        : null,
      educationenddate: data.educationenddate
        ? new Date(data.educationenddate).toISOString().slice(0, 10)
        : null,
    };

    const createEducation = async (education: any) => {
      console.log('Creating education:', education);
      const response = await httpClient.post(
        '/api/educationtraining/education',
        education,
      );
      console.log('Create education response:', response);
      return response.data;
    };

    const updateEducation = async (education: any) => {
      console.log('Updating education:', education);
      const response = await httpClient.put(
        `/api/educationtraining/education/${memberId}`,
        education,
      );
      console.log('Update education response:', response);
      return response.data;
    };

    const createTraining = async (training: any) => {
      console.log('Creating training:', training);
      const response = await httpClient.post(
        '/api/educationtraining/training',
        training,
      );
      console.log('Create training response:', response);
      return response.data;
    };

    const updateTraining = async (training: any) => {
      console.log('Updating training:', training);
      const response = await httpClient.put(
        `/api/educationtraining/training/${training.id}`,
        training,
      );
      console.log('Update training response:', response);
      return response.data;
    };

    const deleteTraining = async (id: number) => {
      console.log('Deleting training ID:', id);
      const response = await httpClient.delete(
        `/api/educationtraining/training/${id}`,
      );
      console.log('Delete training response:', response);
    };

    // Determine deletions, creations, and updates
    const initialIds = new Set<number>(
      initialData?.trainings.map((t: any) => t.id) || [],
    );
    const currentIds = new Set<number>(
      formattedTrainings.map((t: any) => t.id),
    );
    const deletedIds = [...initialIds].filter(
      (id): id is number => !currentIds.has(id),
    );

    const createTasks = formattedTrainings
      .filter((t: any) => !t.id || t.id === 0)
      .map((t: any) => createTraining(t));
    const updateTasks = formattedTrainings
      .filter((t: any) => t.id && t.id > 0 && initialIds.has(t.id))
      .map((t: any) => updateTraining(t));

    const deleteTasks = deletedIds.map((id) => deleteTraining(id));

    const educationTask = initialData?.memberid
      ? updateEducation(educationData)
      : createEducation(educationData);

    try {
      const results = await Promise.all([
        ...createTasks,
        ...updateTasks,
        ...deleteTasks,
        educationTask,
      ]);
      console.log('Results:', results);
      fetchEducationAndTraining();

      notifications.show({
        title: 'Success',
        message: 'Education and training updated successfully',
        color: 'green',
        position: 'bottom-center',
      });
    } catch (error) {
      console.error('Error submitting education and training form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit education and training',
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  const trainingSpecialties = fields.map(
    (_, index) =>
      watch(`trainings.${index}.specialty`) as keyof typeof specialties,
  );

  useEffect(() => {
    trainingSpecialties.forEach((specialty, index) => {
      if (
        initialData?.trainings[index] &&
        initialData.trainings[index].specialty !== specialty
      ) {
        setValue(`trainings.${index}.subspecialty`, []);
      }
    });
  }, [trainingSpecialties, setValue, initialData]);

  return (
    <Container>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Paper withBorder shadow="xs" p="md" mt="md">
          <Group>
            <h3>Education</h3>
          </Group>
          <Grid>
            <Grid.Col span={6}>
              <Controller
                name="degree"
                control={control}
                render={({ field }) => (
                  <Select
                    id="degree"
                    {...field}
                    label="Degree"
                    data={[
                      { value: 'MD', label: 'MD' },
                      { value: 'DO', label: 'DO' },
                      { value: 'NP', label: 'NP' },
                      { value: 'PA', label: 'PA' },
                    ]}
                    clearable
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                id="medicalschool"
                label="Name of Medical School"
                {...register('medicalschool')}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <CustomDateInput
                control={control}
                name="educationstartdate"
                label="Education Start Date"
              />
            </Grid.Col>

            <Grid.Col span={6}>
              <CustomDateInput
                control={control}
                name="educationenddate"
                label="Education End Date"
              />
            </Grid.Col>
          </Grid>
        </Paper>

        {fields.map((item, index) => (
          <Paper key={item.id} withBorder shadow="xs" p="md" mt="md">
            <Group>
              <h3>Training {index + 1}</h3>
              <Button
                id={`toggle-collapse-training-${index}`}
                onClick={() => toggleCollapse(index)}
                size="xs"
              >
                {collapsedIndices[index] ? 'Collapse' : 'Expand'}
              </Button>
              <Button
                id={`remove-training-${index}`}
                color="red"
                onClick={() => remove(index)}
                size="xs"
              >
                Remove
              </Button>
            </Group>
            <Collapse in={collapsedIndices[index]}>
              <Grid>
                <Grid.Col span={6}>
                  <Controller
                    name={`trainings.${index}.trainingtype`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        id={`trainings.${index}.trainingtype`}
                        {...field}
                        label="Training Type"
                        data={[
                          { value: 'Internship', label: 'Internship' },
                          { value: 'Residency', label: 'Residency' },
                          { value: 'Fellowship', label: 'Fellowship' },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    id={`trainings.${index}.institutionhospitalname`}
                    label="Institution/Hospital Name"
                    {...register(`trainings.${index}.institutionhospitalname`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`trainings.${index}.specialty`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        id={`trainings.${index}.specialty`}
                        {...field}
                        label="Specialty"
                        data={Object.keys(specialties).map((specialty) => ({
                          value: specialty,
                          label: specialty,
                        }))}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                {trainingSpecialties[index] &&
                 specialties[trainingSpecialties[index]] &&
                 specialties[trainingSpecialties[index]].length > 0 ? (
                  <Grid.Col span={6}>
                    <Controller
                      name={`trainings.${index}.subspecialty`}
                      control={control}
                      render={({ field }) => (
                        <MultiSelect
                          id={`trainings.${index}.subspecialty`}
                          {...field}
                          label="Subspecialty"
                          data={specialties[trainingSpecialties[index]]}
                          clearable
                        />
                      )}
                    />
                  </Grid.Col>
                ) : (
                  // Empty column to maintain grid layout
                  <Grid.Col span={6}></Grid.Col>
                )}
                <Grid.Col span={6} key={`start-${index}`}>
                  <CustomDateInput
                    control={control}
                    name={`trainings.${index}.startdate`}
                    label={`Training Start Date ${index + 1}`}
                  />
                </Grid.Col>

                <Grid.Col span={6} key={`end-${index}`}>
                  <CustomDateInput
                    control={control}
                    name={`trainings.${index}.enddate`}
                    label={`Training End Date ${index + 1}`}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Textarea
                    id={`trainings.${index}.description`}
                    label="Description"
                    {...register(`trainings.${index}.description`)}
                  />
                </Grid.Col>
              </Grid>
            </Collapse>
          </Paper>
        ))}
        <Group mt="md" mb="lg">
          <Button
            id="add-professional-training"
            onClick={() =>
              append({
                trainingtype: '',
                institutionhospitalname: '',
                specialty: '',
                subspecialty: [],
                startdate: null,
                enddate: null,
                description: '',
              })
            }
          >
            Add Professional Training
          </Button>
        </Group>

        <Group mt="md">
          <Button id="save-education-training" type="submit">
            Save
          </Button>
        </Group>
      </form>
    </Container>
  );
};

export default EducationTrainingForm;
