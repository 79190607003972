import { Title, Text, Group, Button } from '@mantine/core';
import { useState } from 'react';
import { notifications } from '@mantine/notifications';
import useHttpClient from '../hooks/useHttpClient';
import { PhysicianForm } from '../InsurancePortal/PhysicianForm';
import { useLocation } from 'react-router-dom';
function IndividualRegistrationScreen({
  currentStep,
  answers,
  setAnswers,
  back,
  next,
}) {
  const key = currentStep + '.practice';
  const [practice, setPractice] = useState({});
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const httpClient = useHttpClient();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const leadid = searchParams.get('l')?.split('.')?.[1];

  const submitPracticeContact = async (data) => {
    console.log('practice contact data', data);
    try {
      setLoading(true);

      if (data.referralsource === 'Other') {
        data.referralsource = data.referralsourcecustom;
      }

      const { contact: _, ...answersWithoutContact } = answers;
      data.answers = answersWithoutContact;
      data.leadid = leadid;
      console.log(data);
      const response = await httpClient.post(
        '/api/subscriber/onboarding',
        data,
      );

      notifications.show({
        title: 'Success',
        message: 'Your account has been created successfully',
        color: 'green',
        position: 'bottom-center',
      });

      setLoading(false);
      next();
    } catch (error) {
      notifications.show({
        title: 'Error',
        message:
          error?.response?.data?.error ||
          'Your registration could not be completed at this time. Please try again later.',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setLoading(false);
    }
  };

  const [contact, setContact] = useState({});

  let trigger;
  let errors;
  const callback = async (data, trigger_, errors_) => {
    setContact(data);
    trigger = trigger_;
    errors = errors_;
  };

  return (
    <>
      {/* <Title order={2} mb="md" ta="center">
        Provide Your Contact Information
      </Title> */}
      <Title order={2} mb="md" ta="center">
        Help Us Verify Your Eligibility
      </Title>
      <Text ml="xl" mr="xl" size="lg">
        To create your account, we need a few details to confirm you're a
        physician—this helps us ensure a secure and professional community.
      </Text>

      {/* <PracticeContactForm hooks={hooks} /> */}
      <PhysicianForm onboardingFlow cb={callback} />

      <Group justify="flex-end" mt="xl">
        <Button
          variant="default"
          onClick={async () => {
            await setAnswers((answers) => {
              delete answers.practice;
              delete answers[key];
              return { ...answers };
            });
            back();
          }}
          disabled={loading}
        >
          Back
        </Button>
        <Button
          onClick={async () => {
            await setAnswers((answers) => {
              return { ...answers, [key]: true, contact };
            });
            console.log('errors', errors);
            if (!(await trigger())) {
              notifications.show({
                title: 'Error',
                message: 'Please check the form for errors.',
                color: 'red',
                position: 'bottom-center',
              });
              return;
            }

            await submitPracticeContact(contact);
          }}
          disabled={loading}
          loading={loading}
        >
          Complete
        </Button>
      </Group>
    </>
  );
}

export default IndividualRegistrationScreen;
