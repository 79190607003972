import { Button, Group, Modal, TextInput } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import useHttpClient from '../hooks/useHttpClient';

interface PaymentIDInputModalProps {
  opened: boolean;
  onClose: () => void;
  selectedAccounts: any[];
}

const PaymentIDInputModal = ({
  opened,
  onClose,
  selectedAccounts,
}: PaymentIDInputModalProps) => {
  const httpClient = useHttpClient();
  const [bulkPaymentId, setBulkPaymentId] = useState('');
  const [isBulkUpdatingPaymentID, setIsBulkUpdatingPaymentID] = useState(false);

  const handleBulkUpdate = async () => {
    setIsBulkUpdatingPaymentID(true);
    try {
      const response = await httpClient.put(
        '/api/payment/bulk-update-payment-id',
        {
          ids: selectedAccounts,
          paymentId: bulkPaymentId,
        },
      );

      if (response.status === 200) {
        notifications.show({
          title: 'Success',
          message: 'Bulk update successful',
          color: 'green',
        });
      }
    } catch (error) {
      console.error('Error in bulk update:', error);
      notifications.show({
        title: 'Error',
        message: 'Bulk update failed',
        color: 'red',
      });
    } finally {
      setIsBulkUpdatingPaymentID(false);
      onClose();
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      closeOnClickOutside={!isBulkUpdatingPaymentID}
      closeOnEscape={!isBulkUpdatingPaymentID}
      withCloseButton={!isBulkUpdatingPaymentID}
      size={'sm'}
      title="Bulk Update Payment ID"
    >
      <TextInput
        label="Payment ID"
        value={bulkPaymentId}
        onChange={(e) => setBulkPaymentId(e.target.value)}
        placeholder="Enter new Payment ID"
        type={'number'}
      />
      <Group justify={'space-between'}>
        <Button mt="md" onClick={onClose} color="red">
          Cancel
        </Button>
        <Button
          mt="md"
          onClick={handleBulkUpdate}
          loading={isBulkUpdatingPaymentID}
          disabled={isBulkUpdatingPaymentID}
          color="blue"
        >
          Save
        </Button>
      </Group>
    </Modal>
  );
};

export default PaymentIDInputModal;
