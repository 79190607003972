import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Text,
  Group,
  Button,
  Modal,
  Flex,
  Loader,
} from '@mantine/core';
import { RyzeAvatar } from '../AvatarUploader';
import useHttpClient from '../hooks/useHttpClient';
import { notifications } from '@mantine/notifications';
import { IconLogout, IconTrash } from '@tabler/icons-react';
import DeleteConfirmationModal from './delete-confirmation-modal';
import { useAuth } from '../AuthProvider';

interface OptionsModalProps {
  opened: boolean;
  onClose: () => void;
  selectedConversation: number;
  updateMessages: (data: any, readOldMessage?: boolean) => Promise<void>;
  groupName: string;
}

export const OptionsModal = ({
  opened,
  onClose,
  selectedConversation,
  updateMessages,
  groupName,
}: OptionsModalProps) => {
  const [availableConnections, setAvailableConnections] = useState([]);
  const [selectedConnections, setSelectedConnections] = useState([]);
  const [existingMembersInGroupChat, setExistingMembersInGroupChat] = useState(
    [],
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetchingConnections, setIsFetchingConnections] = useState(false);
  const [openDeleteMemberModal, setOpenDeleteMemberModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [memberUsernameToDelete, setMemberUsernameToDelete] = useState(null);

  const httpClient = useHttpClient();
  const auth = useAuth();
  const privileges = auth.getPrivileges();
  const username = privileges[0].username;

  const fetchUserConnections = async () => {
    setIsFetchingConnections(true);
    try {
      const response = await httpClient.get(
        `/api/messages/conversations/${selectedConversation}/available-connections`,
      );

      setAvailableConnections(response.data.availableConnections);
      setExistingMembersInGroupChat(response.data.connectedMembers);
    } catch (error) {
      console.error('Error fetching available connections:', error);
    } finally {
      setIsFetchingConnections(false);
    }
  };

  const handleClose = () => {
    setSelectedConnections([]);
    onClose();
  };

  useEffect(() => {
    if (opened) {
      fetchUserConnections();
    }
  }, [opened, selectedConversation]);

  const handleCheckboxToggle = (id: string) => {
    setSelectedConnections((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return Array.from(newSet);
    });
  };

  const handleAddMember = async () => {
    setIsSubmitting(true);
    try {
      await httpClient.post(
        `/api/messages/conversations/${selectedConversation}/add-member`,
        { memberIds: selectedConnections },
      );

      await updateMessages({ conversationId: selectedConversation });

      notifications.show({
        title: 'Member Added',
        message: 'Members have been successfully added to the group.',
        color: 'green',
      });
    } catch (e) {
      console.error('Error adding member:', e);
      notifications.show({
        title: 'Error',
        message: 'Failed to add members to the group.',
        color: 'red',
      });
    } finally {
      setIsSubmitting(false);
      setSelectedConnections([]);
      onClose();
    }
  };

  const handleDeleteMember = async (memberUsername: string) => {
    setIsDeleting(true);
    try {
      const response = await httpClient.patch(
        `/api/messages/conversations/${selectedConversation}/remove-member`,
        { username: memberUsername },
      );

      if (response.status === 200) {
        notifications.show({
          title: 'Member Removed',
          message: 'The member has been successfully removed from the group.',
          color: 'green',
        });
        setOpenDeleteMemberModal(false);
        fetchUserConnections();
      }
    } catch (e) {
      console.error('Error deleting member:', e);
      notifications.show({
        title: 'Error',
        message: 'Failed to remove the member from the group.',
        color: 'red',
      });
    } finally {
      setIsDeleting(false);
      setOpenDeleteMemberModal(false);
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={handleClose}
      title={<Text fw="bolder">{groupName || 'Chat Options'}</Text>}
      size="xl"
      centered
    >
      <Modal.Body>
        {isFetchingConnections && (
          <Flex justify="center" align="center">
            <Loader />
          </Flex>
        )}
        {!isFetchingConnections && (
          <Flex direction="row" justify="space-between">
            <Flex
              direction="column"
              style={{
                width: '48%',
                height: '400px',
                position: 'relative',
              }}
            >
              <Text fw="bold" mb="sm">
                Members in Group
              </Text>
              <Flex
                direction="column"
                style={{
                  overflowY: 'auto',
                  position: 'absolute',
                  top: '40px',
                  bottom: 0,
                  left: 0,
                  right: 0,
                }}
              >
                {existingMembersInGroupChat.map((member) => (
                  <Group key={member.id} mb="sm" justify="space-between">
                    <Group>
                      <RyzeAvatar
                        key={member.id}
                        memberId={member.id}
                        style={{ marginRight: '10px' }}
                      />
                      <Text>
                        {member.firstname} {member.lastname}
                      </Text>
                    </Group>
                    <Group mr={50}>
                      {member.email === username ? (
                        <Button
                          size="compact-xs"
                          color="red"
                          onClick={() => {
                            setMemberUsernameToDelete(member.email);
                            setOpenDeleteMemberModal(true);
                          }}
                        >
                          <IconLogout size={16} />
                        </Button>
                      ) : (
                        <></>
                      )}
                    </Group>
                    <DeleteConfirmationModal
                      title="Leave Chat"
                      description="Are you sure you want to leave this chat?"
                      action="Leave"
                      opacity={0.2}
                      opened={openDeleteMemberModal}
                      onClose={() => setOpenDeleteMemberModal(false)}
                      onSubmit={() =>
                        handleDeleteMember(memberUsernameToDelete)
                      }
                      isDeleting={isDeleting}
                    />
                  </Group>
                ))}
              </Flex>
            </Flex>
            <Flex
              direction="column"
              style={{
                width: '48%',
                height: '400px',
                position: 'relative',
              }}
            >
              <Text fw="bold" mb="sm">
                Add Connections to Group
              </Text>
              <Flex
                direction="column"
                style={{
                  overflowY: 'auto',
                  position: 'absolute',
                  top: '40px',
                  bottom: 0,
                  left: 0,
                  right: 0,
                }}
              >
                {availableConnections.length === 0 && (
                  <Text>No available connections found</Text>
                )}
                {availableConnections.map((connection) => (
                  <Group key={connection.id} mb="sm">
                    <Checkbox
                      checked={selectedConnections.includes(connection.id)}
                      onChange={() => handleCheckboxToggle(connection.id)}
                    />
                    <RyzeAvatar
                      key={connection.id}
                      memberId={connection.id}
                      style={{ marginRight: '10px' }}
                    />
                    <Text>
                      {connection.firstname} {connection.lastname}
                    </Text>
                  </Group>
                ))}
              </Flex>
            </Flex>
          </Flex>
        )}
      </Modal.Body>
      <Group justify="space-between">
        <Button onClick={handleClose} color="red" style={{ marginTop: '10px' }}>
          Cancel
        </Button>
        <Button
          onClick={handleAddMember}
          style={{ marginTop: '10px' }}
          loading={isSubmitting}
          disabled={selectedConnections.length === 0}
        >
          Add Member
        </Button>
      </Group>
    </Modal>
  );
};

export default OptionsModal;
