import React from 'react';
import { Tabs, Container } from '@mantine/core';
import InsuranceMemberApprovalPage from './InsuranceMemberApproval';
import InsuranceClinicApprovalPage from './InsuranceClinicApproval';

const InsuranceApprovalPortal: React.FC = () => {
  return (
    <Container>
      <Tabs defaultValue="members" variant="outline">
        <Tabs.List>
          <Tabs.Tab value="members">Member Approvals</Tabs.Tab>
          <Tabs.Tab value="clinics">Clinic Approvals</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="members" pt="md">
          <InsuranceMemberApprovalPage />
        </Tabs.Panel>

        <Tabs.Panel value="clinics" pt="md">
          <InsuranceClinicApprovalPage />
        </Tabs.Panel>
      </Tabs>
    </Container>
  );
};

export default InsuranceApprovalPortal;
