import { useEffect, useState } from 'react';
import { Container, Table, Loader, Text, Title } from '@mantine/core';
import useHttpClient from '../hooks/useHttpClient';

const MonthlyPremium = ({ isMember }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const httpClient = useHttpClient();

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const response = await httpClient.get('/api/payment/monthlypremium'); // Replace with your API endpoint
        setData(response.data);
      } catch (err) {
        setError(
          'Failed to load data. ' + err.response.data?.message || err.message,
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Container>
        <Loader size="xl" variant="dots" />
        <Text>Loading data...</Text>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Title order={3}>Monthly Premium</Title>
        <Text color="red" size="lg">
          {error}
        </Text>
      </Container>
    );
  }

  const headers = Object.keys(data[0] || {});
  const transposedData = headers.map((key) => ({
    key,
    values: data.map((row) => row[key]),
  }));

  return (
    <Container>
      <Text size="xl" mb="lg">
        Monthly Premium Data
      </Text>

      <Table striped highlightOnHover withTableBorder withColumnBorders>
        <thead>
          <tr>
            {/* <th>ID</th> */}
            <th>Period</th>
            <th>Enrolled Members</th>
            {!isMember && <th>Plans Count</th>}
            {isMember && <th>Selected Plan</th>}
            {isMember && <th>Final Premium</th>}
          </tr>
        </thead>
        <tbody>
          {data.map((item, idx) => (
            <tr key={idx}>
              {/* <td>{item.id}</td> */}
              <td style={{ textAlign: 'center' }}>
                {item.year} - {item.month}
              </td>
              <td style={{ textAlign: 'center' }}>
                {item.enrolledmembers.length}
              </td>
              {!isMember && (
                <td style={{ textAlign: 'center' }}>
                  {Object.keys(item.planscount).map((i) => {
                    return (
                      <span key={item.id}>
                        {i !== 'null' ? (
                          <>
                            <span
                              style={{ color: 'blue' }}
                            >{`Plan ${i}: `}</span>
                            {item.planscount[i]}
                          </>
                        ) : (
                          <>
                            <span style={{ color: 'blue' }}>{`Pending: `}</span>
                            {item.planscount[i]}
                          </>
                        )}
                        <br />
                      </span>
                    );
                  })}
                </td>
              )}
              {isMember && (
                <td style={{ textAlign: 'center' }}>
                  Option {Object.keys(item.planscount)[0]}
                </td>
              )}
              {isMember && (
                <td style={{ textAlign: 'center' }}>
                  {item.finalpremium ?? 'N/A'}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default MonthlyPremium;
