import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Title,
  TextInput,
  Button,
  Container,
  Grid,
  PasswordInput,
  Loader,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useParams } from 'react-router-dom';
import useHttpClient from '../hooks/useHttpClient';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useNavigate } from 'react-router-dom';

interface OpenEnrollmentEmployeeFormProps {
  initialData?: any;
}

const OpenEnrollmentEmployeeForm: React.FC<OpenEnrollmentEmployeeFormProps> = ({
  initialData,
}) => {
  const { enrollmentcode } = useParams<{ enrollmentcode: string }>();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    defaultValues: initialData,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  // do not use useFormDirtyAlert here

  const [loading, setLoading] = useState(false);
  const password = watch('password');
  const [passwordStrengthScore, setPasswordStrengthScore] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const httpClient = useHttpClient();

  const onSubmit = async (data: any) => {
    setLoading(true);
    if (passwordStrengthScore < 3) {
      notifications.show({
        title: 'Weak Password',
        message:
          'Please choose a stronger password (at least "Good" strength).',
        color: 'red',
        position: 'bottom-center',
      });
      setLoading(false);
      return;
    }
    const submissionData = {
      ...data,
      passwordConfirmation: undefined,
      email: data.email.toLowerCase(),
      type: 'employee',
    };
    try {
      // Send a PUT request to update the form data
      const response = await httpClient.put(
        `/api/organization/employee/${enrollmentcode}`,
        submissionData, // Axios will automatically stringify the data
      );
      if (response.status === 200) {
        // Assuming the API returns the updated data as JSON
        const result = response.data;
        notifications.show({
          title: 'Success',
          message: 'Successfully registered',
          color: 'green',
          position: 'bottom-center',
        });
        navigate(`/login`);
        return;
      } else {
        throw new Error('Failed to update form');
      }
      return;
    } catch (error) {
      console.error('Error updating form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to update form',
        color: 'red',
        position: 'bottom-center',
      });
      setLoading(false);
    }
  };

  return (
    <Container>
      <Title order={2} style={{ textAlign: 'center', marginBottom: '2rem' }}>
        Open Employee Registration
      </Title>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              label="First Name"
              required
              {...register('firstname', { required: true })}
              disabled={loading} // Disable while loading
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <TextInput
              label="Last Name"
              required
              {...register('lastname', { required: true })}
              disabled={loading} // Disable while loading
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <TextInput
              label="Email"
              required
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              })}
              error={errors.email?.message?.toString()}
              disabled={loading}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <PasswordInput
              label="Password"
              type="password"
              onVisibilityChange={setShowPassword}
              maxLength={50}
              required
              {...register('password')}
              disabled={loading} // Disable while loading
            />
            <PasswordStrengthBar
              password={password || ''}
              onChangeScore={(score) => setPasswordStrengthScore(score)}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <PasswordInput
              label="Confirm Password"
              required
              disabled={loading}
              visible={showPassword}
              onVisibilityChange={setShowPassword}
              {...register('passwordConfirmation', {
                required: 'Password confirmation is required',
                validate: (value) =>
                  value === password || 'Passwords do not match',
              })}
              error={errors.passwordConfirmation?.message?.toString()}
            />
          </Grid.Col>
        </Grid>
        <Button type="submit" mt="md" disabled={loading}>
          {loading ? (
            <>
              <Loader color="blue" size="xs" mr="xs" />
              Loading...
            </>
          ) : (
            'Register'
          )}
        </Button>
      </form>
    </Container>
  );
};

export default OpenEnrollmentEmployeeForm;
