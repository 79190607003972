import { Text, Title } from '@mantine/core';

function EmailVerificationScreen() {
  return (
    <>
      <Title order={3} mb="sm">
        Thank You!
      </Title>
      <Text size="lg">
        Please check your email for the verification message we just sent.
      </Text>
    </>
  );
}

export default EmailVerificationScreen;
