import { useState, useMemo } from 'react';
import { Grid, Text, Paper, Container, Title, Loader } from '@mantine/core';
import useHttpClient from './hooks/useHttpClient';
import { useComputedColorScheme } from '@mantine/core';
export default function WecareMemberDashboard({ path }) {
  const computedColorScheme = useComputedColorScheme('light');
  const httpClient = useHttpClient();
  const currentDay = new Date().toLocaleDateString(undefined, {
    weekday: 'long',
  });

  const [userInfo, setUserInfo] = useState<any>();
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [metadata, setMetadata] = useState<any>();

  const fetchUserInformation = async () => {
    setIsloading(true);
    try {
      const { data } = await httpClient.get(`/api/members`);
      setUserInfo(data);
      const { data: userMetadata } = await httpClient.get(`/api/user/metadata`);
      setMetadata(userMetadata);
    } catch (error) {
      console.error('failed to fetch user information', error);
    } finally {
      setIsloading(false);
    }
  };

  useMemo(() => {
    fetchUserInformation();
  }, []);

  return (
    <Container size="xl">
      <Grid>
        <Grid.Col span={12} p="xl" style={{ position: 'relative' }}>
          {isLoading ? (
            <Container
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Loader />
            </Container>
          ) : (
            <>
              <Title order={2} mb="xl" style={{ textAlign: 'left' }}>
                Welcome back and happy {currentDay}, {userInfo?.firstname}!
              </Title>

              <Grid>
                <Grid.Col span={{ base: 12 }}>
                  <Paper
                    p="lg"
                    withBorder
                    radius="md"
                    style={{
                      backgroundColor: '#eae8f9',
                    }}
                  >
                    <Title order={3} mb="sm">
                      Announcements
                    </Title>
                    <Paper p="md" withBorder>
                      <Text fw={700} c="blue">
                        WeCare Is Rolling out Nationally!
                      </Text>
                      <Text>
                        Stay tuned for various benefits we plan to roll out in
                        the coming months
                      </Text>
                    </Paper>
                  </Paper>
                </Grid.Col>

                <Grid.Col span={{ base: 12 }}>
                  <Paper
                    p="lg"
                    withBorder
                    radius="md"
                    style={{
                      backgroundColor: '#eae8f9',
                    }}
                  >
                    <Title order={3} mb="sm">
                      Upcoming Events
                    </Title>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 12,
                      }}
                    >
                      <Paper p="md" withBorder>
                        <Text fw={700} c="blue">
                          MSSNY House of Delegates
                        </Text>
                        <Text size="sm" c="dimmed">
                          Date: Friday April 4, 1:30 PM
                        </Text>
                        <Text>
                          WeCare is sponsoring the Private Practice Committee’s
                          meeting at the MSSNY House of Delegates on Friday
                          April 4 2025. We will be presenting along with some
                          vendors we feel will make a positive impact on the
                          bottom line and the lives of our physicians in private
                          practice.
                        </Text>
                      </Paper>
                      <Paper p="md" withBorder>
                        <Text fw={700} c="blue">
                          NYS Osteopathic Medical Society Annual Regional
                          Osteopathic Convention
                        </Text>
                        <Text size="sm" c="dimmed">
                          Date: Friday May 9, 3:00 PM
                        </Text>
                        <Text>
                          WeCare will be presenting a Women's Healthcare Update.
                        </Text>
                        <a href="https://www.nysoms.org/rocny">
                          Convention Details Here
                        </a>
                      </Paper>
                    </div>
                  </Paper>
                </Grid.Col>
              </Grid>
            </>
          )}
        </Grid.Col>
      </Grid>
    </Container>
  );
}
