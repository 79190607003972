import {
  Group,
  Button,
  Title,
  TextInput,
  Grid,
  Loader,
  Table,
  Text,
  Modal,
  Flex,
  MultiSelect,
} from '@mantine/core';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { notifications } from '@mantine/notifications';
import useHttpClient from './hooks/useHttpClient';
import { User } from '../types/common.types';
import { IconPlus, IconTrash, IconUsers } from '@tabler/icons-react';
import { Member } from '../types/members.types';
import { getPrivilegeTypeFromPath } from '../helpers/privilegeHelper';
import { useAuth } from './AuthProvider';
import ConfirmMemberDeleteModal from './ConfirmMemberDeleteModal';

export default function ClinicUsers({ userInfo, organizationId }) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {} as any,
  });
  // do not use useFormDirtyAlert here

  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState<User[]>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const httpClient = useHttpClient();
  const location = useLocation();
  const [type, setType] = useState<string>('');
  const [isOwner, setIsOwner] = useState<boolean>(false);

  const auth = useAuth();
  const privileges = auth.getPrivileges();
  const currentUsername = privileges[0].username;

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const insurancePrivileges = privileges
    .filter((p) => p.type.startsWith('insurance_'))
    .map((p) => ({
      value: p.type,
      label: p.type.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase()),
    }));

  const hasMultipleInsurancePrivileges = insurancePrivileges.length > 1;
  const isWecarePath = location.pathname.includes('/wecare');

  const [selectedPrivileges, setSelectedPrivileges] = useState<string[]>([]);

  useEffect(() => {
    const newType = getPrivilegeTypeFromPath(location.pathname);
    setType(newType);
  }, [location]);

  useEffect(() => {
    if (type) {
      fetchOrgUsers();
      checkIfUserIsOwner();
    }
  }, [type]);

  // Check if the current user is the owner of the service
  const checkIfUserIsOwner = async () => {
    try {
      // Find the current privilege for this type
      const currentPrivilege = privileges.find((priv) => priv.type === type);

      if (currentPrivilege) {
        // Use type assertion to access the is_owner property
        const isCurrentUserOwner = (currentPrivilege as any).is_owner === true;
        setIsOwner(isCurrentUserOwner);
      }
    } catch (error) {
      console.error('Error checking if user is owner:', error);
      setIsOwner(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchOrgUsers = async () => {
    if (!type) return;

    setLoading(true);
    try {
      const { data } = await httpClient.get<User[]>(
        `/api/user/members/${type}`,
      );
      setMembers(data);
    } catch (error) {
      console.error('Error fetching members', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to fetch users',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      // For Wecare paths, don't use additionalPrivileges even if the user has multiple privileges
      const isWecarePath = location.pathname.includes('/wecare');

      const response = await httpClient.post<Member[]>(
        `/api/user/create-member`,
        {
          ...data,
          email: data.email.toLowerCase(),
          type: isWecarePath
            ? type
            : hasMultipleInsurancePrivileges && data.privileges?.length
              ? data.privileges[0]
              : type,
          additionalPrivileges: isWecarePath
            ? []
            : hasMultipleInsurancePrivileges && data.privileges?.length
              ? data.privileges.slice(1)
              : [],
        },
      );

      if (response.status === 200) {
        reset();
        setSelectedPrivileges([]);
        notifications.show({
          title: 'Invite sent',
          message: 'An email was sent to complete enrollment.',
          color: 'green',
          position: 'bottom-center',
        });
        setShowModal(false);

        fetchOrgUsers();
      }
    } catch (error) {
      console.error('Error creating member:', error);
      notifications.show({
        title: 'Something went wrong',
        message: error?.response?.data || error?.message,
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setLoading(false);
    }
  };

  const getModalTitle = () => {
    const titles = {
      insurance_physician: 'Add Physician User',
      insurance_organization: 'Add Clinic Portal User',
      insurance_employee: 'Add Employee User',
      wecaremember: 'Add Wecare Member',
      wecareadmin: 'Add Wecare Admin',
      ryzeadmin: 'Add Ryze Admin',
    };
    return titles[type] || 'Add User';
  };

  const handleDeleteUser = async () => {
    setIsDeleting(true);
    try {
      const response = await httpClient.delete(
        `/api/user/member/${type}/${selectedUser?.id}/${selectedUser?.username}`,
      );
      if (response.status === 200) {
        notifications.show({
          title: 'Success',
          message: 'User deleted successfully',
          color: 'green',
          position: 'bottom-center',
        });
        setIsDeleting(false);
        setShowDeleteModal(false);
        fetchOrgUsers();
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      notifications.show({
        title: 'Error',
        message: error?.response?.data || error?.message,
        color: 'red',
        position: 'bottom-center',
      });
    }
  };

  return (
    <>
      <Title order={3} mb={10}>
        <IconUsers style={{ marginRight: '10px' }} />
        {type
          ? type.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())
          : 'Portal'}{' '}
        Users
      </Title>
      <Text mb="lg"></Text>
      <Text mb="lg">Here's a list of portal users for this organization.</Text>
      <div
        style={{ maxHeight: '320px', overflowY: 'auto', marginBottom: '20px' }}
      >
        <Table>
          <Table.Thead>
            <Table.Tr
              style={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'rgba(242,242,242)',
                // backgroundColor: '#333',
                zIndex: 1,
              }}
            >
              <Table.Th style={{ width: '60%' }}>Username</Table.Th>
              <Table.Th>Name</Table.Th>
              <Table.Th style={{ width: '10%' }}>Confirmed</Table.Th>
              {isOwner && <Table.Th style={{ width: '10%' }}>Actions</Table.Th>}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {members?.map((member) => (
              <Table.Tr
                style={{
                  fontWeight:
                    member?.username === currentUsername ? 'bold' : 'auto',
                }}
              >
                <Table.Td>
                  {member?.username}{' '}
                  {member?.username === currentUsername ? '(You)' : ''}
                </Table.Td>
                <Table.Td>
                  {member.firstname} {member.lastname}
                </Table.Td>
                <Table.Td style={{ textAlign: 'center' }}>
                  {member?.approved ? 'Yes' : 'No'}
                </Table.Td>
                <Table.Td style={{ textAlign: 'center' }}>
                  {member?.username !== currentUsername && isOwner && (
                    <Button
                      variant="subtle"
                      color="red"
                      size="sm"
                      onClick={() => {
                        setShowDeleteModal(true);
                        setSelectedUser(member);
                      }}
                    >
                      <IconTrash size={16} />
                    </Button>
                  )}
                </Table.Td>
              </Table.Tr>
            )) || (
              <Table.Tr>
                <Table.Td>
                  <i>No users found...</i>
                </Table.Td>
                <Table.Td></Table.Td>
                <Table.Td></Table.Td>
              </Table.Tr>
            )}
          </Table.Tbody>
        </Table>
      </div>
      <Flex justify="flex-end" direction="row" wrap="wrap">
        <Button onClick={() => setShowModal(true)}>
          <IconPlus />
          &nbsp; Add User
        </Button>
      </Flex>

      <Modal
        opened={showModal}
        onClose={() => setShowModal(false)}
        title={<Title order={3}>{getModalTitle()}</Title>}
        centered
        style={{ position: 'absolute' }}
        size="md"
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        >
          <Grid style={{ background: 'none' }}>
            <Grid.Col span={6}>
              <TextInput
                label="First Name"
                required
                {...register('firstname', { required: true })}
                disabled={loading}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput
                label="Last Name"
                required
                {...register('lastname', { required: true })}
                disabled={loading}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput
                label="Username (Email)"
                required
                {...register('email', { required: true })}
                disabled={loading}
              />
            </Grid.Col>

            {/* Add MultiSelect for privileges if user has multiple insurance privileges */}
            {hasMultipleInsurancePrivileges && !isWecarePath && (
              <Grid.Col span={12}>
                <MultiSelect
                  label="Type of Privilege"
                  placeholder="Select privileges"
                  data={insurancePrivileges}
                  value={selectedPrivileges}
                  onChange={(value) => {
                    setSelectedPrivileges(value);
                    setValue('privileges', value);
                  }}
                  disabled={loading}
                />
              </Grid.Col>
            )}
          </Grid>
          <Group justify="space-between">
            <Button
              color="red"
              mt="md"
              mr="xs"
              disabled={loading}
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
            <Button type="submit" mt="md" disabled={loading}>
              {loading ? (
                <>
                  <Loader color="blue" size="xs" mr="xs" />
                  Loading...
                </>
              ) : (
                'Invite User'
              )}
            </Button>
          </Group>
        </form>
      </Modal>
      <ConfirmMemberDeleteModal
        opened={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onSubmit={handleDeleteUser}
        isDeleting={isDeleting}
        title="Delete User"
        description="Are you sure you want to delete this user?"
      />
    </>
  );
}
