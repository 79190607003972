import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { Checkbox, Radio, Text, Grid } from '@mantine/core';

interface TelehealthFormProps {
  control: Control<any>;
  practiceIndex: number;
}

const PracticeLocationTelehealthForm: React.FC<TelehealthFormProps> = ({
  control,
  practiceIndex,
}) => {
  return (
    <Grid>
      <Grid.Col span={12}>
        <Controller
          name={`practices.${practiceIndex}.practicelocation_telehealth.doProvideTelehealthService`}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <Checkbox
              id={`practice-location-telehealth-do-provide-telehealth-service-${practiceIndex}`}
              label="I provide telehealth services"
              checked={field.value}
              onChange={(e) => field.onChange(e.currentTarget.checked)}
            />
          )}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Controller
          name={`practices.${practiceIndex}.practicelocation_telehealth.isTelehealthApplicationHIPAACompliant`}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <Radio.Group
              id={`practice-location-telehealth-is-telehealth-application-hipaa-compliant-${practiceIndex}`}
              label="Do you use a telehealth application/platform that is HIPAA compliant?"
              value={field.value ? 'yes' : 'no'}
              onChange={(value) => field.onChange(value === 'yes')}
            >
              <Radio mt="xs" value="yes" label="Yes" />
              <Radio mt="xs" value="no" label="No" />
            </Radio.Group>
          )}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Controller
          name={`practices.${practiceIndex}.practicelocation_telehealth.canSupportFamilyCaregivers`}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <Radio.Group
              id={`practice-location-telehealth-can-support-family-caregivers-${practiceIndex}`}
              label="Are you willing and able to support family caregivers?"
              value={field.value ? 'yes' : 'no'}
              onChange={(value) => field.onChange(value === 'yes')}
            >
              <Radio mt="xs" value="yes" label="Yes" />
              <Radio mt="xs" value="no" label="No" />
            </Radio.Group>
          )}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Text>Telehealth Service Type:</Text>
      </Grid.Col>
      <Grid.Col span={12}>
        <Controller
          name={`practices.${practiceIndex}.practicelocation_telehealth.audio`}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <Checkbox
              id={`practice-location-telehealth-audio-${practiceIndex}`}
              label="Audio"
              checked={field.value}
              onChange={(e) => field.onChange(e.currentTarget.checked)}
            />
          )}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Controller
          name={`practices.${practiceIndex}.practicelocation_telehealth.audioVideo`}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <Checkbox
              id={`practice-location-telehealth-audio-video-${practiceIndex}`}
              label="Audio/Video"
              checked={field.value}
              onChange={(e) => field.onChange(e.currentTarget.checked)}
            />
          )}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Controller
          name={`practices.${practiceIndex}.practicelocation_telehealth.onlineAdaptiveInterviews`}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <Checkbox
              id={`practice-location-telehealth-online-adaptive-interviews-${practiceIndex}`}
              label="Online Adaptive Interviews"
              checked={field.value}
              onChange={(e) => field.onChange(e.currentTarget.checked)}
            />
          )}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Controller
          name={`practices.${practiceIndex}.practicelocation_telehealth.secureTextMessaging`}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <Checkbox
              id={`practice-location-telehealth-secure-text-messaging-${practiceIndex}`}
              label="Secure Text Messaging"
              checked={field.value}
              onChange={(e) => field.onChange(e.currentTarget.checked)}
            />
          )}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Controller
          name={`practices.${practiceIndex}.practicelocation_telehealth.remoteMonitoring`}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <Checkbox
              id={`practice-location-telehealth-remote-monitoring-${practiceIndex}`}
              label="Remote Monitoring"
              checked={field.value}
              onChange={(e) => field.onChange(e.currentTarget.checked)}
            />
          )}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Controller
          name={`practices.${practiceIndex}.practicelocation_telehealth.storeAndForward`}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <Checkbox
              id={`practice-location-telehealth-store-and-forward-${practiceIndex}`}
              label="Store and Forward"
              checked={field.value}
              onChange={(e) => field.onChange(e.currentTarget.checked)}
            />
          )}
        />
      </Grid.Col>
    </Grid>
  );
};

export default PracticeLocationTelehealthForm;
