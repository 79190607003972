export const SPECIAL_EXPERIENCES_OPTIONS = [
  {
    group: 'Patient Age Groups',
    items: [
      { value: 'infants', label: 'Infants (0-23 months)' },
      { value: 'toddlers', label: 'Toddlers (2-5)' },
      { value: 'children', label: 'Children (6-12)' },
      { value: 'adolescents', label: 'Adolescents (13-18)' },
      { value: 'young_adults', label: 'Young Adults (19-24)' },
      { value: 'adults', label: 'Adults (25-44)' },
      { value: 'middle_aged', label: 'Middle Aged Adults (45-64)' },
      { value: 'older_adults', label: 'Older Adults (65+)' },
    ],
  },
  {
    group: 'Patient Gender Identities',
    items: [
      { value: 'male', label: 'Male' },
      { value: 'female', label: 'Female' },
      {
        value: 'nonbinary',
        label: 'Nonbinary, genderqueer, neither exclusively Male nor Female',
      },
      {
        value: 'trans_male',
        label: 'Transgender male/trans man/female-to-male (FTM)',
      },
      {
        value: 'trans_female',
        label: 'Transgender female/trans woman/male-to-female (MTF)',
      },
    ],
  },
  {
    group: 'Patient Sexual Orientation',
    items: [
      { value: 'asexual', label: 'Asexual' },
      { value: 'bisexual', label: 'Bisexual' },
      { value: 'gay', label: 'Gay' },
      { value: 'lesbian', label: 'Lesbian' },
      { value: 'queer', label: 'Queer' },
      { value: 'questioning', label: 'Questioning' },
      { value: 'straight', label: 'Straight or Heterosexual' },
    ],
  },
  {
    group: 'Special Patient Populations',
    items: [
      { value: 'blind', label: 'Blind or Visually Impaired' },
      { value: 'chronically_ill', label: 'Chronically Medically Ill' },
      { value: 'deaf', label: 'Deaf or Hard-of-Hearing' },
      {
        value: 'developmentally_disabled',
        label: 'Developmentally Disabled',
      },
      { value: 'homeless', label: 'Homeless' },
      { value: 'intellectually_disabled', label: 'Intellectually Disabled' },
      { value: 'hiv', label: 'Living with HIV/AIDS' },
      { value: 'military', label: 'Military and Veterans' },
      { value: 'physically_disabled', label: 'Physically Disabled' },
    ],
  },
  {
    group: 'Patient Racial/Ethnic Groups',
    items: [
      { value: 'asian', label: 'Asian' },
      { value: 'black_african_american', label: 'Black or African American' },
      { value: 'hispanic_latino', label: 'Hispanic or Latino' },
      {
        value: 'native_american_alaska_native',
        label: 'Native American or Alaska Native',
      },
      {
        value: 'native_hawaiian_pacific_islander',
        label: 'Native Hawaiian or Other Pacific Islander',
      },
      { value: 'white', label: 'White' },
      {
        value: 'middle_eastern_north_african',
        label: 'Middle Eastern or North African (MENA)',
      },
      {
        value: 'south_asian',
        label:
          'South Asian (Indian, Pakistani, Bangladeshi, Sri Lankan, Nepali, Bhutanese, Maldivian)',
      },
      {
        value: 'east_asian',
        label: 'East Asian (Chinese, Japanese, Korean, Mongolian, Tibetan)',
      },
      {
        value: 'southeast_asian',
        label:
          'Southeast Asian (Filipino, Thai, Vietnamese, Cambodian, Lao, Burmese, Indonesian, Malaysian, Timorese)',
      },
      {
        value: 'caribbean_black',
        label:
          'Caribbean Black (Haitian, Jamaican, Trinidadian, Barbadian, Bahamian, etc.)',
      },
      {
        value: 'central_south_american',
        label: 'Central/South American Indigenous',
      },
      {
        value: 'aboriginal_torres_strait',
        label: 'Aboriginal or Torres Strait Islander (Australia)',
      },
      { value: 'romani', label: 'Romani (Gypsy)' },
      { value: 'jewish', label: 'Jewish (Ashkenazi, Sephardic, Mizrahi)' },
      { value: 'afro_latinx', label: 'Afro-Latinx' },
      { value: 'mestizo', label: 'Mestizo (Mixed Indigenous and European)' },
      { value: 'mulatto', label: 'Mulatto (Mixed Black and European)' },
      {
        value: 'melanesian',
        label:
          'Melanesian (Fijian, Papua New Guinean, Solomon Islander, Vanuatuan)',
      },
      {
        value: 'micronesian',
        label: 'Micronesian (Guamanian, Marshallese, Palauan, etc.)',
      },
      {
        value: 'polynesian',
        label: 'Polynesian (Samoan, Tongan, Tahitian, Maori, Hawaiian, etc.)',
      },
      {
        value: 'african_indigenous',
        label: 'Indigenous African (Maasai, Zulu, Yoruba, Hausa, Amhara, etc.)',
      },
      { value: 'creole', label: 'Creole' },
      { value: 'mixed_race', label: 'Mixed Race/Multiracial' },
      { value: 'other', label: 'Other (Please Specify)' },
    ],
  },
];
