import React from 'react';
import { Text, Radio, Button, Group, Stack } from '@mantine/core';
import { useForm, Controller } from 'react-hook-form';

interface WorkStatusScreenProps {
  answers: any;
  setAnswers: (answers: any) => void;
  next: (pathIndex?: number) => void;
  back: () => void;
}

const WorkStatusScreen: React.FC<WorkStatusScreenProps> = ({
  answers,
  setAnswers,
  next,
  back,
}) => {
  const { control, handleSubmit, watch } = useForm({
    defaultValues: { work_status: answers.work_status || '' },
  });

  const workStatus = watch('work_status');

  const onSubmit = (data: { work_status: string }) => {
    setAnswers({ ...answers, work_status: data.work_status });

    if (data.work_status === 'working') {
      next(0);
    } else if (data.work_status === 'plan_work_12_months') {
      next(1);
    } else {
      next(2);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text size="xl" mb="md">
        Are you currently working?
      </Text>

      <Controller
        name="work_status"
        control={control}
        rules={{ required: 'Work status is required' }}
        render={({ field, fieldState: { error } }) => (
          <Radio.Group
            {...field}
            value={field.value || ''}
            error={error?.message}
          >
            <Stack gap="sm" mt={10}>
              <Radio value="working" label="Yes, I am" />
              <Radio
                value="plan_work_12_months"
                label="No, but I plan to within next 12 months"
              />
              <Radio value="retired" label="I am retired" />
              <Radio value="in_training" label="I am in training" />
            </Stack>
          </Radio.Group>
        )}
      />

      <Group justify="flex-end" mt="xl">
        <Button variant="default" onClick={back}>
          Back
        </Button>
        <Button type="submit" disabled={!workStatus}>
          Next
        </Button>
      </Group>
    </form>
  );
};

export default WorkStatusScreen;
