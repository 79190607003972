import React from 'react';
import { Tabs, Container } from '@mantine/core';
import Payments from './Payments';
import EligibilityInformation from './EligibilityInformation';
const InsuranceAdminAccountManagement: React.FC = () => {
  return (
    <Container fluid>
      <Tabs defaultValue="payment-information" variant="outline">
        <Tabs.List>
          <Tabs.Tab value="payment-information">Payment Information</Tabs.Tab>
          <Tabs.Tab value="eligibility-information">
            Eligibility Information
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="payment-information" pt="md">
          <Payments />
        </Tabs.Panel>

        <Tabs.Panel value="eligibility-information" pt="md">
          <EligibilityInformation />
        </Tabs.Panel>
      </Tabs>
    </Container>
  );
};

export default InsuranceAdminAccountManagement;
