/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { Group } from '@mantine/core';
import RyzeLogo from './RyzeLogo';
import WecareLogo from './WecareLogo';
import { DoubleNavbar } from './DoubleNavbar';
import { AppShell, Burger } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { mainMenuList, subMenuList } from './menu.config';
import { useAuth } from './AuthProvider';
import { useLocation } from 'react-router-dom';
import { useNotifications } from './NotificationContext';

interface PortalProps {
  subMenuKey?: string;
  children: React.ReactNode;
}

const AppShellWrapper: React.FC<PortalProps> = ({ children, subMenuKey }) => {
  // main menu
  const location = useLocation();
  const pathname = location.pathname;
  const { pendingApprovals } = useNotifications();

  const mainMenuObject = useMemo(() => {
    return mainMenuList.find((menuItem) =>
      menuItem.routes.some((route: string | RegExp) => {
        if (route === '/') {
          return pathname === '/';
        } else if (route instanceof RegExp) {
          return route.test(pathname);
        } else if (typeof route === 'string') {
          return pathname.startsWith(route);
        }
      }),
    );
  }, [pathname]);
  const mainMenu = mainMenuObject?.menu;
  const layout = mainMenuObject?.layout || 'member';

  // submenu menu
  const [opened, { toggle }] = useDisclosure();
  const subMenu = subMenuList[subMenuKey] || {};

  const auth = useAuth();
  // const privileges = auth.getPrivileges();
  // const username = privileges[0].username;
  // const type = privileges[0].type
  //   .replace(/_/g, ' ')
  //   .replace(/\b\w/g, (char) => char.toUpperCase());

  return (
    <AppShell
      header={{ height: 75 }}
      navbar={{
        width: subMenu.menu ? 520 : mainMenu ? 260 : 0,
        breakpoint: 'sm',
        collapsed: { mobile: opened },
      }}
      style={{ background: 'none' }}
      padding="lg"
    >
      <AppShell.Header>
        <Group justify="space-between" style={{ padding: '0 20px' }}>
          <Group align="center">
            <Burger
              opened={opened}
              onClick={toggle}
              hiddenFrom="sm"
              size="sm"
            />
            {layout === 'wecareadmin' || layout === 'wecaremember' ? (
              <a
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  auth.goHome();
                }}
              >
                <WecareLogo />
              </a>
            ) : (
              <a
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  auth.goHome();
                }}
              >
                <RyzeLogo />
              </a>
            )}
          </Group>
        </Group>
      </AppShell.Header>

      {mainMenu && (
        <AppShell.Navbar p="sm">
          <DoubleNavbar
            location={location}
            mainMenu={mainMenu}
            subMenu={subMenu}
            approvals={pendingApprovals}
          />
        </AppShell.Navbar>
      )}

      <AppShell.Main onClick={() => !opened && toggle()}>
        {children}
      </AppShell.Main>
    </AppShell>
  );
};

export default AppShellWrapper;
