import { Title, Text, Button, Group } from '@mantine/core';

function SorryScreen({ back }) {
  return (
    <>
      <Title order={3} mb="md" mt="md">
        We’d Love to Support You in the Future!
      </Title>
      <Text mt="md" size="lg">
        Thank you for your interest. Our health plans are currently designed for
        actively practicing physicians and medical practices. If your needs
        change in the future, we would love to explore how we can support you.
        Feel free to reach out with any questions
      </Text>
      <Group justify="flex-end" mt="xl">
        <Button
          variant="default"
          onClick={() => {
            back();
          }}
        >
          Back
        </Button>
      </Group>
    </>
  );
}

export default SorryScreen;
