import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Group,
  Collapse,
  Paper,
  Grid,
  Input,
  MultiSelect,
  Checkbox,
} from '@mantine/core';
import { IMaskInput } from 'react-imask';
import { notifications } from '@mantine/notifications';
import useHttpClient from './hooks/useHttpClient';
import { STATES_ARRAY } from '../types/members.types';
import { useLoading } from './WecareAdminMemberView';
import CustomDateInput from './CustomDateInput';
import { useFormDirtyAlert } from '../helpers/useFormDirtyAlert';
import { loadScript } from '../helpers/loadScript';

interface FormProps {
  memberId: number;
}

const LiabilityForm: React.FC<FormProps> = ({ memberId }) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, dirtyFields },
    setValue,
  } = useForm({
    defaultValues: {
      coveredbyftca: false,
      uninsured: false,
      liabilities: [],
    },
  });

  useFormDirtyAlert(reset, isDirty, dirtyFields);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'liabilities',
  });
  const httpClient = useHttpClient();
  const { stopLoading } = useLoading();

  const [collapsedIndices, setCollapsedIndices] = useState<boolean[]>([]);
  const [initialData, setInitialData] = useState<any>();
  const [hasLiabilitiesExtra, setHasLiabilitiesExtra] = useState(false);
  const [practiceOptions, setPracticeOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const locationAddressRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [addressPopulated, setAddressPopulated] = useState<boolean[]>([]);

  const handlePlaceSelect = useCallback(
    async (autocomplete: google.maps.places.Autocomplete, index: number) => {
      if (!autocomplete) return;

      const place = autocomplete.getPlace();
      if (!place.address_components) return;

      // Reset relevant address fields
      setValue(`liabilities.${index}.street1`, '');
      setValue(`liabilities.${index}.street2`, '');
      setValue(`liabilities.${index}.city`, '');
      setValue(`liabilities.${index}.state`, '');
      setValue(`liabilities.${index}.zip`, '');

      let streetNumber = '';
      let route = '';
      let street2 = '';
      let city = '';
      let state = '';
      let zipCode = '';

      place.address_components?.forEach((component) => {
        const types = component.types;
        if (types.includes('street_number')) streetNumber = component.long_name;
        if (types.includes('route')) route = component.long_name;
        if (types.includes('subpremise')) street2 = component.long_name;
        if (types.includes('locality')) city = component.long_name;
        else if (types.includes('sublocality')) city = component.long_name;
        if (types.includes('administrative_area_level_1'))
          state = component.short_name;
        if (types.includes('postal_code')) zipCode = component.long_name;
      });

      const street1 = `${streetNumber} ${route}`.trim();
      if (street1) {
        setAddressPopulated((prev) => {
          const newState = [...prev];
          newState[index] = true;
          return newState;
        });
      }

      // Set values in the form
      setValue(`liabilities.${index}.street1`, street1);
      setValue(`liabilities.${index}.street2`, street2);
      setValue(`liabilities.${index}.city`, city);
      setValue(`liabilities.${index}.state`, state);
      setValue(`liabilities.${index}.zip`, zipCode);
      setValue(`liabilities.${index}.country`, 'US');
    },
    [setValue],
  );

  const resetAddress = (index: number) => {
    setAddressPopulated((prev) => {
      const newState = [...prev];
      newState[index] = false;
      return newState;
    });
    if (locationAddressRefs.current[index]) {
      locationAddressRefs.current[index]!.value = '';
    }
  };

  useEffect(() => {
    setAddressPopulated((prev) => {
      const newState = [...prev];
      while (newState.length < fields.length) {
        newState.push(false);
      }
      return newState.slice(0, fields.length);
    });
  }, [fields.length]);

  useEffect(() => {
    const autocompletes: (google.maps.places.Autocomplete | null)[] = [];

    const initAutocomplete = () => {
      locationAddressRefs.current.forEach((ref, index) => {
        if (ref && window.google) {
          const autocomplete = new window.google.maps.places.Autocomplete(ref, {
            types: ['address'],
            componentRestrictions: { country: 'us' },
            fields: [
              'address_components',
              'formatted_address',
              'geometry',
              'name',
            ],
          });

          autocomplete.addListener('place_changed', () => {
            handlePlaceSelect(autocomplete, index);
          });

          autocompletes[index] = autocomplete;
        }
      });
    };

    const googleMapsScriptUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;

    if (!window.google) {
      loadScript(googleMapsScriptUrl)
        .then(() => {
          initAutocomplete();
        })
        .catch((err) =>
          console.error('Failed to load Google Maps script:', err),
        );
    } else {
      initAutocomplete();
    }

    return () => {
      autocompletes.forEach((autocomplete) => {
        if (autocomplete) {
          google.maps.event.clearInstanceListeners(autocomplete);
        }
      });
    };
  }, [fields.length, watch, handlePlaceSelect]);

  const fetchLiabilities = async () => {
    try {
      // Fetch all data in parallel
      const [liabilitiesResponse, liabilitiesextraResponse, practiceResponse] =
        await Promise.all([
          httpClient.get(`/api/liabilities/${memberId}`).catch((e) => {
            return { data: [] };
          }),
          httpClient.get(`/api/liabilitiesextra/${memberId}`).catch((e) => {
            return { data: [] };
          }),
          httpClient.get(`/api/practices/${memberId}`).catch((e) => {
            return { data: [] };
          }),
        ]);

      // Process liabilities data
      const sortedLiabilities = liabilitiesResponse.data
        .map((liability: any) => ({
          ...liability,
          coveredlocations: liability.coveredlocations.map((loc: number) =>
            loc.toString(),
          ),
        }))
        .sort((a: any, b: any) => {
          const expirationDateComparison =
            new Date(a.currentexpirationdate).getTime() -
            new Date(b.currentexpirationdate).getTime();
          if (expirationDateComparison !== 0) return expirationDateComparison;
          const policyNumberComparison = a.policynumber.localeCompare(
            b.policynumber,
          );
          if (policyNumberComparison !== 0) return policyNumberComparison;
          return a.id - b.id;
        });

      // Process liabilities extra data
      const fetchedExtra = { coveredbyftca: false, uninsured: false };
      if (
        liabilitiesextraResponse.data &&
        liabilitiesextraResponse.data.length > 0
      ) {
        Object.assign(fetchedExtra, liabilitiesextraResponse.data[0]);
        setHasLiabilitiesExtra(true);
      } else {
        setHasLiabilitiesExtra(false);
      }

      // Process practices data
      setPracticeOptions(
        practiceResponse?.data?.map((p: any) => {
          const { street1 = '' } = p.locations[0];
          const address = `${p.legalbusinessname} : ${street1}`;

          return {
            value: p.id.toString(),
            label: address,
          };
        }),
      );

      // Update state
      const initialDataValue = {
        liabilities: sortedLiabilities,
        coveredbyftca: fetchedExtra.coveredbyftca,
        uninsured: fetchedExtra.uninsured,
      };
      setInitialData(initialDataValue);

      setCollapsedIndices(sortedLiabilities.map(() => false));
    } catch (error: any) {
      console.error('Error in fetchLiabilities:', error);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    if (initialData) {
      reset({
        coveredbyftca: initialData.coveredbyftca ?? false,
        uninsured: initialData.uninsured ?? false,
        liabilities: initialData.liabilities ?? [],
      });
    }
  }, [initialData, reset]);

  useEffect(() => {
    if (memberId) {
      fetchLiabilities();
    }
  }, [memberId]);

  const toggleCollapse = (index: number) => {
    setCollapsedIndices((prev) => {
      const newCollapsedIndices = [...prev];
      newCollapsedIndices[index] = !newCollapsedIndices[index];
      return newCollapsedIndices;
    });
  };

  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    try {
      const formatData = (items: any[]) => {
        return items.map((item) => ({
          ...item,
          memberid: memberId,
          coveredlocations: item.coveredlocations.map((location: string) =>
            parseInt(location, 10),
          ),
        }));
      };

      const formattedData = formatData(data.liabilities);

      const createLiabilities = async (liabilities: any) => {
        const response = await httpClient.post('/api/liabilities', liabilities);
        return response.data;
      };

      const updateLiabilities = async (liabilities: any) => {
        const response = await httpClient.put(
          `/api/liabilities/${liabilities.id}`,
          liabilities,
        );
        return response.data;
      };

      const deleteLiabilities = async (id: number) => {
        const response = await httpClient.delete(`/api/liabilities/${id}`);
        return response.data;
      };

      const createLiabilitiesExtra = async (extraData: any) => {
        const extraDataWithMemberId = { ...extraData, memberid: memberId };
        const response = await httpClient.post(
          '/api/liabilitiesextra',
          extraDataWithMemberId,
        );
        return response.data;
      };

      const updateLiabilitiesExtra = async (extraData: any) => {
        try {
          const response = await httpClient.put(
            `/api/liabilitiesextra/${memberId}`,
            extraData,
          );
          return response.data;
        } catch (e) {
          console.error('Error updating liabilities extra:', e);
        }
      };

      const initialIds = new Set<number>(
        initialData?.liabilities.map((c: any) => c.id),
      );
      const currentIds = new Set<number>(formattedData.map((c: any) => c.id));
      const deletedIds = [...initialIds].filter(
        (id): id is number => !currentIds.has(id),
      );

      const createTasks = formattedData
        .filter((c: any) => !c.id || c.id === 0)
        .map((c: any) => createLiabilities(c));
      const updateTasks = formattedData
        .filter((c: any) => c.id && c.id > 0 && initialIds.has(c.id))
        .map((c: any) => updateLiabilities(c));
      const deleteTasks = deletedIds.map((id) => deleteLiabilities(id));

      const liabilitiesExtraData = {
        coveredbyftca: data.coveredbyftca ?? false,
        uninsured: data.uninsured ?? false,
        memberid: memberId,
      };

      const extraTask = hasLiabilitiesExtra
        ? updateLiabilitiesExtra(liabilitiesExtraData)
        : createLiabilitiesExtra(liabilitiesExtraData);

      const results = await Promise.all(
        [...createTasks, ...updateTasks, ...deleteTasks, extraTask].filter(
          Boolean,
        ),
      );
      console.log('Liabilities API response results:', results);
      fetchLiabilities();

      notifications.show({
        title: 'Success',
        message: 'Liabilities updated successfully',
        color: 'green',
        position: 'bottom-center',
      });
    } catch (error) {
      console.error('Error submitting liability form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit liability',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      {/* {fields.toString()} */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      >
        <Grid>
          <Grid.Col span={6}>
            <Controller
              name="coveredbyftca"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  id="coveredbyftca"
                  label="I am covered by FTCA"
                  checked={field.value ?? false}
                  onChange={(e) => field.onChange(e.currentTarget.checked)}
                />
              )}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name="uninsured"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  id="uninsured"
                  label="I do not have malpractice/liability insurance"
                  checked={field.value ?? false}
                  onChange={(e) => field.onChange(e.currentTarget.checked)}
                />
              )}
            />
          </Grid.Col>
        </Grid>
        <div style={{ marginTop: '16px' }}></div>
        {fields.map((item, index) => (
          <Paper key={item.id} shadow="xs" p="md" withBorder>
            <Group justify="space-between">
              <Group>
                <h3>Policy {index + 1}</h3>
                <Button
                  id={`toggle-collapse-policy-${index}`}
                  onClick={() => toggleCollapse(index)}
                  size="xs"
                >
                  {collapsedIndices[index] ? 'Expand' : 'Collapse'}
                </Button>
                <Button
                  id={`remove-policy-${index}`}
                  color="red"
                  onClick={() => remove(index)}
                  size="xs"
                >
                  Remove
                </Button>
              </Group>
            </Group>
            <Collapse in={!collapsedIndices[index]}>
              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    id={`liabilities.${index}.policynumber`}
                    label="Policy Number"
                    {...register(`liabilities.${index}.policynumber`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    id={`liabilities.${index}.carrier`}
                    label="Carrier"
                    {...register(`liabilities.${index}.carrier`)}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Controller
                    name={`liabilities.${index}.coveredlocations`}
                    control={control}
                    render={({ field }) => (
                      <MultiSelect
                        id={`liabilities.${index}.coveredlocations`}
                        {...field}
                        label="Covered Locations (Select All Covered by this Policy)"
                        data={practiceOptions}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <CustomDateInput
                    control={control}
                    name={`liabilities.${index}.currenteffectivedate`}
                    label="Current Effective Date"
                  />
                </Grid.Col>

                <Grid.Col span={6}>
                  <CustomDateInput
                    control={control}
                    name={`liabilities.${index}.currentexpirationdate`}
                    label="Current Expiration Date"
                  />
                </Grid.Col>

                <Grid.Col span={6}>
                  <CustomDateInput
                    control={control}
                    name={`liabilities.${index}.originaleffectivedate`}
                    label="Original Effective Date"
                  />
                </Grid.Col>

                <Grid.Col span={12}>
                  <TextInput
                    id={`liabilities.${index}.address`}
                    label="Аddress - Start typing the address below and pick one from the dropdown"
                    placeholder="Start by typing here"
                    data-index={index}
                    ref={(el) => (locationAddressRefs.current[index] = el)}
                    onChange={(e) => {
                      if (!e.target.value) {
                        resetAddress(index);
                      }
                    }}
                  />
                </Grid.Col>

                <Grid.Col span={6}>
                  <TextInput
                    id={`liabilities.${index}.street1`}
                    label={'\u0405treet 1'}
                    {...register(`liabilities.${index}.street1`)}
                    disabled
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    id={`liabilities.${index}.street2`}
                    label={'\u0405treet 2'}
                    {...register(`liabilities.${index}.street2`)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    id={`liabilities.${index}.city`}
                    label="City"
                    {...register(`liabilities.${index}.city`)}
                    disabled
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`liabilities.${index}.country`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        id={`liabilities.${index}.country`}
                        {...field}
                        label="Country"
                        data={[{ value: 'US', label: 'United States' }]}
                        clearable
                        searchable
                        disabled
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`liabilities.${index}.state`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        id={`liabilities.${index}.state`}
                        {...field}
                        label="State"
                        data={STATES_ARRAY.map((state) => ({
                          value: state,
                          label: state,
                        }))}
                        clearable
                        searchable
                        disabled
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    id={`liabilities.${index}.zip`}
                    label="ZIP"
                    {...register(`liabilities.${index}.zip`)}
                    maxLength={5}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.target.value = e.target.value.replace(/\D/g, '');
                    }}
                    disabled
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`liabilities.${index}.phone`}
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Wrapper label="Phone">
                        <Input
                          id={`liabilities.${index}.phone`}
                          data-testid={`liabilities.${index}.phone`}
                          component={IMaskInput}
                          mask="(000) 000-0000"
                          placeholder="(XXX) XXX-XXXX"
                          value={value}
                          onAccept={onChange} // Ensure the masked input's event updates your form
                          inputRef={ref}
                        />
                      </Input.Wrapper>
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`liabilities.${index}.fax`}
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <Input.Wrapper label="Fax">
                        <Input
                          id={`liabilities.${index}.fax`}
                          data-testid={`liabilities.${index}.fax`}
                          component={IMaskInput}
                          mask="(000) 000-0000"
                          placeholder="(XXX) XXX-XXXX"
                          value={value}
                          onAccept={onChange}
                          inputRef={ref}
                        />
                      </Input.Wrapper>
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`liabilities.${index}.unlimitedcoverage`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        id={`liabilities.${index}.unlimitedcoverage`}
                        {...field}
                        label="Unlimited Coverage"
                        data={[
                          { value: 'Y', label: 'Yes' },
                          { value: 'N', label: 'No' },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`liabilities.${index}.type`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        id={`liabilities.${index}.type`}
                        {...field}
                        label="Type of Coverage"
                        data={[
                          { value: 'Select', label: 'Select' },
                          { value: 'Occurrence', label: 'Occurrence' },
                          { value: 'Claims Made', label: 'Claims Made' },
                          { value: 'Individual', label: 'Individual' },
                          { value: 'Shared', label: 'Shared' },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    id={`liabilities.${index}.amountperoccurrence`}
                    label="Amount of Coverage Per Occurrence"
                    leftSection="$"
                    {...register(`liabilities.${index}.amountperoccurrence`)}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.target.value = e.target.value.replace(/\D/g, ''); // Replace any non-numeric characters
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    id={`liabilities.${index}.amountaggregate`}
                    label="Amount of Coverage Aggregate"
                    leftSection="$"
                    {...register(`liabilities.${index}.amountaggregate`)}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.target.value = e.target.value.replace(/\D/g, ''); // Replace any non-numeric characters
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`liabilities.${index}.tailnosecoverage`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        id={`liabilities.${index}.tailnosecoverage`}
                        {...field}
                        label="Tail/Nose Coverage"
                        data={[
                          { value: 'Y', label: 'Yes' },
                          { value: 'N', label: 'No' },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`liabilities.${index}.individualcoverage`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        id={`liabilities.${index}.individualcoverage`}
                        {...field}
                        label="Individual Coverage"
                        data={[
                          { value: 'Y', label: 'Yes' },
                          { value: 'N', label: 'No' },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name={`liabilities.${index}.selfinsured`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        id={`liabilities.${index}.selfinsured`}
                        {...field}
                        label="Self Insured"
                        data={[
                          { value: 'Y', label: 'Yes' },
                          { value: 'N', label: 'No' },
                        ]}
                        clearable
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    id={`liabilities.${index}.institutionaffiliation`}
                    label="Institution Affiliation"
                    {...register(`liabilities.${index}.institutionaffiliation`)}
                  />
                </Grid.Col>
              </Grid>
            </Collapse>
          </Paper>
        ))}
        <Group mt="md">
          <Button
            id="add-policy"
            onClick={() =>
              append({
                policynumber: '',
                coveredlocations: [],
                currenteffectivedate: null,
                currentexpirationdate: null,
                originaleffectivedate: null,
                carrier: '',
                street1: '',
                street2: '',
                city: '',
                country: 'US',
                state: '',
                zip: '',
                phone: '',
                fax: '',
                unlimitedcoverage: '',
                type: '',
                amountperoccurrence: null,
                amountaggregate: null,
                tailnosecoverage: '',
                individualcoverage: '',
                selfinsured: '',
                institutionaffiliation: '',
              })
            }
          >
            Add Policy
          </Button>
        </Group>
        <Group mt="md">
          <Button id="save-liability" type="submit" loading={isSubmitting}>
            Save
          </Button>
        </Group>
      </form>
    </Container>
  );
};

export default LiabilityForm;
