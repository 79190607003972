import { Button, Group, Text, Radio } from '@mantine/core';

function WorkSituationScreen({ currentStep, answers, setAnswers, back, next }) {
  const key = currentStep + '.workSituation';

  return (
    <>
      <Text size="lg">
        Which of these best describes your current work situation?
      </Text>

      <Group justify="flex-start" mt="xl">
        <Radio.Group
          value={answers[key] || ''}
          onChange={(newValue) =>
            setAnswers((values) => ({ ...values, [key]: newValue }))
          }
        >
          <Radio
            value="owner"
            label="I am an owner/co-owner of a medical practice"
            m={10}
          />
          <Radio
            value="non_owner"
            label="I am a non-owner partner or employee of a medical practice"
            m={10}
          />
          <Radio
            value="independent_1099"
            label="I am an independent contractor"
            m={10}
          />
          <Radio
            value="non_clinical"
            label="I work but not in a clinical capacity"
            m={10}
          />
        </Radio.Group>
      </Group>

      <Group justify="flex-end" mt="xl">
        <Button
          variant="default"
          onClick={async () => {
            await setAnswers((answers) => {
              delete answers[key];
              return { ...answers };
            });
            back();
          }}
        >
          Back
        </Button>
        <Button onClick={() => next()} disabled={!answers[key]}>
          Next
        </Button>
      </Group>
    </>
  );
}

export default WorkSituationScreen;
