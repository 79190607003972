import React from 'react';
import {
  Text,
  TextInput,
  Button,
  Grid,
  Select,
  rem,
  InputBase,
} from '@mantine/core';
import { Controller, useForm } from 'react-hook-form';
import { IMaskInput } from 'react-imask';

interface LLCInfoScreenProps {
  answers: any;
  setAnswers: (answers: any) => void;
  next: () => void;
  back: () => void;
}

const LLCInfoScreen: React.FC<LLCInfoScreenProps> = ({
  answers,
  setAnswers,
  next,
  back,
}) => {
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      llc_name: answers.llc_name || '',
      taxid: '',
      taxid_type: 'EIN',
    },
  });

  const tinEinType = watch('taxid_type');

  const onSubmit = (data: any) => {
    setAnswers({ ...answers, ...data });
    next();
  };

  return (
    <>
      <Text size="xl" mb="md">
        Business Entity Information
      </Text>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid gutter="md">
          <Grid.Col span={6}>
            <Controller
              name="llc_name"
              control={control}
              rules={{ required: 'Legal Business Name is required' }}
              render={({ field, fieldState: { error } }) => (
                <TextInput
                  {...field}
                  label="What is the name of your Business entity?"
                  error={error?.message}
                  required
                />
              )}
            />
          </Grid.Col>

          <Grid.Col span={6}>
            <Controller
              name="taxid"
              control={control}
              render={({ field }) => (
                <InputBase
                  {...field}
                  label="Please enter your Tax ID (Select EIN or TIN)"
                  required
                  component={IMaskInput}
                  mask={tinEinType === 'EIN' ? '00-0000000' : '000-00-0000'}
                  placeholder={
                    tinEinType === 'EIN' ? 'XX-XXXXXXX' : 'XXX-XX-XXXX'
                  }
                  leftSectionWidth={95} // make select value appear enough to the right
                  leftSection={
                    <Controller
                      name="taxid_type"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          data={[
                            { value: 'EIN', label: 'EIN' },
                            { value: 'TIN', label: 'TIN' },
                          ]}
                          variant="unstyled"
                          styles={{
                            input: {
                              paddingRight: 0,
                              textAlign: 'center',
                            },
                          }}
                          onChange={(value) => {
                            if (value && value !== tinEinType) {
                              setValue('taxid_type', value, {
                                shouldValidate: true,
                              });
                            }
                          }}
                        />
                      )}
                    />
                  }
                  styles={{
                    input: { paddingLeft: rem(100) }, // avoid text overlap with select
                  }}
                />
              )}
            />
          </Grid.Col>
        </Grid>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: rem(20),
          }}
        >
          <Button variant="default" onClick={back}>
            Back
          </Button>
          <Button type="submit" style={{ marginLeft: rem(10) }}>
            Next
          </Button>
        </div>
      </form>
    </>
  );
};

export default LLCInfoScreen;
