import React, { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  TextInput,
  Container,
  Select,
  Input,
  Grid,
  Title,
  Paper,
  Text,
  PasswordInput,
  Textarea,
  Group,
} from '@mantine/core';
import { STATES_ARRAY } from '../../types/members.types';
import { IMaskInput } from 'react-imask';
import useHttpClient from '../hooks/useHttpClient';
import { useNavigate } from 'react-router-dom';
import PasswordStrengthBar from 'react-password-strength-bar';
import { loadScript } from '../../helpers/loadScript';

interface PracticeRegistrationFormProps {
  hooks: any;
}

// This is a modified copy ClinicForm
const PracticeRegistrationForm: React.FC<PracticeRegistrationFormProps> = ({
  hooks,
}) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    trigger,
    formState: { errors, isDirty, dirtyFields },
  } = hooks;
  // do not use useFormDirtyAlert here

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [passwordStrengthScore, setPasswordStrengthScore] = useState(0);
  const [isPasswordTouched, setIsPasswordTouched] = useState(false);
  const httpClient = useHttpClient();
  const navigate = useNavigate();

  const password = watch('password'); // Watch password for confirmation validation
  const addressRef = useRef<HTMLInputElement>(null);

  // Re-validate password when strength score changes
  useEffect(() => {
    if (isPasswordTouched && password) {
      hooks.trigger('password');
    }
  }, [passwordStrengthScore, hooks, password, isPasswordTouched]);

  // Expose passwordStrengthScore to parent component
  useEffect(() => {
    hooks.setValue('_passwordStrengthScore', passwordStrengthScore, {
      shouldValidate: false,
    });
  }, [passwordStrengthScore, hooks]);

  useEffect(() => {
    let autocomplete: google.maps.places.Autocomplete | null = null;

    const handlePlaceSelect = async () => {
      if (!autocomplete) return;

      const place = autocomplete.getPlace();

      // Reset fields before handling the selected place
      setValue('address', '');
      setValue('street1', '');
      setValue('street2', '');
      setValue('city', '');
      setValue('state', '');
      setValue('zip', '');
      setValue('county', '');

      if (!place.address_components) {
        alert('Please select a complete address with a street number.');
        return;
      }

      let hasStreetNumber = false;
      place.address_components.forEach((component) => {
        if (component.types.includes('street_number')) {
          hasStreetNumber = true;
        }
      });

      if (!hasStreetNumber) {
        alert('Please select an address that includes a street number.');
        return;
      }

      let streetNumber = '';
      let route = '';
      let street2 = '';
      let city = '';
      let state = '';
      let zipCode = '';
      let county = '';

      place.address_components.forEach((component) => {
        const types = component.types;
        if (types.includes('street_number')) streetNumber = component.long_name;
        if (types.includes('route')) route = component.long_name;
        if (types.includes('subpremise')) street2 = component.long_name;
        if (types.includes('locality')) city = component.long_name;
        else if (types.includes('sublocality')) city = component.long_name;
        if (types.includes('administrative_area_level_1'))
          state = component.short_name;
        if (types.includes('postal_code')) zipCode = component.long_name;
        if (types.includes('administrative_area_level_2'))
          county = component.long_name.replace(' County', '');
      });

      const street1Value = `${streetNumber} ${route}`.trim();

      // Update the form fields and manually trigger validation only for address fields
      setValue('street1', street1Value, { shouldDirty: true });
      setValue('street2', street2, { shouldDirty: true });
      setValue('city', city, { shouldDirty: true });
      setValue('state', state, { shouldDirty: true });
      setValue('zip', zipCode, { shouldDirty: true });
      setValue('county', county, { shouldDirty: true });

      // Only trigger validation for address-related fields
      await trigger(['street1', 'street2', 'city', 'state', 'zip', 'county']);
    };

    const initAutocomplete = () => {
      if (addressRef.current && window.google) {
        autocomplete = new window.google.maps.places.Autocomplete(
          addressRef.current,
          {
            types: ['address'],
            componentRestrictions: { country: 'us' },
          },
        );
        autocomplete.addListener('place_changed', handlePlaceSelect);
      }
    };

    const googleMapsScriptUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
    loadScript(googleMapsScriptUrl)
      .then(initAutocomplete)
      .catch((err) => console.error('Failed to load Google Maps script:', err));

    return () => {
      if (autocomplete) {
        google.maps.event.clearInstanceListeners(autocomplete);
      }
    };
  }, [setValue, trigger]);

  return (
    <Group justify="center">
      <Container mt={0} mb="xl">
        <form
          //   onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        >
          <>
            <Paper withBorder shadow="xs" p="md" mt="md">
              <Title order={3} mb="sm">
                Business Information
              </Title>
              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    label="Business Name"
                    required
                    disabled={loading}
                    error={errors.name?.message}
                    {...register('name', {
                      required: 'This field is required.',
                    })}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Practice Website"
                    disabled={loading}
                    {...register('practicewebsite', {})}
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={6}>
                  <Controller
                    name="tin"
                    control={control}
                    rules={{
                      required: 'TIN is required',
                      validate: (value) =>
                        /^\d{2}-\d{7}$/.test(value) ||
                        'Invalid TIN format. Use XX-XXXXXXX',
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <Input.Wrapper
                          label="Tax Identification Number"
                          required
                        >
                          <Input
                            component={IMaskInput}
                            {...field}
                            mask="00-0000000" // TIN format
                            placeholder="XX-XXXXXXX"
                            disabled={loading}
                            error={errors.tin?.message}
                          />
                        </Input.Wrapper>
                        {error && (
                          <span style={{ color: 'red', fontSize: '0.8em' }}>
                            {error.message}
                          </span>
                        )}
                      </>
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Type 2 Organization NPI"
                    maxLength={10}
                    disabled={loading}
                    {...register('npi')}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.target.value = e.target.value.replace(/\D/g, ''); // Replace any non-numeric characters
                    }}
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    label="Estimated Total Number of Employees"
                    type="number"
                    disabled={loading}
                    required
                    min={0}
                    {...register('estimatedemployees', {
                      valueAsNumber: true,
                      min: { value: 0, message: 'Must be a positive number' },
                    })}
                    error={errors.estimatedemployees?.message?.toString()}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Estimated Number of Employees Joining Ryze"
                    type="number"
                    disabled={loading}
                    required
                    min={0}
                    {...register('estimatedjoiningemployees', {
                      valueAsNumber: true,
                      min: { value: 0, message: 'Must be a positive number' },
                    })}
                    error={errors.estimatedjoiningemployees?.message?.toString()}
                  />
                </Grid.Col>
              </Grid>
            </Paper>

            <Paper withBorder shadow="xs" p="md" mt="md">
              <Title order={3}>Address Information</Title>

              <Grid>
                <Grid.Col span={12}>
                  <TextInput
                    label={
                      '\u0410ddress - Start typing your address below and pick one from the dropdown'
                    }
                    ref={addressRef}
                    error={errors.street1?.message}
                    placeholder="Start by typing here"
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label={'\u0405treet 1'}
                    {...register('street1')}
                    disabled
                    required
                    error={errors.street1?.message}
                    {...register('street1', {
                      required: 'This field is required.',
                    })}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label={'\u0405treet 2'}
                    {...register('street2')}
                    disabled={loading}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="City"
                    {...register('city')}
                    disabled
                    readOnly
                    required
                    error={errors.city?.message}
                    {...register('city', {
                      required: 'This field is required.',
                    })}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="State"
                        data={STATES_ARRAY}
                        clearable
                        required
                        {...register('state', {
                          required: 'This field is required.',
                        })}
                        searchable
                        disabled
                        readOnly
                        error={errors.state?.message}
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="ZIP"
                    required
                    {...register('zip')}
                    disabled
                    readOnly
                    maxLength={5}
                    error={errors.zip?.message}
                    {...register('zip', {
                      required: 'This field is required.',
                    })}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="County"
                    required
                    {...register('county')}
                    disabled
                    readOnly
                    error={errors.county?.message}
                    {...register('county', {
                      required: 'This field is required.',
                    })}
                  />
                </Grid.Col>
              </Grid>
            </Paper>

            <Paper withBorder shadow="xs" p="md" mt="md">
              <Title order={3}>Tell us about yourself</Title>
              <Text mt="xs" size="sm">
                You will be our main contact at the practice. You can update
                this information later and add other users from your
                organization later
              </Text>
              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    label="First Name"
                    required
                    disabled={loading}
                    {...register('adminfirstname', {
                      required: 'This field is required',
                    })}
                    error={errors.adminfirstname?.message}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Last Name"
                    required
                    disabled={loading}
                    {...register('adminlastname', {
                      required: 'This field is required',
                    })}
                    error={errors.adminlastname?.message}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="adminphone"
                    control={control}
                    render={({ field }) => (
                      <Input.Wrapper label="Phone Number">
                        <Input
                          component={IMaskInput}
                          {...field}
                          mask="(000) 000-0000"
                          placeholder="(XXX) XXX-XXXX"
                          disabled={loading}
                        />
                      </Input.Wrapper>
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <TextInput
                    label="Title at Practice"
                    required
                    disabled={loading}
                    {...register('admintitle', {
                      required: 'This field is required',
                    })}
                    error={errors.admintitle?.message}
                  />
                </Grid.Col>
              </Grid>
            </Paper>

            <Paper withBorder shadow="xs" p="md" mt="md">
              <Title order={3}>Login Information</Title>
              <Text mt="xs" size="sm">
                This information will be used to create your account. Please
                choose a password of at least "Good" strength.
              </Text>
              <Grid>
                <Grid.Col span={12}>
                  <TextInput
                    label="Email"
                    required
                    disabled={loading}
                    {...register('email', {
                      required: 'This field is required',
                    })}
                    error={errors.email?.message}
                  />
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col span={6}>
                  <PasswordInput
                    label="Password"
                    required
                    disabled={loading}
                    visible={showPassword}
                    onVisibilityChange={setShowPassword}
                    maxLength={50}
                    onFocus={() => setIsPasswordTouched(true)}
                    onPaste={() => {
                      setIsPasswordTouched(true);
                      // Add a small delay to allow PasswordStrengthBar to update before validation
                      setTimeout(() => hooks.trigger('password'), 100);
                    }}
                    {...register('password', {
                      required: 'This field is required',
                      validate: (value) => {
                        // If password is not touched yet, don't show error
                        if (!isPasswordTouched) return true;
                        // Otherwise validate based on strength
                        return (
                          passwordStrengthScore >= 3 ||
                          'Password strength must be good or strong'
                        );
                      },
                    })}
                    error={errors.password?.message}
                  />
                  <PasswordStrengthBar
                    password={password}
                    onChangeScore={(score) => {
                      setPasswordStrengthScore(score);
                      // Re-validate after a small delay to ensure the score is updated
                      if (isPasswordTouched && password) {
                        setTimeout(() => hooks.trigger('password'), 50);
                      }
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <PasswordInput
                    label="Confirm Password"
                    required
                    disabled={loading}
                    visible={showPassword}
                    onVisibilityChange={setShowPassword}
                    {...register('passwordConfirmation', {
                      required: 'Password confirmation is required',
                      validate: (value) =>
                        value === password || 'Passwords do not match',
                    })}
                    error={errors.passwordConfirmation?.message?.toString()}
                  />
                </Grid.Col>
              </Grid>
            </Paper>
            <Paper withBorder shadow="xs" p="md" mt="md">
              <Title order={3} mb="sm">
                Referral Information
              </Title>

              <Grid>
                <Grid.Col span={6}>
                  <Controller
                    name="referralsource"
                    control={control}
                    rules={{
                      required: 'Please select how you heard about us',
                    }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="How did you hear about us?"
                        placeholder="Select an option"
                        data={[
                          { value: 'RPPA', label: 'RPPA' },
                          { value: 'Wecare Event', label: 'Wecare Event' },
                          { value: 'MSSNY', label: 'MSSNY' },
                          { value: 'ACP', label: 'ACP' },
                          { value: 'PIMD', label: 'PIMD' },
                          { value: 'Other', label: 'Other (Please specify)' },
                        ]}
                        clearable
                        required
                        disabled={loading}
                        error={errors.referralsource?.message}
                      />
                    )}
                  />
                </Grid.Col>

                {/* Conditionally render the text input when "Other" is selected */}
                {watch('referralsource') === 'Other' && (
                  <Grid.Col span={6}>
                    <TextInput
                      label="Please specify"
                      placeholder="Enter the source"
                      {...register('referralsourcecustom', {
                        required: 'This field is required',
                      })}
                      required
                      disabled={loading}
                    />
                  </Grid.Col>
                )}
              </Grid>
            </Paper>
            <Paper withBorder shadow="xs" p="md" mt="md">
              <Title order={3}>Questions or Comments</Title>
              <Text mt="xs" size="sm">
                If you have any questions, comments, concerns about your
                registration or Ryze Health, or simply want to share your
                thoughts, feel free to leave us a message below.
              </Text>
              <Textarea
                placeholder="Please write any questions or comments you have here..."
                minRows={4}
                disabled={loading}
                {...register('message')}
              />
            </Paper>
          </>
        </form>
      </Container>
    </Group>
  );
};

export default PracticeRegistrationForm;
