import {
  ReactNode,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
} from 'react';
import {
  Button,
  Stepper,
  Container,
  Title,
  Paper,
  Grid,
  Group,
} from '@mantine/core';
import {
  IconUserCheck,
  IconFriends,
  IconInbox,
  IconNotebook,
  IconX,
  IconChevronLeft,
  IconChevronRight,
  IconRosetteDiscountCheckFilled,
  IconUser,
} from '@tabler/icons-react';
import { useLocation } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import classes from './RegistrationSteppers.module.css';
import useHttpClient from '../hooks/useHttpClient';

interface StepperContextType {
  stepsDone: boolean[];
  markStepAsDone: (tabKey?: string, done?: boolean) => void;
  enabled: boolean;
  setEnabled: (enbale: boolean) => void;
  activeStep: number;
  setActiveStep: (
    step: number | ((current: number) => number) | string,
  ) => void;
  subMenu: any;
}

const StepperContext = createContext<StepperContextType | undefined>(undefined);

export const StepperProvider: React.FC<{
  children: ReactNode;
  totalSteps: number;
  subMenu: any;
}> = ({ children, totalSteps, subMenu }) => {
  // Update the initialization of stepsDone to properly handle the current metadata structure
  const [stepsDone, setStepsDone] = useState<boolean[]>(() => {
    try {
      const userMetadata = JSON.parse(
        localStorage.getItem('userMetadata') || '{}',
      );

      // Determine timeline type from the path
      const path = window.location.pathname;
      let timelineType = 'member'; // default

      if (path.includes('/clinic/employee')) {
        timelineType = 'employee';
      } else if (path.includes('/clinic')) {
        timelineType = 'clinic';
      } else if (path.includes('/employee')) {
        timelineType = 'employee';
      } else if (path.includes('/insurance/member')) {
        timelineType = 'member';
      }

      // Check timeline-specific stepsDone first (new format)
      if (
        userMetadata?.timelineProgress?.[timelineType]?.stepsDone &&
        Array.isArray(userMetadata.timelineProgress[timelineType].stepsDone)
      ) {
        return userMetadata.timelineProgress[timelineType].stepsDone;
      }

      return Array(totalSteps).fill(false);
    } catch (e) {
      console.error('Error initializing stepsDone:', e);
      return Array(totalSteps).fill(false);
    }
  });
  const [enabled, setEnabled] = useState(true);
  const [activeStep, setActiveStep] = useState<number>(0);
  const httpClient = useHttpClient();
  const isInitialLoad = useRef(true);

  /**
   * Marks a step in the stepper as done (showing a check mark on the icon), and optionally validates the step.
   *
   * @param tabKey - The key of the tab to mark as done. If provided, the function also performs validation for that step.
   */
  const markStepAsDone = async (tabKey?: string, done = true) => {
    const keys = subMenu.map((m) => m.key);
    const stepIndex = tabKey ? keys.indexOf(tabKey) : activeStep;

    try {
      const { data: userMetadataResponse } =
        await httpClient.get(`/api/user/metadata`);

      const userMetadata = userMetadataResponse.metadata
        ? userMetadataResponse.metadata
        : {};

      // Initialize the timeline specific progress tracking if not present
      if (!userMetadata.timelineProgress) {
        userMetadata.timelineProgress = {
          clinic: { ts: [], stepsDone: Array(totalSteps).fill(false) },
          member: { ts: [], stepsDone: Array(totalSteps).fill(false) },
          employee: { ts: [], stepsDone: Array(totalSteps).fill(false) },
        };
      }

      // Determine timeline type from the path
      const path = window.location.pathname;
      let timelineType = 'member'; // default

      // Handle special case for /insurance/clinic/employee path
      if (path.includes('/clinic/employee')) {
        timelineType = 'employee';
      } else if (path.includes('/clinic')) {
        timelineType = 'clinic';
      } else if (path.includes('/employee')) {
        timelineType = 'employee';
      } else if (path.includes('/insurance/member')) {
        timelineType = 'member';
      }

      // Ensure the specific timeline progress exists
      if (!userMetadata.timelineProgress[timelineType]) {
        userMetadata.timelineProgress[timelineType] = {
          ts: [],
          stepsDone: Array(totalSteps).fill(false),
        };
      }

      // Update the specific timeline progress
      const timeline = userMetadata.timelineProgress[timelineType];
      timeline.ts = timeline.ts || [];
      timeline.stepsDone = timeline.stepsDone || Array(totalSteps).fill(false);

      // Check if this step is being marked as done and if we should allow it
      if (done) {
        // Only proceed if we're marking as done AND all previous steps are completed
        const currentIndex = stepIndex;

        // If we're not skipping validation, check all previous steps to see if they're completed
        // if (!subMenu?.allowJumpingBetweenForms) {
        //   const allPreviousStepsCompleted =
        //     currentIndex === 0 ||
        //     timeline.stepsDone
        //       .slice(0, currentIndex)
        //       .every((stepDone) => stepDone === true);

        //   // If not all previous steps are completed, show notification and return early
        //   if (!allPreviousStepsCompleted && currentIndex > 0) {
        //     notifications.show({
        //       title: 'Cannot Complete Step',
        //       message: 'You must complete all previous steps first.',
        //       color: 'red',
        //       position: 'bottom-center',
        //     });
        //     return;
        //   }
        // }
      }

      if (timeline.stepsDone[stepIndex] !== done && done) {
        timeline.ts[stepIndex] = new Date().getTime();
      }

      timeline.stepsDone[stepIndex] = done;

      localStorage.setItem('userMetadata', JSON.stringify(userMetadata));

      await httpClient.put(`/api/user/metadata`, {
        userMetadata,
      });

      setStepsDone((prev) => {
        const currentSteps = Array.isArray(prev)
          ? prev
          : Array(totalSteps).fill(false);
        const newStepsDone = [...currentSteps];
        newStepsDone[stepIndex] = done;
        return newStepsDone;
      });
    } catch (e) {
      console.log('Error updating step status:', e);
    }
  };

  const location = useLocation();

  // Handle URL hash changes and sync active step
  useEffect(() => {
    const hash = window.location.hash.replace('#', '');
    const idx = subMenu.findIndex((m) => m.key === hash);
    const newActiveStep = idx < 0 ? 0 : idx;
    setActiveStep(newActiveStep);

    // If this is initial load, let's sync our current step from the server/localStorage
    if (isInitialLoad.current) {
      isInitialLoad.current = false;

      // This helps ensure we're displaying the correct completion state from storage
      const syncStepStatus = async () => {
        try {
          const { data: userMetadataResponse } =
            await httpClient.get(`/api/user/metadata`);
          const userMetadata = userMetadataResponse.metadata || {};

          // Determine timeline type from the path
          const path = window.location.pathname;
          let timelineType = 'member'; // default

          if (path.includes('/clinic/employee')) {
            timelineType = 'employee';
          } else if (path.includes('/clinic')) {
            timelineType = 'clinic';
          } else if (path.includes('/employee')) {
            timelineType = 'employee';
          } else if (path.includes('/insurance/member')) {
            timelineType = 'member';
          }

          if (
            userMetadata.timelineProgress &&
            userMetadata.timelineProgress[timelineType] &&
            userMetadata.timelineProgress[timelineType].stepsDone
          ) {
            setStepsDone(userMetadata.timelineProgress[timelineType].stepsDone);
          }
        } catch (e) {
          console.log('Error syncing step status:', e);
        }
      };

      syncStepStatus();
    }
  }, [location, subMenu]);

  return (
    <StepperContext.Provider
      value={{
        stepsDone,
        enabled,
        setEnabled,
        markStepAsDone,
        activeStep,
        setActiveStep,
        subMenu,
      }}
    >
      {children}
    </StepperContext.Provider>
  );
};

interface RegStepperProps {
  activeStep?: number;
}

export const useStepper = () => {
  const context = useContext(StepperContext);
  if (!context)
    throw new Error('useStepper must be used within a StepperProvider');
  return context;
};

interface NavButtonProps {
  enabled?: boolean;
  validationCallback?: () => Promise<boolean>;
  onClick?: () => Promise<boolean | void> | void;
}

export const PrevStepButton: React.FC<NavButtonProps> = ({
  enabled = false,
  onClick,
}) => {
  const { activeStep, subMenu } = useStepper();

  const prevStep = () => {
    window.location.hash = subMenu[activeStep - 1].key;
  };

  return (
    <Button
      disabled={!enabled || activeStep == 0}
      variant="default"
      onClick={async () => {
        if (onClick) {
          const shouldContinue = await Promise.resolve(onClick());
          if (shouldContinue !== false) {
            prevStep();
          }
        } else {
          prevStep();
        }
      }}
      className={classes.prevStepButton}
      color="blue"
      size="lg"
    >
      <IconChevronLeft /> Back
    </Button>
  );
};

export const NextStepButton: React.FC<NavButtonProps> = ({
  enabled = true,
  validationCallback,
  onClick,
}) => {
  const { stepsDone, activeStep, subMenu, markStepAsDone } = useStepper();

  const nextStep = async () => {
    if (subMenu?.allowJumpingBetweenForms) {
      window.location.hash = subMenu[activeStep + 1].key;
      return true;
    }

    try {
      if (validationCallback && !(await validationCallback())) {
        notifications.show({
          title: 'Incomplete Information',
          message: 'Please complete all required fields before proceeding.',
          color: 'red',
          position: 'bottom-center',
        });
        return false;
      } else {
        // // Mark the current step as done before proceeding
        // await markStepAsDone(subMenu[activeStep].key, true);
        window.location.hash = subMenu[activeStep + 1].key;
        return true;
      }
    } catch (e) {
      notifications.show({
        title: 'Incomplete Information',
        message: e,
        color: 'red',
        position: 'bottom-center',
      });
      return false;
    }
  };

  return (
    <Button
      disabled={!enabled}
      onClick={async () => {
        if (onClick) {
          const shouldContinue = await Promise.resolve(onClick());
          if (shouldContinue !== false) {
            await nextStep();
          }
        } else {
          await nextStep();
        }
      }}
      className={classes.nextStepButton}
      size="lg"
    >
      Next <IconChevronRight />
    </Button>
  );
};

export const CompleteStepButton: React.FC<NavButtonProps> = ({
  enabled = true,
  onClick,
}) => {
  const { markStepAsDone, activeStep, subMenu } = useStepper();

  return (
    <Button
      color="lime"
      disabled={!enabled}
      onClick={async () => {
        // // Mark the current step as done when completing
        // await markStepAsDone(subMenu[activeStep].key, true);
        if (onClick) {
          onClick();
        }
      }}
      className={classes.completeRegButton}
      size="lg"
    >
      <IconRosetteDiscountCheckFilled />
      &nbsp;Complete
    </Button>
  );
};

interface ButtonContainernProps {
  children: ReactNode;
}

export const ButtonContainer: React.FC<ButtonContainernProps> = ({
  children,
}) => {
  const groupRef = useRef(null);

  return (
    <div className={classes.buttonContainer}>
      <Container pt="16" pb="0">
        <Group ref={groupRef} justify="space-between">
          {children}
        </Group>
      </Container>
    </div>
  );
};

export const IndependentPhysRegStepper: React.FC<RegStepperProps> = () => {
  const { enabled, stepsDone, activeStep, subMenu } = useStepper();

  return (
    <div className={classes.stepperDiv}>
      <Stepper
        active={activeStep}
        onStepClick={(step) => {
          if (enabled && subMenu?.allowJumpingBetweenForms) {
            window.location.hash = subMenu[step].key;
          }
        }}
      >
        <Stepper.Step
          completedIcon={activeStep > 0 && stepsDone[0] ? null : <IconX />}
          color={activeStep > 0 && !stepsDone[0] ? 'red' : 'blue'}
          icon={<IconUserCheck />}
        />
        <Stepper.Step
          completedIcon={activeStep > 0 && stepsDone[1] ? null : <IconX />}
          color={activeStep > 1 && !stepsDone[1] ? 'red' : 'blue'}
          icon={<IconFriends />}
        />
        <Stepper.Step
          completedIcon={activeStep > 1 && stepsDone[2] ? null : <IconX />}
          color={activeStep > 2 && !stepsDone[2] ? 'red' : 'blue'}
          icon={<IconInbox />}
        />
        <Stepper.Step
          completedIcon={activeStep > 2 && stepsDone[3] ? null : <IconX />}
          color={activeStep > 3 && !stepsDone[3] ? 'red' : 'blue'}
          icon={<IconNotebook />}
        />
        {/* <Stepper.Step
          completedIcon={activeStep > 4 && stepsDone[4] ? null : <IconX />}
          color={activeStep > 4 && !stepsDone[4] ? 'red' : 'blue'}
          icon={<IconBuildingBank />}
        /> */}
        <Stepper.Completed>
          <Paper withBorder shadow="xs" p="md" w="full">
            <Grid justify="space-between" align="center">
              <Grid.Col span={11}>
                <Title order={3}>Thank you for Registering...</Title>
              </Grid.Col>
            </Grid>
          </Paper>
        </Stepper.Completed>
      </Stepper>
    </div>
  );
};

export const EmployeeRegistrationStepper: React.FC<RegStepperProps> = () => {
  const { enabled, stepsDone, activeStep, subMenu } = useStepper();

  return (
    <div className={classes.stepperDiv}>
      <Stepper
        active={activeStep}
        onStepClick={(step) => {
          if (enabled && subMenu?.allowJumpingBetweenForms) {
            window.location.hash = subMenu[step].key;
          }
        }}
      >
        <Stepper.Step
          completedIcon={activeStep > 0 && stepsDone[0] ? null : <IconX />}
          color={activeStep > 0 && !stepsDone[0] ? 'red' : 'blue'}
          icon={<IconUserCheck />}
        />
        <Stepper.Step
          completedIcon={activeStep > 1 && stepsDone[1] ? null : <IconX />}
          color={activeStep > 1 && !stepsDone[1] ? 'red' : 'blue'}
          icon={<IconFriends />}
        />
        <Stepper.Step
          completedIcon={activeStep > 2 && stepsDone[2] ? null : <IconX />}
          color={activeStep > 2 && !stepsDone[2] ? 'red' : 'blue'}
          icon={<IconNotebook />}
        />
        {/* <Stepper.Step
          completedIcon={activeStep > 3 && stepsDone[3] ? null : <IconX />}
          color={activeStep > 3 && !stepsDone[3] ? 'red' : 'blue'}
          icon={<IconNotebook />}
        /> */}
        <Stepper.Completed>
          <Paper withBorder shadow="xs" p="md" w="full">
            <Grid justify="space-between" align="center">
              <Grid.Col span={11}>
                <Title order={3}>Review your information...</Title>
              </Grid.Col>
            </Grid>
            Click back button to get to previous step
          </Paper>
        </Stepper.Completed>
      </Stepper>
    </div>
  );
};

export const ClinicRegistrationStepper: React.FC<RegStepperProps> = () => {
  const { enabled, stepsDone, activeStep, subMenu } = useStepper();

  return (
    <div className={classes.stepperDiv}>
      <Stepper
        active={activeStep}
        onStepClick={(step) => {
          if (enabled && subMenu?.allowJumpingBetweenForms) {
            window.location.hash = subMenu[step].key;
          }
        }}
      >
        <Stepper.Step
          completedIcon={activeStep > 0 && stepsDone[0] ? null : <IconX />}
          color={activeStep > 0 && !stepsDone[0] ? 'red' : 'blue'}
          icon={<IconUser />}
        />
        <Stepper.Step
          completedIcon={activeStep > 1 && stepsDone[1] ? null : <IconX />}
          color={activeStep > 1 && !stepsDone[1] ? 'red' : 'blue'}
          icon={<IconFriends />}
        />
        <Stepper.Step
          completedIcon={activeStep > 1 && stepsDone[2] ? null : <IconX />}
          color={activeStep > 1 && !stepsDone[2] ? 'red' : 'blue'}
          icon={<IconUserCheck />}
        />
        <Stepper.Step
          completedIcon={activeStep > 1 && stepsDone[3] ? null : <IconX />}
          color={activeStep > 1 && !stepsDone[3] ? 'red' : 'blue'}
          icon={<IconInbox />}
        />
        {/* <Stepper.Step
          completedIcon={activeStep > 2 && stepsDone[4] ? null : <IconX />}
          color={activeStep > 2 && !stepsDone[4] ? 'red' : 'blue'}
          icon={<IconBuildingBank />}
        /> */}
        <Stepper.Completed>
          <Paper withBorder shadow="xs" p="md" w="full">
            <Grid justify="space-between" align="center">
              <Grid.Col span={11}>
                <Title order={3}>Review your information...</Title>
              </Grid.Col>
            </Grid>
            Click back button to get to previous step
          </Paper>
        </Stepper.Completed>
      </Stepper>
    </div>
  );
};
