import { Button, Group, Modal, Table, TextInput } from '@mantine/core';
import { useEffect, useState } from 'react';
import { Member } from '../types/members.types';
import useHttpClient from './hooks/useHttpClient';

interface ArchivedMembersModalProps {
  opened: boolean;
  onClose: () => void;
}

const ArchivedMembersModal = ({
  opened,
  onClose,
}: ArchivedMembersModalProps) => {
  const [archivedMembers, setArchivedMembers] = useState<Member[]>([]);
  const [searchArchivedMember, setsearchArchivedMember] = useState('');
  const httpClient = useHttpClient();
  const [restoringMemberId, setRestoringMemberId] = useState<number | null>(
    null,
  );

  const fetchArchivedMembers = async () => {
    try {
      const response = await httpClient.get<Member[]>('/api/members/archived');
      setArchivedMembers(response.data);
    } catch (error) {
      console.error('Error fetching members', error);
    }
  };

  useEffect(() => {
    fetchArchivedMembers();
  }, [opened]);

  const filteredArchivedMembers = archivedMembers
    .filter((member) =>
      `${member.firstname} ${member.lastname} ${member.npi}`
        .toLowerCase()
        .includes(searchArchivedMember.toLowerCase()),
    )
    .sort((a, b) => {
      if (a.lastname > b.lastname) return 1;
      if (a.lastname < b.lastname) return -1;
      if (a.firstname > b.firstname) return 1;
      if (a.firstname < b.firstname) return -1;
      return 0;
    });

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setsearchArchivedMember(event.target.value);
  };

  const handleArchivedMemberRestore = async (memberId: number) => {
    try {
      setRestoringMemberId(memberId);

      const response = await httpClient.patch(
        `/api/members/${memberId}/restore`,
      );

      if (response.status === 200) {
        setArchivedMembers((prevMembers) =>
          prevMembers.filter((member) => member.id !== memberId),
        );
      }
    } catch (error) {
      console.error('Error restoring member', error);
    } finally {
      // Reset the loading state after the operation completes
      setRestoringMemberId(null);
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="ARCHIVED MEMBERS"
      centered
      closeOnClickOutside={restoringMemberId === null}
      closeOnEscape={restoringMemberId === null}
      withCloseButton={restoringMemberId === null}
      size="lg"
      styles={{
        body: {
          height: '500px', // Fixed height for the modal body
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Group mb={'md'}>
        <TextInput
          placeholder="Search members"
          value={searchArchivedMember}
          onChange={handleSearch}
        />
      </Group>
      <Table className="ryze-list table-striped table-highlight">
        <thead style={{ textAlign: 'left' }}>
          <tr>
            <th>Name</th>
            <th>NPI</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredArchivedMembers.map((member) => (
            <tr key={member.id}>
              <td>{`${member.firstname ?? ''} ${member.lastname ?? ''}`}</td>
              <td>{member.npi}</td>
              <td>
                <Button
                  disabled={restoringMemberId !== null}
                  loading={restoringMemberId === member.id}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleArchivedMemberRestore(member.id);
                  }}
                >
                  Restore
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Modal>
  );
};

export default ArchivedMembersModal;
