import { Button, Group, Text, Radio } from '@mantine/core';

function PracticingOwnTempScreen({ currentStep, answers, setAnswers, back, next }) {
  const key = currentStep + '.tempAction';

  return (
    <>
      <Text size="lg">
        Thank you for your interest! <br />
        <Text color="blue" fw={700}>
          Ryze is now taking applications for physicians and medical practices
        </Text>
      </Text>

      <Group justify="flex-start" mt="xl">
        <Radio.Group
          label={<Text size="lg">I'd like to:</Text>}
          value={answers[key] || ''}
          onChange={(newValue) => setAnswers((values) => ({ ...values, [key]: newValue }))}
        >
            <Radio
              value="get_practice_quote"
              label="Get a Quote for My Practice (I am the decision maker)"
              m={10}
            />
            <Radio
              value="get_individual_quote"
              label="Get a Quote for Myself or My Family"
              m={10}
            />
            <Radio
              value="refer_my_practice"
              label="Refer My Practice to Apply (I cannot make this decision myself)"
              m={10}
            />
        </Radio.Group>
      </Group>

      <Group justify="flex-end" mt="xl">
        <Button
          variant="default"
          onClick={async () => {
            await setAnswers((answers) => {
              delete answers[key];
              return { ...answers };
            });
            back();
          }}
        >
          Back
        </Button>
        <Button
          onClick={() => {
            if (answers[key] === 'get_practice_quote') {
              next(0);
            } else if (answers[key] === 'get_individual_quote') {
              next(1);
            } else if (answers[key] === 'refer_my_practice') {
              next(2);
            }
          }}
          disabled={!answers[key]}
        >
          Next
        </Button>
      </Group>
    </>
  );
}

export default PracticingOwnTempScreen;