import React, { useEffect, useState } from 'react';
import {
  Container,
  Paper,
  Title,
  Text,
  Button,
  Grid,
  Group,
  TextInput,
  Collapse,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import useHttpClient from '../hooks/useHttpClient';
import { useNotifications } from '../NotificationContext';

interface Clinic {
  id: string;
  name: string;
  npi: string;
  message?: string;
  npimatch: string;
  street1: string;
  street2?: string;
  city: string;
  state: string;
  zip: string;
  county: string;
  tin: string;
  adminfirstname?: string;
  adminlastname?: string;
  email?: string;
  emailverification?: boolean;
  created_at: string; // Timestamp from DB
  answers?: Record<string, string>;
}

const InsuranceClinicApprovalPage: React.FC = () => {
  const [clinics, setClinics] = useState<Clinic[]>([]);
  const httpClient = useHttpClient();
  const [searchTerm, setSearchTerm] = useState('');
  const [loadingClinicId, setLoadingClinicId] = useState<string | null>(null);
  const [loadingAction, setLoadingAction] = useState<'approve' | 'deny' | null>(
    null,
  );
  const [expandedClinicIds, setExpandedClinicIds] = useState<Set<string>>(
    new Set(),
  );
  const [showAll, setShowAll] = useState(false);
  const { updatePendingClinicApprovals } = useNotifications();

  useEffect(() => {
    const fetchClinics = async () => {
      try {
        const response = await httpClient.get('/api/organization/approve');

        if (response.status === 200) {
          const data = response.data;

          // Sort by created_at descending (most recent first)
          const sortedData = data.sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime(),
          );

          setClinics(sortedData);
          // Update the notification count
          updatePendingClinicApprovals(sortedData.length);
        } else {
          notifications.show({
            title: 'Error',
            message: 'Failed to fetch clinics for approval.',
            color: 'red',
            position: 'bottom-center',
          });
        }
      } catch (error) {
        console.error('Error fetching clinics:', error);
        notifications.show({
          title: 'Error',
          message: 'An error occurred while fetching clinics.',
          color: 'red',
          position: 'bottom-center',
        });
      }
    };

    fetchClinics();
  }, [httpClient, updatePendingClinicApprovals]);

  // Function to format timestamp to MM/DD/YYYY
  const formatDate = (timestamp: string) => {
    return new Date(timestamp).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  };

  const formatAnswerKey = (key: string) => {
    const parts = key.split('.');
    const label = parts[parts.length - 1]
      .replace(/([A-Z])/g, ' $1') // insert space before capital letters
      .replace(/^./, (str) => str.toUpperCase()); // capitalize first letter
    return label;
  };

  // Function to handle approval or denial
  const handleAction = async (clinicId: string, approved: boolean) => {
    try {
      setLoadingClinicId(clinicId);
      setLoadingAction(approved ? 'approve' : 'deny');

      const response = await httpClient.put('/api/user/approve/clinic', {
        id: clinicId,
        approved,
        type: 'insurance_organization',
      });

      if (response.status === 200) {
        notifications.show({
          title: approved ? 'Clinic Approved' : 'Clinic Denied',
          message: approved
            ? 'Clinic has been approved successfully.'
            : 'Clinic has been denied.',
          color: approved ? 'green' : 'red',
          position: 'bottom-center',
        });

        // Remove the clinic from the list after action
        setClinics((prevClinics) => {
          const updatedClinics = prevClinics.filter(
            (clinic) => clinic.id !== clinicId,
          );
          // Update the notification count
          updatePendingClinicApprovals(updatedClinics.length);
          return updatedClinics;
        });
      } else {
        throw new Error('Action failed');
      }
    } catch (error) {
      console.error('Error performing action:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to update clinic status.',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setLoadingClinicId(null);
      setLoadingAction(null);
    }
  };

  const filteredClinics = clinics.filter((clinic) =>
    `${clinic.name} ${clinic.npi} ${clinic.adminfirstname} ${clinic.adminlastname} ${clinic.email}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase()),
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const toggleClinicExpansion = (clinicId: string) => {
    setExpandedClinicIds((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(clinicId)) {
        newSet.delete(clinicId);
      } else {
        newSet.add(clinicId);
      }
      return newSet;
    });
  };

  const toggleAll = () => {
    if (showAll) {
      setExpandedClinicIds(new Set());
    } else {
      setExpandedClinicIds(new Set(clinics.map((c) => c.id)));
    }
    setShowAll(!showAll);
  };

  return (
    <Container>
      <Group justify="space-between" mb={10}>
        <Title order={2} ta="center">
          Clinics Pending Approval
        </Title>
        <TextInput
          placeholder="Search clinics"
          value={searchTerm}
          onChange={handleSearch}
        />
      </Group>

      <Group justify="flex-end" mb={20}>
        <Button variant="light" onClick={toggleAll}>
          {showAll ? 'Hide Onboarding Answers' : 'Show Onboarding Answers'}
        </Button>
      </Group>
      {filteredClinics.length === 0 ? (
        <Title order={4} ta="center" mt={50} fw={500}>
          No clinics requiring approval.
        </Title>
      ) : (
        filteredClinics.map((clinic, index) => (
          <Paper key={index} withBorder shadow="sm" p="md" mb="md">
            <Grid>
              <Grid.Col span={6}>
                <Text>
                  <strong>Clinic Name:</strong> {clinic.name}
                </Text>
                <Text>
                  <strong>Address:</strong> {clinic.street1}{' '}
                  {clinic.street2 && `${clinic.street2}, `}
                  {clinic.city}, {clinic.state} {clinic.zip}
                </Text>
                <Text>
                  <strong>TIN:</strong> {clinic.tin}
                </Text>
                <Text>
                  <strong>Registration Date:</strong>{' '}
                  {formatDate(clinic.created_at)}
                </Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text>
                  <strong>NPI:</strong> {clinic.npi}
                </Text>
                <Text>
                  <strong>NPI Match:</strong>{' '}
                  <span
                    style={{
                      color: clinic.npimatch === 'match' ? 'green' : 'red',
                    }}
                  >
                    {clinic.npimatch}
                  </span>
                </Text>
                <Text>
                  <strong>Administrator:</strong> {clinic.adminfirstname || '-'}{' '}
                  {clinic.adminlastname || '-'}
                </Text>
                <Text>
                  <strong>Email:</strong> {clinic.email}{' '}
                  <span
                    style={{
                      color: clinic.emailverification ? 'green' : 'red',
                    }}
                  >
                    ({clinic.emailverification ? 'Verified' : 'Not Verified'})
                  </span>
                </Text>
              </Grid.Col>
            </Grid>
            {clinic.message && (
              <Text>
                <strong>Message:</strong> {clinic.message}
              </Text>
            )}
            {clinic.answers && (
              <>
                <Button
                  size="xs"
                  variant="subtle"
                  onClick={() => toggleClinicExpansion(clinic.id)}
                  mt="sm"
                >
                  {expandedClinicIds.has(clinic.id)
                    ? 'Hide Onboarding Answers'
                    : 'Show Onboarding Answers'}
                </Button>

                <Collapse in={expandedClinicIds.has(clinic.id)}>
                  <Paper withBorder p="sm" mt="sm" radius="md">
                    <Title order={5} mb="xs">
                      Onboarding Answers
                    </Title>
                    <Grid>
                      {Object.entries(clinic.answers)
                        .filter(
                          ([, value]) =>
                            typeof value !== 'object' || value === null,
                        )
                        .map(([key, value], idx) => (
                          <Grid.Col span={6} key={idx}>
                            <Text>
                              <strong>{formatAnswerKey(key)}:</strong>{' '}
                              {String(value)}
                            </Text>
                          </Grid.Col>
                        ))}
                    </Grid>
                  </Paper>
                </Collapse>
              </>
            )}
            <Grid justify="space-between" mt="md">
              <Grid.Col span={6}>
                <Button
                  disabled={
                    !clinic.emailverification || loadingClinicId === clinic.id
                  }
                  color="green"
                  fullWidth
                  onClick={() => handleAction(clinic.id, true)}
                  loading={
                    loadingClinicId === clinic.id && loadingAction === 'approve'
                  }
                  style={{ marginRight: 10 }}
                >
                  Approve
                </Button>
              </Grid.Col>
              <Grid.Col span={6}>
                <Button
                  color="red"
                  variant="outline"
                  fullWidth
                  onClick={() => handleAction(clinic.id, false)}
                  disabled={loadingClinicId === clinic.id}
                  loading={
                    loadingClinicId === clinic.id && loadingAction === 'deny'
                  }
                >
                  Deny
                </Button>
              </Grid.Col>
            </Grid>
          </Paper>
        ))
      )}
    </Container>
  );
};

export default InsuranceClinicApprovalPage;
