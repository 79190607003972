import React, { useEffect, useRef, useState } from 'react';
import {
  Text,
  TextInput,
  Button,
  Group,
  Grid,
  Paper,
  Select,
  InputBase,
  rem,
} from '@mantine/core';
import { useForm, Controller } from 'react-hook-form';
import { STATES_ARRAY } from '../../types/members.types';
import { loadScript } from '../../helpers/loadScript';
import { IMaskInput } from 'react-imask';

interface PracticeInfoScreenProps {
  practiceInfo: any;
  setPracticeInfo: (info: any) => void;
  next: () => void;
  back: () => void;
  isOwner?: boolean;
}

const PracticeInfoScreen: React.FC<PracticeInfoScreenProps> = ({
  practiceInfo,
  setPracticeInfo,
  next,
  back,
  isOwner = true,
}) => {
  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      practice_name: practiceInfo.practice_name || '',
      practice_street1: practiceInfo.practice_street1 || '',
      practice_street2: practiceInfo.practice_street2 || '',
      practice_city: practiceInfo.practice_city || '',
      practice_state: practiceInfo.practice_state || '',
      practice_zip: practiceInfo.practice_zip || '',
      practice_country: 'US',
      taxid_type: practiceInfo.taxid_type || 'EIN',
      taxid: practiceInfo.tin || '',
      organization_npi: practiceInfo.organization_npi || '',
    },
  });

  const taxid_type = watch('taxid_type');

  const practiceAddressRef = useRef<HTMLInputElement | null>(null);
  const [addressPopulated, setAddressPopulated] = useState(false);

  const handlePlaceSelect = async (
    autocomplete: google.maps.places.Autocomplete,
  ) => {
    if (!autocomplete) return;

    const place = autocomplete.getPlace();
    if (!place.address_components) return;

    setValue('practice_street1', '');
    setValue('practice_street2', '');
    setValue('practice_city', '');
    setValue('practice_state', '');
    setValue('practice_zip', '');
    setValue('practice_country', 'US');

    let streetNumber = '';
    let route = '';
    let street2 = '';
    let city = '';
    let state = '';
    let zipCode = '';

    place.address_components?.forEach((component) => {
      const types = component.types;
      if (types.includes('street_number')) streetNumber = component.long_name;
      if (types.includes('route')) route = component.long_name;
      if (types.includes('subpremise')) street2 = component.long_name;
      if (types.includes('locality')) city = component.long_name;
      else if (types.includes('sublocality')) city = component.long_name;
      if (types.includes('administrative_area_level_1'))
        state = component.short_name;
      if (types.includes('postal_code')) zipCode = component.long_name;
    });

    const street1 = `${streetNumber} ${route}`.trim();
    if (street1) {
      setAddressPopulated(true);
    }

    setValue('practice_street1', street1);
    setValue('practice_street2', street2);
    setValue('practice_city', city);
    setValue('practice_state', state);
    setValue('practice_zip', zipCode);
    setValue('practice_country', 'US');
  };

  const resetAddress = () => {
    setAddressPopulated(false);
    if (practiceAddressRef.current) {
      practiceAddressRef.current.value = '';
    }
  };

  useEffect(() => {
    let practiceAutocomplete: google.maps.places.Autocomplete | null = null;

    const initAutocomplete = () => {
      if (practiceAddressRef.current && window.google) {
        practiceAutocomplete = new window.google.maps.places.Autocomplete(
          practiceAddressRef.current,
          {
            types: ['address'],
            componentRestrictions: { country: 'us' },
          },
        );
        practiceAutocomplete.addListener('place_changed', () =>
          handlePlaceSelect(practiceAutocomplete!),
        );
      }
    };

    const googleMapsScriptUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;

    loadScript(googleMapsScriptUrl)
      .then(initAutocomplete)
      .catch((err) => console.error('Failed to load Google Maps script:', err));

    return () => {
      if (practiceAutocomplete) {
        google.maps.event.clearInstanceListeners(practiceAutocomplete);
      }
    };
  }, []);

  const onSubmit = (data: any) => {
    setPracticeInfo({ ...practiceInfo, ...data });
    next();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text size="xl" mb="md">
        What is your primary practice location or office address?
      </Text>

      <Controller
        name="practice_name"
        control={control}
        rules={{ required: 'Practice Name is required' }}
        render={({ field, fieldState: { error } }) => (
          <TextInput
            {...field}
            label="Full Legal Name of your Practice"
            error={error?.message}
            required
          />
        )}
      />
      <br />

      <Paper withBorder shadow="xs" p="md">
        <TextInput
          label="Address - Start typing and select from dropdown"
          placeholder="Start typing here"
          ref={practiceAddressRef}
          onChange={(e) => {
            if (!e.target.value) {
              resetAddress();
            }
          }}
        />
        <Grid>
          <Grid.Col span={6}>
            <Controller
              name="practice_street1"
              control={control}
              render={({ field }) => (
                <TextInput {...field} label="Street 1" disabled required />
              )}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name="practice_street2"
              control={control}
              render={({ field }) => <TextInput {...field} label="Street 2" />}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name="practice_city"
              control={control}
              render={({ field }) => (
                <TextInput {...field} label="City" disabled required />
              )}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name="practice_state"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label="State"
                  data={STATES_ARRAY.map((state) => ({
                    value: state,
                    label: state,
                  }))}
                  clearable
                  searchable
                  disabled
                  required
                />
              )}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name="practice_zip"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  label="ZIP"
                  maxLength={5}
                  disabled
                  required
                />
              )}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name="practice_country"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  label="Country"
                  data={[{ value: 'US', label: 'United States' }]}
                  clearable
                  searchable
                  disabled
                  required
                />
              )}
            />
          </Grid.Col>
        </Grid>
      </Paper>

      {isOwner && (
        <Grid gutter="md" mt="md">
          <Grid.Col span={6}>
            <Controller
              name="taxid"
              control={control}
              render={({ field }) => (
                <InputBase
                  {...field}
                  label="Tax ID (Select EIN or TIN)"
                  required
                  component={IMaskInput}
                  mask={taxid_type === 'EIN' ? '00-0000000' : '000-00-0000'}
                  placeholder={
                    taxid_type === 'EIN' ? 'XX-XXXXXXX' : 'XXX-XX-XXXX'
                  }
                  leftSectionWidth={95} // make select value appear enough to the right
                  leftSection={
                    <Controller
                      name="taxid_type"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          data={[
                            { value: 'EIN', label: 'EIN' },
                            { value: 'TIN', label: 'TIN' },
                          ]}
                          variant="unstyled"
                          styles={{
                            input: {
                              paddingRight: 0,
                              textAlign: 'center',
                            },
                          }}
                          onChange={(value) => {
                            if (value && value !== taxid_type) {
                              setValue('taxid_type', value, {
                                shouldValidate: true,
                              });
                            }
                          }}
                        />
                      )}
                    />
                  }
                  styles={{
                    input: { paddingLeft: rem(100) }, // avoid text overlap with select
                  }}
                />
              )}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Controller
              name="organization_npi"
              control={control}
              rules={{ required: 'Type 2 (Organization) NPI is required' }}
              render={({ field, fieldState: { error } }) => (
                <TextInput
                  {...field}
                  label="Type 2 (Organization) NPI"
                  error={error?.message}
                  required
                  maxLength={10}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    e.target.value = e.target.value.replace(/\D/g, ''); // Replace any non-numeric characters
                  }}
                />
              )}
            />
          </Grid.Col>
        </Grid>
      )}

      <Group justify="flex-end" mt="xl">
        <Button variant="default" onClick={back}>
          Back
        </Button>
        <Button type="submit">Next</Button>
      </Group>
    </form>
  );
};

export default PracticeInfoScreen;
