import React, { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextInput,
  Button,
  Container,
  Select,
  Grid,
  Paper,
  Title,
  Text,
  PasswordInput,
  Textarea,
  Loader,
} from '@mantine/core';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import PasswordStrengthBar from 'react-password-strength-bar';
import { STATES_ARRAY } from '../../types/members.types';
import { notifications } from '@mantine/notifications';
import useHttpClient from '../hooks/useHttpClient';
import { useNavigate } from 'react-router-dom';
import {
  useOptionalWecareMemberContext,
  OptionalWecareMemberProvider,
} from '../OptionalWecareMemberProvider';
import { loadScript } from '../../helpers/loadScript';

interface PhysicianFormProps {
  initialData?: any;
  onFormSubmit?: (data: any) => void;
  cb?: (data: any, trigger: any, errors: any) => void;
  memberId?: number;
  onboardingFlow?: boolean;
}

export const PhysicianForm: React.FC<PhysicianFormProps> = ({
  initialData,
  onFormSubmit,
  onboardingFlow,
  memberId,
  cb,
}) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    trigger,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    defaultValues: initialData,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  // do not use useFormDirtyAlert here

  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrengthScore, setPasswordStrengthScore] = useState(0);
  const [isMemberLoaded, setIsMemberLoaded] = useState(false); // Track if member data is loaded
  const [wecareExistingUser, setWecareExistingUser] = useState(false); // Track whether to disable fields
  const [professionalIds, setProfessionalIds] = useState([]);
  const fetchMemberDataRef = useRef(false); // Track if fetchMemberData has already run
  const [loading, setLoading] = useState(false);
  const [stateSelectOpened, setStateSelectOpened] = useState(false);

  const httpClient = useHttpClient();
  const navigate = useNavigate();

  const password = watch('password');

  const initialPriorities = [
    { id: '1', label: 'Affordable Costs' },
    {
      id: '2',
      label: 'Ease of use and navigation (doctor and hospital choice)',
    },
    { id: '3', label: 'Billing clarity and transparency' },
  ];

  const [priorities, setPriorities] = useState(initialPriorities);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const updatedPriorities = Array.from(priorities);
    const [reorderedItem] = updatedPriorities.splice(result.source.index, 1);
    updatedPriorities.splice(result.destination.index, 0, reorderedItem);
    setPriorities(updatedPriorities);
  };

  const onSubmit = async (data: any) => {
    if (!wecareExistingUser && passwordStrengthScore < 3) {
      notifications.show({
        title: 'Weak Password',
        message:
          'Please choose a stronger password (at least "Good" strength).',
        color: 'red',
        position: 'bottom-center',
      });
      return;
    }

    const submissionData = {
      ...data,
      email: data.email.toLowerCase(),
      type: 'independent',
      subscriberrelationship: 'Primary',
      priorities: priorities.map((priority) => priority.label),
      address: undefined,
    };

    delete submissionData.passwordConfirmation;

    try {
      setLoading(true);
      const response = await httpClient.post(
        '/api/subscriber/verify',
        submissionData,
      );
      const addUserResult = response.data.addUserResult;

      if (onFormSubmit) {
        onFormSubmit(response.data);
      }

      navigate('/insurance/register/confirmation', {
        state: { addUserResult },
      });
    } catch (error) {
      console.error('Error submitting form', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to submit physician form',
        color: 'red',
        position: 'bottom-center',
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchMemberData = async () => {
    try {
      setLoading(true);
      if (memberId && memberId > 0) {
        // Avoid fetching again if it's already fetched once
        if (fetchMemberDataRef.current) return;
        fetchMemberDataRef.current = true;

        // Fetch member data
        const memberResponse = await httpClient.get(`/api/members/${memberId}`);
        const memberData = memberResponse.data;

        setValue('firstname', memberData.firstname);
        setValue('lastname', memberData.lastname);
        setValue('npi', memberData.npi);
        setValue('email', memberData.email);

        // Fetch professional IDs
        const professionalIdsResponse = await httpClient.get(
          `/api/professionalids/license/${memberId}`,
        );
        const professionalIds = professionalIdsResponse.data;

        if (professionalIds.length > 0) {
          setProfessionalIds(professionalIds);

          // Set initial values only if they haven't been manually set
          const firstProfessionalId = professionalIds[0];
          setValue('licensenumber', firstProfessionalId.idnumber);
          setValue('licensestate', firstProfessionalId.state);
        }

        setWecareExistingUser(true);
        setIsMemberLoaded(true);
      }
    } catch (error) {
      console.log('No member data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleLicenseChange = (value) => {
    const selectedLicense = professionalIds.find((id) => id.idnumber === value);
    if (selectedLicense) {
      setValue('licensestate', selectedLicense.state);
    }
  };

  useEffect(() => {
    if (memberId) {
      if (!onboardingFlow) {
        fetchMemberData();
      }
    } else {
      // Reset fields and enable them if no memberId is present
      reset();
      setWecareExistingUser(false);
      setIsMemberLoaded(false);
    }
  }, [memberId]);

  const addressRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    let autocomplete: google.maps.places.Autocomplete | null = null;

    const handlePlaceSelect = async () => {
      if (!autocomplete) return;

      const place = autocomplete.getPlace();

      // Reset fields before handling the selected place
      setValue('address', '');
      setValue('street1', '');
      setValue('street2', '');
      setValue('city', '');
      setValue('state', '');
      setValue('zip', '');
      setValue('county', '');

      if (!place.address_components) {
        alert('Please select a complete address with a street number.');
        return;
      }

      let hasStreetNumber = false;
      place.address_components.forEach((component) => {
        if (component.types.includes('street_number')) {
          hasStreetNumber = true;
        }
      });

      if (!hasStreetNumber) {
        alert('Please select an address that includes a street number.');
        return;
      }

      let streetNumber = '';
      let route = '';
      let street2 = '';
      let city = '';
      let state = '';
      let zipCode = '';
      let county = '';

      place.address_components.forEach((component) => {
        const types = component.types;
        if (types.includes('street_number')) streetNumber = component.long_name;
        if (types.includes('route')) route = component.long_name;
        if (types.includes('subpremise')) street2 = component.long_name;
        if (types.includes('locality')) city = component.long_name;
        else if (types.includes('sublocality')) city = component.long_name;
        if (types.includes('administrative_area_level_1'))
          state = component.short_name;
        if (types.includes('postal_code')) zipCode = component.long_name;
        if (types.includes('administrative_area_level_2'))
          county = component.long_name.replace(' County', '');
      });

      const street1Value = `${streetNumber} ${route}`.trim();

      // Update the form fields and manually trigger validation only for address fields
      setValue('street1', street1Value, { shouldDirty: true });
      setValue('street2', street2, { shouldDirty: true });
      setValue('city', city, { shouldDirty: true });
      setValue('state', state, { shouldDirty: true });
      setValue('zip', zipCode, { shouldDirty: true });
      setValue('county', county, { shouldDirty: true });

      // Only trigger validation for address-related fields
      await trigger(['street1', 'street2', 'city', 'state', 'zip', 'county']);
    };

    const initAutocomplete = () => {
      if (addressRef.current && window.google) {
        autocomplete = new window.google.maps.places.Autocomplete(
          addressRef.current,
          {
            types: ['address'],
            componentRestrictions: { country: 'us' },
          },
        );
        autocomplete.addListener('place_changed', handlePlaceSelect);
      }
    };

    const googleMapsScriptUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
    loadScript(googleMapsScriptUrl)
      .then(initAutocomplete)
      .catch((err) => console.error('Failed to load Google Maps script:', err));

    return () => {
      if (autocomplete) {
        google.maps.event.clearInstanceListeners(autocomplete);
      }
    };
  }, [setValue, trigger]);

  const formData = watch();
  useEffect(() => {
    if (cb)
      cb(
        {
          ...formData,
          email: formData.email?.toLowerCase(),
          type: 'independent',
          subscriberrelationship: 'Primary',
          priorities: priorities.map((priority) => priority.label),
          passwordConfirmation: undefined,
          address: undefined,
        },
        trigger,
        errors,
      );
  }, [cb, formData, errors]);

  return (
    <Container>
      {!onboardingFlow && (
        <>
          <Title
            order={1}
            style={{ textAlign: 'center', marginBottom: '0.5rem' }}
          >
            Help Us Verify Your Eligibility.
          </Title>
          <Title
            order={4}
            style={{ textAlign: 'center', marginBottom: '2rem' }}
          >
            To create your account, we need a few details to confirm you're a
            physician—this helps us ensure a secure and professional community.
          </Title>
        </>
      )}

      {isMemberLoaded || !memberId ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        >
          {memberId && (
            <Text mt="xs" size="sm" color="red">
              Your information was prepopulated from your Wecare account where
              available. You do not need to create another account
            </Text>
          )}

          <Paper withBorder shadow="xs" p="md" mt="md">
            <Title order={3} mb="sm">
              Personal Information
            </Title>
            <Grid>
              <Grid.Col span={6}>
                <TextInput
                  label="First Name"
                  required
                  disabled={wecareExistingUser || loading}
                  {...register('firstname', { required: true })}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label="Last Name"
                  required
                  disabled={wecareExistingUser || loading}
                  {...register('lastname', { required: true })}
                />
              </Grid.Col>
            </Grid>
            {wecareExistingUser ? (
              // Grid component for existing WeCare users with enabled, non-clearable selects
              <Grid>
                <Grid.Col span={6}>
                  <Controller
                    name="licensenumber"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Medical License Number"
                        placeholder="Select a license"
                        data={professionalIds.map((id) => ({
                          value: id.idnumber,
                          label: id.idnumber,
                        }))}
                        onChange={(value) => {
                          field.onChange(value);
                          handleLicenseChange(value);
                        }}
                        required
                      />
                    )}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="licensestate"
                    control={control}
                    rules={{ required: 'State is required' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        label="Medical License Ѕtate"
                        data={STATES_ARRAY.map((state) => ({
                          value: state,
                          label: state,
                        }))}
                        clearable={false}
                        disabled
                        searchable
                        required
                      />
                    )}
                  />
                </Grid.Col>
              </Grid>
            ) : (
              // Original Grid component for new users with clearable, disabled selects
              <Grid>
                <Grid.Col span={6}>
                  <TextInput
                    label="Medical License Number"
                    required
                    {...register('licensenumber', { required: true })}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <Controller
                    name="licensestate"
                    control={control}
                    rules={{ required: 'State is required' }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        autoComplete={Math.random().toString()}
                        label="Medical License State"
                        data={STATES_ARRAY.map((state) => ({
                          value: state,
                          label: state,
                        }))}
                        onInput={(e) => {
                          const elem = e.target as HTMLInputElement;
                          setValue('licensestate', elem.value);
                          setTimeout(() => {
                            setStateSelectOpened(false);
                          });
                        }}
                        dropdownOpened={stateSelectOpened}
                        onDropdownOpen={() => setStateSelectOpened(true)}
                        onDropdownClose={() => setStateSelectOpened(false)}
                        clearable
                        searchable
                        required
                      />
                    )}
                  />
                </Grid.Col>
              </Grid>
            )}
            <Grid>
              <Grid.Col span={12}>
                <TextInput
                  label="NPI"
                  required
                  maxLength={10}
                  disabled={wecareExistingUser || loading}
                  {...register('npi', { required: true })}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    e.target.value = e.target.value.replace(/\D/g, ''); // Replace any non-numeric characters
                  }}
                />
              </Grid.Col>
            </Grid>
          </Paper>

          <Paper withBorder shadow="xs" p="md" mt="md">
            <Title order={3}>Address Information</Title>

            <Grid>
              <Grid.Col span={12}>
                <TextInput
                  label={
                    '\u0410ddress - Start typing your address below and pick one from the dropdown'
                  }
                  ref={addressRef}
                  placeholder="Start by typing here"
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label={'\u0405treet 1'}
                  {...register('street1', {
                    required: 'This field is required.',
                  })}
                  disabled
                  required
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label={'\u0405treet 2'}
                  {...register('street2', {})}
                  disabled={loading}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label="City"
                  {...register('city', {
                    required: 'This field is required.',
                  })}
                  disabled
                  readOnly
                  required
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <Controller
                  name="state"
                  control={control}
                  rules={{
                    required: 'Please select a state',
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      label="State"
                      data={STATES_ARRAY.map((state) => ({
                        value: state,
                        label: state,
                      }))}
                      clearable
                      required
                      disabled
                      // {...register('state', {
                      //   required: 'This field is required.',
                      // })}
                      searchable
                      error={errors.state?.message?.toString()}
                    />
                  )}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label="ZIP"
                  required
                  disabled
                  readOnly
                  maxLength={5}
                  {...register('zip', {
                    required: 'This field is required.',
                  })}
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <TextInput
                  label="County"
                  required
                  disabled
                  readOnly
                  {...register('county', {
                    required: 'This field is required.',
                  })}
                />
              </Grid.Col>
            </Grid>
          </Paper>

          <Paper withBorder shadow="xs" p="md" mt="md">
            <Title order={3} mb="sm">
              Login Information
            </Title>
            <Text mt="xs" size="sm">
              This information will be used to create your account. Please
              choose a password of at least "Good" strength.
            </Text>
            <Grid>
              <Grid.Col span={12}>
                <TextInput
                  label="Email"
                  required
                  disabled={wecareExistingUser || loading}
                  {...register('email', { required: true })}
                />
              </Grid.Col>
            </Grid>
            {/* Skip password section if user exists */}
            {!wecareExistingUser && (
              <Grid>
                <Grid.Col span={6}>
                  <PasswordInput
                    label="Password"
                    required
                    disabled={loading}
                    visible={showPassword}
                    onVisibilityChange={setShowPassword}
                    maxLength={50}
                    {...register('password', { required: true })}
                  />
                  <PasswordStrengthBar
                    password={password}
                    onChangeScore={(score) => setPasswordStrengthScore(score)}
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <PasswordInput
                    label="Confirm Password"
                    required
                    disabled={loading}
                    visible={showPassword}
                    onVisibilityChange={setShowPassword}
                    {...register('passwordConfirmation', {
                      required: 'Password confirmation is required',
                      validate: (value) =>
                        value === password || 'Passwords do not match',
                    })}
                    error={errors.passwordConfirmation?.message?.toString()}
                  />
                </Grid.Col>
              </Grid>
            )}
          </Paper>

          <Paper withBorder shadow="xs" p="md" mt="md">
            <Title order={3} mb="sm">
              Additional Information
            </Title>
            <Grid>
              <Grid.Col span={6}>
                <Controller
                  name="referralsource"
                  control={control}
                  rules={{ required: 'Please select how you heard about us' }}
                  render={({ field, fieldState: { error } }) => (
                    <Select
                      {...field}
                      label="How did you hear about us?"
                      placeholder="Select an option"
                      error={error?.message}
                      data={[
                        { value: 'RPPA', label: 'RPPA' },
                        { value: 'Wecare Event', label: 'Wecare Event' },
                        { value: 'MSSNY', label: 'MSSNY' },
                        { value: 'ACP', label: 'ACP' },
                        { value: 'PIMD', label: 'PIMD' },
                        { value: 'Other', label: 'Other (Please specify)' },
                      ]}
                      clearable
                      required
                      disabled={loading}
                    />
                  )}
                />
              </Grid.Col>

              {watch('referralsource') === 'Other' && (
                <Grid.Col span={6}>
                  <TextInput
                    label="Please specify"
                    placeholder="Enter the source"
                    {...register('referralsourcecustom', { required: true })}
                    required
                    disabled={loading}
                  />
                </Grid.Col>
              )}
            </Grid>

            {/* Health Insurance Priorities */}
            <Text mt="xs" size="sm" fw={500}>
              {' '}
              {/* Set weight to match other labels */}
              What is the Most Important to You About Your Health Insurance?
            </Text>
            <Text mt="xs" size="sm">
              {' '}
              Drag and drop to rank order your priorities
            </Text>
            <Grid>
              <Grid.Col span={12}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="priorities" isDropDisabled={loading}>
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {priorities.map((priority, index) => (
                          <Draggable
                            key={priority.id}
                            draggableId={priority.id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  padding: '8px',
                                  margin: '4px 0',
                                  backgroundColor: '#f1f1f1',
                                  borderRadius: '4px',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  cursor: 'grab', // Set grab cursor by default
                                  userSelect: 'none', // Prevent text selection
                                  ...provided.draggableProps.style,
                                }}
                              >
                                <span>{priority.label}</span>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Grid.Col>
            </Grid>
          </Paper>

          <Paper withBorder shadow="xs" p="md" mt="md">
            <Title order={3}>Questions or Comments</Title>
            <Text mt="xs" size="sm">
              If you have any questions, comments, concerns about your
              registration or Ryze Health, or simply want to share your
              thoughts, feel free to leave us a message below.
            </Text>
            <Textarea
              placeholder="Please write any questions or comments you have here..."
              minRows={4}
              disabled={loading}
              {...register('message')}
            />
          </Paper>

          {!cb && (
            <Button type="submit" mt="md" disabled={loading}>
              {loading ? (
                <>
                  <Loader color="blue" size="xs" mr="xs" />
                  Loading...
                </>
              ) : (
                'Submit'
              )}
            </Button>
          )}
        </form>
      ) : (
        <Text>Loading member data...</Text>
      )}
    </Container>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const WrappedPhysicianForm = () => {
    const { memberId } = useOptionalWecareMemberContext();
    return (
      <Container mt="xl" mb="xl">
        <PhysicianForm memberId={memberId} />
      </Container>
    );
  };
  return (
    <OptionalWecareMemberProvider>
      <WrappedPhysicianForm />
    </OptionalWecareMemberProvider>
  );
};
